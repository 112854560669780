export default {
    data: () => ({
        //this is the select values mapped to a human readable presentable value
        actionTypeSelectOptions: [
            { option: 'Do Nothing', value: 'Disabled' },
            { option: 'Dial-by-name Directory', value: 'Directory' },
            { option: 'Transfer', value: 'Transfer' },
            { option: 'Transfer to E.164 number', value: 'TransferE164' },
           // { option: 'Transfer to Voicemail', value: 'Voicemail' },
            { option: 'Prompt for Extension #', value: 'Extension' },
            /*
                The DirectDial option throws an error when being set for the Not Active transition.
                Also it seems from testing that direct dial is the same as Extension just without the option to play the prompt.
                We have not be able to determine a difference between Extension and DirectDial so this option has been remove.
                Note initial development use this options so there might be parts of code that still reference it.
                If so they should be deleted.
             */
            //{ option: 'Dial Extension directly', value: 'DirectDial' },
            { option: 'Disconnect call', value: 'DisconnectCall' },
            { option: 'Return Auto Attendant', value: 'Menu' },
            { option: 'Add to Call Queue', value: 'Queue' },
        ],
        transitionEventSelectOptions: [
            { option: '0', value: '0'},
            { option: '1', value: '1'},
            { option: '2', value: '2'},
            { option: '3', value: '3'},
            { option: '4', value: '4'},
            { option: '5', value: '5'},
            { option: '6', value: '6'},
            { option: '7', value: '7'},
            { option: '8', value: '8'},
            { option: '9', value: '9'},
            { option: '*', value: '*'},
            { option: '#', value: '#'},
            { option: 'Fax', value: 'f'},
            { option: 'No Input', value: 'Timeout'},
        ],
        //validPromptAudioTypes:"audio/*",
        validPromptAudioTypes:".mp3,.wav,audio/mpeg,audio/wav",
    }),
    methods: {
        actionValueToDisplay(action){
            let ret = this.actionTypeSelectOptions.find(c => c.value == action);
            if(!ret){
                return 'Do Nothing';
            }else{
                return ret.option;
            }
        },

        formatTransitionDataToSave(values){
            if(values.announce_ext_numbers === true){
                values.announce_ext_numbers = 'Y';
            }else{
                values.announce_ext_numbers = 'N';
            }

            if(values.action == 'Disabled') {
                values.play_prompt = 'N';
            }else{
                if (values.play_prompt === true) {
                    values.play_prompt = 'Y';
                }
                if (values.play_prompt === false) {
                    values.play_prompt = 'N';
                }
            }

            return values;
        },

        processTransitionDataToSave(formData, formDataLabel, values){
            formData.append("prompt_audio_file", values.prompt_audio_file);
            delete values.prompt_audio_file;

            formData.append(formDataLabel, JSON.stringify(this.formatTransitionDataToSave(values)));

            return formData;
        },

        processDataToSave(form){
            let working = JSON.parse(JSON.stringify(form));

            if(working.direct_dial_enabled != 'Y'){
                working.direct_dial_enabled = 'N';
            }
            let params = new FormData();
            for (let prop in working) {
                if (Object.prototype.hasOwnProperty.call(working, prop)) {
                    params.append(prop, working[prop]);
                }
            }

            if(working.active_toggle == 'always'){
                params.delete('period');
            }else{
                let period = working.period;
                let paramsPeriod = '';
                let paramsPeriodDesc = '';

                params.delete('period');

                period.forEach(e => {
                    paramsPeriod += (paramsPeriod ? ', ' : '') + e.period;
                    paramsPeriodDesc += (paramsPeriodDesc ? ' or ' : '') + e.description;
                });
                params.append("period", paramsPeriod);
                params.append("period_desc", paramsPeriodDesc);
            }
            params.delete('active_toggle');
            params.delete('actionValues');

            //note we must pull the action values from the raw form. Not the 'working' version
            let actionValues = form.actionValues;
            actionValues.event = 'Not Active';

            params = this.processTransitionDataToSave(params,'actionValues',actionValues);
            return params;
        },
    },

};
<template>
  <v-row dense>
    <v-col cols="12" sm="6" lg="4">
      <CallQueueCard v-if="callQueueData" :callQueueData="callQueueData" :loading="loading" />
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <CallQueueSettingsCard v-if="callQueueData" :callQueueData="callQueueData" :loading="loading" />
    </v-col>
    <v-col cols="12" sm="6" lg="4">
      <CallQueueSubscriptionCard v-if="callQueueData" :callQueueData="callQueueData" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import CallQueueCard from "../../components/cards/CallQueues/CallQueueCard";
import CallQueueSettingsCard from "../../components/cards/CallQueues/CallQueueSettingsCard";
import CallQueueSubscriptionCard from "../../components/cards/CallQueues/CallQueueSubscriptionCard";
export default {
    name: 'CallQueueSummary',
    data: () => ({
        busy:false,
        dialogs: {
            newDID: false,
        },
        changeAddressDialog:false,
        extensionRingGroupsResponse:null
    }),
    components: {
      CallQueueSubscriptionCard,
      CallQueueCard,CallQueueSettingsCard
    },
    computed: {
        callQueueData() {
          if(this.$store.state.data['call-queue'] && this.$store.state.data['call-queue'].i_c_queue == parseInt(this.$route.params.callQueueId)){
            return this.$store.state.data['call-queue'];
          }
          return null;
        },
        loading() {
            return this.fetchStoreLoadingByLabel('call-queue') || this.busy;
        },
    },

  methods: {

  },

};
</script>

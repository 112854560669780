<template>
  <ComplexDialog
      :title="'Edit '+data.account_type_label"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
    <v-text-field v-model="form.note" :label="data.account_type_label+' Description/Note'" outlined clearable counter="41" :rules="[
            (v) =>
                (!v || v.length <= 41)  ||
                'Description/Note must be 41 characters or less',

        ]"/>
    <v-text-field v-model="form.cont1" label="Name / Billing Reference" outlined clearable counter="120" :rules="[
          (v) =>
              (!v || v.length <= 120) ||
              'Description/Note must be 120 characters or less',
      ]"/>
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditTrunkDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      note:null,
      cont1:null,
    },
    busy: false,
    isValid: false,
  }),
  methods: {
    async update() {
      this.busy = true;
      const response = await this.Api.send('put', 'trunks/' + this.data.i_account, this.form);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    data: {
      type: Object,
      default: null
    }
  },
  created(){
    if(this.data.i_account) {
      this.form.note = this.data.note;
      this.form.cont1 = this.data.cont1;
    }
  },
  watch: {
    iAccount(value) {
      if(value != null){
        this.form.note = this.data.note;
        this.form.cont1 = this.data.cont1;
      }
    },
  },
  computed:{
    iAccount: function( ){
      if(this.data.i_account) {
        return this.data.i_account;
      }else{
        return null;
      }
    }
  }
};
</script>
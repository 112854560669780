<template>
  <v-form v-model="valid">
    <v-row dense>
      <v-col cols="12">
        <v-text-field autofocus outlined label="Email Address to send Faxes" v-model="form.email" :rules="rules.email" />
      </v-col>
      <v-col cols="12">
        <SwitchYesNoInput v-model="form.keepInboxFax" label="Retain copy of fax in the fax inbox" class="mx-2 my-1" hide-details />
      </v-col>
      <v-col cols="12" v-if="dids.length > 1">
        <SwitchYesNoInput v-model="form.wholeTrunk" label="All trunk numbers are used for fax" class="mx-2 my-1" hide-details />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SwitchYesNoInput from '@/components/pieces/SwitchYesNoInput';
export default {
  name: 'TrunkFax2MailSettingsForm',
  components: { SwitchYesNoInput },
  props: {
    data: {
      type: Object,
      default: null
    },
    dids:{
      type: Array,
      default: function( ){
        return [];
      },
    },
    value:{
      type:Object,
      default: function( ){
        return {};
      }
    }
  },
  data: () => ({
    valid: false,
    form:{
      email:null,
      keepInboxFax: 'Y',
      wholeTrunk: 'Y'
    },
    rules: {
      required:[
        (v) => !!v || 'Required',
      ],
      email: [
        (v) => !!v || 'Required',
        (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      ],
    }
  }),

  watch: {

    valid(val) {
      if (val) {
        this.$emit('input', this.form);
      } else {
        this.$emit('input', null);
      }
    },

    form:{
      deep: true,
      handler:function(val){
        this.$emit('input', this.valid ? val : null);
      }
    }
  },

  created() {
    if(this.value) {
      this.form = this.value;
    }
  }

};
</script>

<style scoped>

</style>
<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">Customer</v-chip>
            <v-icon right x-large>mdi-account</v-icon>
        </template>
        <p class="title text--primary" v-if="data.name">{{ data.name }}</p>

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Account No</span>
            </v-col>
            <v-col cols="7">
                {{ data.i_customer }}
            </v-col>
          <v-col cols="5">
            <span class="font-weight-bold">Customer Account Type</span>
          </v-col>
          <v-col cols="7">
            {{ data.customer_class_label }}
            <IconWithTooltip
                right
                v-if="data.i_customer_class_label == 'Unknown'"
                tooltip="Non-standard customer class in use"
                small
            />
          </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Company</span>
            </v-col>
            <v-col cols="7">
                <IconWithTooltip
                    v-if="!data.companyname"
                    tooltip="No company name"
                    small
                />
                {{ data.companyname }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Created</span>
            </v-col>
            <v-col cols="7">
                <LocalDateTime :value="data.creation_date" />
            </v-col>
        </v-row>
        <template v-slot:actions v-if="data.bill_status !== 'C'">
            <ButtonWithTooltip
              text="SMS"
              flat
              tooltip="Send SMS to customer"
              icon="mdi-send"
              @click="emitSendCustomerSMS();"
            />
            <ButtonWithTooltip
                text="Edit"
                flat
                tooltip="Edit Customer Details"
                icon="mdi-pencil"
                @click="showEditContactDetailsDialog = true"
            />
            <ButtonWithTooltip
                text="Delete"
                flat
                tooltip="Terminate Customer"
                icon="mdi-delete"
                @click="showTerminateDialog = true"
            />
          <EditContactDetailsDialog
              v-model="showEditContactDetailsDialog"
              :customerData="data"
          />

            <TerminateCustomerDialog
                v-model="showTerminateDialog"
                :data="data"
            />

        </template>
    </BasicCard>
</template>

<script>
//import Alert from '../../pieces/Alert';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TerminateCustomerDialog from '../../dialogs/Customer/TerminateCustomerDialog';
import EditContactDetailsDialog from "../../dialogs/Customer/EditContactDetailsDialog";
export default {
    name: 'CustomerCard',
    components: {
        TerminateCustomerDialog,
        ButtonWithTooltip,
        LocalDateTime,
        BasicCard,
        IconWithTooltip,
        EditContactDetailsDialog,
    },
    props: ['data', 'loading'],
    data() {
        return {
            showTerminateDialog: false,
            showEditContactDetailsDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
        async emitSendCustomerSMS(){
          document.dispatchEvent(new CustomEvent('sendCustomerSMS'));
        },
    },
};
</script>

<template>
    <ComplexDialog
        :title="title"
        :max-width="850"
        :loading="busy"
        v-model="visible"
        :close-confirmation="!!card_info"
        :step="step"
        :step-next-text="step===3 ? (error_message ? 'Retry Saving Card' : 'Save Card') : 'Next'"
        :step-next-disabled="step===1 && !card_info || step===2 && !address"
        :step-end="4"
        @step-end="close"
        step-end-text="Finish"
        @step-back="step--"
        @step-next="next"
        @hide="close"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <v-alert v-if="data && !!data.payment_method" text outlined type="warning">
                    The existing payment method ({{data.payment_method.payment_method}} {{data.payment_method.number}}) will be replaced.
                </v-alert>
                <p class="subtitle-1 text--primary">Please enter the credit card details below</p>
                <CreditCardDetailsForm v-model="card_info" />
                <span class="text--disabled">Credit Card information is saved with our payment processor. Breeze Connect retains only the first/last 4 digits and expiry of the card.</span>
            </v-window-item>
            <v-window-item :value="2">
                <p class="subtitle-1 text--primary">What is the billing address for this credit card?</p>
                <ExistingAddressDetailsForm
                    :customer-id="data.i_customer"
                    v-model="address"
                />
                <p class="text--disabled">Please note: This address may be used for validation purposes by the card issuer.</p>
            </v-window-item>
            <v-window-item :value="3">
                <v-alert v-if="error_message" outlined type="error" transition="expand-transition">{{error_message}}</v-alert>
                <p class="subtitle-1 text--primary">Please review the details below to ensure they are correct.</p>
                <p>Please note: An authorization for $0.01 will be submitted to validate the card.</p>
                <v-sheet class="mt-2 pa-4" color="background_accent" rounded>
                    <v-row dense v-if="card_info && address">
                        <v-col cols="4" class="font-weight-bold">
                            Credit Card Number/Expiry
                        </v-col>
                        <v-col cols="8">
                            {{card_info.number.slice(0, 4)}}xxxxxxxx{{card_info.number.slice(card_info.number.length - 4)}} (expires {{ card_info.exp_date | luxon('MM/yyyy') }})
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Cardholder Name
                        </v-col>
                        <v-col cols="8">
                            {{card_info.name}}
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Cardholder Address
                        </v-col>
                        <v-col cols="8">
                            <AddressText :data="address" />
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-checkbox
                    v-model="auto_payments"
                    label="Enable automatic payments with this card"
                />
                <v-expand-transition>
                    <v-alert v-if="auto_payments" text type="info">
                        Invoices will be automatically charged to this card on the due date each month.
                    </v-alert>
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="4">
                <v-row no-gutters>
                    <v-col cols="12" class="text-center py-4">
                        <v-icon size="120" color="success"
                        >mdi-check-circle-outline</v-icon
                        >
                        <p class="text-h6">Credit card saved successfully</p>
                    </v-col>
                </v-row>
            </v-window-item>

        </v-window>

        <!-- auto payments checkbox -->

    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import CreditCardDetailsForm from '../../pieces/CreditCardDetailsForm';
import apiMixin from '../../../mixins/apiMixin';
import ExistingAddressDetailsForm from '../../pieces/Forms/ExistingAddressDetailsForm';
import AddressText from '../../pieces/Address/AddressText';
export default {
    name: 'AddCreditCardDialog',
    mixins: [apiMixin, dialogMixin],
    components: { AddressText, ExistingAddressDetailsForm, CreditCardDetailsForm, ComplexDialog },
    data: () => ({
        step: 1,
        card_info: null,
        auto_payments: false,
        busy: false,
        address: null,
        error_message: null
    }),
    methods: {
        async submit() {
            this.busy = true;
            const response = await this.Api.send('post','customers/' + this.data.i_customer + '/add_payment_method', {card_info: this.card_info, address_info: this.address, auto_payments: this.auto_payments ? 1 : 0}, false);
            if (response.success) {
                this.step++;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                this.error_message = response.data && response.data.description ? response.data.description : "An unknown error occurred while trying to process the card authorization. Please try again later.";
            }
            this.busy = false;
        },
        close() {
            if (this.visible) this.visible = false;
            this.address = null;
            this.card_info = null;
            this.error_message = null;
            this.step = 1;
        },
        next() {
            if (this.step < 3) {
                this.step++
            } else if (this.step === 3) {
                this.submit();
            } else if (this.step === 4) {
                this.visible = false;
            }
        }
    },
    props: {
        repId: {
            default: null,
        },
        title: {
            type: String,
            default: 'Save New Payment Method'
        },
        data: {
            type: Object,
            default: null
        }
    },
};
</script>
<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        title="Payment Method"
    >
        <div v-if="!!data.payment_method">
            <div class="d-flex flex-row justify-center">
                <div>
                    <CreditCard
                        :number="data.payment_method.number"
                        :expiry="data.payment_method.exp_date"
                        :chip="'Automatic Payments ' + (data.bp_charge_cc==='N' ? 'Disabled' : 'Enabled')"
                        :chip-color="data.bp_charge_cc==='N' ? null : 'success'"
                    />
                </div>
            </div>
            <v-expand-transition>
                <v-alert v-if="data.bp_charge_cc!=='N'" text type="info" class="mt-4 mb-0">
                    Invoices will be automatically charged to this card on the due date.
                </v-alert>
            </v-expand-transition>
        </div>
        <div v-else>
            <p class="text-h6">None</p>
        </div>


        <template v-slot:actions>
            <ButtonWithTooltip
                id="autoPayButton"
                v-if="!!data.payment_method"
                :icon="data.bp_charge_cc==='N' ? 'mdi-check' : 'mdi-close'"
                :text="(data.bp_charge_cc==='N' ? 'Enable' : 'Disable') + ' Autopay'"
                flat
                @click="autoPayDialog=true"
                :disabled="data.status == 'closed'"
            />
            <ButtonWithTooltip
                id="addCardButton"
                :icon="!!data.payment_method ? 'mdi-pencil' : 'mdi-plus'"
                :text="!!data.payment_method ? 'Edit' : 'Add'"
                flat
                @click="addCardDialog=true"
                :disabled="data.status == 'closed'"
            />
            <ButtonWithTooltip
                v-if="!!data.payment_method"
                id="deleteCardButton"
                icon="mdi-delete"
                text="Delete"
                color="error"
                flat
                @click="deleteCardDialog=true"
                :disabled="data.status == 'closed'"
            />
        </template>
        <CustomerAutoPayDialog v-if="!!data.payment_method" v-model="autoPayDialog" :data="data" />
        <AddCreditCardDialog v-model="addCardDialog" :data="data" />
        <DeleteCreditCardDialog v-if="!!data.payment_method" v-model="deleteCardDialog" :data="data" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import CreditCard from '../../pieces/CreditCard';
import AddCreditCardDialog from '../../dialogs/Customer/AddCreditCardDialog';
import DeleteCreditCardDialog from '../../dialogs/Customer/DeleteCreditCardDialog';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CustomerAutoPayDialog from '../../dialogs/Customer/CustomerAutoPayDialog';

export default {
    name: 'CustomerPaymentInfoCard',
    components: { CustomerAutoPayDialog, ButtonWithTooltip, DeleteCreditCardDialog, AddCreditCardDialog, CreditCard, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            addCardDialog: false,
            deleteCardDialog: false,
            autoPayDialog: false,
            valueFields: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: ''
            },
        };
    },
    filters: {
        upper(value) {
            return value.toString().toUpperCase();
        },
        expiry(value) {
            return value.toString().toUpperCase();
        },
        card(value) {
            return value.toString().replaceAll(/(.{4})/g, '$1     ');
        },
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>

<template>
  <v-text-field
      v-model="input"
      :dense="dense"
      :outlined="outlined"
      :hide-details="hideDetails"
      :label="label"
      :hint="hint"
      :counter="64"
      class="password-input"
      readonly
  >
    <template v-slot:append>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs}">
          <v-btn
            v-on="on"
            v-bind="attrs"
            icon
            class="action-button"
            @click="generateTrunkID"
          >
            <v-icon v-html="'mdi-refresh'" />
          </v-btn>
        </template>
        <span>Generate new Trunk ID</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs}">
          <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              class="action-button"
              @click="numeric=!numeric"
          >
            <v-icon v-html="numeric ? $vuetify.icons.values.letters : $vuetify.icons.values.numbers" />
          </v-btn>
        </template>
        <span v-text="'Switch to ' + (numeric ? 'alphanumeric' : 'numbers only')" />
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import { mdiNumeric, mdiAlphabeticalVariant } from '@mdi/js';

export default {
    name: 'TrunkIDField',
    data: () => ({
        input: null,
        numeric: false
    }),
    props: {
        value: {
            type: String,
            default: null,
        },
      length: {
        type: Number,
        default: 7
      },
      outlined: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: null
      },
      hint: {
        type: String,
        default: null
      },
    },
  methods: {
    generateTrunkID() {
      var charset = this.numeric ? "0123456789" : "abcdefghjkmnpqrstuvwxyz23456789";
      var id = '';
      do{
        id = this.numeric ? "83" : "";
        for (var i = 0, n = charset.length; i < (this.numeric ? this.length - 2 : this.length); ++i) {
          id += charset.charAt(Math.floor(Math.random() * n));
        }
      } while (id.charAt(0) === 'b' && id.charAt(1) === 'c')

      this.input = id;
    }
  },
  watch: {
      input(val) {
        if (this.value!==val) this.$emit('input', val);
      },
      value(val) {
        if (this.input!==val) this.input = val;
      },
    numeric() {
      this.generateTrunkID();
    }
  },
  created() {
      if (this.value) {
        this.input = this.value;
      } else {
        this.generateTrunkID();
      };

  }
};
</script>

<style scoped>
.v-input .action-button {
  margin-top: -6px;
}

</style>
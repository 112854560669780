<template>
  <BasicCard
      :loading="isBusy"
      title="General Settings"

  >
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Ref ID</span>
      </v-col>
      <v-col cols="7">
        {{ aaData.i_menu }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Name</span>
      </v-col>
      <v-col cols="7">
        {{ aaData.name }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Active Period</span>
      </v-col>
      <v-col cols="7">
        {{ aaData.period_desc }}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Dial a known extension directly</span>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="aaData.direct_dial_enabled == 'Y'"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>
      </v-col>
    </v-row>

    <v-row dense v-if="aaData.direct_dial_enabled == 'Y'">
      <v-col cols="5">
        <span class="font-weight-bold">Interdigit timeout</span>
      </v-col>
      <v-col cols="7">
        {{ aaData.next_digit_timeout }}
      </v-col>
    </v-row>
<br />
    <v-card
        outlined
    >
      <v-card-text>
        <p>Inactive settings</p>
        <AutoAttendantActionFormDisplay v-if="aaData.inactive_auto_account_transition" :trunkData="trunkData" :aaData="aaData" :aatData="aaData.inactive_auto_account_transition" />
      </v-card-text>
    </v-card>

    <template v-slot:actions>
      <ButtonWithTooltip
          text="Targeted Actions"
          flat
          tooltip="List actions that are pointing to this Auto Attendant"
          icon="mdi-format-list-bulleted"
          @click="showTargetListDialog = true"
      />
      <ButtonWithTooltip
          text="Edit"
          flat
          tooltip="Edit general settings"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />

      <v-tooltip :small="true" bottom open-delay="200" v-if="aaData.name == 'ROOT'">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn :text="true" disabled>
              <v-icon
                  :small="true"
                  :left="true"
              >mdi-delete</v-icon>Delete
            </v-btn>
          </div>
        </template>
        <span>Not possible to delete ROOT transition</span>
      </v-tooltip>

      <ButtonWithTooltip
          v-if="aaData.name != 'ROOT'"
          text="Delete"
          flat
          tooltip="Delete Auto Attendant"
          icon="mdi-delete"
          @click="showDeleteDialog = true"
          color="error"
      />
    </template>
  <AutoAttendantEditGeneralSettingsDialog
      :loading="isBusy" :trunkData="trunkData" :aaData="aaData" v-model="showEditDialog"
    />

    <AutoAttendantDeleteDialog v-if="aaData.i_menu" :loading="isBusy" :trunkData="trunkData" :aaData="aaData" v-model="showDeleteDialog"/>
    <AutoAttendantTargetedTransitionDialog :loading="isBusy" :trunkData="trunkData" :aaData="aaData" v-model="showTargetListDialog"/>

  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import AutoAttendantEditGeneralSettingsDialog
  from "../../dialogs/AutoAttendants/AutoAttendantEditGeneralSettingsDialog";
import AutoAttendantActionFormDisplay from "../../pieces/AutoAttendant/AutoAttendantActionFormDisplay";
import AutoAttendantTransitionDeleteDialog from "../../dialogs/AutoAttendants/AutoAttendantDeleteDialog";
import AutoAttendantDeleteDialog from "../../dialogs/AutoAttendants/AutoAttendantDeleteDialog";
import AutoAttendantTargetedTransitionDialog from "../../dialogs/AutoAttendants/AutoAttendantTargetedTransitionsDialog";
export default {
    name: 'AutoAttendantGeneralSettingsCard',
    mixins: [apiMixin, paginationComponentMixin],
    data: () => ({
      busy:false,
      showEditDialog:false,
      showDeleteDialog:false,
      showTargetListDialog:false,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
    }),
    components: {
      AutoAttendantTargetedTransitionDialog,
      AutoAttendantDeleteDialog,
      AutoAttendantActionFormDisplay,
      AutoAttendantEditGeneralSettingsDialog,
      ButtonWithTooltip,
      BasicCard,
    },
    props: {
        trunkData: {
            type: Object,
            required:true
        },
        aaData: {
          type: Object,
          required:true
        },
      loading: {
        type: Boolean,
        default:false,
      },
    },
    methods: {
      getInactiveActionLabel(label){
        if(!label){
          return 'Do Nothing';
        }
        let map = {
          "Disabled":"Do Nothing"
        }
        if(map[label]){
          return map[label];
        }else{
          return label;
        }
      },
      downloadFile( )
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+this.aaData.inactive_auto_account_transition.i_menu_transition+'/prompt-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },
    },
    computed:{
      isBusy( ){
        return this.loading || this.busy;
      }
    }
};
</script>

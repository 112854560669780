<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
      <template v-slot:feature>
        <v-chip v-if="data.last_provisioned_at != null" class="success ml-2">Provisioned</v-chip>
        <v-chip v-else class="warning ml-2">Not Provisioned</v-chip>
        <v-icon right x-large>mdi-deskphone</v-icon>
      </template>
      <p class="title text--primary mt-1" v-if="data.id">{{ data.mac_address }}</p>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Reference ID</span>
      </v-col>
      <v-col cols="7">
        {{ data.id }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Name</span>
      </v-col>
      <v-col cols="7">
        {{ data.label }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">MAC</span>
      </v-col>
      <v-col cols="7">
        {{data.mac_address}}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Manufacturer/Model</span>
      </v-col>
      <v-col cols="7">
        {{data.manufacturer}} / {{data.model}}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Created</span>
      </v-col>
      <v-col cols="7">
        <LocalDateTime :value="data.created_at" hide-tooltip />
      </v-col>
      </v-row>
        <template v-slot:actions>
          <ButtonWithTooltip
              text="Extension"
              icon="mdi-eye"
              :to="{ name: 'TrunkSummary', params: { customerId: data.i_customer, trunkId: data.i_account}}"
              tooltip="View Extension Details"
              flat
              v-if="data.i_account"
          />
          <ButtonWithTooltip
              text="Customer"
              icon="mdi-eye"
              :to="{ name: 'CustomerSummary', params: { customerId: data.i_customer}}"
              tooltip="View Customer Details"
              flat
              v-if="data.i_customer"
          />
          <ButtonWithTooltip
              text="Change Extension"
              flat
              tooltip="Change Extension"
              icon="mdi-sync"
              @click="showChangeDialog = true"
          />
          <ButtonWithTooltip
              text="Edit"
              flat
              tooltip="Edit Device"
              icon="mdi-pencil"
              @click="showEditDialog = true"
          />
          <ButtonWithTooltip
              text="Delete"
              flat
              tooltip="Terminate Device"
              icon="mdi-delete"
              @click="showTerminateDialog = true"
              color="error"
          />
        </template>
        <TerminateVoipDeviceDialog
            v-if="data.id != undefined"
            v-model="showTerminateDialog"
            :deviceData="data"
        />
      <EditVoipDeviceDialog v-model="showEditDialog" :deviceData="data" v-if="data.label"/>
      <ChangeVoipDeviceExtensionDialog v-model="showChangeDialog" :loading="loading" :deviceData="data" :extensionId="data.porta_extension_i_c_ext" v-if="data.label"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TerminateVoipDeviceDialog from '../../dialogs/VoipDevice/TerminateVoipDeviceDialog';
import LocalDateTime from '../../pieces/LocalDateTime';
import EditVoipDeviceDialog from "../../dialogs/VoipDevice/EditVoipDeviceDialog";
import ChangeVoipDeviceExtensionDialog from "../../dialogs/VoipDevice/ChangeVoipDeviceExtensionDialog";
export default {
    name: 'DeviceCard',
    components: {EditVoipDeviceDialog, LocalDateTime, TerminateVoipDeviceDialog, ButtonWithTooltip, BasicCard, ChangeVoipDeviceExtensionDialog },
    props: ['data','loading'],
    data: () => ({
      showTerminateDialog: false,
      showEditDialog: false,
      showChangeDialog: false,
    }),
};
</script>

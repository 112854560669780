<template>
    <ComplexDialog
        v-model="visible"
        :title="'Update Music on Hold'"
    >
      <CustomerMOHForm :loading="isBusy" v-model="form" :customerData="customerData" :mohList="mohList" />
        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >Update</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import AutoAttendantPromptForm from "../../pieces/AutoAttendant/AutoAttendantPromptForm";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import CustomerMOHForm from "../../pieces/Customer/CustomerMOHForm";
    export default {
        name: 'CustomerMOHUpdateDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin],
        components: {CustomerMOHForm,  ComplexDialog },
        props: {
            customerData: {
                type: Object,
                required: true,
            },
          mohList:{
              type: Array,
            required:true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,
        }),
      methods:{
        async submit() {
          this.busy = true;
          this.Api.setHttpObject({"timeout":60000});
          let params = new FormData();
          let responseUpload = null;

          if(this.form.fileSource == 'upload' && this.form.toggle == 'true') {
            params.append("music_on_hold_file", this.form.audioUploadFile);
            params.append('name', this.form.audioUploadName);
            //first we upload the file. Then we use the return i_moh to update the music on hold
            responseUpload = await this.Api.send('post','customers/' + this.customerData.i_customer+ '/moh-audio-file', params);
            if (responseUpload.success && responseUpload.data.i_moh) {
              this.showGlobalSuccessMessage('Successfully uploaded music on hold file.');
            }else{
              this.showGlobalErrorMessage('Failed to update Intro Prompt.');
              return false;
            }
          }

          //now we take the select i_moh or the upload i_moh and assign it as the music on hold
          let response = null;
          params = {};
          if(this.form.toggle == 'true'){
            if(this.form.fileSource == 'upload') {
              params.i_moh = responseUpload.data.i_moh;
            }else{
              params.i_moh = this.form.fileSource;
            }
            params.enabled = 'Y';
          }else{
            params.enabled = 'N';
          }

          response = await this.Api.send('put','customers/' + this.customerData.i_customer+ '/moh', params);
          if (response.success && response.data.i_customer) {
            this.showGlobalSuccessMessage('Successfully updated Menu on hold.');
          }else{
            this.showGlobalErrorMessage('Failed to update Music on Hold details.');
          }

          this.busy = false;
          this.Api.setHttpObject({"timeout":20000});

          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
      },
      computed: {
        isBusy( ){
          return this.loading || this.busy;
        },
        mohToggle( ){
          let tmp = null;
          this.customerData.service_features.forEach(function (feature) {
            if(feature.name == 'music_on_hold'){
              tmp = feature.flag_value;
            }
          });
          return tmp;
        },
        iMoh( ){
          let tmp = null;
          this.customerData.service_features.forEach(function (feature) {
            if(feature.name == 'music_on_hold'){
              feature.attributes.forEach(function (item) {
                if(item.name == 'i_moh'){
                  tmp = item.effective_values[0];
                }
              });
            }
          });
          return tmp;
        },
        mohFile( ){
          if(!this.iMoh){
            return null
          }
          let tmp = null;
          let self = this;
          this.mohList.forEach(function (file) {
            if(file.i_moh == self.iMoh){
              tmp = file.name;
            }
          });
          return tmp;
        }
      },
    };
</script>
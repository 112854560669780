<template>
    <BasicCard
        :loading="loading"
        title="Extension Contact Details"
    >
        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Name</span>
            </v-col>
            <v-col cols="7">
                {{ data.firstname }} {{ data.lastname }}
            </v-col>
        </v-row>
      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Email</span>
        </v-col>
        <v-col cols="7">
          {{ data.email }}
        </v-col>
      </v-row>
      <v-row dense v-if="!!data.note">
        <v-col cols="5">
          <span class="font-weight-bold">Phone Number</span>
        </v-col>
        <v-col cols="7">
          {{data.phone1}}
        </v-col>
      </v-row>
        <template v-slot:actions>
          <ButtonWithTooltip
              text="Edit"
              flat
              tooltip="Edit Contact Details"
              icon="mdi-pencil"
              @click="showEditDialog = true"
          />
        </template>
      <EditExtensionContactDialog v-model="showEditDialog" :data="data"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import EditExtensionContactDialog from "../../dialogs/Extension/EditExtensionContactDialog";
export default {
    name: 'ExtensionContactDetailsCard',
    components: {EditExtensionContactDialog, ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data: () => ({
      showEditDialog: false,
    }),
};
</script>

<template>
    <v-sheet class="rounded-lg pa-2 credit-card-background" width="320" height="202">
        <div class="d-flex" style="height: 65px;">
            <div class="mr-auto" />
            <div>
                <v-chip v-if="chip" :color="chipColor" label small>{{chip}}</v-chip>
            </div>
        </div>
        <div class="d-flex align-end" style="height: 30px; padding-left: 30px; padding-right: 5px;">
            <div class="mr-auto">
                <v-img :src="require('@/assets/img/3rd_party/chip.png')" contain :max-width="40" />
            </div>
            <div>
                <v-img :src="require('@/assets/img/3rd_party/nfc.png')" contain :max-width="20" />
            </div>
        </div>
        <div class="d-flex justify-center align-center font-weight-bold white--text" style="height: 35px;">
            <div class="credit-card-number" v-html="card_html" />
        </div>
        <div class="d-flex flex-row" style="height: 55px;">
            <div class="flex-grow-1">
                <div class="text-right" style="padding-right: 10px;">
                    <span v-if="expiry" class="font-weight-thin">Valid until <span class="credit-card-expiry">{{expiry | luxon('MM/yy') }}</span></span>
                </div>
                <div style="padding-left: 20px;" class="text-left pt-1">
                    <span v-if="name" class="font-weight-bold">{{name | upper}}</span>
                </div>

            </div>
            <div class="d-flex flex-column align-end" style="width: 80px;">
                <div class="mt-auto"/>
                <div>
                    <v-img v-if="card_type==='mastercard'" :src="require('@/assets/img/3rd_party/mastercard_white.png')" contain :max-height="48" :max-width="60" position="right" />
                    <v-img v-else-if="card_type==='visa'" :src="require('@/assets/img/3rd_party/visa_white.png')" contain :max-height="48" :max-width="75" position="right" class="ma-1" />
                </div>

            </div>
        </div>

    </v-sheet>
</template>

<script>
    import creditCardMixin from '../../mixins/creditCardMixin';

    export default {
        name: 'CreditCard',
        mixins: [creditCardMixin],
        props: {
            number: {
                type: String,
                default: null
            },
            expiry: {
                type: String,
                default: null
            },
            name: {
                type: String,
                default: null
            },
            chip: {
                type: String,
                default: null
            },
            chipColor: {
                type: String,
                default: 'grey'
            },
        },
        computed: {
            card_html() {
                if (!this.number) return null;
                let value = this.number.toString();
                return value.replace(/(.{4})(.{4})?(.{4})?(.{1,4})?/g, '$1<span class="pl-5" />$2<span class="pl-5" />$3<span class="pl-5" />$4');
            },
            card_type() {
                return this.get_card_type(this.number);
            }
        },
        filters: {
            upper(value) {
                return value.toString().toUpperCase();
            },
            expiry(value) {
                return value.toString().toUpperCase();
            },
        },
    };
</script>

<style scoped>
div {
    color: white;
}
.credit-card-background {
    background-image: linear-gradient(147deg, #354fce 0,#0c296b 74%);
}
.credit-card-expiry {
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: 'Courier New';
}
.credit-card-number {
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: 'Courier New';
}
</style>
<template>
    <BasicCard
        :loading="loading"
        title="Service Policy"
    >

      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Enable rInstance</span>
        </v-col>
        <v-col cols="7">
          <v-icon
              v-if="this.rInstance == 'Y'"
              color="success"
              class="mr-2"
          >mdi-check</v-icon>

          <v-icon
              v-else
              color="error"
              class="mr-2"
          >mdi-cancel</v-icon>
        </v-col>
      </v-row>

        <template v-slot:actions>
          <ButtonWithTooltip
              text="Edit"
              flat
              tooltip="Edit"
              icon="mdi-pencil"
              @click="showEditDialog = true"
          />
        </template>
      <TrunkServicePolicyEditDialog v-model="showEditDialog" :trunkData="trunkData"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TrunkServicePolicyEditDialog from "../../dialogs/Trunk/TrunkServicePolicyEditDialog";
import trunkSettingsDataMixin from "../../../mixins/trunkSettingsDataMixin";
import apiMixin from "../../../mixins/apiMixin";
import dialogMixin from "../../../mixins/dialogMixin";
export default {
    name: 'TrunkServicePolicy',
    components: {ButtonWithTooltip, BasicCard,TrunkServicePolicyEditDialog},
    mixins: [ trunkSettingsDataMixin],
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      trunkData: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      showEditDialog: false,
    }),
  computed: {
    rInstance() {
      if(this.trunkData && this.trunkData.service_features){
        let record = this.trunkData.service_features.find((e) => e.name === 'voice_service_policy');
        let id = record.attributes.find((e) => e.name === 'id');
        if(id.values[0] == this.servicePolicyRInstanceId && record.flag_value == 'Y'){
          return 'Y';
        }
      }
      return 'N';
    }
  },
};
</script>

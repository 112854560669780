<template>
  <div>
  <v-form v-model="valid">
    <v-simple-table dense class="mt-2">
      <thead>
      <tr>
        <th>Time Periods</th>
        <th class="text-right">Delete</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!timeConditionsList || (timeConditionsList && (!timeConditionsList.length || timeConditionsList.length == 0))" key="nodata">
        <td colspan="2" class="text-center">
          No time periods
        </td>
      </tr>
      <tr v-for="(time_window, index) in timeConditionsList" :key="index">
        <td>
          {{time_window.description}}
        </td>
        <td class="text-right">
          <v-btn icon @click="deletePeriod(index)"><v-icon v-html="'mdi-close'" /></v-btn>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <v-sheet rounded class="pa-2 mt-4" color="background_accent">
      <p class="font-weight-bold mb-3" v-if="false">Add a time period</p>
      <v-row>
        <v-col cols="12">
          <v-btn block text color="primary" @click="showCreateDialog=true"><v-icon left v-html="'mdi-plus'"/> Add Time Period</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-form>
  <TimeConditionCreateEditDialog v-model="showCreateDialog" :loading="loading" @confirm="confirmTimeCondition"/>
  </div>
</template>

<script>
import TimeConditionForm from "../Forms/TimeConditionForm";
import TimeConditionCreateEditDialog from "../../dialogs/TimeConditionCreateEditDialog";
export default {
    name: 'AutoAttendantActiveTimeConditionComponent',
    components: {TimeConditionCreateEditDialog},
    data: () => ({
      valid: false,
      busy: false,
      form:{},
      period: null,
      showCreateDialog: false,
      timeConditionsList:[]
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
      isBusy( ){
        return this.loading || this.busy;
      }
    },
    methods: {
      confirmTimeCondition(val){
        //form details and add it to the timeConditionsList
        if(val!=null){
          this.timeConditionsList.push(val);
        }
        this.updateCallback(this.timeConditionsList);
      },
      deletePeriod(index) {
        this.timeConditionsList.splice(index, 1);
        this.updateCallback(this.timeConditionsList);
      },
    },
    created( ){
        if(this.workingTimePeriods != null){
          this.timeConditionsList = this.workingTimePeriods;
        }else{
          //need to pull from aaData.
        }
      this.updateCallback(this.timeConditionsList);
    },
    props: {
      trunkData: {
        type: Object,
        required:true,
      },
      aaData:{
        type: Object,
        default: function (){return {}}
      },
      /**
       * These are the current working time periods that are in the list.
       * This is needed because if a this UI is toggled on and off any newly added TimePeriods in the list are lost.
       * Hence then new timeperiods are added we pass them up the chain and then pass them back into this component again.
       * Note if this is not null this list is used. If this is null then the list of time period from the aaData is used (if present).
       */
      workingTimePeriods:{
        type: Array,
        default: null
      },
      loading: {
        type:Boolean,
        default:false,
      },
      updateCallback: {
        type:Function,
        required:true,
      },
    }
};
</script>
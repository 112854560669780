<template>
    <v-row dense>
        <v-col cols="12" sm="6" lg="4">
            <TrunkCallerIDCard :data="trunkData" :loading="loading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <TrunkCallBarringCard :data="trunkData" :loading="loading" v-if="trunkData.account_type != 'RING-GROUP'"/>
        </v-col>
    </v-row>
</template>

<script>
import TrunkCallerIDCard from '../../components/cards/Trunk/TrunkCallerIDCard';
import trunkDataMixin from '../../mixins/trunkDataMixin';
import TrunkCallBarringCard from '../../components/cards/Trunk/TrunkCallBarringCard';
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
export default {
    name: 'TrunkCallSettings',
    mixins: [trunkDataMixin,GlobalFetchStoreMixin],
    components: { TrunkCallBarringCard, TrunkCallerIDCard },
    computed: {
      loading() {
        return this.fetchStoreLoading || this.trunkLoading;
      }
    }
};
</script>

<template>
    <v-autocomplete
        :items="data"
        :loading="loading"
        :label="label"
        :search-input.sync="query"
        :readonly="!!id"
        :hint="!id ? 'Start typing ID to search' : ''"
        :error-messages="errorMessages"
        item-text="id"
        item-value="i_account"
        @change="change"
        @click:clear="clear"
        outlined
        no-filter
        hide-no-data
        :open-on-clear="initDropdown"
        clearable
        @keydown.enter.native.prevent
        return-object
        autofocus
    />
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'TrunkDropdown',
    mixins: [apiMixin],
    data: () => ({
        data: [],
        loading: false,
        timer: null,
        query: null,
        currentQuery: '',
        error: false,
        selected: null,
        id: null,
    }),
    methods: {
        change: function (value) {
            this.currentQuery = value ? value.id : '';
            this.selected = value;
            this.id = value ? value.i_account : null;
            this.$emit('update', value);
        },
        clear: function () {
            this.selected = null;
            this.$emit('update', null);
        },
        async search(query) {
            this.loading = true;
            this.currentQuery = query;
            let params = {};
            if (query) {
                params.query = query;
                params.id = query;
            }
            if (this.customerId) {
                params.i_customer = this.customerId;
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            const response = await this.Api.send('get', 'trunks?'+queryString);
            this.loading = false;
            if (response.success) {
                this.data = response.data.data;
            } else {
                this.error = true;
            }
        },
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('get','trunks/' + id);
            this.loading = false;
            if (response.success) {
                this.data = [response.data.data];
                this.currentQuery = response.data.data.id;
                this.$emit('update', response.data.data);
            } else {
                this.error = true;
            }
        },
    },
    watch: {
        query(val) {
            clearTimeout(this.timer);
            if (val) {
                if (!this.selected || this.selected.id !== val) {
                    this.timer = setTimeout(() => {
                        if (val !== this.currentQuery) {
                            this.search(val);
                        }
                    }, 500);
                }
            } else {
                if (this.initDropdown) {
                    this.search(null);
                }
                this.selected = null;
                this.currentQuery = '';
            }
        },
        id(val) {
            this.$emit('input', val);
        },
        value(val) {
            if (val !== this.id) {
                this.id = val;
                this.get(val);
            }
        },
    },
    created() {
        if (this.value) {
            this.id = this.value;
            this.get(this.id);
        } else {
            this.search(null);
        }
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: Number,
            default: null,
        },
        customerId: {
            type: Number,
            default: null,
        },
        errorMessages: {
            type: String,
            default: '',
        },
        initDropdown: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>

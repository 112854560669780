<template>
    <SimpleDialog
        v-model="visible"
        title="Delete Credit Card?"
        @click="deleteCard"
        :loading="busy"
        styledHeader
    >
        The saved {{data.payment_method.payment_method}} {{data.payment_method.number}} will be removed<span v-if="data.bp_charge_cc!=='N'"> and automatic payments will be disabled</span>. Are you sure?
    </SimpleDialog>
</template>

<script>
    import SimpleDialog from '../templates/SimpleDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import apiMixin from '../../../mixins/apiMixin';
    export default {
        name: 'DeleteCreditCardDialog',
        mixins: [apiMixin, dialogMixin],
        components: { SimpleDialog },
        data: () => ({
            busy: false
        }),
        methods: {
            async deleteCard() {
                if (!this.data) return;
                this.busy = true;
                const response = await this.Api.send('post','customers/' + this.data.i_customer + '/delete_payment_method');
                if (response.success) {
                    document.dispatchEvent(new CustomEvent('refreshRequested'));
                    this.visible = false;
                }
                this.busy = false;
            }
        },
        props: {
            data: {
                type: Object,
                default: null
            }
        }
    };
</script>

<style scoped>

</style>
<template>
  <AutoAttendantTargetedTransitionsList :iCustomer="trunkData.i_customer" :loading="loading" :inactiveTargetedTransitions="inactive" :actionTargetedTransitions="actions" />
</template>

<script>
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import AutoAttendantTargetedTransitionsList from "../../pieces/AutoAttendant/AutoAttendantTargetedTransitionsList";
export default {
    name: 'AutoAttendantTargetedTransitionsListCard',
    mixins: [apiMixin, refreshListenerMixin,ringGroupDataMixin,AutoAttendantMixin],
    data: () => ({
      busy: false,
      inactive: [],
      actions: [],
    }),
    components: {
      AutoAttendantTargetedTransitionsList
    },
    props: {
        trunkData: {
            type: Object,
            requiured:true,
        },
        aaData: {
          type: Object,
          requiured:true,
        },
        loading: {
          type: Boolean,
          default:false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
  watch: {
    trunkData(value) {
      if(value && this.aaData){
        this.reset();
      }
    },
    aaData(value) {
      if(value && this.trunkData){
        this.reset();
      }
    },
  },
  created( ){
      this.reset( );
  },
    methods: {
      reset( ){
        this.inactive = [];
        this.actions = [];
        if(this.trunkData && this.trunkData.i_account && this.aaData && this.aaData.i_menu) {
          this.update();
        }
      },

      async update( ){
        this.busy = true;
        let response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/targeted-auto-attendant-transitions');
        if(response.success){
          this.inactive = response.data['not_active'];
          this.actions = response.data['actions'];
        }
        this.busy = false;
      },

    },
};
</script>

<template>
    <ComplexDialog
        v-model="visible"
        :title="'Edit Auto Attendant General Settings for account '+ trunkData.id"
    >
      <p>Edit the general settings.</p>
  <AutoAttendantGeneralSettingsForm :loading="isBusy" :trunkData="trunkData" :aaData="aaData" :changeCallBack="settingsChanged"/>
        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >Update</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
    export default {
        name: 'AutoAttendantEditGeneralSettingsDialog',
        mixins: [dialogMixin, apiMixin, AutoAttendantMixin],
        components: {AutoAttendantGeneralSettingsForm,  ComplexDialog },
        props: {
            trunkData: {
                type: Object,
                required: true,
            },
          aaData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,
          rules: {
            required: v => !!v || 'Required.',
            numeric: v => !isNaN(v) || 'Must be whole number',
          },
        }),

      methods:{
          settingsChanged(form){
            this.form = form;
          },
        async submit() {
          this.busy = true;
          let params = this.processDataToSave(this.form);

          let response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu, params);
          if (response.success) {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
          }
          this.busy = false;
        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      }
    };
</script>

<style scoped>

</style>
<template>
    <v-row dense>
        <v-col cols="12" sm="6">
            <TrunkProductsCard :data="trunkData" :loading="trunkLoading" />
        </v-col>
        <v-col cols="12" sm="6">
        </v-col>
        <v-col cols="12">
            <TrunkDiscountsCard :trunk-id="trunkData.i_account" :loading="trunkLoading" v-if="trunkData && trunkData.i_account"/>
        </v-col>
    </v-row>
</template>

<script>
import trunkDataMixin from '../../mixins/trunkDataMixin';
import TrunkProductsCard from '../../components/cards/Trunk/TrunkProductsCard';
import TrunkDiscountsCard from '../../components/cards/Trunk/TrunkDiscountsCard';
export default {
    name: 'TrunkBilling',
    components: { TrunkDiscountsCard, TrunkProductsCard },
    mixins: [trunkDataMixin],
};
</script>

<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :loading="busy"
    >
    <v-window>
      <v-window-item>
        <p class="subtitle-1 text--primary">
          Time Window Details
        </p>
        <TimeConditionForm :callBackFunction="timeConditionChanged"/>
      </v-window-item>
    </v-window>

        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                color="primary"
                :loading="busy"
                @click="confirm"
                :disabled="(!timeCondition)"
            >Confirm Settings</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ContactDetailsForm from "../pieces/Forms/ContactDetailsForm";
    import ComplexDialog from './templates/ComplexDialog';
    import dialogMixin from '../../mixins/dialogMixin';
    import apiMixin from '../../mixins/apiMixin';
    import TimeConditionForm from "../pieces/Forms/TimeConditionForm";
    export default {
        name: 'TimeConditionCreateEditDialog',
        mixins: [apiMixin, dialogMixin],
        components: {
          TimeConditionForm,
          ComplexDialog,
        },
        data: () => ({
          busy: false,
          timeCondition:null,
        }),
        methods: {
          confirm( ){
            this.visible = false;
            if(!this.timeCondition){
              this.$emit('confirm', null);
            }else{
              this.$emit('confirm', this.timeCondition);
            }
          },
          timeConditionChanged(val){
            this.timeCondition = val;
          }
        },
      computed: {
        isBusy( ){
          return this.loading || this.busy;
        }
      },
        props: {
            title: {
                type: String,
                default: 'Add Time Window'
            },
        },
      loading: {
        type:Boolean,
        default:false,
      },

    };
</script>

<style scoped>

</style>
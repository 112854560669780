<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="Name"
                        :disabled="isLoading"
                        v-model="localFilter.name"
                        @keydown.enter="updateLocalFilter"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-select
                        hide-details
                        outlined
                        dense
                        label="Status"
                        v-model="localFilter.status"
                        :disabled="isLoading"
                        :items="statusOptions"
                        item-text="option"
                        item-value="value"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCard
            :page="filter.page ? filter.page : 1"
            :data="data"
            :limit="filter.limit ? filter.limit : 10"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Invoice No</th>
                        <th v-if="$store.state.user.isAdmin">i_invoice</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Issue Date</th>
                        <th class="text-center">Due Date</th>
                        <th class="text-right">Period total</th>
                        <th class="text-right">Payments / adjustments</th>
                        <th class="text-right">Outstanding balance</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td :colspan="7" class="text-center">
                            No invoices found
                        </td>
                    </tr>
                    <tr
                        v-for="item in data.data"
                        :key="item.i_invoice"
                        v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark && item.invoice_status === 'Overdue',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.invoice_status === 'Overdue',
                        }"
                    >
                        <td>{{ item.invoice_number }}</td>
                        <td v-if="$store.state.user.isAdmin">{{ item.i_invoice }}</td>
                        <td class="text-center"><InvoiceStatus :data="item" /></td>
                        <td class="text-center"><LocalDateTime :value="item.issue_date" hide-tooltip /></td>
                        <td class="text-center"><LocalDateTime :value="item.due_date" hide-tooltip /></td>
                        <td class="text-right"><Currency :value="item.amount_net" hide-tooltip /></td>
                        <td class="text-right"><Currency :value="(item.amount_paid + item.adjustments)" hide-tooltip /> </td>
                        <td class="text-right">
                            <Currency v-if="Math.abs(item.amount_net - item.amount_paid - item.adjustments).toFixed(2)>0" :value="(item.amount_net - item.amount_paid - item.adjustments)" />
                            <span v-else>Paid in full</span>
                        </td>

                        <td class="text-center">
                            <ButtonWithTooltip icon="mdi-information" color="primary" tooltip="View details" @click="showSummary(item)" />
                            <ButtonWithTooltip v-if="item.is_pdf_exists===1" icon="mdi-download" color="primary" tooltip="Download PDF"
                                :href="linkBase +
                                    (custId ? 'customers/' + custId : 'admin') +
                                    '/invoice/' + item.i_invoice + '/download?session-token=' + $store.state.session.token" />
                        </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCard>
        <InvoiceSummaryDialog v-model="summary.show" :data="summary.data" />
    </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import LocalDateTime from '../../pieces/LocalDateTime';
import InvoiceStatus from '../../pieces/Invoice/InvoiceStatus';
import Currency from '../../pieces/Currency';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import InvoiceSummaryDialog from '../../dialogs/Invoice/InvoiceSummaryDialog';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
export default {
    name: 'InvoiceListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        localFilter: {
            name: null,
            status: null
        },
        linkBase: process.env.VUE_APP_API_BASE || '/api/',
        summary: {
            show: false,
            data: {}
        },
        statusOptions: [
            { option: 'Active (all)', value: null },
            { option: 'Active (not suspended)', value: 'O' },
            { option: 'Active (suspended)', value: 'S' },
            { option: 'Closed', value: 'C' },
            { option: 'Exported', value: 'E' },
        ],
    }),
    components: {
        InvoiceSummaryDialog,
        ButtonWithTooltip,
        Currency,
        InvoiceStatus,
        LocalDateTime,
        PaginationCard,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        custId: {
            type: Number,
            default: null,
        },
        small: {
            type: String,
            default: null,
        },
    },
    methods: {
        async update() {
            this.isLoading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            /*this.localFilter.status = this.filter.status;
            if (this.filter.status && this.filter.status !== '')
                params.status = this.filter.status;
            this.localFilter.name = this.filter.name;
            if (this.filter.name && this.filter.name !== '')
                params.name = this.filter.name;
            if (this.repId > 0) {
                params.i_rep = this.repId;
            } else {
                if (this.getRepNames) params.get_rep_names = 1;
            }*/
            const response = await this.Api.send('post',this.custId ? 'customers/' + this.custId + '/invoicelist' : 'invoices/list', params);
            this.isLoading = false;
            if (response.success) {
                this.data = response.data;
                this.firstLoad = false;
            } else {
                this.data = [];
            }
        },
        showSummary(data) {
            this.summary.data = data;
            this.summary.show = true;
        },
    },
};
</script>
<template>
  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <v-card
        outlined
    >
      <v-card-title>
        Intro
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="form.intro_toggle" class="mt-0" row>
          <v-radio
              label="Disabled"
              value="none"
              key="0"
          ></v-radio>
          <v-radio
              label="Enabled"
              value="company-greeting"
              key="1"
          ></v-radio>
        </v-radio-group>

        <v-slide-y-transition>
          <v-card
              outlined
              v-if="form.intro_toggle=='company-greeting'"
              class="mt-2"
          >
            <v-card-text>
              <div>Intro prompt audio file.
                <span v-if="currentIntroFileName"><br />Current File: <strong>{{currentIntroFileName}}</strong></span>
                <span v-if="aaData.prompt_intro_file_last != null && aaData.prompt_intro_file_last.status == 'FINISHED'"><br /><a href="#" @click="downloadFile('intro')">Download current stored file</a></span>
                <br /></div>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-file-input
                      v-model="form.intro_prompt_audio_file"
                      show-size
                      :disabled="isBusy"
                      :rules="form.intro_toggle=='company-greeting' && (!this.aaData || this.aaData.msg_intro_set == 0) ? [rules.file,rules.required] : [rules.file]"
                      :accept="validPromptAudioTypes"
                      label="Upload Prompt File (max 3mb)"
                      prepend-icon="mdi-volume-high"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </v-card-text>
    </v-card>
<br />
    <v-card>
      <v-card-title>
        Menu
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="form.menu_toggle" class="mt-0" row>
          <v-radio
              label="Disabled"
              value="none"
              key="0"
          ></v-radio>
          <v-radio
              label="Enabled"
              value="custom"
              key="1"
          ></v-radio>
        </v-radio-group>

      <v-slide-y-transition>
        <v-card
            outlined
            v-if="form.menu_toggle=='custom'"
            class="mt-2"
        >
          <v-card-text>
            <div>Menu prompt audio file.
              <span v-if="currentMenuFileName"><br />Current File: <strong>{{currentMenuFileName}}</strong></span>
              <span v-if="aaData.prompt_menu_file_last != null && aaData.prompt_menu_file_last.status == 'FINISHED'"><br /><a href="#" @click="downloadFile('menu')">Download current stored file</a></span>
              <br /></div>
            <v-row no-gutters>
              <v-col cols="12">
                <v-file-input
                    v-model="form.menu_prompt_audio_file"
                    show-size
                    :disabled="isBusy"
                    :rules="form.menu_toggle=='custom' && (!this.aaData || this.aaData.msg_menu_set == 0) ? [rules.file,rules.required] : [rules.file]"
                    :accept="validPromptAudioTypes"
                    label="Upload Prompt File (max 3mb)"
                    prepend-icon="mdi-volume-high"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
      </v-card-text>
    </v-card>

  </v-form>
</template>

<script>
import apiMixin from "../../../mixins/apiMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import SpinnerOverlay from "../Global/SpinnerOverlay";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
export default {
  name: 'AutoAttendantPromptForm',
  mixins: [apiMixin,ringGroupDataMixin,AutoAttendantMixin],
  components: {SpinnerOverlay},
  data: () => ({
    linkBase: process.env.VUE_APP_API_BASE || '/api/',
    valid: false,
    busy: false,
    form:{
      intro_toggle:'none',
      intro_prompt_audio_file:null,
      menu_toggle:'none',
      menu_prompt_audio_file:null,
    },
    currentIntroFileName:null,
    currentMenuFileName:null,
    rules: {
      required: v => !!v || 'Required.',
      numeric: v => !isNaN(v) || 'Must be whole number',
      file: v => !v || v.size < 3145728 || 'Audio file size should be less than 3 MB.',
    },
    ringGroupExtensionsCount: 0,
  }),
  computed: {
    highlightColor() {
      return 'accent';
    },
    isBusy( ){
      return this.loading || this.busy;
    }
  },
  watch: {
    valid(value) {
      if (value) {
        this.$emit('input', this.form);
      } else {
        this.$emit('input', null);
      }
    },
    aaData(value) {
      this.reset();
    },
  },
  methods: {

    downloadFile(prompt_type)
    {
      const url = this.linkBase + 'auto-attendant-accounts/'+this.aaData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+prompt_type+'/audio-file?session-token=' + this.$store.state.session.token;
      window.location.href = url;
    },
    reset( ){
      this.valid = false;
      this.form.intro_toggle = 'none';
      this.form.intro_prompt_audio_file = null;
      this.form.menu_toggle = 'none';
      this.form.menu_prompt_audio_file = null;

      if(this.aaData){
        if(this.aaData.msg_intro_type == 'custom'){
          this.form.intro_toggle = 'company-greeting';
        }
        if(this.aaData.msg_menu_type == 'custom'){
          this.form.menu_toggle = 'custom';
        }
      }
    },
  },
  created() {
    this.reset( );
  },
  props: {
    aaData:{
      type: Object,
      default: function (){return {}}
    },
    loading: {
      type:Boolean,
      default:false,
    },
  }
};
</script>
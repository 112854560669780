<template>
    <div>
        <v-sheet
            color="background_accent"
            rounded
        >  <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
            <p class="text-h6 mb-1">Call Queue Subscription</p>
            <p><span class="text-subtitle-1">$4.95 per month </span><span class="text--disabled">including GST</span></p>
            <p >For full details refer to the <a href="https://breezeconnect.com.au/our-policies/" target="_blank">Critical Information Summary</a>.</p>
          </v-col>
          <v-col cols="12" md="6" class=" text-right">

            <span class="text-h5 text--disabled">Monthly Plan Fees </span><span class="text-h3">${{4.95 | thousandSeparator}} </span>
          </v-col>
        </v-row>
        </v-sheet>
    </div>
</template>

<script>
export default {
    name: 'CallQueueSubscriptionSummary',
    data: () => ({

    }),
    props: {
            },
    methods: {

    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;

            let decimal = false;
            if(value % 1 != 0){
              decimal = true;
            }

            let ret = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if(!decimal){
              ret = ret+'.00';
            }
            return ret;
        },
    },
};
</script>
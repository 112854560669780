<template>
    <ComplexDialog
        title="Call Forwarding Settings"
        v-model="visible"
        :loading="busy"
        :max-width="1000"
    >

        <TrunkForwardingSettingsForm v-if="visible" :followme="followme" :forwarding-mode="forwarding_mode" v-model="form" :data="data"/>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="!form"
                :loading="busy"
                @click="update"
                text
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import TrunkForwardingSettingsForm from "../../pieces/Trunk/TrunkForwardingSettingsForm";
export default {
    name: 'CallForwardSettingsDialog',
    mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
    components: { TrunkForwardingSettingsForm, ComplexDialog },
    data: () => ({
        valid: false,
        busy: false,
        form: null,
      timeoutRules: [
        (v) => !!v || 'Required',
        (v) => /^\d+$/i.test(v) || 'Enter a valid number',
        (v) => parseInt(v)<=120 || 'Maximum timeout is 120 seconds',
        (v) => parseInt(v) !== 0 || 'Cannot be zero',
      ],
    }),
    methods: {
        async update() {
            this.busy = true;
            const response = await this.Api.send('post', 'trunks/' + this.data.i_account + '/follow_me', this.form);
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.visible = false;
            }
            this.busy = false;
        }
    },
    computed: {
      forwarding_mode() {
        return this.data && this.data.service_features ? this.data.service_features.find((e) => e.name === 'forward_mode').effective_flag_value : null;
      },
    },
    props: {
        data: {
            type: Object,
            default: null
        },
      followme: {
        type: Object,
        default: null
      },
    }
};
</script>
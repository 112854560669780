<template>
    <v-chip small :color="color">
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    name: 'DIDStatus',
    props: ['data'],
    computed: {
        text: function () {
            if (this.data.i_account) {
                return 'In Use';
            }
            if (this.data.i_customer) {
                return 'Reserved';
            }
            if (this.data.frozen === 'Y') {
                return 'Frozen';
            }
            if (!this.data.i_customer && this.data.frozen === 'N') {
                return 'Unused';
            }
            return 'Unknown';
        },
        color: function () {
            if (this.data.i_account) {
                return 'primary';
            }
            if (this.data.frozen === 'Y') {
                return 'error';
            }
            if (!this.data.i_customer && this.data.frozen === 'N') {
                return null;
            }
            return 'Unknown';
        },
    },
};
</script>

<template>
    <ComplexDialog
        title="Fax to mail"
        v-model="visible"
        :loading="busy"
        :step="faxStep"
        :stepEnd="3"
        @step-next="stepUp"
        @step-back="faxStep--"
        @step-end="visible = false"
        :stepNextText="faxStep == 2 ? 'Enable Fax to Mail' : 'Next'"
        :maxWidth="600"
        :stepNextDisabled="(faxStep == 1 && form == null)"
    >
      <v-expand-transition>
        <TrunkFax2MailSettingsForm v-if="faxStep==1" :data="data" :dids="dids" v-model="form"/>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="faxStep==2">
          <p>The following settings will be applied</p>
          <v-row dense class="text--primary">
            <v-col cols="6">Send faxes to</v-col>
            <v-col cols="6">
              {{form.email}}
            </v-col>
            <v-col cols="6">Store copy of fax in fax inbox</v-col>
            <v-col cols="6">
              <v-icon v-if="form.keepInboxFax==='Y'" v-html="'mdi-check-circle'" color="success" />
              <v-icon v-else v-html="'mdi-close-circle'" color="error" />
            </v-col>
            <v-col cols="6" v-if="form.wholeTrunk==='Y'">Trunk answer mode</v-col>
            <v-col cols="6" v-if="form.wholeTrunk==='Y'">
              Send to voicemail
            </v-col>
            <v-col cols="12" v-if="form.wholeTrunk==='N'">
              <v-alert transition="expand" type="warning" text>Please create a <strong>Call Screening</strong> rule for your fax to email DID. Please ensure that the <strong>“Action”</strong> is set to <strong>“Send to Voicemail”</strong>.
                Contact Breeze Connect support if more help is required.</v-alert>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="faxStep==3">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6">Fax to Mail enabled successfully</p>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="form == null"
                :loading="busy"
                @click="update"
                text
            >Save</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import TrunkFax2MailSettingsForm from "../../pieces/Trunk/TrunkFax2MailSettingsForm";
export default {
    name: 'VoiceFaxMailSettingsDialog',
    mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
    components: {TrunkFax2MailSettingsForm, ComplexDialog },
    data: () => ({
       // valid: false,
        form: {
          email:null,
          keepInboxFax:'Y',
          wholeTrunk:'Y',
        },
        busy: false,
        dids:[],
        faxStep:1,
    }),
    methods: {
      async getDidList() {
        this.busy = true;
        const response = await this.Api.send('post', 'trunks/' + this.data.i_account + '/did/list');
        this.busy = false;
        if (response.success) {
          this.dids = response.data.data;
        }
      },
      async update() {
          this.busy = true;
          const response = await this.Api.send('post', 'trunks/' + this.data.i_account + '/fax-to-mail', this.form);
          if (response.success) {
              document.dispatchEvent(new CustomEvent('refreshRequested'));
              this.faxStep++;
          }else{
            this.stepDown();
          }
          this.busy = false;
          return response;
      },
      stepUp( ){
        if(this.faxStep == 2){
          this.update( );
        }else {
          this.faxStep++;
        }
      },
      stepDown( ){
        this.faxStep--;
      }
    },
  watch: {
    visible(val) {
      if (!val) {
        this.faxStep = 1;
      }
    }
  },
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    created(){
        this.getDidList( );
    }
};
</script>
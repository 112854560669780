<template>
    <ComplexDialog
        :loading="loading || busy"
        v-model="visible"
        :max-width="500"
        :title="'Change '+data.account_type_label+' Password'"
    >
      <PasswordField v-model="updatePasswordData.new_password" label="New Password *" outlined autoGenerate/>
    <!--<PasswordUpdate :error="error" @updateApplied="changePassword" v-model="updatePasswordData"/>-->
    <template v-slot:actions>
        <v-spacer/>
        <!-- <v-btn text @click="changePassword" color="primary" :disabled="!updatePasswordData.current_password || !updatePasswordData.new_password || updatePasswordData.new_password!==updatePasswordData.confirm_password">Apply</v-btn>-->
      <v-btn text @click="changePassword" color="primary" :disabled="!updatePasswordData.new_password">Apply</v-btn>
      </template>
    </ComplexDialog>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
//import PasswordUpdate from "../../pieces/PasswordUpdate";
import PasswordField from "../../pieces/PasswordField";
export default {
    name: 'TrunkUpdatePasswordDialog',
    mixins: [apiMixin, dialogMixin],
    components: {PasswordField, ComplexDialog },
    data: () => ({
      busy: false,
      error:null,
      updatePasswordData:{
        "new_password":null,
      },
    }),
    props: {
      data: {},
    },

  methods: {
    async changePassword() {
      this.busy = true;
      //const response = await this.Api.send('put', 'trunks/'+this.data.i_account+'/update-password', { current_password: this.updatePasswordData.current_password, new_password: this.updatePasswordData.new_password,check_password: this.updatePasswordData.confirm_password });
      const response = await this.Api.send('put', 'trunks/'+this.data.i_account+'/update-password', { new_password: this.updatePasswordData.new_password});
      if (response.success) {
        this.busy = false;
        this.visible = false;
        this.updatePasswordData = {
          "new_password":null,
        };
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      } else {
        if(response.data.description){
          this.error = response.data.description;
        }
        this.busy = false;
      }
    }
  }
};
</script>

<template>
  <v-form>
    <v-row dense no-gutters>
      <v-col cols="12" md="4" class="pr-md-2">
        <TimePicker :errorMessages="(startTimeError==null?[]:[startTimeError])" v-model="form.start_time"
                    label="Start time" clearable :disabled="form.all_day"/>
      </v-col>
      <v-col cols="12" md="4" class="px-md-2">
        <TimePicker :errorMessages="(endTimeError==null?[]:[endTimeError])" v-model="form.end_time" label="End time"
                    clearable :disabled="form.all_day"/>
      </v-col>
      <v-col cols="12" md="4" class="pl-md-2">
        <v-switch v-model="form.all_day" label="Whole day"/>
      </v-col>
      <v-col cols="12">
        <TimeConditionWeekdayPicker v-model="form.weekdays" outlined/>
      </v-col>
      <v-col cols="12">
        <TimeConditionDayPicker v-model="form.days" outlined/>
      </v-col>
      <v-col cols="12">
        <TimeConditionMonthPicker v-model="form.months" outlined/>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const TimeRegex = /^([0-1]\d|2[0-3])(?::([0-5]\d)(?::([0-5]\d))?)$/i;
import TimeConditionMonthPicker from '@/components/pieces/Trunk/TimeConditionMonthPicker';
import TimeConditionWeekdayPicker from '@/components/pieces/Trunk/TimeConditionWeekdayPicker';
import TimeConditionDayPicker from '@/components/pieces/Trunk/TimeConditionDayPicker';
import TimePicker from '@/components/pieces/Forms/TimePicker';

export default {
  name: 'TimeConditionForm',
  components: {TimePicker, TimeConditionDayPicker, TimeConditionWeekdayPicker, TimeConditionMonthPicker},
  data: () => ({
    form: {
      months: [],
      weekdays: [],
      days: [],
      hours: [],
      start_time: null,
      end_time: null,
      all_day: false,
    },
    startTimeError: null,
    endTimeError: null,
  }),
  watch: {
    value(val) {
      if (val === null) {
        this.form.months = [];
        this.form.weekdays = [];
        this.form.days = [];
        this.form.hours = [];
        this.form.all_day = false;
        this.form.start_time = null;
        this.form.end_time = null;
      }
    },
    form: {
      deep: true,
      handler: function (value) {
        this.startTimeError = null;
        this.endTimeError = null;
        if ((value.months.length || value.weekdays.length || value.days.length || value.hours.length) && ((value.start_time && value.end_time) || value.all_day)) {
          let timeWindowParts = [];
          let descriptionString = '';
          // parse weekdays, days of month & months
          if (value.weekdays.length) {
            timeWindowParts.push('wd{' + value.weekdays.join(' ') + '}');
            descriptionString += ' on ' + value.weekdays.join(', ');
          }
          if (value.days.length) {
            timeWindowParts.push('md{' + value.days.join(' ') + '}');
            descriptionString += ' on ' + value.days.join(', ');
          }
          if (value.months.length) {
            timeWindowParts.push('mo{' + value.months.join(' ') + '}');
            descriptionString += ' of ' + value.months.join(', ');
          }
          // parse start/end times
          let timePeriodString = timeWindowParts.join(' ');

          if (value.start_time && value.end_time) {
            let startTime = value.start_time.match(TimeRegex);
            let endTime = value.end_time.match(TimeRegex);
            let times = [];
            // add in hours and minutes conditions to times array
            if (startTime && endTime) {
              let startHour = parseInt(startTime[1]);
              let startMinute = parseInt(startTime[2]);
              let endHour = parseInt(endTime[1]);
              let endMinute = parseInt(endTime[2]);
              // check start time > end time
              if (startHour <= endHour && (startHour === endHour ? startMinute < endMinute : true)) {
                descriptionString = 'From ' + value.start_time + ' to ' + value.end_time + descriptionString;
                // check if time period starts/stops within same hour
                if (startHour === endHour) {
                  times.push('hr{' + startHour.toString() + '}min{' + startMinute.toString() + '-' + endMinute.toString() + '}');
                } else {
                  // if time does not start at 0 minutes, add time to cover from start minutes to 59 (up to start of next hour)
                  if (startMinute !== 0) {
                    times.push('hr{' + startHour.toString() + '}min{' + startMinute.toString() + '-59}');
                    startHour = startHour + 1;
                  }
                  if (startHour < endHour - 1) {
                    times.push('hr{' + startHour.toString() + '-' + (endHour - 1).toString() + '}');

                  } else if (startHour === endHour - 1) {
                    times.push('hr{' + startHour.toString() + '}');
                  }
                  // if time does not end at 0 minutes, add time to cover from 0 to minute value of last hour
                  if (endMinute !== 0) {
                    times.push('hr{' + endHour.toString() + '}min{0-' + endMinute.toString() + '}');
                  }
                }
              } else {
                this.startTimeError = 'Must be before end time.';
                this.endTimeError = 'Must be after start time.';
                return;
              }
            }
            // loop through times array and prepend each one to existing timeWindowString with comma separators
            if (times.length) {
              timeWindowParts = [];
              times.forEach(e => {
                timeWindowParts.push(e + (timePeriodString ? ' ' + timePeriodString : ''));
              });
              timePeriodString = timeWindowParts.join(',');
            }
          } else {
            descriptionString = 'All day' + descriptionString;
          }
          if (this.callBackFunction != null) {
            this.callBackFunction(timePeriodString ? {
              period: timePeriodString,
              description: descriptionString
            } : null);
          } else {
            this.$emit('input', timePeriodString ? {
              period: timePeriodString,
              description: descriptionString
            } : null);
          }
        } else {
          if (this.callBackFunction != null) {
            this.callBackFunction(null);
          } else {
            this.$emit('input', false);
          }
        }
      }
    }
  },
  props: {
    value: {
      default: null
    },
    /**
     * This property is needed as the default $emit to 'input' method for passing the data does not work well.
     * Because the dialogMixin listens to emit 'input' and this changes the visibility of any parent dialog.
     * So we cannot use it here is this form is in a nested dialog. IE dialog in a dialog.
     */
    callBackFunction: {
      type: Function,
      default: null
    }
  }
};
</script>
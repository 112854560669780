<template>
    <div>

      <v-row no-gutters>
        <v-col
            v-for="(item, key) in faxtomailPlanData.types"
            :key="key" :cols="$vuetify.breakpoint.xsOnly ? 12 : undefined"
        >
          <v-hover
              v-slot="{ hover }"
          >
            <v-card
                v-ripple
                outlined
                :elevation="subProduct===item.value ? 4 : hover ? 2 : 0"
                :color="subProduct===item.value ? highlightColor : hover ? 'background_accent' : undefined"
                :height="($vuetify.breakpoint.xsOnly ? 90 : 150) + (subProduct===item.value ? 10 : 0) + (item.price_label ? 20 : 0)"
                class="px-2 text-center"
                :class="subProduct===item.value ? 'ma-2 py-5' : 'ma-3 py-4'"
                @click="changeSubProduct(item.value)"
            >
              <v-icon v-html="item.icon" color="secondary" size="48" :class="$vuetify.breakpoint.xsOnly ? 'float-left ma-2' : 'mb-2'" />
              <h3 class="mb-0">{{item.name}}</h3>
              <p class="text--disabled subtitle-2 mb-1">{{item.description}}</p>
              <p style="font-size:x-large"><strong>{{item.price_label}}</strong></p>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

    </div>
</template>

<script>
import PlanSelectSlider from '../Forms/PlanSelectSlider';
import planDataMixin from '../../../mixins/planDataMixin';
export default {
    name: 'FaxToMailPlanForm',
    mixins: [planDataMixin],
    components: { },
    data: () => ({

        subProduct: null,
        productOption: null,

        originalSubProduct: null,
        originalProductOption: null,
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
        dirtyAndValid() {
          return (this.subProduct != this.originalSubProduct) && (this.subProduct != null);
        }
    },
    watch: {
        productOption(value) {
            this.update();
        }
    },
    methods: {
        changeProductOption(value) {
            this.productOption = value;
        },
        changeSubProduct(value) {
          this.subProduct = value;
        },
        update() {
            if (this.dirtyAndValid) {
              this.$emit('input', {
                subProduct: this.subProduct,
                productOption: this.productOption
              });
            } else {
              this.$emit('input', null);
            }

        }
    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
  created() {
    if(this.planDetails.planTag) {
      this.originalSubProduct = this.planDetails.planTag;
      this.subProduct = this.planDetails.planTag;
    }else{
      if(!this.newProduct) {
        this.originalSubProduct = 'no-plan';
      }
      this.subProduct = 'no-plan';
      if(this.newProduct){
        //watch is not triggered in created
        this.update( );
      }
    }

    /*
    We need to build the watcher here as the watcher fires after the created method and clears the productOption value.
    Using the beforeCreate method does not work as it cannot access planDetails. Not sure why but I could not get it working and found this solution.
     */
    this.$watch('subProduct', () => {
      this.update(); //clear the submitted values. Needs a product option
      this.productOption = null;
    });

  },
    props: {
      planDetails: {
        type: Object,
        default: function() { return {}; },
      },
      newProduct: {
        type: Boolean,
        default:false
      }
    }
};
</script>
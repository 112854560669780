<template>
  <div>
    <v-select
        v-model="input"
        :loading="!items || busy"
        :items="items"
        item-value="i_cp_condition"
        item-text="name"
        :dense="dense"
        :outlined="outlined"
        :hide-details="hideDetails"
        :label="label"
        @change="updated"
    />
    <CallScreeningTimeWindowDialog v-model="showDialog" @change="change" :data="data" :conditions="conditions != null ? conditions.filter(e => e.type === 'TimeWindow') : null" />
  </div>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import CallScreeningTimeWindowDialog from "../../dialogs/Trunk/CallScreeningTimeWindowDialog";

export default {
  name: 'TrunkCallScreeningTimeConditionSelect',
  components: { CallScreeningTimeWindowDialog },
  mixins: [apiMixin],
  data: () => ({
    input: null,
    busy: false,
    items: [],
    showDialog: false
  }),
  methods: {
      async update() {
        this.busy = true;
        const response = await this.Api.send('get', 'trunks/' + this.data.i_account + '/call_screening_conditions?type=TimeWindow', null, true);
        if (response.success) {
          this.items = [ {i_cp_condition: 0, name: 'New time window'}, {i_cp_condition: 'divider', divider: true}, {i_cp_condition: null, name: 'Any time'}, ...response.data ];
        }
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.busy = false;
      },
    change(val) {
      this.input = val;
      this.update();
    },
    updated(val) {
        if (val===0) {
            this.showDialog = true;
        }
    }
  },
  watch: {
      input(val) {
        if (val!==this.value) this.$emit('input', val!==0 ? val : null);
      },
    showDialog(val) {
      if (!val && this.input===0) this.input = null;
    }
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    conditions: {
      type: Array,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Time window'
    },
  },
  created() {
    if (this.value) this.input = this.value;
    if (this.conditions) {
      this.items = [ {i_cp_condition: 0, name: 'New time window'}, {i_cp_condition: 'divider', divider: true}, {i_cp_condition: null, name: 'Any time'}, ...this.conditions.filter(e => e.type === 'TimeWindow') ];
    } else {
      this.update();
    }
  }
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ComplexDialog',{attrs:{"title":_vm.title,"max-width":650,"loading":_vm.busy},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.step === 2)?_c('v-btn',{attrs:{"text":"","disabled":_vm.busy || _vm.taskProgress > 0},on:{"click":function($event){_vm.step--}}},[_vm._v("Back")]):_vm._e(),_c('v-spacer'),(_vm.step !== 2)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.busy ||
                (_vm.step === 1 && !_vm.newTrunkId) ||
                (_vm.trunkId && _vm.trunkId === _vm.newTrunkId),"text":""},on:{"click":function($event){_vm.step++}}},[_vm._v("Next")]):_vm._e(),(_vm.step === 2 && _vm.taskProgress < 100)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.busy,"text":""},on:{"click":_vm.moveDIDs}},[_vm._v("Move "+_vm._s(_vm.didListLocal.length)+" DID"),(_vm.didListLocal.length !== 1)?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e(),(_vm.step === 2 && _vm.taskProgress === 100)?_c('v-btn',{attrs:{"disabled":_vm.busy,"color":"primary","tooltip":"Close this window","text":""},on:{"click":function($event){_vm.visible=false}}},[_vm._v("Close")]):_vm._e()]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.visible)?_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('p',{staticClass:"subtitle-1 text--primary"},[_vm._v(" Where would you like to move the selected DIDs? ")]),_c('v-expand-transition',[(!!_vm.customerId && !_vm.changeCustomer)?_c('p',[_c('a',{on:{"click":_vm.showCustomerChoice}},[_vm._v("Click here")]),_vm._v(" to transfer to a different customer ")]):_vm._e()]),_c('v-expand-transition',[(!_vm.customerId || _vm.changeCustomer)?_c('CustomerDropdown',{attrs:{"autofocus":""},on:{"clear":function($event){_vm.newTrunkId = null},"update":_vm.updateCustomer},model:{value:(_vm.newCustomerId),callback:function ($$v) {_vm.newCustomerId=$$v},expression:"newCustomerId"}}):_vm._e()],1),_c('v-expand-transition',[((_vm.customerId && !_vm.changeCustomer) || _vm.newCustomerId)?_c('div',[_c('TrunkDropdown',{attrs:{"customer-id":_vm.newCustomerId ? _vm.newCustomerId : _vm.customerId,"error-messages":_vm.trunkId && _vm.trunkId === _vm.newTrunkId
                                ? 'The selected DIDs are already assigned to this account'
                                : '',"label":'Account',"init-dropdown":""},on:{"update":_vm.updateTrunk},model:{value:(_vm.newTrunkId),callback:function ($$v) {_vm.newTrunkId=$$v},expression:"newTrunkId"}})],1):_vm._e()])],1),_c('v-window-item',{attrs:{"value":2}},[(_vm.newTrunkId)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"subtitle-1 text--primary"},[(!_vm.busy && !_vm.taskProgress)?_c('span',[_vm._v("Please review the list of "+_vm._s(_vm.didListLocal.length)+" DID"),(_vm.didListLocal.length !== 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" to be moved to "+_vm._s(_vm.trunkData ? _vm.trunkData.account_type_label : 'Trunk')+" '"+_vm._s(_vm.newTrunkData ? _vm.newTrunkData.id : '')+"' (ID "+_vm._s(_vm.newTrunkData ? _vm.newTrunkData.i_account : '')+").")]):_vm._e(),(_vm.busy)?_c('span',[_vm._v("Please wait while the DIDs are moved...")]):_vm._e(),(!_vm.busy && _vm.taskProgress)?_c('span',[_vm._v("Operation completed, please check results below.")]):_vm._e()]),(_vm.busy || _vm.taskProgress > 0)?_c('v-progress-linear',{staticClass:"my-4",attrs:{"rounded":"","height":"25","value":_vm.taskProgress},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,false,3002676263)}):_vm._e(),_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"400"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Phone Number")]),(_vm.changeCustomer || !_vm.customerId)?_c('th',[_vm._v(" Existing Customer ")]):_vm._e(),(_vm.changeCustomer || !_vm.customerId)?_c('th',[_vm._v(" New Customer ")]):_vm._e(),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.didListLocal),function(item){return _c('tr',{key:item.number,attrs:{"dense":""}},[_c('td',[_vm._v(_vm._s(item.number))]),(_vm.changeCustomer || !_vm.customerId)?_c('td',[_vm._v(" "+_vm._s(item.i_customer ? item.customer_name : '-')+" ")]):_vm._e(),(_vm.changeCustomer || !_vm.customerId)?_c('td',[_vm._v(" "+_vm._s(_vm.newCustomerData ? _vm.newCustomerData.name : '-')+" ")]):_vm._e(),_c('td',[(!_vm.busy && !_vm.taskProgress)?_c('span',[(
                                                item.i_account ||
                                                item.i_customer
                                            )?_c('span',[_vm._v("Move")]):_c('span',[_vm._v("Assign")]),(_vm.newTrunkId > 0)?_c('span',[_vm._v(" DID to trunk '"+_vm._s(_vm.newTrunkData ? _vm.newTrunkData.id : '')+"'")]):(item.i_customer)?_c('span',[_vm._v(" to customer"),(_vm.newCustomerData)?_c('span',[_vm._v(" '"+_vm._s(_vm.newCustomerData.id)+"'")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.busy || _vm.taskProgress)?_c('span',[(item.success)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.error)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-alert")]):_vm._e(),(item.busy)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}}):_vm._e(),_vm._v(_vm._s(item.status)+" ")],1):_vm._e()])])}),0)])],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <BasicCard
        title="Emergency Services Address (IPND)"
        v-bind:class="{
            'error lighten-4': (!$vuetify.theme.dark && portaAccountData.ipnd_message.type == 'error'),
            'error darken-4': ($vuetify.theme.dark && portaAccountData.ipnd_message.type == 'error'),
            'warning lighten-4': (!$vuetify.theme.dark && portaAccountData.ipnd_message.type == 'warning'),
            'warning darken-4': ($vuetify.theme.dark && portaAccountData.ipnd_message.type == 'warning'),
        }"
        :loading="isBusy"
    >
      <template v-slot:feature>
        <v-icon x-large color="error" v-if="portaAccountData.ipnd_message.type == 'error'">mdi-alert-circle</v-icon>
        <v-icon x-large color="error" v-if="portaAccountData.ipnd_message.type == 'warning'">mdi-alert</v-icon>
      </template>

      <Alert
        :type="portaAccountData.ipnd_message.type"
        :message="portaAccountData.ipnd_message.message"
      />
      </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard.vue';
import Alert from "../../pieces/Alert.vue";

export default {
    name: 'TrunkIPNDMessageCard',
    components: { BasicCard,Alert},
    //mixins: [apiMixin],
    data: () => ({
        busy: false,
        editConfirmedAddressDialog: false,
        editUnconfirmedAddressDialog: false,
    }),
    computed: {
        isBusy: function () {
          return this.busy || this.loading;
        },
    },
    props: {
      portaAccountData: {
        type: Object,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

};
</script>

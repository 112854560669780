<template>
    <BasicCard title="Call Forwarding" :loading="loading || busy || !forwarding_mode" :no-skeleton="!!data.service_features && !!followme && !!forwarding_mode">
        <v-row dense class="text--primary">
          <v-col cols="12" class="text-h6">
            {{this.trunkSettingsData.forwardingModes.find((e) => e.value === forwarding_mode) ? this.trunkSettingsData.forwardingModes.find((e) => e.value === forwarding_mode).text : 'Unknown'}}
          </v-col>
        </v-row>
      <v-row dense class="text--primary" v-if="forwarding_mode==='F' || forwarding_mode==='Y'">
        <v-col cols="6">
          Sequence: {{followme && followme.followme_info && followme.followme_info.sequence ? followme.followme_info.sequence : 'Unknown'}}
        </v-col>
      </v-row>
      <v-row dense class="text--primary" v-if="forwarding_mode!=='N' && followme && followme.followme_numbers">
        <v-alert type="error" text class="mt-4 mb-0" v-if="followme.followme_numbers.length == 0">
          No forwarding destinations set. Please update settings.
        </v-alert>
        <v-col cols="12" v-for="item in followme.followme_numbers" :key="item.i_follow_me_number">
          <span class="font-weight-bold text--secondary" v-text="item.i_follow_order + '. '"/>
          <span class="text--secondary" v-text="'Forward to '"/>
          {{item.redirect_number}}
          <span class="text--secondary" v-text="' - timeout '"/>
          {{item.timeout}}s
          <span class="text--secondary" v-text="(item.keep_original_cli==='Y' ? ', keep CLI' : '') + (item.keep_original_cld==='Y' ? ', keep CLD' : '')"/>
        </v-col>
      </v-row>
      <template v-slot:actions>
            <ButtonWithTooltip text="Edit" icon="mdi-pencil" flat @click="showDialog=true" :disabled="loading || busy" />
        </template>
      <CallForwardSettingsDialog v-model="showDialog" :data="data" :loading="loading" :followme="followme" />

    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CallForwardSettingsDialog from "../../dialogs/Trunk/CallForwardSettingsDialog";
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import apiMixin from '@/mixins/apiMixin';
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
export default {
    name: 'TrunkCallForwardingCard',
    components: { CallForwardSettingsDialog, ButtonWithTooltip, BasicCard },
    mixins: [apiMixin, trunkSettingsDataMixin, refreshListenerMixin],
    data: () => ({
        showDialog: false,
        followme: null,
        busy: false
    }),
    computed: {
        forwarding_mode() {
            return this.data && this.data.service_features ? this.data.service_features.find((e) => e.name === 'forward_mode').effective_flag_value : null;
        },
        iAccount( ){
          return this.data.i_account;
        }
    },
    methods: {
      async update() {
        this.busy = true;
        const response = await this.Api.send('get','trunks/' + this.data.i_account + '/follow_me', null, true);
        if (response.success) {
          this.followme = response.data;
          this.visible = false;
        }
        this.busy = false;
      }
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    watch: {
      loading(val) {
        if (!val && this.iAccount){
          this.update();
        }
      },
      iAccount(val){
        if(val && !this.loading){
          this.update();
        }
      }
    },
    mounted() {
      if (!this.loading) this.update();
    }
};
</script>

<style scoped></style>

<template>
    <v-chip
        small
        :color="color"
        :label="label"
    >
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    name: 'InvoiceStatus',
    props: {
        data: {
            default: [],
        },
        label: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        text: function () {
            if (this.data.is_void==='Y') return 'VOID';
            let status = this.data.invoice_status || this.data.invoice_status_desc || 'Unknown';
            if (status === 'Previous balance remaining') status = 'Do not pay *';
            return status;
        },
        color: function () {
            switch(this.data.i_invoice_status) {
                case 2:
                case 3:
                    return 'info';
                case 4:
                    return 'error';
                case 1:
                case 5:
                case 6:
                    return 'success';
            }
            return '';
        },
    },
};
</script>

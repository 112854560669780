<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <FaxToMailListCard
                :cust-id="customerData.i_customer"
                :loading="customerLoading"
                @update="updateFilter"
                :filter="filter"
                show-filters
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Add New Fax to Mail account" icon="mdi-plus" @click="showNewFaxToMail=true" :loading="customerLoading" />
      <NewTrunkDialog v-model="showNewFaxToMail" v-if="customerData.i_customer" type="fax-to-mail" :customer-id="customerData.i_customer" :customer-data="customerData" @trunk="goToAccount" />
    </v-row>
</template>

<script>
import FaxToMailListCard from "../../components/cards/FaxToMail/FaxToMailListCard";
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import NewTrunkDialog from "../../components/dialogs/Trunk/NewTrunkDialog";
import FloatingButton from '@/components/pieces/FloatingButton';
export default {
    name: 'CustomerFaxToMailList',
    mixins: [filterMixin, customerDataMixin],
    components: {
      NewTrunkDialog,
      FloatingButton,
      FaxToMailListCard,
    },
  methods: {
      goToAccount(id) {
        this.showNewFaxToMail = false;
        let route = this.$router.resolve({ name: 'TrunkSummary', params: { customerId: this.customerData.i_customer, trunkId: id }});
        this.$router.push({ name: 'TrunkSummary', params: { customerId: this.customerData.i_customer, trunkId: id }});
      }
  },
  computed: {
    customerTypeTag: function () {
      if(this.customerData != undefined){
        return this.customerData.customer_class_tag;
      }
      return undefined;
    }
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            id: null,
            note:null,
            active: 'Y'
        },
        showNewFaxToMail: false
    })
};
</script>

<template>
    <v-chip :small="small" :color="color">
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    name: 'DIDCarrierStatus',
    props: {
        small: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: null,
        },
    },
    computed: {
        text: function () {
            switch (this.status) {
                case 'active':
                    return 'Active';
                case 'pending':
                    return 'Pending';
                case 'terminated':
                    return 'Terminated';
                case 'not_found':
                    return 'Not Found';
                case 'check_routing':
                    return 'Check Routing';
                default:
                    return 'Unknown';
            }
        },
        color: function () {
            switch (this.status) {
                case 'active':
                    return 'success';
                case 'pending':
                case 'check_routing':
                    return 'warning';
                case 'terminated':
                case 'not_found':
                    return 'error';
                default:
                    return null;
            }
        },
    },
};
</script>

<template>
    <BasicCard
        :loading="isLoading"
        title="Subscription Details"
    >
      <v-row no-gutters v-if="subscriptions">
        <v-col
            cols="12"
        >


          <v-sheet class="pa-4 mb-2" rounded color="background_accent" v-for="(subscription,rowIndex) in subscriptions"
                   :key="rowIndex">
            <v-chip label small class="float-right">{{ subscription.int_status == 1 ? 'Active' : 'Pending'}}</v-chip>
            <h3 class="text--primary">Call Queue subscription</h3>
            <p class="mb-0">
              <Currency :value="subscription.effective_fee" :add-gst="false" hide-tooltip /> per month
              <span v-if="subscription.activation_date">
              <br />Effective from
              <LocalDateTime :value="subscription.activation_date" />
              </span>
              <span v-else-if="subscription.start_date">
              <br />Start Date
              <LocalDateTime :value="subscription.start_date" />
              </span>
              <span v-if="subscription.finish_date">
                <br />End Date
                <LocalDateTime :value="subscription.finish_date" />
              </span>
            </p>
          </v-sheet>
        </v-col>
      </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import apiMixin from '@/mixins/apiMixin';
import Currency from "../../pieces/Currency";
import LocalDateTime from "../../pieces/LocalDateTime";
export default {
    name: 'CallQueueSubscriptionCard',
    components: {BasicCard,Currency,LocalDateTime},
    mixins: [apiMixin],
  props: {
    loading: {
      type: Boolean,
      default:false,
    },
    callQueueData: {
      type: Object,
      required: true
    },
  },
    data: () => ({
      busy:false,
      subscriptions: [],
    }),
  methods:{
    async getSubscription() {
      this.busy = true;
      const response = await this.Api.send('get','call-queues/'+this.callQueueData.i_c_queue+'/subscription');
      this.busy = false;
      if(response.success) {
        this.subscriptions = response.data;
      }
    },
  },
  created: function(){
    this.getSubscription( );
  },
  computed:{
      isLoading( ){
        return this.loading || this.busy;
      }
  }
};
</script>

<template>
    <SimpleDialogOld
        :visible="visible"
        title="Import DID List"
        @close="close"
        @click="emitDIDs"
        yes-text="Import"
        no-text="Cancel"
        :yes-enabled="didList.length"
        persistent
    >
        <p>Please paste a list of DIDs to be imported below.</p>
        <v-textarea
            auto-grow
            outlined
            v-model="importText"
            :messages="
                didList.length +
                ' DID' +
                (didList.length !== 1 ? 's' : '') +
                ' (or range' +
                (didList.length !== 1 ? 's' : '') +
                ') to import'
            "
        />
    </SimpleDialogOld>
</template>

<script>
import SimpleDialogOld from '../../dialogs/templates/SimpleDialogOld';
export default {
    name: 'DIDListImport',
    components: { SimpleDialogOld },
    data: () => ({
        data: [],
        importText: '',
    }),
    methods: {
        emitDIDs: function () {
            this.$emit('import', this.didList);
            this.importText = '';
            this.$emit('close');
        },
        close: function () {
            this.importText = '';
            this.$emit('close');
        },
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        didList: function () {
            let regexResults = this.importText.match(
                /(?:(?:61|0)[2,3,4,7,8](?:\d{6}00-99|\d{7}0-9|\d{7}#|\d{6}##|\d{8})|(?:61)?(?:1300|1800)\d{6})/g
            );
            let results = [];
            if (regexResults) {
                regexResults.forEach((value) => {
                    if (value.charAt(0) === '0') {
                        value = `61${
                            value.charAt(0) === '0' ? value.substring(1) : value
                        }`;
                    }

                    switch ((value.match(/#/g) || []).length) {
                        case 1:
                            results.push(value.replace('#', '0-9'));
                            break;
                        case 2:
                            results.push(value.replace('##', '00-99'));
                            break;
                        default:
                            results.push(value);
                            break;
                    }
                });
            }
            results.sort();
            return results;
        },
    },
};
</script>

<style scoped></style>

<template>
  <SimpleDialog
      :title="'Linked Actions'"
      v-model="visible"
      :loading="busy"
      okOnly
      yesText="Close"
      :maxWidth="800"
      styledHeader
  >
  <p>Actions that are linked to Auto Attendant '{{aaData.name}}'.</p>
  <AutoAttendantTargetedTransitionsListCard :trunkData="trunkData" :aaData="aaData" />

  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../templates/SimpleDialog";
import AutoAttendantTargetedTransitionsListCard
  from "../../cards/AutoAttendant/AutoAttendantTargetedTransitionsListCard";
import dialogMixin from "../../../mixins/dialogMixin";
    export default {
        name: 'AutoAttendantTargetedTransitionDialog',
        mixins: [dialogMixin],
        components: { SimpleDialog,AutoAttendantTargetedTransitionsListCard},
        props: {
            trunkData: {
            type: Object,
            required: true,
          },
          aaData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,

        }),
      methods:{

      },
      computed:{

      },
      created() {
      }
    };
</script>
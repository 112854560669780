<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <CallQueueListCard
                :i_customer="customerData.i_customer"
                :loading="customerLoading"
                @update="updateFilter"
                :filter="filter"
                show-filters
                :localSearch="false"
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Create new Call Queue" icon="mdi-plus" @click="showNewCallQueue=true" :loading="customerLoading" />
      <CreateCallQueueDialog v-model="showNewCallQueue" :i_customer="customerData.i_customer"/>
    </v-row>
</template>

<script>
import CallQueueListCard from "../../components/cards/CallQueues/CallQueueListCard";
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import CreateCallQueueDialog from "../../components/dialogs/CallQueues/CreateCallQueueDialog";
export default {
    name: 'CustomerRingGroupList',
    mixins: [filterMixin, customerDataMixin],
    components: {
      CreateCallQueueDialog,
      FloatingButton,
      CallQueueListCard
    },
  methods: {
      goToAccount(id) {
        this.showNewCallQueue = false;
        let route = this.$router.resolve({ name: 'TrunkSummary', params: { customerId: this.customerData.i_customer, trunkId: id }});
        this.$router.push({ name: 'TrunkSummary', params: { customerId: this.customerData.i_customer, trunkId: id }});
      }
  },
  computed: {
    customerTypeTag: function () {
      if(this.customerData != undefined){
        return this.customerData.customer_class_tag;
      }
      return undefined;
    }
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            id: null,
            active: 'Y'
        },
      showNewCallQueue: false
    })
};
</script>

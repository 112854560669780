<template>
    <ComplexDialog
        :title="(form.i_cp_rule ? 'Edit' : 'Add') + ' Call Screening Rule'"
        v-model="visible"
        :loading="busy"
    >

      <SwitchYesNoInput v-model="form.is_active" label="Enable rule" class="pb-4" />
      <TrunkCallScreeningFromConditionSelect v-model="form.from_number_i_cp_condition" :data="data" :conditions="conditions" outlined />
      <TrunkCallScreeningToConditionSelect v-model="form.to_number_i_cp_condition" :data="data" :conditions="conditions" outlined />
      <TrunkCallScreeningTimeConditionSelect v-model="form.time_window_i_cp_condition" :data="data" :conditions="conditions" outlined />
      <v-select outlined label="Action" :items="selectAnswerModes" v-model="form.action" />

        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="false"
                :loading="busy"
                @click="sendRequest"
                text
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import TrunkCallScreeningFromConditionSelect from '@/components/pieces/Trunk/TrunkCallScreeningFromConditionSelect';
import TrunkCallScreeningToConditionSelect from '@/components/pieces/Trunk/TrunkCallScreeningToConditionSelect';
import TrunkCallScreeningTimeConditionSelect from '@/components/pieces/Trunk/TrunkCallScreeningTimeConditionSelect';
import SwitchYesNoInput from '@/components/pieces/SwitchYesNoInput';
export default {
    name: 'CallScreeningRuleDialog',
    mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
    components: { SwitchYesNoInput, TrunkCallScreeningTimeConditionSelect, TrunkCallScreeningToConditionSelect, TrunkCallScreeningFromConditionSelect, ComplexDialog },
    data: () => ({
        valid: false,
        form: {
            i_cp_rule: null,
            action: 2,
            is_active: 'Y',
            order_number: null,
            to_number_i_cp_condition: null,
            from_number_i_cp_condition: null,
            time_window_i_cp_condition: null
        },
        busy: false,
        timeoutRules: [
          (v) => !!v || 'Required',
          (v) => /^\d+$/i.test(v) || 'Enter a valid number',
          (v) => parseInt(v)<=120 || 'Maximum timeout is 120 seconds',
          (v) => parseInt(v) !== 0 || 'Cannot be zero',
        ],
      selectAnswerModes:[],
    }),
    methods: {
        async sendRequest() {
            this.busy = true;
            const response = await this.Api.send(!!this.form.i_cp_rule ? 'put' : 'post', 'trunks/' + this.data.i_account + '/call_screening_rule', this.form);
            if (response.success) {
              this.$emit('refresh');
              this.visible = false;
            }
            this.busy = false;
        },
      genSelectAnswerModes( ){
        let rework = null;
        if(this.data.account_type == 'RING-GROUP'){
          rework = this.trunkSettingsData.answerModes.filter(mode => mode.text.indexOf("Ring") != 0);
          rework = rework.map(mode2 =>  {
            if(mode2.text == 'Forward Only'){
              mode2.text = 'Ring Only';
            }else if(mode2.text == 'Forward then voicemail'){
              mode2.text = 'Ring then voicemail'
            }
            return mode2;
          });
          this.selectAnswerModes = rework;
        }else {
          this.selectAnswerModes =  this.trunkSettingsData.answerModes;
        }
      }
    },
  computed: {
    accountType( ){
      if(this.data && this.data.account_type){
        return this.data.account_type;
      }else{
        return null;
      }
    },
  },
  created(){
    this.genSelectAnswerModes( );
  },
  watch: {
    visible(val) {
      if (val) {
         if (!!this.rule && this.rule.i_cp_rule) this.form = { ...this.rule };
      } else {
         this.form = {
           i_cp_rule: null,
           action: 2,
           is_active: 'Y',
           order_number: null,
           to_number_i_cp_condition: null,
           from_number_i_cp_condition: null,
           time_window_i_cp_condition: null
         }
      }
    },
    accountType(val){
      if(val != null){
        this.genSelectAnswerModes( );
      }
    }
  },
    props: {
        rule: {
            type: Object,
            default: null
        },
        conditions: {
            type: Array,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    }
};
</script>
<template>
    <v-row dense>
        <v-col cols="12" v-if="busyCount > 0">
            Loading...
        </v-col>
        <v-col cols="12" v-else>
            <v-radio-group v-if="!!data" v-model="address_id" class="mt-0 px-3">
                <v-radio
                    v-for="address in data"
                    :key="address.address_id"
                    :value="address.address_id"
                    class="mb-3"
                >
                    <template v-slot:label>
                        <AddressText :data="address" />
                    </template>
                </v-radio>
                <v-radio
                    :key="0"
                    :value="0"
                >
                    <template v-slot:label>
                        Enter a new address
                    </template>
                </v-radio>
            </v-radio-group>
            <v-expand-transition>
                <div v-if="!data || address_id===0">
                  <ContactDetailsForm
                      v-model="contact"
                      showBcc
                  />
                </div>
            </v-expand-transition>
        </v-col>
    </v-row>
</template>

<script>
    import apiMixin from '../../../mixins/apiMixin';
    import AddressText from '../Address/AddressText';
    import ContactDetailsForm from "./ContactDetailsForm";

    export default {
        name: 'ExistingContactDetailsForm',
        components: { AddressText,ContactDetailsForm },
        mixins: [apiMixin],
        data: () => ({
            busyCount: 0,
            data: null,
            error: false,
            address_id: null,
            contact: null,
            customerInfo: null
        }),
        methods: {
            async getContacts() {
              //this is a place holder for when we have contact for a customer.
                return true;
                // this.busyCount++;
                // const response = await this.Api.send('get','customers/' + this.customerId + '/addresses');
                // if (response.success) {
                //     this.data = response.data;
                // } else {
                //     this.error = true;
                // }
                // this.busyCount--;
            },
            async loadCustomer()
            {
              this.busyCount++;
              const response = await this.Api.send('post','customers/' + this.customerId + '/info');
              if (response.success) {
                  this.customerInfo = response.data;
                  //build the contact
                  let contact = {
                    "salutation":response.data.salutation,
                    "firstname":response.data.firstname,
                    "lastname":response.data.lastname,
                    "phone1":response.data.phone1,
                    "phone2":response.data.phone2,
                    "email":response.data.email,
                    "bcc":response.data.bcc,
                  };
                  this.contact = contact;
                this.$emit('input', contact);
              } else {
                  this.error = true;
              }
              this.busyCount--;
            }
        },
        watch: {
            address_id(value) {
                let data = this.data.find((v) => v.address_id === value);
                this.address = !!data ? data : null;
            },
            contact(value) {
                this.$emit('input', value);
            }
        },
        props: {
            customerId: {
                type: Number,
                required: true
            },
            value: {
                type: Object,
                default: null
            }
        },
        created() {
          this.getContacts();
          this.loadCustomer();
        }
    };
</script>
<template>
    <BasicCard
        :loading="loading"
        title="Call Queue Settings"
    >
      <v-row dense>
        <v-col cols="7">
          <span class="font-weight-bold">Announce the number of callers ahead of them in the queue</span>
        </v-col>
        <v-col cols="5">
          <v-icon
              v-if="callQueueData.announce_number_of_callers == 'Y'"
              color="success"
              class="mr-2"
          >mdi-check</v-icon>
          <v-icon
              v-else
              color="error"
              class="mr-2"
          >mdi-cancel</v-icon>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="7">
          <span class="font-weight-bold">Announce estimated wait time</span>
        </v-col>
        <v-col cols="5">
          <v-icon
              v-if="callQueueData.announce_estimated_wait_time == 'Y'"
              color="success"
              class="mr-2"
          >mdi-check</v-icon>
          <v-icon
              v-else
              color="error"
              class="mr-2"
          >mdi-cancel</v-icon>
        </v-col>
      </v-row>
      <v-row dense v-if="(callQueueData.announce_estimated_wait_time == 'Y' || callQueueData.announce_number_of_callers == 'Y')">
        <v-col cols="7">
          <span class="font-weight-bold">Interval between announcements</span>
        </v-col>
        <v-col cols="5">
          {{callQueueData.announcement_interval}} minute{{callQueueData.announcement_interval > 1 ? 's' : ''}}
        </v-col>
      </v-row>

      <v-row dense v-if="(callQueueData.announce_estimated_wait_time == 'Y')">
        <v-col cols="7">
          <span class="font-weight-bold">Average handle time</span>
        </v-col>
        <v-col cols="5">
          {{callQueueData.average_duration}} minute{{callQueueData.average_duration > 1 ? 's' : ''}}
        </v-col>
      </v-row>

      <v-row dense v-if="(callQueueData.moh_file_name != null && callQueueData.moh_file_name != '')">
        <v-col cols="7">
          <span class="font-weight-bold">Play music on hold</span>
        </v-col>
        <v-col cols="5">
          <v-icon
              color="success"
              class="mr-2"
          >mdi-check</v-icon>
        </v-col>
      </v-row>

      <v-row dense v-if="(callQueueData.moh_file_name != null && callQueueData.moh_file_name != '')">
        <v-col cols="7">
          <span class="font-weight-bold">Music on Hold file</span>
        </v-col>
        <v-col cols="5">
          {{callQueueData.moh_file_name}}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="downloadFile()"
              >
                <v-icon v-html="'mdi-download'" />
              </v-btn>
            </template>
            <span>Download file</span>
          </v-tooltip>
        </v-col>
      </v-row>
        <template v-slot:actions>
          <v-btn
              v-if="$route.name != 'TrunkSummary'"
              :to="{ name: 'TrunkSummary',params: {customerId : callQueueData.i_customer, trunkId:callQueueData.ring_group_i_account} }"
              text
          ><v-icon v-html="'mdi-phone-voip'" left /> Ring Group</v-btn>

          <ButtonWithTooltip
              text="Edit"
              flat
              tooltip="Edit Settings"
              icon="mdi-pencil"
              @click="showEditDialog = true"
          />
        </template>
      <EditCallQueueSettingsDialog :callQueueData="callQueueData" :loading="loading" v-model="showEditDialog"/>
    </BasicCard>
</template>

<script>
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import EditRingGroupSettingsDialog from "../../dialogs/RingGroups/EditRingGroupSettingsDialog";
import EditCallQueueSettingsDialog from "../../dialogs/CallQueues/EditCallQueueSettingsDialog";
export default {
    name: 'CallQueueSettingsCard',
    mixins: [ringGroupDataMixin],
    components: {EditCallQueueSettingsDialog, ButtonWithTooltip, BasicCard },
    props: {
      callQueueData: {
        type: Object,
        required:true,
      },
      loading: {
        type: Boolean,
        default:false,
      },
    },
    data: () => ({
      showEditDialog: false,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
    }),
    methods:{
      downloadFile( )
      {
        const url = this.linkBase + 'call-queues/'+this.callQueueData.i_c_queue+'/moh-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },
    }
};
</script>

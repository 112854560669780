<template>
    <v-row dense>
        <v-col cols="12" sm="6" lg="4">
            <DeviceCard :data="data" :loading="loading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <DeviceProvisionCard :data="data" :loading="loading" />
        </v-col>
      <v-col cols="12" sm="6" lg="4">
        <DeviceUsersCard :data="data" :loading="loading" />
      </v-col>

    </v-row>
</template>

<script>
import DeviceCard from "../../components/cards/VoipDevice/DeviceCard";
import DeviceProvisionCard from "../../components/cards/VoipDevice/DeviceProvisionCard";
import DeviceUsersCard from "../../components/cards/VoipDevice/DeviceUsersCard";
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
export default {
    name: 'TrunkSummary',
    data: () => ({
        deviceData:null,
    }),
    components: {
        DeviceProvisionCard,
        DeviceCard,
        DeviceUsersCard,
    },
  mixins: [refreshListenerMixin],
  created: function( ) {
    if (this.iAccount) {
      this.update();
    }
  },
  methods:{
    async get(id, force) {
      let redirect = {
             name: 'TrunkSummary',
             params:{trunkId : this.trunkData.i_account},
         };
      this.fetchStoreRequest('voipDevice', 'extensions/'+this.trunkData.extensionDetails.i_c_ext+'/voip-devices/'+id, {id:id}, redirect, 'get', force);
      this.buildBreadCrumbs();
    },
    update() {
      this.get(this.$route.params.voipDeviceId, true);
    },
    buildBreadCrumbs( ){
      let breadCrumbs = [
        {
          name:"VoipDevice",
          label: "Device ("+this.$route.params.voipDeviceId+")",
          params:{"deviceId":this.$route.params.voipDeviceId}
        }
      ];
      this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
    }
  },
  watch: {
    iAccount(value) {
      if (value != null){
        this.update( );
      }
    },
    voipDeviceId(value) {
      this.update( );
    },
  },
    computed: {
        data() {
         return this.fetchStoreGetData('voipDevice', {id:this.$route.params.voipDeviceId})
        },
        loading() {
            return this.$store.state.loading.trunk;
        },
        iAccount( ){
          if(!this.trunkData){
            return null;
          }else{
            return this.trunkData.i_account;
          }
        },
      voipDeviceId( ){
          return this.$route.params.voipDeviceId
      },
        trunkData() {
          if (
              this.$store.state.data.trunk &&
              this.$store.state.data.trunk.i_account ===
              parseInt(this.$route.params.trunkId)
          ) {
            return this.$store.state.data.trunk;
          }
          return {};
        },
    }
};
</script>

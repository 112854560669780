<template>
  <ComplexDialog
    title="Edit IPND Address"
    v-model="visible"
    :loading="isBusy"
    :step="step"
    :step-end="4"
    :step-next-disabled="nextValid"
    :step-back-disabled="backValid"
    @step-next="next"
    @step-back="back"
    @step-end="finish"
    :step-end-text="'Close'"
    :step-next-text="nextLabel"
    :max-width="1000"
  >
    <v-window v-model="step">
      <v-window-item :value="1">
        <p>Update the Emergency Services Address (IPND) address. Note this must be a valid business or government address registered by the Geoscape Geocoded National Address File (G-NAF).</p>
        <v-card
          outlined
          class="mb-6"
        >
          <v-card-text>
            <v-row dense v-if="portaAccountData.ipnd_list[0].ipnd.customer_contact_name_1">
              <strong class="mr-2">Contact Name:</strong> {{ portaAccountData.ipnd_list[0].ipnd.customer_contact_name_1 }}
            </v-row>
          </v-card-text>
        </v-card>

        <AddressDetailsForm
          v-model="address"
          show-search
          hideTick
          showManualEntry
          defaultShow="manual"
        />

      </v-window-item>
      <v-window-item :value="2">
        <h2>Matching Addresses</h2>
        <p>Matching address found. Please select the a suitable address to continue.</p>
        <v-radio-group v-if="matchedAddresses != null" v-model="selectedAddress" class="mt-0 px-3">
          <v-radio
            v-for="(address, index) in matchedAddresses"
            :key="index"
            :value="address"
            class="mb-3">
            <template v-slot:label>
              {{ address.gnaf_address }}
            </template>
          </v-radio>

        </v-radio-group>
      </v-window-item>
      <v-window-item :value="3">
        <h2>Confirm Address</h2>
        <p>Select and confirm this address as the IPND address for this Account.</p>
        <h4 v-if="selectedAddress != null">{{ selectedAddress.gnaf_address }}</h4>
      </v-window-item>
      <v-window-item :value="4">
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="success">mdi-check-circle-outline</v-icon>
            <p class="text-h6">IPND address confirmed successfully</p>
            <p v-if="selectedAddress != null">Address: {{ selectedAddress.gnaf_address }}</p>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>

    <template v-slot:actions>
      <v-spacer />
      <v-btn
        :loading="busy"
        text
        :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import addressDataMixin from "../../../mixins/addressDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AddressDetailsForm from "../../pieces/Forms/AddressDetailsForm.vue";
import AddressText from "../../pieces/Address/AddressText.vue";
const StreetNumberRegex = /^(\d{1,5})([A-Z])?(?:\-(\d{1,5})([A-Z])?)?$/;
const NameRegex = /^[A-Z](?:(?:[A-Za-z -]+)?[A-Za-z])?$/;

export default {
  name: 'EditTrunkIPNDAddressDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin,GlobalHelperMixin, addressDataMixin],
  components: { AddressDetailsForm, ComplexDialog },
  data: () => ({
    step:1,
    address:null,
    matchedAddresses:null, //address return as matches.
    selectedAddress: null, //select address record
    busy: false,
    isValid: false,
    rules: {
      numeric: v => !isNaN(v) || 'Must be whole number',
      required: v => !!v || 'Required',
      postcode: v => (!v || /^[0-7][0-9]{3}$/.test(v)) || 'Invalid Postcode',
      street_number: [
        v => (!v || !/\s+/.test(v)) || 'Cannot contain spaces',
        v => (!v || StreetNumberRegex.test(v)) || 'Invalid street number',
      ],
      street_name: [
        v => !!v || 'Required',
        //v => (v != null && v.length <= 25) || 'Cannot be more than 25 characters',
        v => NameRegex.test(v) || 'Invalid street name',
      ],
      suburb: [
        v => !!v || 'Required',
        //v => (v != null && v.length <= 40) || 'Cannot be more than 40 characters',
        v => NameRegex.test(v) || 'Invalid suburb name',
      ],
    }

  }),
  computed: {
    isBusy: function () {
      return this.busy || this.loading;
    },
    // Used to trigger the next button in the dialog.
    nextValid: function( ){
      if(this.step == 1){
        return this.address == null;
      }else if(this.step == 2){
        return this.selectedAddress == null;
      }else if(this.step == 3){
        return false;
      }
      return true;
    },
    backValid: function( ){
      if(this.step == 4){
        return true;
      }

      return false;
    },
    nextLabel: function( ){
      if(this.step == 1){
        return 'Submit address';
      }else if(this.step == 2){
        return 'Select address';
      }else if(this.step == 3){
        return 'Confirm address';
      }else if(this.step == 4){
        return 'Close';
      }else{
        return 'Error';
      }
    },
  },
  methods: {
    reset() {
      this.step = 1;
      this.address = null;
      this.matchedAddresses = null;
      this.selectedAddress = null;
      if(this.portaAccountData.ipnd_list.length > 0 && this.portaAccountData.ipnd_list[0].is_bc_address){
        this.address = {};
        this.address.building_number = this.portaAccountData.ipnd_list[0].ipnd.service_building_1st_nr;
        this.address.building_type = this.portaAccountData.ipnd_list[0].ipnd.service_building_type;
        this.address.floor_number = this.portaAccountData.ipnd_list[0].ipnd.service_building_floor_number;
        if (this.address.floor_number !== null && this.address.floor_number !== undefined && this.address.floor_number !== '') {
          this.address.floor_type = null;
        }else{
          this.address.floor_type = 'FL';
        }

        let value = this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1;
        if (value !== null && value !== undefined && value !== '') {
          value = this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_2;
          if (value !== null && value !== undefined && value !== '') {
            this.address.street_number = this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1+''+this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1_suffix;
            this.address.street_number += '-'+this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1+''+this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1_suffix;
          }else{
            this.address.street_number = this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1+''+this.portaAccountData.ipnd_list[0].ipnd.service_street_house_nr_1_suffix;
          }
        }

        this.address.street_name = this.portaAccountData.ipnd_list[0].ipnd.service_street_name_1;
        this.address.street_suffix = this.portaAccountData.ipnd_list[0].ipnd.service_street_suffix_1;
        if(this.addressData.street_types_netsip_convert[this.portaAccountData.ipnd_list[0].ipnd.service_street_type_1]) {
          this.address.street_type = this.addressData.street_types_netsip_convert[this.portaAccountData.ipnd_list[0].ipnd.service_street_type_1];
        }else{
          this.address.street_type = this.portaAccountData.ipnd_list[0].ipnd.service_street_type_1;
        }
        this.address.suburb = this.portaAccountData.ipnd_list[0].ipnd.service_address_locality;
        this.address.postcode = this.portaAccountData.ipnd_list[0].ipnd.service_address_postcode;
        this.address.state = this.portaAccountData.ipnd_list[0].ipnd.service_address_state;
      }

    },

    next( ){
      if(this.step == 1){
        this.validateAddress();
      }else if(this.step == 2){
        this.step = 3;
      }else if(this.step == 3){
        this.confirmAddress( );
      }
    },
    back( ){
      this.step--;
    },
    finish( ){
      this.visible = false;
    },

    /**
     * This is used to validate the address with NetSip. It can return 1 to many results.
     * @returns {Promise<void>}
     */
    async validateAddress() {
      this.busy = true;
      this.Api.setHttpObject({timeout:60000});
      const response = await this.Api.send('post', 'net-sip/ipnd/address-search', this.address);
      this.Api.setHttpObject({timeout:20000});
      if (response.success) {
        // document.dispatchEvent(new CustomEvent('refreshRequested'));
        // this.visible = false;
        if(response.data.length == 1){
          this.matchedAddresses = response.data;
          this.selectedAddress = response.data[0];
          this.step = 3;
        }else {
          this.matchedAddresses = response.data;
          this.step = 2;
        }
      }
      this.busy = false;
    },

    /**
     * Send address to confirm
     * @returns {Promise<void>}
     */
    async confirmAddress() {
      /**
       * For some reason netSip may not return the postcode which is required on submission.
       */
      if(this.selectedAddress.address_postcode == null || this.selectedAddress.address_postcode == ''){
        //note we are assuming a postcode match here.
        this.selectedAddress.address_postcode = this.address.postcode;
      }

      this.busy = true;
      const response = await this.Api.send('put', 'trunks/'+this.portaAccountData.i_account+'/confirm-ipnd-address', this.selectedAddress);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.step = 4;
      }
      this.busy = false;
    },
  },
  watch: {
    visible(value) {
      this.reset();
    },
  },
  props: {
    portaAccountData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.reset();
  }
};
</script>
<template>
  <ComplexDialog
      v-model="visible"
      title="Edit Invoice Type"
      :loading="busy"
      :maxWidth="600"
  >
    <v-alert type="info" outlined dense v-if="false">
      To edit any other details please contact admin.
    </v-alert>
    <v-alert v-if="showError" type="error" outlined dense>
      {{ this.errorMessage }}
    </v-alert>

    <v-form v-model="valid">
      <v-row dense>
        <v-col cols="12">
          <v-select outlined label="Invoice Type" :items="invoiceTypes" v-model="form.invoiceTemplateType" :rules="rules.required" hide-details />
        </v-col>
      </v-row>
    </v-form>


    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible = false" text>Cancel</v-btn>
      <v-btn @click="changeId( )" :disabled="!valid" text color="primary">Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ComplexDialog from "../templates/ComplexDialog";
export default {
  name: 'EditBillingDetailsDialog',
  components: { ComplexDialog},
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    busy: false,
    showError: false,
    errorMessage:'',
    valid:null,
    invoiceTypes: [

      {
        "value": "summary",
        "text": 'Summary',
      },
      {
        "value": "detailed",
        "text": 'Itemised',
      },
    ],
    form: {
      "invoiceTemplateType":null
    },
    rules: {
      required: [
        (v) => !!v || 'Required',
      ],
    }
  }),
  props: {
    customerData: {
      type: Object,
      default: function( ){
        return {}
      }
    },
  },
  methods: {
    async changeId() {
      this.busy = true;
      let params = {};
      params.invoice_template_type = this.form.invoiceTemplateType;
      const responseNewAddress = await this.Api.send('post','customers/' + this.customerData.i_customer + '/update', params, false);
      if (responseNewAddress.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;

    },
    reset( ){
      if(!this.customerData.i_template){
        this.form.invoiceTemplateType = 'summary';
      }else if(this.customerData.i_template == '5' || this.customerData.i_template == '99'){ //staging and production
        this.form.invoiceTemplateType = 'detailed';
      }else{
        this.form.invoiceTemplateType = 'summary';
      }
    }
  },
  watch: {
    visible(value) {
      if (this.value){
        this.reset( );
      }
    },
  },
  created( ){
    this.reset( );
  }
};
</script>
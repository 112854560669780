<template>

  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
  <v-row no-gutters>
    <v-col cols="12">

      <v-select
          outlined
          label="Ringing Setting"
          v-model="form.ringing"
          :disabled="isBusy"
          :items="ringGroupExtensionSelects.ringingSelect"
          item-text="option"
          item-value="value"
          hide-details
          :menu-props="{ top: true, offsetY: true }"
          :rules="[rules.required]"
      />

    </v-col>
  </v-row>
    <v-card
        outlined
        v-if="form.ringing=='ring'"
        class="mt-2"
    ><v-card-text>
      <div>Ring Settings</div><br />
    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field
            v-model="form.hunt_delay"
            label="Ring delay seconds"
            :rules="form.ringing=='ring' ? [ruleCheckRingDelay] : []"
            outlined
            hint="Delay in seconds before the phone starts to ring."
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="form.hunt_expire"
            label="Ringing duration seconds"
            :rules="form.ringing=='ring' ? [ruleCheckRingDuration] : []"
            outlined
            hint="How long the phone should ring for after the delay."
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="form.ringing=='ring' && Number.isInteger(form.hunt_delay) && Number.isInteger(form.hunt_expire)">
      <v-col cols="12" class="text-center">
        <p>Wait for <strong>{{form.hunt_delay}}</strong> seconds then ring for <strong>{{form.hunt_expire}}</strong> seconds.</p>
      </v-col>
    </v-row>
    </v-card-text></v-card>

    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
            v-model="form.huntstop"
            label="Ignore Follow-me/Voicemail"
            value="Y"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" v-if="this.trunkData.ringGroupDetails.sequence == 'Order'">
        <v-text-field
            v-model="form.hunt_order"
            label="Ring Order"
            :rules="this.trunkData.ringGroupDetails.sequence == 'Order' ? [checkOrder] : []"
            outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import apiMixin from "../../../mixins/apiMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import SpinnerOverlay from "../Global/SpinnerOverlay";
export default {
  name: 'RingGroupExtensionForm',
  mixins: [apiMixin,ringGroupDataMixin],
  components: {SpinnerOverlay},
  data: () => ({
    valid: false,
    busy: false,
    form:{
      ringing:null,
      hunt_delay:null,
      hunt_expire:null,
      hunt_order:null,
      huntstop: null,
    },
    rules: {
      required: v => !!v || 'Required.',
      numeric: v => !isNaN(v) || 'Must be whole number',
      orderMin: v => v > 0 || 'Order must be greater than zero.',
      orderMax: v=> v > (this.ringGroupExtensionsCount + 1) || 'Order cannot be greater than '+(this.ringGroupExtensionsCount + 1),

    },
    ringGroupExtensionsCount: 0,
  }),
  computed: {
    highlightColor() {
      return 'accent';
    },
    checkOrder( ){
      const order = this.form.hunt_order;
      if(!order){
        return 'Order is required.';
      }
      if(isNaN(order)){
        return 'Order must be numeric.';
      }
      if(order < 1){
        return 'Order must be greater than zero.';
      }
      if(order > (this.ringGroupExtensionsCount + 1)){
        return 'Order must be less than '+(this.ringGroupExtensionsCount + 1)+' as there are '+this.ringGroupExtensionsCount+' currently assigned extensions to this Ring Group.';
      }

      return true;
    },
    isBusy( ){
      return this.loading || this.busy;
    }
  },
  watch: {
    productOption(value) {
      this.update();
    },
    valid(value) {
      if (value) {
        this.$emit('input', this.form);
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    reset( ){
      this.valid = false;
      this.form.ringing = null;
      this.form.hunt_delay = 3;
      this.form.hunt_expire = 10;
      this.form.huntstop = null;
      this.form.hunt_order = null;
      this.ringGroupExtensionsCount = 0;


      this.form.hunt_active = null;

      if(this.rgExtData != null){
        if(this.rgExtData.hunt_active == 'N'){
          this.form.ringing = 'disabled';
        }else if(this.rgExtData.hunt_delay != null && this.rgExtData.hunt_expire != null){
          this.form.ringing = 'ring';
          this.form.hunt_delay = this.rgExtData.hunt_delay;
          this.form.hunt_expire = this.rgExtData.hunt_expire;
        }else{
          this.form.ringing = 'enabled';
        }

        if(this.rgExtData.huntstop == 'Y'){
          this.form.huntstop = 'Y';
        }

        this.form.hunt_order = this.rgExtData.hunt_order;
      }

      if(this.trunkData.ringGroupDetails.sequence == 'Order'){
        this.setRingGroupExtensionsCount( );
      }

    },
    async setRingGroupExtensionsCount () {
      this.busy = true;
      let response = await this.Api.send('get', 'ring-groups/'+this.trunkData.ringGroupDetails.i_c_group+'/extensions');
      this.busy = false
      if(this.rgExtData == null) {
        this.form.hunt_order = response.data.total + 1;
      }
      this.ringGroupExtensionsCount = response.data.total;
    },

    ruleCheckRingDelay(numberIn) {
      let number = numberIn;
      if(numberIn == null) {
        number = this.form.hunt_delay;
      }

      if (number == null) {
        return 'Required';
      }

      if(isNaN(number)){
        return 'Must be numeric.';
      }

      if (parseInt(number) < 0 || parseInt(number) > 300) {
        return 'Ring Delay Seconds must be between 0 and 300.';
      }

      if(number % 1 != 0){
        return 'Ring Delay Seconds must be a whole number.';
      }

      if(number.length > 3){
        return 'Incorrect count of characters. Must be 3 numbers.';
      }

      return true;
    },

    ruleCheckRingDuration(numberIn){
      let number = numberIn;
      if(numberIn == null) {
        number = this.form.hunt_expire;
      }

      if (number == null) {
        return 'Required';
      }

      if(isNaN(number)){
        return 'Must be numeric.';
      }

      if (parseInt(number) < 0 || parseInt(number) > 300) {
        return 'Ring Duration Seconds must be between 0 and 300.';
      }

      if(number % 1 != 0){
        return 'Ring Duration Seconds must be a whole number.';
      }

      if(number.length > 3){
        return 'Incorrect count of characters. Must be 3 numbers.';
      }

      return true;
    }
  },


  created() {
    this.reset( );
  },
  props: {
    trunkData: {
      type: Object,
      required:true,
    },
    loading: {
      type:Boolean,
      default:false,
    },
    rgExtData: {
      type:Object,
      default:null,
    }

  }
};
</script>
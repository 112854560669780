<template>

  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />

    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
            v-model="form.announce_number_of_callers"
            label="Announce the number of callers ahead of them in the queue"
            value="Y"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
            v-model="form.announce_estimated_wait_time"
            label="Announce estimated wait time"
            value="Y"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-slide-y-transition>
    <v-card
    outlined
    v-if="form.announce_estimated_wait_time=='Y' || form.announce_number_of_callers == 'Y'"
    class="mt-2 extra-settings-div"
    :width="form.announce_estimated_wait_time=='Y' ? '100%' : '50%'"
    ><v-card-text class="pb-0">
    <v-row>
      <v-slide-x-transition>
        <v-col cols="6" v-if="form.announce_estimated_wait_time=='Y'">
          <v-text-field
              v-model="form.average_duration"
              label="Average handle time"
              :rules="form.announce_estimated_wait_time=='Y' ? [rules.required , rules.numeric] : []"
              hint="In minutes"
              outlined
          />
        </v-col>
      </v-slide-x-transition>
      <v-col :cols="form.announce_number_of_callers=='Y' && (form.announce_estimated_wait_time==null || form.announce_estimated_wait_time == 'N') ? 12 : 6" v-if="form.announce_estimated_wait_time=='Y' || form.announce_number_of_callers == 'Y'">
        <v-text-field
            v-model="form.announcement_interval"
            label="Interval between announcements"
            :rules="form.announce_estimated_wait_time=='Y' || form.announce_number_of_callers == 'Y'? [rules.required , rules.numeric] : []"
            hint="In minutes"
            outlined
        />
      </v-col>
    </v-row>
  </v-card-text></v-card>
      </v-slide-y-transition>

    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
            v-model="form.play_music_on_hold"
            label="Play music on hold"
            value="Y"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-slide-y-transition>
    <v-card
        outlined
        v-if="form.play_music_on_hold=='Y'"
        class="mt-2"
    ><v-card-text>
      <div>Music on Hold file
        <span v-if="currentMohFileName"><br />Current File: <strong>{{currentMohFileName}}</strong></span>
        <br /></div>
      <v-row no-gutters>
        <v-col cols="12">
          <v-file-input
              v-model="form.music_on_hold_file"
              show-size
              :disabled="isBusy"
              :rules="form.play_music_on_hold=='Y' && !currentMohFileName ? [rules.file,rules.required] : [rules.file]"
              accept=".mp3,.wav,audio/mpeg,audio/wav"
              label="Upload Music File (max 3mb)"
              prepend-icon="mdi-phone-ring"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card-text></v-card>
      </v-slide-y-transition>

  </v-form>
</template>

<script>
import apiMixin from "../../../mixins/apiMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import SpinnerOverlay from "../Global/SpinnerOverlay";
export default {
  name: 'CallQueueSettingsForm',
  mixins: [apiMixin,ringGroupDataMixin],
  components: {SpinnerOverlay},
  data: () => ({
    valid: false,
    busy: false,
    form:{
      announce_number_of_callers:'N',
      announce_estimated_wait_time:'N',
      average_duration:null,
      announcement_interval:null,
      play_music_on_hold: 'N',
      music_on_hold_file: null,
    },
    currentMohFileName:null,
    rules: {
      required: v => !!v || 'Required.',
      numeric: v => !isNaN(v) || 'Must be whole number',
      file: v => !v || v.size < 3145728 || 'Audio file size should be less than 3 MB.',
    },
    ringGroupExtensionsCount: 0,
  }),
  computed: {
    highlightColor() {
      return 'accent';
    },
    isBusy( ){
      return this.loading || this.busy;
    }
  },
  watch: {
    valid(value) {
      if (value) {
        this.$emit('input', this.form);
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    reset( ){
      this.valid = false;
      this.form.announce_number_of_callers = 'N';
      this.form.announce_estimated_wait_time = 'N';
      this.form.announcement_interval = null;
      this.form.average_duration = null;
      this.form.play_music_on_hold = 'N';
      this.form.music_on_hold_file = null;
      this.currentMohFileName = null;

      if(this.callQueueData){
        this.form.announce_number_of_callers = this.callQueueData.announce_number_of_callers;
        this.form.announce_estimated_wait_time = this.callQueueData.announce_estimated_wait_time;
        this.form.announcement_interval = this.callQueueData.announcement_interval;
        this.form.average_duration = this.callQueueData.average_duration;
        if(this.callQueueData.i_moh != null){
          this.form.play_music_on_hold = 'Y';
          this.currentMohFileName = this.callQueueData.moh_file_name;
        }

      }

    },
  },
  created() {
    this.reset( );
  },
  props: {
    callQueueData: {
      type: Object,
      default:null
    },
    loading: {
      type:Boolean,
      default:false,
    },
  }
};
</script>
<style>
.extra-settings-div{
  max-width: auto;
}
</style>
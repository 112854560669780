<template>
    <BasicCard title="Voice calls configuration" :loading="loading" :no-skeleton="false">
        <v-row dense class="text--primary">
            <v-col cols="4"> Dialing Rule: </v-col>
            <v-col cols="8">{{dialRuleName}} <v-icon @click="showDialingRuleDialog=true">mdi-pencil</v-icon></v-col>
        </v-row>
      <v-row dense class="text--primary">
        <v-col cols="4"> Apply rule to CLI: </v-col>
        <v-col cols="8">
          <v-chip v-if="translateCliIn" color="green" text-color="white">Enabled</v-chip>
          <v-chip v-if="!translateCliIn" color="red" text-color="white">Disabled</v-chip>
        </v-col>
      </v-row>
      <v-row dense class="text--primary">
        <v-col cols="4"> Apply rule to CLD: </v-col>
        <v-col cols="8">
          <v-chip v-if="translateCldIn" color="green" text-color="white">Enabled</v-chip>
          <v-chip v-if="!translateCldIn" color="red" text-color="white">Disabled</v-chip>
        </v-col>
      </v-row>
      <v-row dense class="text--primary">
        <v-col cols="4" style="vertical-align:middle"> Global Music on hold: </v-col>
        <v-col cols="8">
          <v-chip v-if="mohToggle == 'Y'" color="green" text-color="white">Enabled</v-chip>
          <v-chip v-if="mohToggle == 'N'" color="red" text-color="white">Disabled</v-chip>
        </v-col>
      </v-row>
      <v-row dense class="text--primary" v-if="mohToggle == 'Y'">
        <v-col cols="4" style="vertical-align:middle"> Current File: </v-col>
        <v-col cols="8">
          {{mohFile}}
        </v-col>
      </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip text="Dialing Rule Configuration" icon="mdi-pencil" flat @click="showDialingRuleDialog=true" />
            <ButtonWithTooltip text="Music on hold" icon="mdi-pencil" flat @click="showMOHDialog=true" />
        </template>
      <CustomerDialingRuleConfigDialog v-model="showDialingRuleDialog" :customerData="customerData" :i-dial-rule="iDialRule" :translate-cli-in="translateCliIn" :translate-cld-in="translateCldIn" :dial-rule-options="dialRuleOptions" />
      <CustomerMOHUpdateDialog v-if="customerData.i_customer" v-model="showMOHDialog" :customerData="customerData" :mohList="mohList" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CustomerDialingRuleConfigDialog from "../../dialogs/Customer/CustomerDialingRuleConfigDialog";
import CustomerMOHUpdateDialog from "../../dialogs/Customer/CustomerMOHUpdateDialog";
export default {
    name: 'CustomerVoiceCallsConfigurationCard',
    components: {CustomerMOHUpdateDialog, CustomerDialingRuleConfigDialog, ButtonWithTooltip, BasicCard },
    data: () => ({
      showDialingRuleDialog: false,
      showMOHDialog: false,
      busy: false,
      dialRuleOptions:[],
      //iDialRule:null,
      dialRuleName:null,
      mohList:[],
    }),
  methods: {
    async update() {
      this.busy = true;

      const response = await this.Api.send('get','customers/'+this.customerData.i_customer+'/dialing-rule-options');
      if(response.success){
        this.dialRuleOptions = response.data;
      }
      //really this is useful for the create process. We get the dialName after we pull the options
      this.dialRuleName = this.getDialName(this.iDialRule);

      const responseMoh = await this.Api.send('get','customers/'+this.customerData.i_customer+'/moh-list');
      if(responseMoh.success){
        this.mohList = responseMoh.data;
      }

      this.busy = false;
    },
    getDialName(iDialRule){
      let tmp = null;
      this.dialRuleOptions.forEach(function (item) {
        if(parseInt(item['i_dial_rule']) == iDialRule){
          tmp = item['name'];
        }
      });
      return tmp;
    }
  },
  watch: {
      //if customerData this update, iDialRule will update and hence we pull the dialName
    iDialRule(value) {
      if(value != null){
        this.dialRuleName = this.getDialName(value);
      }
    },
    customerData(value) {
      if(value != null && value.i_customer){
        this.update( );
      }
    }
  },
  created() {
      if(this.customerData && this.customerData.i_customer){
        //pull the options on load.
        this.update( );
      }
  },
  computed: {
    isBusy( ){
      return this.loading || this.busy;
    },
    //return correct value based on the customerData which is updated externally.
    iDialRule( ){
      let tmp = null;
      if(!this.customerData.service_features){
        return null;
      }
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'voice_dialing'){
          feature.attributes.forEach(function (item) {
            if(item.name == 'i_dial_rule'){
              tmp = item.effective_values[0];
            }
          });
        }
      });
      return parseInt(tmp);
    },
    translateCliIn( ){
      let tmp = false; //assuming its is off as default.
      if(!this.customerData.service_features){
        return null;
      }
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'voice_dialing'){
          feature.attributes.forEach(function (item) {
            if(item.name == 'translate_cli_in'){
              tmp = item.effective_values[0] == 'Y';
            }
          });
        }
      });
      return tmp;
    },
    translateCldIn( ){
      let tmp = false; //assuming its is off as default.
      if(!this.customerData.service_features){
        return null;
      }
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'voice_dialing'){
          feature.attributes.forEach(function (item) {
            if(item.name == 'translate_cld_in'){
              tmp = item.effective_values[0] == 'Y';
            }
          });
        }
      });
      return tmp;
    },
    mohToggle( ){
      let tmp = null;
      if(!this.customerData.service_features){
        return null;
      }
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'music_on_hold'){
          tmp = feature.flag_value;
        }
      });
      return tmp;
    },
    iMoh( ){
      let tmp = null;
      if(!this.customerData.service_features){
        return null;
      }
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'music_on_hold'){
          feature.attributes.forEach(function (item) {
            if(item.name == 'i_moh'){
              tmp = item.effective_values[0];
            }
          });
        }
      });
      return tmp;
    },
    mohFile( ){
      if(!this.iMoh){
        return null
      }
      let tmp = null;
      let self = this;
      this.mohList.forEach(function (file) {
        if(file.i_moh == self.iMoh){
          tmp = file.name;
        }
      });
      return tmp;
    }
  },
  props: {
      customerData: {
          default: null,
      },
      loading: {
          type: Boolean,
          default: false
      }
  },
};
</script>
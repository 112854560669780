<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="6" :md="serviceId ? 6 : 4" v-if="serviceId===3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="CLI"
                        v-model="localFilter.cli"
                        :disabled="serviceId!==3 && localFilter.i_service!=='3'"
                        @keydown.enter="updateLocalFilter"
                    />
                </v-col>
                <v-col cols="12" sm="6" :md="serviceId ? 6 : 4" v-if="serviceId===3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="CLD"
                        v-model="localFilter.cld"
                        :disabled="serviceId!==3 && localFilter.i_service!=='3'"
                        @keydown.enter="updateLocalFilter"
                    />
                </v-col>
                <!--v-col cols="12" sm="6" md="4" v-if="!acctId">
                    <TrunkDropdown
                        :customer-id="custId"
                        dense
                        hide-details
                        label="Trunk"
                        init-dropdown
                        v-model="localFilter.i_account"
                        allow-none
                    />
                </v-col-->
                <v-col cols="12" sm="6" :md="serviceId===3 ? 6 : 3">
                    <DateTimePicker
                        key="fromDate"
                        v-model="localFilter.from"
                        dense
                        hide-details
                        label="From date"
                    />
                </v-col>
                <v-col cols="12" sm="6" :md="serviceId===3 ? 6 : 3">
                    <DateTimePicker
                        key="toDate"
                        v-model="localFilter.to"
                        dense
                        hide-details
                        label="To date"
                    />
                </v-col>
              <v-col cols="12" sm="6" md="3" v-if="!serviceId">
                <v-select
                    hide-details
                    outlined
                    dense
                    label="Transaction Type"
                    v-model="localFilter.i_service"
                    :items="serviceTypes"
                    @change="changeServiceType"
                />
              </v-col>
                <v-col cols="12" sm="6" md="6" v-if="serviceId===3">
                      <v-switch class="ma-0 pa-2" v-model="localFilter.include_unsuccessful" hide-details label="Include unanswered/failed calls" />
                </v-col>
                <v-col cols="12" sm="6" :md="serviceId===3 ? 6 : 3">
                  <v-row>
                    <v-col cols="6" xs="12">
                    <v-btn
                        block
                        height="40"
                        :loading="isLoading"
                        @click="updateLocalFilter"
                    >Search</v-btn>
                    </v-col>
                    <v-col cols="6" xs="12">
                  <v-btn
                      block
                      height="40"
                      :loading="isLoading"
                      @click="downloadCSV"
                  ><v-icon>mdi-download</v-icon> CSV</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
            </v-row>
        </v-card>

        <PaginationCard
            v-if="!!this.filter.from && !!this.filter.to"
            :page="filter.page ? filter.page : 1"
            :data="data"
            :limit="filter.limit ? filter.limit : 10"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <v-simple-table>
                <thead>
                <tr>
                <th v-for="(header,headerIndex) in headers"
                    :key="headerIndex">
                  <v-tooltip small bottom open-delay="200" v-if="header=='Recording'">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{header}}</span>
                    </template>
                    <span>Note call recordings are available on compatible accounts/trunks with a compatible plan.</span>
                  </v-tooltip>
                  <span v-if="header!='Recording'">{{header}}</span>
                </th>
                </tr>
                </thead>
                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No <span v-text="serviceId===3 ? 'calls' : 'transactions'"/> found
                        </td>
                    </tr>
                  <tr
                      v-for="(row,rowIndex) in tableData"
                      :key="rowIndex"
                  >
                    <td
                        v-for="(item,itemIndex) in row"
                        :key="itemIndex"
                        :class="item.cellClass"
                    >
                      <IconWithTooltip v-if="item.tag == 'type'" :icon="item.icon" :tooltip="item.tooltip" />
                      <router-link v-if="item.tag == 'account'"
                          :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.i_account,
                                    },
                                }"
                          style="cursor: pointer"
                          class="pa-4"
                      >
                        {{ item.accountId }}
                      </router-link>
                      <v-btn icon @click="downloadRecording(item.i_account, item.i_xdr, item.call_recording_id)" v-if="item.tag == 'recording' && item.csvString == 'yes'">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>

                      <LocalDateTime v-if="item.tag == 'connectTime' || item.tag == 'to' || item.tag == 'dateTime'" :value="item.localDateTime" show-time />
                      <Currency v-if="item.tag == 'cost'" :value="item.amount" />
                      <span v-if="item.colString != null">{{item.colString}}</span>
                      <IconWithTooltip v-if="item.tag == 'fromCli' && item.icon" icon="mdi-incognito-circle" tooltip="Private mode" right />
                    </td>
                  </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCard>
      <SimpleDialog
          v-model="showErrorDialog"
          ok-only
          title="CSV download"
          :max-width="420"
      >
        <v-alert type="error" class="mb-0">{{ dialogError }}</v-alert>

      </SimpleDialog>
    </div>
</template>

<script>
import PaginationCard from '../templates/PaginationCard';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import DateTimePicker from '../../pieces/Forms/DateTimePicker';
import { mdiPhoneIncoming, mdiPhoneOutgoing } from '@mdi/js';

import LocalDateTime from '../../pieces/LocalDateTime';
import Currency from '../../pieces/Currency';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
//import TrunkDropdown from '../../pieces/Forms/TrunkDropdown';
export default {
    name: 'TransactionListCard',
    components: {
      SimpleDialog,
        IconWithTooltip, Currency, LocalDateTime,
        //TrunkDropdown,
        DateTimePicker, PaginationCard },
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        linkBase: process.env.VUE_APP_API_BASE || '/api/',
        i_service: null,
        headers:[],
        tableData:[],
        csvToggle: false,
        localFilter: {
            include_unsuccessful: false,
            cli: null,
            cld: null,
            from: null,
            to: null,
            i_account: null,
            i_service: '2'
        },
        serviceTypes: [
            { value: '1', text: 'Credits/Adjustments' },
            { value: '2', text: 'Payments' },
            { value: '3', text: 'Voice Calls' },
            { value: '4', text: 'Subscriptions' },
            { value: '15', text: 'DID Usage' },
            { value: '12', text: 'Taxes' },

        ],
        icon: {
            incoming: mdiPhoneIncoming,
            outgoing: mdiPhoneOutgoing
        },
        flags: {
            overdraft: 1,
            time_adjusted: 2,
            outgoing: 4,
            incoming: 8,
            forwarded: 12,
            refeed: 16,
            privacy: 32,
            recorded: 64,
            hidden: 128,
            incremental: 256,
            derived: 512,
        },
      dialogError:null,
      showErrorDialog:false,
    }),
    methods: {
        async update( ) {
            if (!!this.filter.from && !!this.filter.to) {
                if (this.firstLoad) this.data = [];
                this.isLoading = true;
                this.getLocalFilters();
                this.headers = this.buildHeaders( );
                let params = this.getRequestParams( );
                this.Api.setHttpObject({timeout:60000});
                const response = await this.Api.send('post',(this.acctId ? ('trunks/' + this.acctId) : ('customers/' + this.custId)) + '/transactions', params,false);
                this.selected = [];
                this.selectAll = false;
                if (response.success) {
                    this.data = response.data;
                    this.tableData = this.buildTableData(response.data.data);
                } else {
                    this.data = [];
                }
                this.isLoading = false;
                this.firstLoad = false;
                if(this.csvToggle){
                  this.csvToggle = false;

                  /*
                  We need to validate the data for CSV Download
                   */
                  const dateTo = new Date(this.filter.to);
                  const dateFrom = new Date(this.filter.from);
                  const diff = dateTo.getTime() - dateFrom.getTime( );
                  const days = 3024000000; //35 days in milliseconds.
                  if(diff < 0){
                    this.dialogError = 'To time must be greater than from time.';
                    this.showErrorDialog = true;
                    return;
                  }else if(diff > days){
                    this.dialogError = 'Date range cannot exceed 35 days. Please adjust filters and try again';
                    this.showErrorDialog = true;
                    return;
                  }
                  window.location.href = this.getCSVUrl(params);
                }
            }
        },
        getRequestParams( ) {
          let params = { limit: this.filter.limit, page: this.filter.page, from: this.filter.from, to: this.filter.to };
          //if (this.custId > 0) params.i_customer = this.custId;
          if (this.filter.cli) params.cli = this.filter.cli;
          if (this.filter.cld) params.cld = this.filter.cld;
          if (this.serviceId) params.i_service = this.serviceId;
          if (this.filter.i_service) params.i_service = this.filter.i_service;
          if (this.filter.include_unsuccessful) params.show_unsuccessful = 'Y';
          if (this.acctId > 0) params.i_account = this.acctId;
          return params
        },
        getCSVUrl(params = null){
          if(params == null) {
             params = this.getRequestParams();
          }
          delete params.limit;
          delete params.page;
          let url = this.linkBase + (this.acctId ? ('trunks/' + this.acctId) : ('customers/' + this.custId)) + '/transactions/csv?session-token=' + this.$store.state.session.token;

          let serialize = function(obj) {
            var str = [];
            for (var p in obj)
              if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              }
            return str.join("&");
          }

          url += '&'+serialize(params);
          return url;
        },
        downloadCSV( ){
          this.csvToggle = true;
          this.updateLocalFilter();
        },
        changeServiceType(value) {
            this.firstLoad = true;
            if (value!=='3') {
                this.localFilter.cli = null;
                this.localFilter.cld = null;
                this.localFilter.include_unsuccessful = false;
            }
        },
      downloadRecording(iAccount, ixdr, call_recording_id){
        window.location.href = this.linkBase + 'trunks/' + iAccount + '/transactions/'+ixdr+'/call-recording/'+encodeURIComponent(call_recording_id)+'?session-token=' + this.$store.state.session.token;
      },
      buildHeaders() {
        let csvHeaders = [];
        if (this.filter.i_service === '3') {
          csvHeaders.push('Type');
        }
        if (this.filter.i_service === '15') {
          csvHeaders.push('DID');
        }
        if (!this.acctId && (this.filter.i_service === '3' || this.filter.i_service === '4')) {
          csvHeaders.push('Account');
        }
        if (this.filter.i_service === '3' || this.filter.i_service==='15') {
          csvHeaders.push('From (CLI)');
        }
        if (this.filter.i_service === '3') {
          csvHeaders.push('To (CLD)');
        }
        if (this.filter.i_service === '3') {
          csvHeaders.push('Country');
        }
        if (this.filter.i_service !== '3') {
          csvHeaders.push('Type');
        }
        csvHeaders.push('Description');
        if (this.filter.i_service === '3') {
          csvHeaders.push('Connect Time');
        } else if (this.filter.i_service === '4') {
          csvHeaders.push('From');
        } else {
          csvHeaders.push('Date/time');
        }
        if (this.filter.i_service === '4') {
          csvHeaders.push('To');
        }
        if (this.filter.i_service === '3') {
          csvHeaders.push('Duration');
        }
        csvHeaders.push('Cost');

        if (this.filter.i_service === '3') {
          csvHeaders.push('Recording');
        }

        return csvHeaders;
      },
      buildTableData(data){
          let headers = this.buildHeaders();
          let tableData = [];
        let rowEntry = [];
        let item = {};
        let row;
        let header;
        for(let i=0;i<data.length; i++){
          row = data[i];
          rowEntry = [];
          for(let y=0;y<headers.length; y++){
            header = headers[y];
            item = {};
            item.colString = null;
            switch (header) {
              case 'Type':
                if (this.filter.i_service === '3') {
                  item.tag = 'type';
                  if ((row.bit_flags & this.flags.forwarded) === this.flags.forwarded) {
                    item.tooltip = 'Forwarded';
                    item.icon = 'mdi-phone-forward';
                    item.csvString = 'Forwarded';
                  } else if ((row.bit_flags & this.flags.incoming) === this.flags.incoming) {
                    item.tooltip = 'Incoming';
                    item.icon = this.icon.incoming;
                    item.csvString = 'Incoming';
                  } else if ((row.bit_flags & this.flags.outgoing) === this.flags.outgoing) {
                    item.tooltip = 'Outgoing';
                    item.icon = this.icon.outgoing;
                    item.csvString = 'Forwarded';
                  }
                } else {
                  item.tag = 'typeAlt';
                  item.colString = row.description;
                  item.csvString = row.description;
                }
                break;
              case 'DID':
                item.tag = 'did';
                item.colString = 'DID detail Missing'; //this needs to be updated
                item.csvString = 'DID detail Missing'; //this needs to be updated
                break;
              case 'Account':
                item.tag = 'account';
                item.i_customer = row.i_customer;
                item.i_account = row.i_account;
                item.accountId = row.account_id;
                item.csvString = row.account_id;
                item.cellClass = 'pa-0';
                break;
              case 'From (CLI)':
                item.tag = 'fromCli';
                item.colString = row.CLI;
                item.csvString = row.CLI;
                item.icon = false;
                if ((row.bit_flags & this.flags.privacy) === this.flags.privacy) {
                  item.icon = true;
                }
                break;
              case 'To (CLD)':
                item.tag = 'toCld';
                item.colString = row.CLD;
                item.csvString = row.CLD;
                break;
              case 'Country':
                item.tag = 'country';
                item.colString = row.country || 'Not set';
                item.csvString = row.country || 'Not set';
                break;
              case 'Description':
                item.tag = 'description';
                item.colString = row.description;
                item.csvString = row.description;
                break;
              case 'Connect Time':
                item.tag = 'connectTime';
                item.localDateTime = row.connect_time;
                item.csvString = this.$luxon(row.connect_time, "dd-MM-yyyy h:mm");
                break;
              case 'From':
                item.tag = 'from';
                item.localDateTime = row.connect_time;
                item.csvString = this.$luxon(row.connect_time, "dd-MM-yyyy h:mm");
                break;
              case 'Date/time':
                item.tag = 'dateTime';
                item.localDateTime = row.connect_time;
                item.csvString = this.$luxon(row.connect_time, "dd-MM-yyyy h:mm");
                break;
              case 'To':
                item.tag = 'to';
                item.localDateTime = row.disconnect_time;
                item.csvString = this.$luxon(row.disconnect_time, "dd-MM-yyyy h:mm");
                break;
              case 'Duration':
                item.tag = 'duration';
                item.colString = new Date(row.charged_quantity * 1000).toISOString().substr(11, 8);
                item.csvString = new Date(row.charged_quantity * 1000).toISOString().substr(11, 8);
                break;
              case 'Cost':
                item.tag = 'cost';
                item.amount = row.charged_amount;
                item.csvString = row.charged_amount; //format this
                break;
              case 'Recording':
                item.tag = 'recording';
                if(row.cr_download_ids && row.cr_download_ids.length > 1) {
                  item.colString = 'Error';
                  item.csvString = 'Error';
                }else if(row.cr_download_ids && row.cr_download_ids.length > 0){
                  item.i_account = row.i_account;
                  item.i_xdr = row.i_xdr;
                  item.call_recording_id = row.cr_download_ids[0];
                  item.csvString = 'yes';
                }else{
                  item.colString = null;
                  item.csvString = null;
                }
                break;
              default:
                throw new Error('Unhandled header (' + header + ')');
            }
            rowEntry.push(item);
          }
          tableData.push(rowEntry);
        }
        return tableData;
      },
    },
    computed: {
        defaultFrom() {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date.toISOString().slice(0, 19).replace('T', ' ');
        },
        defaultTo() {
            let date = new Date();
            date.setHours(23);
            date.setMinutes(59);
            date.setSeconds(59);
            return date.toISOString().slice(0, 19).replace('T', ' ');
        }

    },
    props: {
        custId: {
            type: Number,
            default: null,
        },
        acctId: {
            type: Number,
            default: null,
        },
        serviceId: {
            type: Number,
            default: null
        }
    },
    created() {
        if (!this.localFilter.from) this.localFilter.from = this.defaultFrom;
        if (!this.localFilter.to) this.localFilter.to = this.defaultTo;
        if (this.serviceId) this.localFilter.i_service = this.serviceId.toString();
    }
};
</script>

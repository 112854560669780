<template>
    <BasicCard :loading="loading || !data || !data.assigned_addons_display" title="Plan Details" :no-skeleton="!!data && !!data.assigned_addons_display">
        <v-row no-gutters v-if="!!data.assigned_addons_display && data.assigned_addons_display.length">
            <v-col
                cols="12"
                v-for="item in data.assigned_addons_display"
                v-bind:key="item.i_product"
            >
                <v-sheet class="pa-4 mb-2" rounded color="background_accent">
                    <v-chip label small class="float-right">Active</v-chip>
                    <h3 class="text--primary">{{ item.end_user_name }}</h3>
                    <p class="mb-0">
                        <Currency :value="item.product_subscription ? item.product_subscription.effective_fee : 0" add-gst hide-tooltip /> per month
                        <br />Effective from
                        <LocalDateTime :value="item.addon_effective_from" />
                    </p>
                </v-sheet>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="12">
                <v-alert text type="warning">This trunk has no assigned plan!</v-alert>
            </v-col>
        </v-row>
        <template v-slot:actions>
            <v-btn
                v-if="$route.name != 'TrunkBilling'"
                :to="{ name: 'TrunkBilling' }"
                text
            ><v-icon v-html="'mdi-cash-usd'" left /> Billing</v-btn>
            <v-btn
                @click="viewPlanDialog=true"
                text
            ><v-icon v-html="'mdi-information-outline'" left /> Details</v-btn>
            <v-btn
                @click="changePlanDialog=true"
                text
            ><v-icon v-html="'mdi-pencil'" left /> Edit</v-btn>
        </template>
        <ViewTrunkPlanDialog
            v-if="data.account_type == 'SIP-TRUNK'"
            v-model="viewPlanDialog"
            :data="data"
        />
        <ViewExtensionPlanDialog
            v-if="data.account_type == 'HOSTED-EXTENSION'"
            v-model="viewPlanDialog"
            :data="data"
        />
      <ViewFaxToMailPlanDialog v-if="data.account_type == 'FAX-TO-MAIL'"
                               v-model="viewPlanDialog"
                               :data="data"
      />

      <ViewRingGroupPlanDialog v-if="data.account_type == 'RING-GROUP'"
                               v-model="viewPlanDialog"
                               :data="data"
      />

      <ViewAutoAttendantPlanDialog v-if="data.account_type == 'AUTO-ATTENDANT'"
                                   v-model="viewPlanDialog"
                                   :data="data"
      />
        <ChangeTrunkPlanDialog
            v-model="changePlanDialog"
            :data="data"
            show-nfr
        />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import Currency from '../../pieces/Currency';
import ChangeTrunkPlanDialog from '../../dialogs/Trunk/ChangeTrunkPlanDialog';
import ViewTrunkPlanDialog from '../../dialogs/Trunk/ViewTrunkPlanDialog';
import ViewExtensionPlanDialog from "../../dialogs/Extension/ViewExtensionPlanDialog";
import ViewFaxToMailPlanDialog from "../../dialogs/FaxToMail/ViewFaxToMailPlanDialog";
import ViewRingGroupPlanDialog from "../../dialogs/RingGroups/ViewRingGroupPlanDialog";
import ViewAutoAttendantPlanDialog from "../../dialogs/AutoAttendants/ViewAutoAttendantPlanDialog";

export default {
    name: 'TrunkProductsCard',
    components: {
      ViewAutoAttendantPlanDialog,
      ViewRingGroupPlanDialog,
        ViewFaxToMailPlanDialog,
        ViewTrunkPlanDialog,
        ViewExtensionPlanDialog,
        ChangeTrunkPlanDialog,
        Currency,
        LocalDateTime,
        BasicCard,
    },
    data: () => ({
        changePlanDialog: false,
        viewPlanDialog: false,
    }),
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        data: {},
    },
};
</script>

<template>
    <SimpleDialog
        v-if="!!data"
        v-model="visible"
        :title="(blocked ? 'Unblock' : 'Block') + ' customer?'"
        :loading="busy"
        @click="update"
    >
        <v-alert v-if="!blocked && !!data.i_rep" type="warning" outlined dense>
            The partner will be unable to un-block this account.
        </v-alert>
        Are you sure you would like to <span v-html="blocked ? 'un' : undefined" />block this customer?
        <span v-if="blocked"> Calls and portal access will be restored.</span>
        <span v-else> This will block all calls and suspend portal access.</span>
    </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import SimpleDialog from '../templates/SimpleDialog';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CustomerBlockStatusDialog',
    components: { SimpleDialog },
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        busy: false
    }),
    methods: {
        async update() {
            this.busy = true;
            const response = await this.Api.send('post',
                'customers/' + this.data.i_customer + '/update', {blocked: this.blocked ? 'N' : 'Y'}
            );
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.visible = false;
            }
            this.busy = false;
        }
    },
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        blocked() {
            return !!this.data ? this.data.blocked==='Y' : false;
        }
    }
};
</script>
<template>
    <v-text-field
        label="Card Number"
        :outlined="outlined"
        :dense="dense"
        :autofocus="autofocus"
        :rules="rules"
        :counter="16"
        :maxlength="16"
        :error-messages="!!input && input.length===16 && !valid ? 'Invalid card number' : null"
        v-model="input"
        :hint="!input ? 'MasterCard or VISA only' : null"
    >
        <template v-slot:prepend-inner>
            <div class="mr-2">
                <v-scroll-y-transition leave-absolute>
                    <v-img v-if="type==='visa'" :src="visaIcon" :height="dense ? 24 : 20" :width="42" contain />
                    <v-img v-else-if="type==='mastercard'" :src="mastercardIcon" :height="dense ? 24 : 20" :width="42" contain />
                    <v-icon v-else style="width: 42px;">mdi-credit-card</v-icon>
                </v-scroll-y-transition>
            </div>
        </template>
        <template v-slot:append v-if="!!input && input.length===16">
            <v-fade-transition>
                <v-icon v-if="valid" v-text="'mdi-check'" color="success" />
                <v-icon v-else v-text="'mdi-close'" color="error" />
            </v-fade-transition>
        </template>
    </v-text-field>
</template>

<script>
const CardRegex = /^(?:4[0-9]{2}|5[1-5][0-9]|2[2-7]2)[0-9]{13}$/;
const CardTypeRegex = /^(?:4[0-9]{2}|5[1-5][0-9]|2[2-7]2)/;
import creditCardMixin from '../../mixins/creditCardMixin';
import visaIcon from '../../assets/img/3rd_party/visa.svg';
import mastercardIcon from '../../assets/img/3rd_party/mastercard.svg';
export default {
    name: 'CreditCardNumberField',
    mixins: [creditCardMixin],
    data: () => ({
        input: null,
        type: null,
        message: null,
        visaIcon: visaIcon,
        mastercardIcon: mastercardIcon,
        rules: [
            v => !v || !/[^0-9]+/.test(v) || 'Invalid characters - should only contain numbers',
            v => !v || v.length<3 || CardTypeRegex.test(v) || 'Invalid card type - Mastercard or VISA only',
            v => !v || !!v && v.length===16 || 'Too short',
        ]
    }),
    watch: {
        input(val) {
            this.type = this.get_card_type(val);
            let data = this.validate_credit_card(val) ? val : null;
            if (data!==this.value) this.$emit('input', data);
        },
        value(val) {
            if (!!val && this.input!==val) this.input = val;
        },
    },
    computed: {
        valid() {
            return this.validate_credit_card(this.input);
        }
    },
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: null
        }
    }
};
</script>
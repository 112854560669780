<template>
    <BasicCard
        title="Call Screening"
        :loading="loading || busy"
        :no-skeleton="!!rules && !!conditions"
        no-padding
    >
      <v-simple-table>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Time</th>
            <th>Action</th>
            <th class="text-center">Active</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <v-slide-x-transition tag="tbody" hide-on-leave group>
          <tr v-if="!!rules && rules.length===0" key="nodata">
            <td colspan="7" class="text-center">
              No rules configured
            </td>
          </tr>
          <tr v-for="(rule, index) in rules" :key="rule.i_cp_rule" :class="{'grey': rule.is_active!=='Y', 'lighten-2': !$vuetify.theme.dark, 'darken-2': $vuetify.theme.dark}">
            <td>
              <span v-if="!conditions">Loading...</span>
              <span v-else-if="rule.from_number_i_cp_condition">
                {{conditions.find((e) => e.i_cp_condition === rule.from_number_i_cp_condition) ? conditions.find((e) => e.i_cp_condition === rule.from_number_i_cp_condition).name : 'Unknown'}}
              </span>
              <span v-else>Any caller</span>
            </td>
            <td>
              <span v-if="!conditions">Loading...</span>
              <span v-else-if="rule.to_number_i_cp_condition">
                {{conditions.find((e) => e.i_cp_condition === rule.to_number_i_cp_condition) ? conditions.find((e) => e.i_cp_condition === rule.to_number_i_cp_condition).name : 'Unknown'}}
              </span>
              <span v-else>Any callee</span>
            </td>
            <td>
              <span v-if="!conditions">Loading...</span>
              <span v-else-if="rule.time_window_i_cp_condition">
                {{conditions.find((e) => e.i_cp_condition === rule.time_window_i_cp_condition) ? conditions.find((e) => e.i_cp_condition === rule.time_window_i_cp_condition).name : 'Unknown'}}
              </span>
              <span v-else>Any time</span>
            </td>

            <td>
              {{answerModeDisplay(rule.action)}}
            </td>
            <td class="text-center">
              <v-icon v-if="rule.is_active==='Y'" v-html="'mdi-check-circle'" color="success" />
              <v-icon v-else v-html="'mdi-close-circle'" color="error" />
            </td>
            <td class="text-center">
              <ButtonWithTooltip icon="mdi-chevron-up" color="warning" tooltip="Move rule up" @click="move(index, -1)" :disabled="index===0" />
              <ButtonWithTooltip icon="mdi-chevron-down" color="warning" tooltip="Move rule down" @click="move(index, 1)" :disabled="index===rules.length-1" />
              <ButtonWithTooltip icon="mdi-pencil" color="warning" tooltip="Edit rule" @click="editRule(index)" :disabled="rule.editing" />
              <ButtonWithTooltip icon="mdi-delete" color="warning" tooltip="Delete rule" @click="confirmDeleteRule(index)" :disabled="rule.editing" />
            </td>
          </tr>
        </v-slide-x-transition>
      </v-simple-table>
        <template v-slot:actions>
          <ButtonWithTooltip text="Edit Conditions" icon="mdi-pencil" flat @click="showConditionsDialog=true" />
          <ButtonWithTooltip text="Add Rule" icon="mdi-plus" flat @click="addRule" />
        </template>
      <CallScreeningConditionsDialog v-model="showConditionsDialog" :data="data" :conditions="conditions" @refresh="updateConditions" :loading="loading || busy" />
      <CallScreeningRuleDialog v-model="showRuleDialog" :rule="currentRule" :data="data" :conditions="conditions" @refresh="update" />
      <SimpleDialog v-model="showConfirmDelete" title="Delete rule?" @click="deleteRule(currentRule.i_cp_rule)">
        Are you sure you wish to delete this call screening rule?
      </SimpleDialog>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '@/mixins/apiMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import SimpleDialog from '@/components/dialogs/templates/SimpleDialog';
import CallScreeningRuleDialog from "../../dialogs/Trunk/CallScreeningRuleDialog";
import CallScreeningConditionsDialog from '@/components/dialogs/Trunk/CallScreeningConditionsDialog';
export default {
    name: 'TrunkCallScreeningCard',
    mixins: [apiMixin, trunkSettingsDataMixin],
    components: { CallScreeningConditionsDialog, CallScreeningRuleDialog, SimpleDialog, ButtonWithTooltip, BasicCard },
    data: () => ({
        showRuleDialog: false,
        showConfirmDelete: false,
        showConditionsDialog: false,
        rules: null,
        conditions: null,
        busy: false,
        currentRule: null
    }),
    methods: {
      answerModeDisplay(action){
        if(action !== null){
          let value = this.trunkSettingsData.answerModes.find((e) => e.value === action);
          if(value != null){
            let text = this.trunkSettingsData.answerModes.find((e) => e.value === action).text;
            if(this.data.account_type == 'RING-GROUP'){
              text = text.replace('Forward','Ring');
            }
            return text;
          }else{
            return 'Unknown';
          }
        }else{
          return 'Loading...';
        }
      },
      update() {
        this.updateRules();
        this.updateConditions();
      },
      async updateRules() {
        this.busy = true;
        const rules = await this.Api.send('get', 'trunks/' + this.data.i_account + '/call_screening_rules', null, true);
        if (rules.success) {
          this.rules = rules.data;
        }
        this.busy = false;
      },
      async updateConditions() {
        this.busy = true;
        const conditions = await this.Api.send('get', 'trunks/' + this.data.i_account + '/call_screening_conditions', null, true);
        if (conditions.success) {
          this.conditions = conditions.data;
        }
        this.busy = false;
      },
      confirmDeleteRule(index) {
        this.currentRule = { ...this.rules[index] };
        this.showConfirmDelete = true;
      },
      addRule() {
        this.currentRule = null;
        this.showRuleDialog = true;
      },
      editRule(index) {
        this.currentRule = { ...this.rules[index] };
        this.showRuleDialog = true;
      },
      getRuleList() {
        let rules = [];
        this.rules.forEach(e => rules.push(e.i_cp_rule));
        return rules;
      },
      async move(id, offset) {
        this.busy = true;
        let rules = this.getRuleList();
        let rule = rules[id];
        rules.splice(id, 1);
        rules.splice(id + offset, 0, rule);
        const response = await this.Api.send('put', 'trunks/' + this.data.i_account + '/arrange_call_screening_rules', {rules: rules});
        if (response.success)
          this.update();
        this.busy = false;
      },
      async deleteRule(id) {
        this.busy = true;
        const response = await this.Api.send('delete', 'trunks/' + this.data.i_account + '/call_screening_rule', {i_cp_rule: id});
        if (response.success) {
          this.showConfirmDelete = false;
          this.update();
        }
        this.busy = false;
      },
    },
  computed:{
    iAccount: function( ){
      if (!!this.data && this.data.i_account) {
        return this.data.i_account;
      }else{
        return null;
      }
    }
  },
    watch: {
        // loading(val) {
        //     if (!val && !!this.data && this.data.i_account) {
        //         this.update();
        //     }
        // },
      /*
      This will fire on a page reload were as create does not.
      However this will fire in addition to the create update call when entering the page.
      Hence it updates twice which is not idea but works under both refresh and create,
       */
      iAccount(val){
        if(val != null){
          this.update();
        }
      }
    },
    props: {
        data: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if (!!this.data && this.data.i_account) {
          this.update();
        }
    }
};
</script>

<template>
    <BasicCard :title="data.account_type_label+' Auth Details'" :loading="loading">
        <v-row dense class="body-1 text--primary">
            <v-col cols="4">
                <span class="font-weight-bold">Auth Type</span>
            </v-col>
            <v-col cols="8">
                <span v-if="data.i_account_role === 1">Registration based</span>
                <span v-else-if="data.i_account_role === 6">IP based</span>
                <span v-else>Unknown</span>
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Auth ID</span>
            </v-col>
            <v-col cols="8">
                {{ data.id }}
                <CopyToClipboard :value="data.id" small />
            </v-col>
            <v-col cols="4" v-if="data.i_account_role !== 6">
                <span class="font-weight-bold">Password</span>
            </v-col>
            <v-col cols="8" v-if="data.i_account_role !== 6">
                <Password :value="data.h323_password" />
            </v-col>
        </v-row>

        <template v-slot:actions>
          <ButtonWithTooltip
              icon="mdi-key"
              text="Change Password"
              tooltip="Change your password"
              color="primary"
              flat
              @click="updatePasswordDialog=true"
          />
        </template>
      <TrunkUpdatePasswordDialog v-model="updatePasswordDialog" :data="data" :error="passwordUpdateError" />
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import Password from '../../pieces/Password';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CopyToClipboard from '../../pieces/CopyToClipboard';
import TrunkUpdatePasswordDialog from "../../dialogs/Trunk/TrunkUpdatePasswordDialog";
export default {
    name: 'TrunkAuthCard',
    components: {TrunkUpdatePasswordDialog, CopyToClipboard, ButtonWithTooltip, Password, BasicCard },
  data: () => ({
    updatePasswordDialog:false,
    passwordUpdateError: null,
    updatePasswordData:false,
  }),
    props: ['data', 'loading'],


};
</script>

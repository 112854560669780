<template>
    <ComplexDialog
        :title="title"
        :max-width="850"
        :loading="busy"
        v-model="visible"
        :close-confirmation="!!card_info"
        :step="step"
        :step-next-text="step===4 ? (error_message ? 'Retry Payment' : 'Pay Now') : 'Next'"
        :step-next-disabled="step===1 && (!amount || parseFloat(amount)<=0) || step===2 && !card_info && use_existing_card!==1 || step===3 && !address"
        :step-end="5"
        @step-end="close"
        step-end-text="Finish"
        @step-back="back"
        @step-next="next"
        @hide="close"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <p class="subtitle-1 text--primary">How much would the customer like to pay?</p>
                <v-radio-group v-if="!!data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance>0" v-model="amount_choice" @change="amountChange" class="mt-0 px-3">
                    <v-radio
                        v-if="data.unpaid_invoice_info.invoices_summary.overdue_balance>0"
                        :value="1"
                        class="mb-3"
                    >
                        <template v-slot:label>
                            <span><Currency :value="data.unpaid_invoice_info.invoices_summary.overdue_balance" hide-tooltip /> - Overdue balance</span>
                        </template>
                    </v-radio>
                    <v-radio
                        v-if="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance!==data.unpaid_invoice_info.invoices_summary.overdue_balance && data.unpaid_invoice_info.invoices_summary.outstanding_balance>0"
                        :value="2"
                        class="mb-3"
                    >
                        <template v-slot:label>
                            <span><Currency :value="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance" hide-tooltip /> - Outstanding balance<span v-if="data.unpaid_invoice_info.invoices_summary.overdue_balance>0"> (including overdue amount)</span></span>
                        </template>
                    </v-radio>
                    <v-radio
                        :value="3"
                    >
                        <template v-slot:label>
                            Enter an amount
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-expand-transition>
                    <v-text-field
                        v-if="amount_choice===3 || !data.unpaid_invoice_info || (data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance===0)"
                        autofocus
                        :rules="rules.number"
                        v-model="amount"
                        outlined
                        label="Amount"
                        prefix="$"
                    />
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="2">
                <p class="subtitle-1 text--primary">Please enter the credit card details</p>
                <v-radio-group v-if="!!data && !!data.payment_method" v-model="use_existing_card" class="mt-0 px-3">
                    <v-radio
                        :value="1"
                        class="mb-3"
                    >
                        <template v-slot:label>
                            Use the existing saved card ({{data.payment_method.payment_method}} {{data.payment_method.number}})
                        </template>
                    </v-radio>
                    <v-radio
                            :value="2"
                    >
                        <template v-slot:label>
                            Enter a new card
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-expand-transition>
                    <div v-if="!data.payment_method || use_existing_card===2">
                        <CreditCardDetailsForm v-model="card_info" />
                        <span class="text--disabled">Credit Card information is saved with our payment processor. Breeze Connect retains only the first/last 4 digits and expiry of the card.</span>
                        <v-checkbox
                            v-model="save_card"
                            class="px-3"
                            label="Save this card for future use"
                        />
                        <v-expand-transition>
                            <v-alert v-if="!!data.payment_method && save_card" text outlined type="warning">
                                The existing payment method ({{data.payment_method.payment_method}} {{data.payment_method.number}}) will be replaced.
                            </v-alert>
                        </v-expand-transition>
                    </div>
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="3">
                <p class="subtitle-1 text--primary">What is the billing address for this credit card?</p>
                <ExistingAddressDetailsForm
                    :customer-id="data.i_customer"
                    v-model="address"
                />
                <p class="text--disabled">Please note: This address may be used for validation purposes by the card issuer.</p>
            </v-window-item>
            <v-window-item :value="4">
                <v-alert v-if="error_message" outlined type="error" transition="expand-transition">{{error_message}}</v-alert>
                <p class="subtitle-1 text--primary">Please review the details below to ensure they are correct.</p>
                <v-sheet class="mt-2 pa-4" color="background_accent" rounded>
                    <v-row dense>
                        <v-col cols="4" class="font-weight-bold">
                            Amount to pay
                        </v-col>
                        <v-col cols="8" class="font-weight-bold">
                            <Currency :value="parseFloat(amount)" hide-tooltip />
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Credit Card Number/Expiry
                        </v-col>
                        <v-col cols="8" v-if="card_info">
                            {{card_info.number.slice(0, 4)}}xxxxxxxx{{card_info.number.slice(card_info.number.length - 4)}} (expires {{ card_info.exp_date | luxon('MM/yyyy') }})
                        </v-col>
                        <v-col cols="8" v-else-if="!!data && !!data.payment_method">
                            Existing saved card ({{data.payment_method.payment_method}} {{data.payment_method.number}})
                        </v-col>
                    </v-row>
                    <v-row dense v-if="!!card_info">
                        <v-col cols="4" class="font-weight-bold">
                            Cardholder Name
                        </v-col>
                        <v-col cols="8">
                            {{card_info.name}}
                        </v-col>
                        <v-col cols="4" class="font-weight-bold">
                            Save Card Info
                        </v-col>
                        <v-col cols="8">
                            <span v-text="save_card ? 'Yes' : 'No'" />
                        </v-col>
                    </v-row>
                    <v-row dense v-if="address">
                        <v-col cols="4" class="font-weight-bold">
                            Cardholder Address
                        </v-col>
                        <v-col cols="8">
                            <AddressText :data="address" />
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-checkbox
                    v-if="save_card"
                    class="px-3"
                    v-model="auto_payments"
                    label="Enable automatic payments with this card"
                />
                <v-alert transition="expand-transition" v-if="auto_payments" text type="info">
                    Invoices will be automatically charged to this card on the due date each month.
                </v-alert>
            </v-window-item>
            <v-window-item :value="5">
                <v-row no-gutters>
                    <v-col cols="12" class="text-center py-4">
                        <v-icon size="120" color="warning"
                        >mdi-clock-outline</v-icon
                        >
                        <p class="text-h6">Payment authorized successfully</p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-alert icon="mdi-alert" outlined type="warning">Please note: Payments may take up to 6 hours to reflect on the account/invoice balances.</v-alert>
                  </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import CreditCardDetailsForm from '../../pieces/CreditCardDetailsForm';
import apiMixin from '../../../mixins/apiMixin';
import ExistingAddressDetailsForm from '../../pieces/Forms/ExistingAddressDetailsForm';
import AddressText from '../../pieces/Address/AddressText';
import Currency from '../../pieces/Currency';
export default {
    name: 'ProcessPaymentDialog',
    mixins: [apiMixin, dialogMixin],
    components: { Currency, AddressText, ExistingAddressDetailsForm, CreditCardDetailsForm, ComplexDialog },
    data: () => ({
        step: 1,
        card_info: null,
        auto_payments: false,
        save_card: false,
        amount: null,
        amount_choice: null,
        busy: false,
        address: null,
        use_existing_card: null,
        valid: false,
        error_message: null,
        rules: {
            number: [
                (v) => !!v || 'Required',
                (v) =>
                    /^\d+(?:\.\d{1,2})?$/i.test(v) ||
                    'Enter a valid number',
                (v) => parseFloat(v) !== 0 || 'Cannot be zero',
            ],
        },
    }),
    methods: {
        async submit() {
            this.error_message = null;
            this.busy = true;
            let params = {amount: this.amount};
            if (this.card_info) {
                params.card_info = this.card_info;
                params.save_card = this.save_card ? 1 : 0;
                if (this.save_card) {
                    params.address_info = this.address;
                    params.auto_payments = this.auto_payments ? 1 : 0;
                }
            }
            const response = await this.Api.send('post', 'customers/' + this.data.i_customer + '/process_payment', params, false);
            if (response.success) {
                this.step++;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                this.error_message = response.data && response.data.description ? response.data.description : "An unknown error occurred while trying to process the payment. Please try again later.";
            }
            this.busy = false;
        },
        close() {
            if (this.visible) this.visible = false;
            this.address = null;
            this.card_info = null;
            this.amount_choice = null;
            this.amount = null;
            this.save_card = null;
            this.use_existing_card = null;
            this.step = 1;
            this.error_message = null;
        },
        next() {
            if (this.step === 2) {
                this.step = this.save_card ? 3 : 4;
            } else if (this.step < 4) {
                this.step++
            } else if (this.step === 4) {
                this.submit();
            } else if (this.step === 5) {
                this.visible = false;
            }
        },
        amountChange(val) {
            switch (val) {
                case 1:
                    this.amount = this.data.unpaid_invoice_info.invoices_summary.overdue_balance;
                    break;
                case 2:
                    this.amount = this.data.unpaid_invoice_info.invoices_summary.outstanding_balance;
                    break;
                case 3:
                    this.amount = null;
                    break;
            }
        },
        back() {
            if (this.step===4) {
                if (this.error_message) this.error_message = null;
                this.step = this.save_card ? 3 : 2;
            } else {
                this.step--;
            }
        }
    },
    props: {
        repId: {
            default: null,
        },
        title: {
            type: String,
            default: 'Process Customer Payment'
        },
        data: {
            type: Object,
            default: null
        }
    },
};
</script>
<template>
    <BasicCard
        title="Emergency Services Address (IPND)"
        v-bind:class="{
            'error lighten-4': (!$vuetify.theme.dark && !portaAccountData.ipnd_list[0].confirmed),
            'error darken-4': ($vuetify.theme.dark && !portaAccountData.ipnd_list[0].confirmed),
        }"
        :loading="isBusy"
    >
      <template v-slot:feature>
        <v-icon x-large color="error" v-if="!portaAccountData.ipnd_list[0].confirmed"
        >mdi-alert-circle</v-icon
        >
      </template>

      <Alert
        v-if="portaAccountData.ipnd_list.length > 1"
        type="error"
        message="Too many IPND records returned for this PortaAccount. Please contact Admin."
      />


      <Alert
        v-if="portaAccountData.ipnd_list[0].is_bc_address"
        type="error"
        message="Emergency Service address is missing. Please update Address Details."
      />
      <Alert
        v-else-if="!portaAccountData.ipnd_list[0].confirmed"
        type="error"
        message="Address is unconfirmed. Edit this address to be correct."
      />

      <Alert
        v-if="portaAccountData.ipnd_list[0].is_bc_address && $store.state.user.user_type === 'admin'"
        type="error"
        message="This is linked to Leaders Address. This needs attention. "
      />

      <div v-if="$store.state.user.user_type === 'admin' || !portaAccountData.ipnd_list[0].is_bc_address">
        <p class="title text--primary">
          {{portaAccountData.ipnd_list[0].ipnd_formatted_string}}
        </p>

        <v-row dense v-if="portaAccountData.ipnd_list[0].ipnd.customer_name_1">
          <v-col cols="4" class="font-weight-bold"> Name </v-col>
          <v-col cols="8">
            {{ portaAccountData.ipnd_list[0].ipnd.customer_name_1 }}
          </v-col>
        </v-row>

        <v-row dense v-if="portaAccountData.ipnd_list[0].ipnd.customer_contact_name_1">
          <v-col cols="4" class="font-weight-bold"> Contact Name </v-col>
          <v-col cols="8">
            {{ portaAccountData.ipnd_list[0].ipnd.customer_contact_name_1 }}
          </v-col>
        </v-row>

        <v-row dense v-if="portaAccountData.ipnd_list[0].ipnd.customer_contact_nr">
          <v-col cols="4" class="font-weight-bold"> Contact Number </v-col>
          <v-col cols="8">
            {{ portaAccountData.ipnd_list[0].ipnd.customer_contact_nr }}
          </v-col>
        </v-row>
      </div>
        <template v-slot:actions v-if="!portaAccountData.ipnd_list[0].is_bc_address">
          <ButtonWithTooltip
            text="Edit Address"
            icon="mdi-pencil"
            v-if="!portaAccountData.ipnd_list[0].confirmed"
            flat
            @click="editUnconfirmedAddressDialog = true"
          />
          <ButtonWithTooltip
            text="Edit Address"
            icon="mdi-pencil"
            v-if="portaAccountData.ipnd_list[0].confirmed"
            flat
            @click="editConfirmedAddressDialog = true"
          />
        </template>
      <SimpleDialog
        v-model="editConfirmedAddressDialog"
        :title="'Edit IPND Address'"
        :okOnly="true"
        :loading="isBusy"
      >
        In order to edit the IPND address, update the Address Details for this Account.
      </SimpleDialog>
      <EditTrunkIPNDAddressDialog
        v-model="editUnconfirmedAddressDialog"
        :porta-account-data="portaAccountData"
      ></EditTrunkIPNDAddressDialog>
      </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard.vue';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip.vue';
import Alert from "../../pieces/Alert.vue";
import LocalDateTime from '../../pieces/LocalDateTime.vue';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../../dialogs/templates/SimpleDialog.vue";
import EditTrunkIPNDAddressDialog from "../../dialogs/Trunk/EditTrunkIPNDAddressDialog.vue";
export default {
    name: 'TrunkIPNDCard',
    components: { EditTrunkIPNDAddressDialog, SimpleDialog, ButtonWithTooltip,BasicCard,Alert},
    //mixins: [apiMixin],
    data: () => ({
        busy: false,
        editConfirmedAddressDialog: false,
        editUnconfirmedAddressDialog: false,
    }),
    computed: {
        isBusy: function () {
          return this.busy || this.loading;
        }
    },
    props: {
      portaAccountData: {
        type: Object,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      reset() {
          this.editConfirmedAddressDialog = false;
          this.editUnconfirmedAddressDialog = false;
      },
        // async update() {
        //     this.isLoading = true;
        //     const response = await this.Api.send('post', 'tickets/myagentinfo', null, true);
        //     this.isLoading = false;
        //     if (response.success) {
        //         this.data = response.data.data;
        //     } else {
        //         this.data = [];
        //     }
        // },
    },
    created( ){
      this.reset( );
    },
};
</script>

<template>
  <SimpleDialog
    v-model="visible"
    :title="(!!this.form.i_cp_condition ? 'Edit' : 'New') + ' Time Window'"
    yes-text="Save"
    :loading="busy"
    :yes-disabled="!valid || !time_windows.length"
    :max-width="600"
    no-text="Cancel"
    @click="submit"
    persistent
    styledHeader
  >
    <v-form v-model="valid">
      <v-text-field v-model="form.name" label="Name *" outlined :rules="rules.name" autofocus :error-messages="errors.name" />
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>Time Periods</th>
            <th class="text-right">Delete</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="time_windows && !time_windows.length" key="nodata">
          <td colspan="2" class="text-center">
            No time periods added!
          </td>
        </tr>
          <tr v-for="(time_window, index) in time_windows" :key="index">
            <td>
              {{time_window.description}}
            </td>
            <td class="text-right">
              <v-btn icon @click="deletePeriod(index)"><v-icon v-html="'mdi-close'" /></v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-sheet rounded class="pa-4 mt-4" color="background_accent">
        <p class="font-weight-bold mb-3">Add a time period</p>
        <v-row>
          <v-col cols="12">
            <TimeConditionForm v-model="period" v-if="visible" />
          </v-col>
          <v-col cols="12">
            <v-btn block text color="primary" :disabled="!period" @click="addPeriod"><v-icon left v-html="'mdi-plus'"/> Add Time Period</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from '@/components/dialogs/templates/SimpleDialog';
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import TimeConditionForm from '@/components/pieces/Forms/TimeConditionForm';
export default {
  name: 'CallScreeningTimeWindowDialog',
  components: { TimeConditionForm, SimpleDialog },
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    valid: false,
    busy: false,
    period: null,
    time_windows: [],
    form: {
      i_cp_condition: null,
      name: "",
      time_window: {
        period: '',
        description: ''
      },
      type: 'TimeWindow'
    },
    rules: {
      name: [(v) => !!v || 'Name is required'],
    },
    errors: {
      name: null
    }
  }),
  methods: {
    addPeriod() {
        if (!this.period) return;
        this.time_windows.push({ ...this.period });
        this.period = null;
    },
    deletePeriod(index) {
        this.time_windows.splice(index, 1);
    },
    async submit() {
      this.busy = true;
      let period = '';
      let description = '';
      this.time_windows.forEach(e => {
        period += (period ? ', ' : '') + e.period;
        description += (description ? ' or ' : '') + e.description;
      });
      this.form.time_window = {
        period: period,
        description: description
      };
      const response = await this.Api.send(!!this.form.i_cp_condition ? 'put' : 'post', 'trunks/' + this.data.i_account + '/call_screening_condition', this.form);
      if (response.success) {
        this.$emit('change', response.data.i_cp_condition);
        this.visible = false;
      }
      this.busy = false;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.condition) {
          this.form = JSON.parse(JSON.stringify(this.condition));
          let timePeriods = this.form.time_window.period.split(', ');
          let timeDescriptions = this.form.time_window.description.split(' or ');
          if (timePeriods && timeDescriptions && timePeriods.length===timeDescriptions.length) {
            for (let i = 0; i < timePeriods.length; i++) {
              this.time_windows.push({ period: timePeriods[i], description: timeDescriptions[i]});
            }
          }
        }
      } else {
        this.form.name = '';
        this.time_windows = [];
        this.form.i_cp_condition = null;
        this.errors.name = null;
      }
    },
    'form.name'(val) {
      if (val && this.conditions && this.conditions.length) {
        let valid = true;
        this.conditions.forEach(e => {
          if (val && e.name.toLowerCase()===val.toLowerCase() && e.i_cp_condition!==this.form.i_cp_condition) valid = false;
        });
        this.errors.name = valid ? null : 'This name is already in use';
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    condition: {
      type: Object,
      default: null
    },
    conditions: {
      type: Array,
      default: null
    }
  }
};
</script>
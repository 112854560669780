<template>
  <ComplexDialog
      title="Link and send email NBN Order"
      v-model="visible"
      :loading="busy"
      :max-width="800"
  >
    <SpinnerOverlay v-if="this.isBusy"/>
    <p>To send the customer details about the NBN Subscription we need to link this subscription to the original order.</p>
    <p>Select the order this NBN Subscription is linked to.</p>
    <h3>Subscription Details</h3>
    <p v-if="nbnSubscription">
      <strong>Name: </strong>{{nbnSubscription.name}}<br />
      <strong>Activation Date: </strong>{{nbnSubscription.activation_date}}<br />
      <strong>Fee: </strong><Currency :value="nbnSubscription.effective_fee" addGst/>
    </p>
    <div v-if="nbnSubscription != null && nbnSubscription.order_id != null">
      <h3>Order Details</h3>
      <p>
        <strong>Created Date: </strong>{{nbnSubscription.order_created_at}}<br />
        <strong>Address: </strong>{{nbnSubscription.kinetix_address_json.formattedAddress}}<br />
        <strong>Alternate Email: </strong>{{nbnSubscription.alternate_email}}<br />
        <v-switch inset class="ma-0 pb-2" v-model="showEdit" hide-details label="Update/Edit" />
      </p>
    </div>
    <v-form v-model="valid" v-if="(nbnSubscription == null || nbnSubscription.order_id == null) || showEdit">
      <v-text-field v-model="form.alt_email" label="Additional email address to send to" hint="If included an additional email will be sent to this email address"  :rules="rules.email" outlined/>
      <v-select outlined label="Orders: #id - Address (created)" :items="unlinkedNbnOrderOptions" v-model="form.order_id" :rules="rules.required"/>
    </v-form>

    <v-alert type="warning" text class="mt-2" v-if="nbnSubscription != null && nbnSubscription.order_id != null && nbnSubscription.order_id != form.order_id">
      Note that this Subscription is already linked to order #{{nbnSubscription.order_id}}. Continuing here will cause the link to order #{{nbnSubscription.order_id}} to be removed. Hence
      order #{{nbnSubscription.order_id}} will no longer be associated with an NBN subscription instance.
    </v-alert>

    <template v-slot:actions>
      <v-spacer />
      <v-btn
          @click="visible=false"
          text
      >Cancel</v-btn>
      <v-btn
          v-if="nbnSubscription != null && nbnSubscription.order_id != null && !showEdit"
          @click="sendEmail(nbnSubscription.order_id)"
          text
          color="primary"
      >Send Email</v-btn>
      <v-btn
          @click="submit"
          v-if="(nbnSubscription == null || nbnSubscription.order_id == null) || showEdit"
          :disabled="!valid"
          text
          color="primary"
      >{{(nbnSubscription != null && nbnSubscription.order_id != null)?'Update and Send Email':'Link and Send Email'}}</v-btn>
    </template>

  </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import Currency from "../../pieces/Currency";
import SpinnerOverlay from "../../pieces/Global/SpinnerOverlay";
import ComplexDialog from "../templates/ComplexDialog";
export default {
    name: 'NBNLinkOrderDialog',
    components: {SpinnerOverlay, Currency,ComplexDialog},
    mixins: [apiMixin, dialogMixin,GlobalHelperMixin],
    data: () => ({
      busy: false,
      valid: false,
      unlinkedNbnOrders:null,
      nbnOrder:null,
      showEdit:false,
      form:{
        alt_email:null,
        order_id:null,
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
        ],
        email: [
          //(v) => !!v || 'Required',
          (v) => ((v==null || v=='') || /^.+@.+\..+$/.test(v)) || 'Invalid email',
        ],
      }
    }),
    props: {
      nbnSubscription:{
        type: Object,
        default:null
      },
      customerData:{
        type: Object,
        default: null,
      }
    },
  computed: {
    unlinkedNbnOrderOptions( ){
      let ret = [];
      if(this.unlinkedNbnOrders == null){
        return [];
      }else{
        let temp;
        this.unlinkedNbnOrders.forEach(function(item){
          let date = new Date(item.created_at);
          temp = {};
          temp.text = '#'+item.id+' - '+item.kinetix_address_json.formattedAddress+' ('+date.toDateString()+')';
          temp.value = item.id
          ret.push(temp);
        });
      }
      return ret;
    },
    isBusy(){
      return this.loading || this.busy;
    },
    // orderId(){
    //   if(this.nbnSubscription == null || this.nbnSubscription.order_id == null){
    //     return null;
    //   }else{
    //     return this.nbnSubscription.order_id;
    //   }
    // }
  },
    watch: {
      visible(val) {
        if(val){
         this.getNBNOrdersToLink( );
         this.setup( );
         this.showEdit = false;
        }
      },
      // orderId(val){
      //   if(val != null){
      //     this.getNBNOrder( );
      //   }
      // }
    },
    methods: {
      setup( ){
        this.form={
          alt_email:null,
          order_id:null,
        };

        if(this.nbnSubscription != null && this.nbnSubscription.order_id != null){
          this.form.alt_email = this.nbnSubscription.alternate_email;
          this.form.order_id = this.nbnSubscription.order_id;
        }
      },
      submit( ){
        let params = this.form;
        params.i_customer_subscription = this.nbnSubscription.i_customer_subscription;
        this.$emit('submitLink', params);
        this.visible = false;
      },
      sendEmail(order_id){
        this.$emit('submitLink', {"order_id":order_id});
        this.visible = false;
      },

      async getNBNOrdersToLink( ){
        this.busy = true;
        let query = 'linked=0&i_subscription='+this.nbnSubscription.i_subscription;
        if(this.nbnSubscription != null && this.nbnSubscription.order_id != null){
          query += '&include_current_order_id='+this.nbnSubscription.order_id;
        }
        const response = await this.Api.send('get','customers/'+this.customerData.i_customer+'/nbn-orders?'+query);
        this.busy = false;
        if (response.success) {
          this.unlinkedNbnOrders = response.data;
        }
      },
      // async getNBNOrder( ){
      //   this.busy = true;
      //   const response = await this.Api.send('get','customers/'+this.customerData.i_customer+'/nbn-orders/'+this.orderId);
      //   this.busy = false;
      //   if (response.success) {
      //     this.nbnOrder = response.data;
      //   }
      // },
    },
};
</script>
<template>
  <SimpleDialog
      :title="'Linked Actions'"
      v-model="visible"
      :loading="busy"
      okOnly
      yesText="Close"
      :maxWidth="800"
      styledHeader
  >
  <p>Actions that are linked to Call Queue '{{cqData.group_id}}'.</p>
  <AutoAttendantTargetedTransitionsList :iCustomer="cqData.i_customer" :loading="loading" :inactiveTargetedTransitions="inactive" :actionTargetedTransitions="actions"/>

  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../templates/SimpleDialog";
import AutoAttendantTargetedTransitionsList from "../../pieces/AutoAttendant/AutoAttendantTargetedTransitionsList";
import dialogMixin from "../../../mixins/dialogMixin";
    export default {
        name: 'CallQueueTargetedTransitionDialog',
        mixins: [dialogMixin],
        components: { SimpleDialog,AutoAttendantTargetedTransitionsList},
        props: {
          cqData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
      data: () => ({
        busy: false,
        inactive: [],
        actions: [],
      }),
      methods:{
        reset( ){
          this.inactive = [];
          this.actions = [];
          if(this.cqData && this.cqData.i_c_queue) {
            this.update();
          }
        },

        async update( ){
          this.busy = true;
          let response = await this.Api.send('get','call-queues/'+this.cqData.i_c_queue+'/targeted-auto-attendant-transitions');
          if(response.success){
            this.inactive = response.data['not_active'];
            this.actions = response.data['actions'];
          }
          this.busy = false;
        },
      },
      computed:{

      },
      created( ){
        this.reset( );
      },
    };
</script>
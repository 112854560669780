<template>
  <v-combobox v-model="input" label="Days of the month" :outlined="outlined" :hide-details="hideDetails" :disabled="disabled" multiple @change="update" small-chips deletable-chips />
</template>

<script>
const DaysRegex = /^((?:[1-3][0-9]|0?[1-9]))(?:-([0-3]?[0-9]))?$/i;
export default {
  name: 'TimeConditionDayPicker',
  data: () => ({
    input: null
  }),
  methods: {
    update() {
      let days = [];
      this.input.forEach(e => {
        let result = e.match(DaysRegex);
        if (result) {
          // check for range
          if (parseInt(result[2])) {
            // ensure start is less than end, end is <=31, then add everything in between
            if (parseInt(result[1]) < parseInt(result[2]) && parseInt(result[2]) <= 31) {
              for (let i = parseInt(result[1]); i <= parseInt(result[2]); i++) {
                days.push(i);
              }
            }
          // otherwise add single number if <=31
          } else if (parseInt(result[1])<=31) {
            days.push(parseInt(result[1]));
          }
        }
      })
      // sort array
      days.sort(function(a, b) { return a - b; });
      days = [ ...new Set(days)];
      let days_updated = [];
      let rangePosition = null;
      days.forEach((e, i) => {
        if (!rangePosition) { // if not working on a range
          rangePosition = parseInt(e);
          days_updated.push(e.toString())
        } else {
          if (rangePosition===(e-1)) {
            rangePosition = parseInt(e);
          } else {
            if (days_updated[days_updated.length-1]!==rangePosition.toString()) {
              days_updated[days_updated.length-1] += '-' + rangePosition.toString();
            }
            days_updated.push(e.toString())
            rangePosition = parseInt(e);
          }
        }
      })
      if (rangePosition && days_updated[days_updated.length-1]!==rangePosition.toString()) {
        days_updated[days_updated.length-1] += '-' + rangePosition.toString();
      }
      this.input = days_updated;
      this.$emit('input', days_updated);
    }
  },
  watch: {
    value(val) {
      if (Array.isArray(val) && val.length===0 && this.input.length) {
        this.input = [];
      }
    }
  },
  props: {
    value: {
      type: Array,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.value) this.input = this.value;
  }
};
</script>

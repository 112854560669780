<template>
  <v-select v-model="input" :items="days" label="Week days" :outlined="outlined" :hide-details="hideDetails" :disabled="disabled" multiple @change="update">
    <template v-slot:prepend-item>
      <v-list-item
          ripple
          @click="toggleSelectAll"
      >
        <v-list-item-action>
          <v-icon v-text="input.length === 0 ? 'mdi-checkbox-blank-outline' : input.length === days.length ? 'mdi-close-box' : 'mdi-minus-box'" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'TimeConditionWeekdayPicker',
  data: () => ({
    input: null,
    days: [
      {
        "value": "Su",
        "text": "Sunday"
      },
      {
        "value": "Mo",
        "text": "Monday"
      },
      {
        "value": "Tu",
        "text": "Tuesday"
      },
      {
        "value": "We",
        "text": "Wednesday"
      },
      {
        "value": "Th",
        "text": "Thursday"
      },
      {
        "value": "Fr",
        "text": "Friday"
      },
      {
        "value": "Sa",
        "text": "Saturday"
      },
    ],
  }),
  methods: {
    update() {
      let selected = [];
      let startIndex = null
      this.days.forEach((e, i) => {
        if (this.input.includes(e.value)) {
          if (!startIndex && startIndex!==0) {
            selected.push(e.value);
            startIndex = i;
          }
        } else {
          if (startIndex || startIndex===0) {
            if (startIndex < i - 1)
              selected[selected.length - 1] += '-' + this.days[i - 1].value;
            startIndex = null;
          }
        }
      })
      if (startIndex===0 || (!!startIndex && startIndex!==this.days.length - 1))
        selected[selected.length - 1] += '-' + this.days[this.days.length - 1].value;
      this.$emit('input', selected);
    },
    toggleSelectAll() {
      if (this.input.length<this.days.length) {
        this.input = [];
        this.days.forEach(e => this.input.push(e.value));
      } else {
        this.input = [];
      }
      this.update();
    },
  },
  watch: {
    value(val) {
      if (Array.isArray(val) && val.length===0) {
        this.input = [];
      }
    }
  },
  props: {
    value: {
      type: Array,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.value) this.input = this.value;
  }
};
</script>

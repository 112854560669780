<template>
    <div>
        <v-card v-if="showFilters && localSearch" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="Ring Group Number"
                            :disabled="isLoading"
                            v-model="searchFilters.ring_group_number"
                            @keydown.enter="updateLocalFilter"
                    />
                </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Ring Group Name"
                    :disabled="isLoading"
                    v-model="searchFilters.ring_group_name"
                    @keydown.enter="updateLocalFilter"
                />
              </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>

      <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="listData.total_records"
          :totalPages="listData.total_pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(listData.results) ? listData.results.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="false"
      >
        <v-simple-table>
          <thead>
          <tr>
            <th>Ref ID</th>
            <th class="text-center">Ring Group Number</th>
            <th class="text-center">Ring Group Name</th>
          </tr>
          </thead>

          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="listData.results && listData.results.length === 0" key="nodata">
              <td colspan="100%" class="text-center">
                No Devices found
              </td>
            </tr>
            <tr
                v-for="item in listData.results"
                :key="item.i_c_queue"
            >
              <td>
                <router-link
                    :to="{
                                    name: 'CallQueueSummary',
                                    params: {
                                        customerId: i_customer,
                                        callQueueId:item.i_c_queue,
                                    },
                                }"
                    class="font-weight-medium"
                >{{ item.i_c_queue }}</router-link
                >
              </td>
              <td class="text-center">
                {{ item.group_id }}
              </td>
              <td class="text-center">
                {{ item.group_name }}
              </td>
            </tr>
          </v-slide-x-transition>
        </v-simple-table>
      </PaginationCardV2>

    </div>
</template>

<script>
import PaginationCardV2 from "../templates/PaginationCardV2";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
export default {
    name: 'CallQueueListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
      busy:false,
      searchFilters: {
        ring_group_id: null,
        ring_group_name: null,
        page:1,
        limit: 10,
      },
      listData: {
        total_records:0,
        total_pages:0
      },
    }),
    components: {
      PaginationCardV2,
    },
    props: {
        i_customer: {
          type: Number,
          default: null,
        },
        hidePagination: {
            type: Boolean,
            default: false,
        },
        repId: {
            type: Number,
            default: null,
        },
        custId: {
            type: Number,
            default: null,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
      /**
       * The search for the call queues had 2 different version. Admin searches local database where as the customer searches via PortaOne (with has limited search options.).
       * This toggles tells us when one is being used. If we are not using local (via PortaOne) then we remove the search.
       *
       */
        localSearch: {
          type: Boolean,
          default: true,
        },
    },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
  },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },

      async update() {
        /*if(this.i_customer == null){
          return;
        }*/
        this.busy = true;
        let params = this.searchFilters;

        for (const [key, value] of Object.entries(params)) {
          if(value == null || value == undefined){
            delete params[key];
          }
        }

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

        let response;
        if(this.i_customer == null){
          response = await this.Api.send('get','/call-queues?' + queryString);
        }else {
          response = await this.Api.send('get', 'customers/' + this.i_customer + '/call-queues?' + queryString);
        }

        this.busy = false;
        if (response.success) {
          this.listData = response.data;
          this.paginationData = {
            data: response.data.results,
            count: response.data.total_records,
            pages: response.data.total_pages
          };
          if (this.firstLoad) this.firstLoad = false;
        } else {
          this.listData = [];
        }
      },
    },
};
</script>

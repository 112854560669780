<template>
    <ComplexDialog
        :title="'New Extension Device'"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="2"
        :step-next-disabled="!form.valid"
        :step-end-text="'Open device'"
        :step-next-text="'Create'"
        :max-width="400"
        @step-next="next"
        @step-end="finish"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-form v-model="form.valid">
            <v-row no-gutters>
              <v-col cols="12">
                <p class="subtitle-1 text--primary">
                  Device Manufacturer and Model
                </p>
                <p>Input the details of the device you wish to add.</p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-select
                    v-model="form.sdID"
                    label="Manufacturer/Model"
                    :items="deviceSelectOptions"
                    :disabled="loading || busy"
                    item-text="option"
                    item-value="value"
                    hide-details
                    outlined
                    :rules="rules.sdID"
                    class="mb-2"
                /><br />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                    v-model="form.mac"
                    label="Mac Address"
                    :rules="[ruleCheckMac]"
                    outlined
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                    v-model="form.label"
                    label="Name"
                    :rules="rules.label"
                    :counter="20"
                    hint="For reference"
                    outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="2">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6">New device '{{ form.label }}' created successfully</p>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
import Vue from 'vue';
export default {
  name: 'NewVoipDeviceDialog',
  components: {ComplexDialog},
  data: () => ({
    step: 1,
    busy: false,
    deviceSelectOptions: [
      { option: 'Active', value: 'Y' },
      { option: 'Terminated', value: 'N' },
    ],
    supportedDevices:null,
    createdVoipDeviceID: null,
    form: {
      sdID: null, //supported devices id
      label: null,
      mac: null,
    },
    rules: {
      mac: [
        (v) =>
            !v || v.length == 12 ||
            'Mac Address must have 12 characters.',
      ],
      label: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length < 21) || 'Label cannot be more than 20 characters.'
      ],
      sdID: [
        (v) => !!v || 'Please select',
      ],
    }
  }),
  created: function(){
    this.updateSupportVoipDevices();
  },
  watch: {
    visible(value) {
      if (value) {
        this.i_account = null;
      } else {
        this.reset( );
      }
    },
  },
  computed: {
    ruleCheckMac(){
      const mac = this.form.mac;

      if(mac != null && mac.includes(":", 2)){
        if (mac == null || mac.length != 17) {
          return 'Invalid character count.';
        }
      }else {
        if (mac == null || mac.length != 12) {
          return 'Invalid character count.';
        }
      }
      if(!this.supportedDevices){
        return true;
      }else if(!this.form.sdID) {
        return true;
      }

      let formattedMac = mac.replaceAll(':', '');

      let found = null;
      for(let i=0;i<this.supportedDevices.length;i++){
        if(this.supportedDevices[i].id == this.form.sdID){
          found = this.supportedDevices[i];
          break;
        }
      }

      if(!found){
        return true;
      }

      if(found.manufacturer == 'yealink'){
        let match = null;
        let prefix = ['001565','805EC0','805E0C', '249AD8'];
        for(let i=0;i<prefix.length;i++){
          if(formattedMac.toLowerCase().indexOf(prefix[i].toLowerCase()) === 0){
            match = prefix[i];
            break;
          }
        }
        if(!match){
          let reWork = prefix.map(x => "'"+x+"'");
          let lastPrefix = reWork[(reWork.length - 1)];
          delete(reWork[(reWork.length - 1)]);
          //return 'MAC address is not valid for selected device. Must start with \'001565\', \'805EC0\' or \'805E0C\'.';
          return 'MAC address is not valid for selected device. Must start with '+reWork.join(', ')+' or '+lastPrefix+'.';
        }
      }
      return true;
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.form.sdID = null;
      this.form.mac = null;
      this.form.label = null;
    },
    getManufacturerTitle(name){
      if(name == 'yealink'){
        return 'Yealink';
      }

      return name;
    },

    async updateSupportVoipDevices () {
      this.busy = true;
      let response = await this.Api.send('get', 'extensions/supported-voip-devices');
      this.deviceSelectOptions = [];
      this.supportedDevices = response.data;
      response.data.forEach(element => this.deviceSelectOptions.push({"value":element.id,"option":this.getManufacturerTitle(element.manufacturer)+'/'+element.model}));
      this.busy = false;
    },

    async next() {
      if (this.step===1) {
        this.busy = true;
        let params = {
          label: this.form.label,
          mac_address: this.form.mac.replaceAll(':', ''),
          supported_voip_device_id: this.form.sdID,
        }
        let response = await this.Api.send('post', 'extensions/'+this.icext+'/voip-devices', params);
        if (response.success) {
          this.createdVoipDevice = response.data;
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.step++;
        }
        this.busy = false;
      } else {
        this.step++;
      }
    },
    back() {
      this.step--;
    },
    finish() {
      this.$router.push({ name: 'VoipDevice',  params: {customerId:this.customerId, trunkId: this.createdVoipDevice.i_account, voipDeviceId: this.createdVoipDevice.id }});
      this.visible = false;
      //this.$emit('trunk', this.i_account);
    },
  },
  props: {
    icext: {
      type: Number,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
  },
  mixins: [apiMixin, dialogMixin],
};
</script>
<template>
    <BasicCard
        title="Call Barring"
        :loading="loading || !callBarringData || !data || !data.service_features"
        :no-skeleton="!!data && !!data.service_features && !!callBarringData"
    >
        <v-row dense class="text--primary" v-if="!!data && !!data.service_features && !!callBarringData">
            <v-col v-if="this.data.service_features.find((e) => e.name === 'call_barring').effective_flag_value!=='Y'" cols="12">
                <v-alert text type="info">Call barring has not been configured for this service. It is recommended to configure call barring now.</v-alert>
                Call barring is not configured for this service.
            </v-col>
            <v-col v-else-if="currentRules" cols="12">
                <p class="text-h6">{{currentRules.length}} active call barring rule<span v-if="currentRules.length!==1">s</span></p>
                <p v-for="rule in currentRules" cols="12" :key="rule" class="my-1">
                    <v-icon v-html="'mdi-alert-octagon'" left small />{{callBarringData.find((e) => e.i_cp_condition===rule).name}}
                </p>
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip text="Edit" icon="mdi-pencil" flat @click="showChangeDialog=true" />
        </template>
        <ChangeTrunkCallBarringDialog v-model="showChangeDialog" :data="currentRules" :i_account="data.i_account" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import ChangeTrunkCallBarringDialog from '../../dialogs/Trunk/ChangeTrunkCallBarringDialog';
import callBarringDataMixin from '../../../mixins/callBarringDataMixin';
export default {
    name: 'TrunkCallBarringCard',
    components: { ChangeTrunkCallBarringDialog, ButtonWithTooltip, BasicCard },
    mixins: [callBarringDataMixin],
    data: () => ({
        showChangeDialog: false
    }),
    computed: {
        currentRules() {
            return (!!this.data && !!this.data.service_features) ?
                this.data.service_features.find((e) => e.name === 'call_barring').effective_flag_value==='Y' ? this.data.service_features.find((e) => e.name === 'call_barring').attributes.find((f) => f.name === 'call_barring_rules').effective_values.sort() :
                null : null;
        },
    },
    props: {
        data: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
};
</script>

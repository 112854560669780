<template>
  <ComplexDialog
      title="Edit Call Queue Settings"
      v-model="visible"
      :loading="busy"
  >
    <CallQueueSettingsForm :callQueueData="callQueueData" :loading="busy" v-model="form" />

    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="submitUpdate"
          text
          :disabled="form == null"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import CallQueueSettingsForm from "../../pieces/CallQueues/CallQueueSettingsForm";
export default {
  name: 'EditCallQueueSettingsDialog',
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  components: {CallQueueSettingsForm, ComplexDialog },
  data: () => ({
    valid: false,
    form: {},
    customerRingGroups: [],
    busy: false,
    rules: {
      required: [
        (v) => !!v || 'Required',
      ]
    }
  }),
  methods: {
    async submitUpdate() {
      this.busy = true;

      const params = new FormData();
      if(this.form.announce_number_of_callers != 'Y'){
        params.append("announce_number_of_callers", 'N');
      }else{
        params.append("announce_number_of_callers", 'Y');
      }
      if(this.form.announce_estimated_wait_time != 'Y'){
        params.append("announce_estimated_wait_time", 'N');
      }else{
        params.append("announce_estimated_wait_time", 'Y');
      }

      params.append("average_duration", this.form.average_duration);
      params.append("announcement_interval", this.form.announcement_interval);

      if(this.form.play_music_on_hold == 'Y'){
        params.append("play_music_on_hold", 'Y');
        params.append("music_on_hold_file", this.form.music_on_hold_file);
      }else{
        params.append("play_music_on_hold", 'N');
      }

      //setup the axios object
    /*  this.Api.setHttpObject({
        "headers":{
          "Content-Type": "multipart/form-data; boundary="+params._boundary
        }
      });*/

      //note passing in the FormDatam will correctly set the Content-Type header
      //Note that PHP cannot handle multipart/form-data in a PUT request. Hence we must use POST for the update.
      const response = await this.Api.send('post', 'call-queues/' + this.callQueueData.i_c_queue, params);
      if (response.success) {
        this.showGlobalSuccessMessage('Update successful');
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },
  },
  props: {
    callQueueData: {
      type: Object,
      default: null
    }
  },
};
</script>
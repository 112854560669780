import {mdiHandshake} from "@mdi/js";

export default {
    data: () => {
        return ({
            ringGroupSelects: {
                sequence: [
                    { option: 'Order', value: 'Order'},
                    { option: 'Random', value: 'Random' },
                    { option: 'Simultaneous', value: 'Simultaneous' },
                    { option: 'Least used', value: 'LeastUsed' },
                ],
                callerId: [
                    { option: 'Caller\'s phone number and name', value: 'Y'},
                    { option: 'Ring Group phone number and name', value: 'N' },
                    { option: 'Caller\'s phone number with Ring Group name', value: 'I' },
                ],
            },
            ringGroupExtensionSelects: {
                ringingSelect: [
                    { option: 'Ring', value: 'ring' },
                    { option: 'Permanently Enabled', value: 'enabled' },
                    { option: 'Disabled', value: 'disabled' },
                ],
            }
        });
    },
};
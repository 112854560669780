<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="customerData && customerData.flagged_notes">
                <PageAlert
                    type="info"
                    icon="mdi-pin"
                    :button="$route.name!=='CustomerNotes'"
                    button-text="View Notes"
                    :button-to="{ name: 'CustomerNotes' }"
                >
                    {{customerData.flagged_notes[0].note}}<br/>
                    <span style="font-size: 10pt;">{{customerData.flagged_notes[0].firstname}} {{customerData.flagged_notes[0].lastname}} added this note <LocalDateTime :value="customerData.flagged_notes[0].created" relative />.</span>
                    <span v-if="customerData.flagged_notes.length>1" class="text--disabled"><br/>Note: there are multiple pinned notes for this customer - only the most recent is shown above.</span>
                </PageAlert>
            </v-col>
          <v-col cols="12" v-if="customerData && devicesPendingConfig && devicesPendingConfig.total_records > 0">
            <PageAlert
                type="warning"
                icon="mdi-deskphone"
                :button="$route.name!=='CustomerPendingVoipDevices'"
                button-text="View Devices"
                :button-to="{ name: 'CustomerPendingVoipDevices' }"
            >

                <span v-if="devicesPendingConfig.total_records < 4">
                  The following device(s) need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device.
                  Devices: <router-link :class="'device-warning-link'" v-if="this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[0].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[0].id" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[0].i_account,
                                        voipDeviceId:devicesPendingConfig.results[0].id,
                                    }}">{{devicesPendingConfig.results[0].label}}/{{devicesPendingConfig.results[0].mac_address}} (ext:{{devicesPendingConfig.results[0].label}})</router-link>
                  <span v-else>{{devicesPendingConfig.results[0].label}}/{{devicesPendingConfig.results[0].mac_address}} (ext:{{devicesPendingConfig.results[0].label}})</span>
                  <span v-if="devicesPendingConfig.results[1] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[1].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[1].id)">, <router-link :class="'device-warning-link'" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[1].i_account,
                                        voipDeviceId:devicesPendingConfig.results[1].id,
                                    }}">{{devicesPendingConfig.results[1].label}}/{{devicesPendingConfig.results[1].mac_address}} (ext:{{devicesPendingConfig.results[1].label}})</router-link></span>
                  <span v-else-if="devicesPendingConfig.results[1]">, {{devicesPendingConfig.results[1].label}}/{{devicesPendingConfig.results[1].mac_address}} (ext:{{devicesPendingConfig.results[1].label}})</span>
                  <span v-if="devicesPendingConfig.results[2] && (this.$route.name != 'VoipDevice' || this.$route.params.customerId != customerData.i_customer || this.$route.params.trunkId != devicesPendingConfig.results[2].i_account || this.$route.params.voipDeviceId != devicesPendingConfig.results[2].id)">, <router-link :class="'device-warning-link'" :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: devicesPendingConfig.results[2].i_account,
                                        voipDeviceId:devicesPendingConfig.results[2].id,
                                    }}">{{devicesPendingConfig.results[2].label}}/{{devicesPendingConfig.results[2].mac_address}} (ext:{{devicesPendingConfig.results[2].label}})</router-link></span>
                  <span v-else-if="devicesPendingConfig.results[2]">, {{devicesPendingConfig.results[2].label}}/{{devicesPendingConfig.results[2].mac_address}} (ext:{{devicesPendingConfig.results[2].label}})</span>.
                </span>
                <span v-else>
                  There are {{devicesPendingConfig.total_records}} devices that need to be reprovisioned in order for changes to take effect on the Device. This can be achieved by rebooting the Device.
                </span>
            </PageAlert>
          </v-col>
            <v-col cols="12">

                <!-- content -->
                <router-view />

                <!-- dialogs -->
                <CustomerBlockStatusDialog v-model="unblockDialog" :data="customerData" />
                <SMSCustomerSendDialog v-model="smsDialog" :customerData="customerData"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import customerDataMixin from '../../mixins/customerDataMixin';
import apiMixin from '../../mixins/apiMixin';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
import CustomerBlockStatusDialog from '../../components/dialogs/Customer/CustomerBlockStatusDialog';
import PageAlert from '../../components/core/PageAlert';
import LocalDateTime from '../../components/pieces/LocalDateTime';
import SMSCustomerSendDialog from "../../components/dialogs/SMS/SMSCustomerSendDialog.vue";
export default {
    name: 'CustomerParent',
    mixins: [apiMixin, customerDataMixin, refreshListenerMixin],
    components: {
      SMSCustomerSendDialog,
      LocalDateTime,
      PageAlert,
      CustomerBlockStatusDialog,
    },
    data: () => ({
      unblockDialog: false,
      unsuspendDialog: false,
      creditLimitDialog: false,
      smsDialog: false,
      //devicesPendingConfig:null,
    }),
    methods: {
        async get(id, force) {
            if (
                force ||
                !this.customerData ||
                this.customerData.i_customer !== parseInt(id)
            ) {
                this.$store.commit('loading', { name: 'customer', data: true });
                const response = await this.Api.send('post','customers/' + id + '/info', { extended_info: 1});
                if (response.success) {

                    let customerData = response.data;
                    if(customerData.customer_class_tag=="hosted-extension"){
                      //check for any devices that need a fresh config file.
                      customerData.devicesPendingConfig = await this.getDevicesNeededNewConfig(customerData.i_customer);
                    }

                    this.$store.commit('data', {
                        name: 'customer',
                        data: customerData,
                    });

                  this.$store.commit('loading', {
                    name: 'customer',
                    data: false,
                  });
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
            }
            if (this.$store.state.user.user_type === 'admin' && this.customerData.i_rep){
              await this.getPartner(this.customerData.i_rep);
            }
            if (this.$store.state.user.user_type === 'admin' && this.customerData.i_parent){
              await this.getReseller(this.customerData.i_parent);
            }

        },
        async getReseller(id) {
            if (
                this.$store.state.data.reseller &&
                this.$store.state.data.reseller.i_customer === parseInt(id)
            ) {
                this.$store.commit('loading', {
                    name: 'customer',
                    data: false,
                });
            } else {
                this.$store.commit('loading', { name: 'reseller', data: true });
                const response = await this.Api.send('post',
                    'customers/' + id + '/info'
                );
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'reseller',
                        data: response.data,
                    });
                    this.$store.commit('loading', {
                        name: 'reseller',
                        data: false,
                    });
                }
            }
        },
        async getPartner(id) {
            if (this.customerData.i_rep &&
                (!this.$store.state.data.partner ||
                this.$store.state.data.partner.i_rep !== id)
            ) {
                this.$store.commit('loading', { name: 'partner', data: true });
                const response = await this.Api.send('post',
                    'partners/' + id + '/info'
                );
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'partner',
                        data: response.data,
                    });
                }
                this.$store.commit('loading', { name: 'partner', data: false });
            }
        },
        async getDevicesNeededNewConfig(i_customer){
          const response = await this.Api.send('get','customers/' + i_customer + '/voip-devices-pending-config');
          if (response.success) {
            return response.data;
          }else{
            return null;
          }
        },
        update() {
            this.get(this.$route.params.customerId, true);
        },
        updateFailed() {
            this.$store.commit('loading', { name: 'customer', data: false });
            this.$router.push({ name: 'CustomerList' });
        },
      sendSms(){
        this.smsDialog = true;
      }
    },
    created() {
        this.get(this.$route.params.customerId);
    },
  /**
   * this will set the global customer data.
   * Note that if already loaded it used loaded data
   */
    watch: {
      $route(to, from) {
        this.get(to.params.customerId);
      },
    },
    // Note beforeRouteUpdate seems to be inconsistant.
    // beforeRouteUpdate(to, from, next) {
    //     console.log('route update');
    //     this.get(to.params.customerId);
    //     next();
    // },

  /**
   * This feature was added to allow us to target the updating of customer data within the application.
   * For example when deleting a call queue it hooks into this lister so we can update the call queue count in the side menu.
   * We cannot use a global refresh here as the process tried to refresh the deleted call queue and it failed to find it as it had been deleted.
   */
  mounted() {
    document.addEventListener('refreshRequestedParentCustomer', this.update);
    document.addEventListener('sendCustomerSMS', this.sendSms);
  },
  beforeDestroy() {
    document.removeEventListener('refreshRequestedParentCustomer', this.update);
    document.removeEventListener('sendCustomerSMS', this.sendSms);
  },
};
</script>

<style scoped>
.device-warning-link{
  color:#000000;
}
</style>

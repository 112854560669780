<template>
    <SimpleDialog
        v-model="visible"
        :title="(data.bp_charge_cc==='N' ? 'Enable' : 'Disable') + ' Automatic Payments?'"
        @click="changeAutoPay"
        :loading="busy"
        styledHeader
    >
        Automatic payments will be <span v-if="data.bp_charge_cc==='N'">enabled. Invoices will be automatically charged to the saved card on the due date</span><span v-else>disabled. Invoices will need to paid manually</span>.
    </SimpleDialog>
</template>

<script>
    import SimpleDialog from '../templates/SimpleDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import apiMixin from '../../../mixins/apiMixin';
    export default {
        name: 'CustomerAutoPayDialog',
        mixins: [apiMixin, dialogMixin],
        components: { SimpleDialog },
        data: () => ({
            busy: false
        }),
        methods: {
            async changeAutoPay() {
                if (!this.data) return;
                this.busy = true;
                const response = await this.Api.send('post','customers/' + this.data.i_customer + '/update_automatic_payments', { enabled: this.data.bp_charge_cc==='N' ? 1 : 0 });
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'customer',
                        data: { bp_charge_cc: this.data.bp_charge_cc==='N' ? 'Y' : 'N' },
                        update: true
                    });
                    this.visible = false;
                }
                this.busy = false;
            }
        },
        props: {
            data: {
                type: Object,
                default: null
            }
        }
    };
</script>

<style scoped>

</style>
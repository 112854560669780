<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <ExtensionVoipDeviceListCard
                    v-if="customerData.i_customer"
                    :small="$vuetify.breakpoint.xsOnly"
                    :filter="filter"
                    @update="updateFilter"
                    show-filters
                    :customerId="customerData.i_customer"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ExtensionVoipDeviceListCard from "../../components/cards/Extension/ExtensionVoipDeviceListCard";
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
export default {
    name: 'VoipDeviceList',
    mixins: [filterMixin,customerDataMixin],
    data: () => ({
        filter: {
            active: 'Y',
            id: null,
            limit: 10,
            page: 1,
        },
    }),
    components: {
      ExtensionVoipDeviceListCard,
    }
};
</script>

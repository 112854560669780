<template>
    <ComplexDialog
        :title="'Assign Extensions to Ring Group'"
        v-model="visible"
        :step="step"
        :loading="isBusy"
        :step-end="4"
        :step-next-disabled="!form.valid"
        :step-end-text="'Close'"
        :step-next-text="(step == 3 ? 'Confirm' : 'Next')"
        :hide-step-buttons = "step == 1"
        :stepNextDisabled="(step == 2 && form.extensionSettings == null)"
        :max-width="800"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <p class="subtitle-1 text--primary">
            Extension Select
          </p>
          <p>Select the extension you wish to assign to the ring group.</p>
          <RingGroupExtensionSelectListCard
              v-if="trunkData"
              :custId="trunkData.i_customer"
              :ring-group-id="trunkData.ringGroupDetails.i_c_group"
              :loading="isBusy"
              :filter="{
          limit: 10,
          page: 1,
        }"
              @selected="selectedExtension"
              show-filters
              select
              small
          />
        </v-window-item>
        <v-window-item :value="2">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="subtitle-1 text--primary">
                Extension Settings
              </p>
              <p>Adjust settings for the extension to be assigned.</p>
            </v-col>
          </v-row>
          <RingGroupExtensionForm
              v-model="form.extensionSettings"
              :trunkData="trunkData"
          />

        </v-window-item>
        <v-window-item :value="3">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="subtitle-1 text--primary">
                Summary
              </p>
              <p>Please review the setting and confirm your submission.</p>
            </v-col>
          </v-row>
          <div class="pa-4 mb-2 v-sheet theme--light rounded background_accent" v-if="selectExtensionDetails && form.extensionSettings && form.extensionSettings.ringing">
            <h3 class="text--primary">Summary</h3>
            <p class="mb-0">Extension Number: <strong>{{this.selectExtensionDetails.id}}</strong></p>
            <p class="mb-0">Ring Setting: <strong>{{ringGroupExtensionSelects.ringingSelect.find(element => element.value == form.extensionSettings.ringing).option}}</strong></p>
            <p class="mb-0" v-if="form.extensionSettings.ringing == 'ring'">Ring delay: <strong>{{form.extensionSettings.hunt_delay}}</strong></p>
            <p class="mb-0" v-if="form.extensionSettings.ringing == 'ring'">Ring duration: <strong>{{form.extensionSettings.hunt_expire}}</strong></p>
            <p class="mb-0" v-if="form.extensionSettings.ringing == 'ring'">Wait for <strong>{{form.extensionSettings.hunt_delay}}</strong> seconds then ring for <strong>{{form.extensionSettings.hunt_expire}}</strong> seconds.</p>
            <p class="mb-0">Ignore Follow-me/Voicemail: <strong>{{(form.extensionSettings.huntstop ? 'Yes' : 'No')}}</strong></p>
            <p class="mb-0" v-if="this.trunkData.ringGroupDetails.sequence == 'Order'">Order: <strong>{{form.extensionSettings.hunt_order}}</strong></p>

          </div>
        </v-window-item>
        <v-window-item :value="4">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6" v-if="this.selectExtensionDetails">Extension '{{ this.selectExtensionDetails.id }}' assigned successfully</p>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import ComplexDialog from "../templates/ComplexDialog";
import RingGroupExtensionSelectListCard from "../../cards/RingGroup/RingGroupExtensionSelectListCard";
import RingGroupExtensionForm from "../../pieces/RingGroup/RingGroupExtensionForm";
export default {
  name: 'LinkRingGroupExtensionDialog',
  components: {RingGroupExtensionForm, RingGroupExtensionSelectListCard, ComplexDialog},
  mixins: [apiMixin, dialogMixin,ringGroupDataMixin],
  data: () => ({
    step: 1,
    busy: false,
    form: {
      i_c_ext: null,
      extensionSettings: null
    },
    selectExtensionDetails: {},


    rules: {
      mac: [
        (v) =>
            !v || v.length == 12 ||
            'Mac Address must have 12 characters.',
      ],
      label: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length < 21) || 'Label cannot be more than 20 characters.'
      ],
      sdID: [
        (v) => !!v || 'Please select',
      ],
    }
  }),
  created: function(){
    this.reset( );
  },
  watch: {
    visible(value) {
      if (value) {
        this.i_account = null;
      } else {
        this.reset( );
      }
    },
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy || this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.form.i_c_ext = null;
      this.form.extensionSettings = null;
      this.selectExtensionDetails = null;

    },

    selectedExtension(data){
      let account = data;
      this.form.i_c_ext = account.extensionDetails.i_c_ext;
      this.selectExtensionDetails = account.extensionDetails;
      this.next( );
    },


    async next() {
      if (this.step===3) {
        this.busy = true;
        let params = this.form.extensionSettings;
        params.i_c_ext = this.form.i_c_ext;
        let response = await this.Api.send('post', 'ring-groups/'+this.trunkData.ringGroupDetails.i_c_group+'/extensions', params);
        if (response.success) {
          this.createdVoipDevice = response.data.data;
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.step++;
        }
        this.busy = false;
      } else {
        this.step++;
      }
    },
    back() {
      this.step--;
    },
    finish() {
      this.visible = false;
    },
  },
  props: {
    trunkData: {
      type: Object,
      required: true,
    },
  },

};
</script>
<template>
    <div>
        <v-sheet
            color="background_accent"
            rounded
            class="pa-4 mt-4"
            v-if="!!subProductDetails"
        >  <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
            <p class="text-h6 mb-1">{{subProductDetails.name}}</p>
            <p><span class="text-subtitle-1">{{subProductDetails.cost | currency}} per month </span><span class="text--disabled">including GST</span></p>
            <p v-if="subProductDetails.url">For full details refer to the <a :href="subProductDetails.url" target="_blank">Critical Information Summary</a>.</p>
          </v-col>
          <v-col cols="12" md="6" class=" text-right">

            <span class="text-h5 text--disabled">Monthly Plan Fees </span><span class="text-h3">{{subProductDetails.cost | currency}} </span>
          </v-col>
        </v-row>
        </v-sheet>
        <v-sheet v-if="((!subProductDetails || subProductDetails.value == null) ) || subProductDetails.value == 'no-plan'" rounded class="pa-4 mb-4" color="background_accent">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;" v-if="!subProductDetails">
              <p class="text-h6 mb-1">No plan selected</p>
              <p><span class="text-subtitle-1">$0.00 per month </span></p>
            </v-col>
            <v-col cols="12" md="6" v-if="!subProductDetails">
              <v-simple-table style="max-width: 600px;" dense>
                <thead>
                <tr>
                  <th class="text-left">
                    Calls to
                  </th>
                  <th class="text-left">
                    Cost
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    All destinations
                  </td>
                  <td>Not available</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
                <strong class="font-weight-bold red--text">Fax to Mail will not function until a plan is selected.</strong>
            </v-col>
          </v-row>
        </v-sheet>
    </div>
</template>

<script>
import planDataMixin from '../../../mixins/planDataMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'FaxToMailPlanInfoSummary',
    mixins: [planDataMixin, GlobalHelperMixin],
    data: () => ({
        subProductDetails:null,
        productOptionDetails:null,
        type: null
    }),
    props: {
        data: {
            type: Object,
            default: null,
        }
    },
    methods: {

    },
    created() {
        if (this.data && this.data.subProduct){
          this.subProductDetails = this.faxtomailPlanData.types.find(f => f.value == this.data.subProduct);
          /*if (this.subProductDetails) {
            this.productOptionDetails = this.subProductDetails.options.find(f => f.value == this.data.productOption);
          }*/
        }
    }
};
</script>
<style scoped>
    table > tbody > tr:hover {
        background: transparent !important;
    }
    table > tbody > tr > td {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
    table > thead > tr > th {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
</style>
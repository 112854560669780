<template>
  <ComplexDialog
      :title="customerData == null ? 'empty' : 'Send SMS to '+customerData.name+' ('+customerData.i_customer+').'"
      v-model="visible"
      :max-width="500"
      :step="step"
      :loading="isBusy"
      :step-end="endStep"
      :step-next-disabled="nextStepDisabled"
      @step-next="next"
      @step-back="back"
      @step-end="finish"
      :step-end-text="'Close'"
      :step-next-text="'Send'"
      :step-back-text="(step == endStep)  ? 'Close' : 'Back'"
  >
    <SpinnerOverlay v-if="this.isBusy"/>
    <v-window v-model="step" :touch="{left: null, right: null}">
      <v-window-item :value="1">
        <p>Fill out this form to instantly send the customer an SMS.</p>
        <v-form v-model="valid">
          <v-text-field v-model="form.mobile" label="Number to send SMS to" hint="Must be a mobile number"  :rules="rules.mobile" outlined/>
          <v-textarea
            v-model="form.message"
            name="input-7-1"
            variant="filled"
            label="Message"
            hint="Message to send the user"
            auto-grow
            model-value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
            :rules="rules.sms_message"
            outlined
            :counter="160"
          ></v-textarea>
        </v-form>
      </v-window-item>
      <v-window-item :value="2">
        <v-row no-gutters>
          <v-col cols="12" class="py-4">
            <div class="text-center">
              <v-icon size="120" color="success">mdi-check-circle-outline</v-icon>
              <p class="text-h6">SMS Sent successfully</p>
            </div>
            <v-card
              class="mx-auto"
              max-width="460"
              outlined
            >
              <v-card-text>
                <div>Number</div>
                <p class="text-h5 text--primary">
                  {{form.mobile}}
                </p>
                <p class="mb-1">Message</p>
                <div class="text--primary">
                  {{form.message}}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import Currency from "../../pieces/Currency";
import SpinnerOverlay from "../../pieces/Global/SpinnerOverlay";
import ComplexDialog from "../templates/ComplexDialog";
export default {
    name: 'SMSCustomerSendDialog',
    components: {SpinnerOverlay,ComplexDialog},
    mixins: [apiMixin, dialogMixin],
    data: () => ({
      busy: false,
      valid: false,
      step:1,
      endStep:2,
      form:{
        mobile:null,
        message:null,
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
        ],
        mobile: [
          (v) => !!v || 'Required',
          //(v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
          //(v) => (/^(?:0[2,3,5-9]\d{8}|04\d{8}|(?:\+?61\d{9}))$/.test(v.replace(/\s+/g, ''))) || 'Invalid Phone/Mobile number.'
          (v) => (/^04\d{2}\s?\d{3}\s?\d{3}$/.test(v.replace(/\s+/g, ''))) || 'Invalid Mobile number.'
        ],
        sms_message:[
          (v) => !!v || 'Required',
          (v) => v != null && v.length < 161 || 'Must be no greater than 160 characters',
        ]
      }
    }),
    props: {
      customerData:{
        type: Object,
        default: null,
      }
    },
  computed: {
    isBusy(){
      return this.loading || this.busy;
    },
    nextStepDisabled( ){
      if(this.isBusy){
        return true;
      }else if(!this.valid){
        return true;
      }
      return false;
    },
  },
    watch: {
      visible(val) {
        if(val){
          this.setup( );
        }
      },

    },
    methods: {
      async next() {
        if(this.step == 1){
          this.sendSms( )
        }
      },
      back() {
       //there is no back process as this dialog only has 2 slides
      },
      finish() {
        this.visible = false;
      },
      setup( ){
        this.step = 1;
        this.form = {
          mobile:null,
          message:null,
        }

        if(this.customerData.phone2){
          //if((/^(?:0[2,3,5-9]\d{8}|04\d{8}|(?:\+?61\d{9}))$/.test(this.customerData.phone2.replace(/\s+/g, '')))){
          if((/^04\d{2}\s?\d{3}\s?\d{3}$/.test(this.customerData.phone2.replace(/\s+/g, '')))){
            this.form.mobile = this.customerData.phone2;
          }
        }

        if(this.form.mobile == null){
          if(this.customerData.phone1){
            //if((/^(?:0[2,3,5-9]\d{8}|04\d{8}|(?:\+?61\d{9}))$/.test(this.customerData.phone1.replace(/\s+/g, '')))){
            if((/^04\d{2}\s?\d{3}\s?\d{3}$/.test(this.customerData.phone1.replace(/\s+/g, '')))){
              this.form.mobile = this.customerData.phone1;
            }
          }
        }
      },

      async sendSms(){
        this.busy = true;
        let params = this.form;
        const response = await this.Api.send('post','customers/'+this.customerData.i_customer+'/sms/custom', params);
        this.busy = false;
        if (response.success) {
          this.step = 2;
        }
      },
    },
};
</script>
<template>
    <ComplexDialog
        v-model="visible"
        :title="'View Plan Details - Extension '+ data.id"
    >
        <ExtensionPlanInfoSummary
            :data="planData"
        />
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                @click="visible=false"
                text
            >Close</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ComplexDialog from '../templates/ComplexDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import ExtensionPlanInfoSummary from '../../pieces/Extension/ExtensionPlanInfoSummary';
    export default {
        name: 'ViewExtensionPlanDialog',
        mixins: [dialogMixin],
        components: { ExtensionPlanInfoSummary, ComplexDialog },
        props: {
            data: {
                type: Object,
                default: null
            }
        },
      computed:{
          planData( ){
            let ret = {
              subProduct: this.data.planDetails.planTag,
              productOption: null
            }

            if(this.data.planDetails.addons[0]) {
              ret.productOption = this.data.planDetails.addons[0].tag;
            }
            return ret;

          }
      }
    };
</script>

<style scoped>

</style>
<template>
  <ComplexDialog
      title="Call Screening Conditions"
      v-model="visible"
      :loading="loading || busy"
  >
    <v-tabs v-model="tab" class="mb-2" fixed-tabs>
      <v-tab>Caller Number Groups</v-tab>
      <v-tab>Callee Number Groups</v-tab>
      <v-tab>Time Windows</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :value="0">
        <v-simple-table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Number(s)</th>
            <th>In Use</th>
            <th>Actions</th>
          </tr>
          </thead>
          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="!!numbers_from && numbers_from.length===0" key="nodata">
              <td colspan="7" class="text-center">
                No conditions configured
              </td>
            </tr>
            <tr v-for="rule in numbers_from" :key="rule.i_cp_condition">
              <td>{{rule.name}}</td>
              <td><v-chip v-for="(number, index) in rule.numbers" :key="index" v-text="(number.negated==='Y' ? 'Any except ' : '') + number.number" small class="ma-1" /></td>
              <td><v-icon v-text="rule.is_used==='Y' ? 'mdi-check-circle' : 'mdi-close-circle'" :color="rule.is_used==='Y' ? 'success' : 'error'" /></td>
              <td class="text-no-wrap">
                <ButtonWithTooltip icon="mdi-pencil" tooltip="Edit Number Group" @click="editNumberGroup(rule.i_cp_condition)" :key="1" />
                <ButtonWithTooltip icon="mdi-delete" tooltip="Delete Number Group" :key="2" :disabled="rule.is_used==='Y'" @click="confirmDelete(rule.i_cp_condition)" />
              </td>
            </tr>
          </v-slide-x-transition>
        </v-simple-table>
        <v-btn text block @click="addCallerCondition" class="mt-2"><v-icon v-text="'mdi-plus'" />Add new</v-btn>
      </v-tab-item>
      <v-tab-item :value="1">
        <v-simple-table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Number(s)</th>
            <th>In Use</th>
            <th>Actions</th>
          </tr>
          </thead>
          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="!!numbers_to && numbers_to.length===0" key="nodata">
              <td colspan="7" class="text-center">
                No conditions configured
              </td>
            </tr>
            <tr v-for="rule in numbers_to" :key="rule.i_cp_condition">
              <td>{{rule.name}}</td>
              <td><v-chip v-for="(number, index) in rule.numbers" :key="index" v-text="(number.negated==='Y' ? 'Any except ' : '') + number.number" small class="ma-1" /></td>
              <td><v-icon v-text="rule.is_used==='Y' ? 'mdi-check-circle' : 'mdi-close-circle'" :color="rule.is_used==='Y' ? 'success' : 'error'" /></td>
              <td class="text-no-wrap">
                <ButtonWithTooltip icon="mdi-pencil" tooltip="Edit Number Group" @click="editNumberGroup(rule.i_cp_condition)" :key="1" />
                <ButtonWithTooltip icon="mdi-delete" tooltip="Delete Number Group" :key="2" :disabled="rule.is_used==='Y'" @click="confirmDelete(rule.i_cp_condition)" />
              </td>
            </tr>
          </v-slide-x-transition>
        </v-simple-table>
        <v-btn text block @click="addCalleeCondition" class="mt-2"><v-icon v-text="'mdi-plus'" />Add new</v-btn>
      </v-tab-item>
      <v-tab-item :value="2">
        <v-simple-table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>In Use</th>
            <th>Actions</th>
          </tr>
          </thead>
          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="!!time_windows && time_windows.length===0" key="nodata">
              <td colspan="7" class="text-center">
                No conditions configured
              </td>
            </tr>
            <tr v-for="rule in time_windows" :key="rule.i_cp_condition">
              <td>{{rule.name}}</td>
              <td>{{rule.time_window.description}}</td>
              <td><v-icon v-text="rule.is_used==='Y' ? 'mdi-check-circle' : 'mdi-close-circle'" :color="rule.is_used==='Y' ? 'success' : 'error'" /></td>
              <td class="text-no-wrap">
                <ButtonWithTooltip icon="mdi-pencil" tooltip="Edit Time Window" @click="editTimeWindow(rule.i_cp_condition)" :key="1" />
                <ButtonWithTooltip icon="mdi-delete" tooltip="Delete Time Window" :key="2" :disabled="rule.is_used==='Y'" @click="confirmDelete(rule.i_cp_condition)" />
              </td>
            </tr>
          </v-slide-x-transition>
        </v-simple-table>
        <v-btn text block @click="addTimeCondition" class="mt-2"><v-icon v-text="'mdi-plus'" />Add new</v-btn>
      </v-tab-item>
    </v-tabs-items>
    <template v-slot:actions>
      <v-spacer />
      <v-btn text @click="visible=false">Close</v-btn>
    </template>
    <CallScreeningNumberGroupDialog
        v-model="showNumberRuleDialog"
        :data="data"
        :type="new_condition_type"
        :condition="current_number_condition"
        :conditions="new_condition_type==='FromNumber' ? numbers_from : numbers_to"
        @change="$emit('refresh')"
    />
    <CallScreeningTimeWindowDialog
        v-model="showTimeWindowDialog"
        :data="data"
        :condition="current_time_condition"
        :conditions="time_windows"
        @change="$emit('refresh')"
    />
    <SimpleDialog v-model="showDeleteDialog" :loading="busy" title="Delete condition?" @click="deleteRule(current_number_condition)" styledHeader>
      Are you sure you want to delete the condition?
    </SimpleDialog>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ButtonWithTooltip from '@/components/pieces/ButtonWithTooltip';
import CallScreeningNumberGroupDialog from '@/components/dialogs/Trunk/CallScreeningNumberGroupDialog';
import SimpleDialog from '@/components/dialogs/templates/SimpleDialog';
import CallScreeningTimeWindowDialog from "./CallScreeningTimeWindowDialog";

export default {
  name: 'CallScreeningConditionsDialog',
  components: { CallScreeningTimeWindowDialog, SimpleDialog, CallScreeningNumberGroupDialog, ButtonWithTooltip, ComplexDialog },
  data: () => ({
    busy: false,
    tab: 0,
    numbers_to: null,
    numbers_from: null,
    time_windows: null,
    current_number_condition: null,
    current_time_condition: null,
    new_condition_type: null,
    showNumberRuleDialog: false,
    showTimeWindowDialog: false,
    showDeleteDialog: false
  }),
  mixins: [apiMixin, dialogMixin],
  methods: {
    async get() {
      this.busy = true;
      let response = await this.Api.send('get', 'trunks/' + this.data.i_account + '/call_screening_condition')
      this.busy = false;
    },
    parseRules() {
      if (this.conditions && this.conditions.length) {
        this.numbers_from = [ ...this.conditions.filter(e => e.type === 'FromNumber')]
        this.numbers_to = [ ...this.conditions.filter(e => e.type === 'ToNumber')]
        this.time_windows = [ ...this.conditions.filter(e => e.type === 'TimeWindow')]
      } else {
        this.numbers_from = [];
        this.number_to = [];
        this.time_windows = [];
      }
    },
    editNumberGroup(id) {
      this.current_number_condition = { ...this.conditions.find(e => e.i_cp_condition === id) };
      this.showNumberRuleDialog = true;
    },
    editTimeWindow(id) {
      this.current_time_condition = { ...this.conditions.find(e => e.i_cp_condition === id) };
      this.showTimeWindowDialog = true;
    },
    addCalleeCondition() {
      this.current_number_condition = null;
      this.new_condition_type = 'ToNumber';
      this.showNumberRuleDialog = true;
    },
    addCallerCondition() {
      this.current_number_condition = null;
      this.new_condition_type = 'FromNumber';
      this.showNumberRuleDialog = true;
    },
    addTimeCondition() {
      this.current_time_condition = null;
      this.showTimeWindowDialog = true;
    },
    confirmDelete(id) {
      this.current_number_condition = { ...this.conditions.find(e => e.i_cp_condition === id) };
      this.new_condition_type = null;
      this.showDeleteDialog = true;
    },
    async deleteRule(condition) {
      this.busy = true;
      let response = await this.Api.send('delete', 'trunks/' + this.data.i_account + '/call_screening_condition', { condition: condition });
      if (response.success) {
        this.$emit('refresh');
        this.showDeleteDialog = false;
      }
      this.busy = false;
    }
  },
  props: {
    conditions: {
      type: Array,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  watch: {
    conditions: {
      handler() {
        this.parseRules()
      },
      deep: true
    },
  },
  created() {
    this.parseRules()
  }
};
</script>
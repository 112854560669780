<template>
    <v-row dense v-if="trunkData">
        <v-col cols="12">
            <ExtensionRingGroupListCard
                v-if="trunkData.extensionDetails != undefined"
                :trunkData="trunkData"
                :loading="isBusy"
                @update="updateFilter"
                :filter="filter"
                show-filters
            />
        </v-col>
    </v-row>
</template>

<script>
import ExtensionRingGroupListCard from '../../components/cards/Extension/ExtensionRingGroupListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import trunkDataMixin from "../../mixins/trunkDataMixin";
export default {
  name: 'ExtensionRingGroupList',
  mixins: [filterMixin, customerDataMixin, trunkDataMixin],
  components: {
    ExtensionRingGroupListCard,
  },
  data: () => ({
    filter: {
      limit: 10,
      page: 1,
      id: null,
      active: 'Y'
    },
    extensionData: null,
  }),
  methods: {
  },
  computed: {
    isBusy: function( ){
      return this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },

  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },

};
</script>

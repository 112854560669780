<template>
  <div>
    <v-row>
      <v-col lg="12">
        <v-card
            :loading="isBusy"
            :disabled="isBusy"
            height="100%"
            class="flexcard"
            elevation="0"
            outlined
        >
          <v-card-subtitle>Inactive Actions</v-card-subtitle>
          <v-card-text>
            <span  v-if="inactiveTargetedTransitions && inactiveTargetedTransitions.length == 0">Not linked to any Inactive Actions.</span>
            <ul v-else>
              <li v-for="item in inactiveTargetedTransitions"
                  :key="item.i_menu_transition"><a @click="openAutoAttendant(item.auto_attendant_i_account, item.i_menu)">{{
                  item.auto_attendant_name
                }}</a></li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-card
            :loading="isBusy"
            :disabled="isBusy"
            height="100%"
            class="flexcard"
            elevation="0"
            outlined
        >
          <v-card-subtitle>Auto Attendant Actions</v-card-subtitle>
          <v-card-text>
            <span  v-if="actionTargetedTransitions && actionTargetedTransitions.length == 0">Not linked to any Auto Attendant Actions.</span>
            <ul v-else>
              <li v-for="item in actionTargetedTransitions"
                  :key="item.i_menu_transition">
                <a @click="openAutoAttendant(item.auto_attendant_i_account, item.i_menu)">
                  Input ({{item.event}}) {{
                    item.auto_attendant_name
                  }}</a></li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
export default {
    name: 'AutoAttendantTargetedTransitionsList',
    mixins: [apiMixin, refreshListenerMixin,ringGroupDataMixin,AutoAttendantMixin],
    data: () => ({
      busy: false,
      inactive: [],
      actions: [],
    }),
    components: {
    },
    props: {
      inactiveTargetedTransitions:{
        type: Array,
        required: true
      },
      actionTargetedTransitions:{
        type: Array,
        required: true
      },
      iCustomer:{
        type: Number,
        required: true
      },
        loading: {
          type: Boolean,
          default:false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },

    methods: {

      openAutoAttendant(i_account, i_menu)
      {
        this.visible = false;
        this.$router.push({name: 'AutoAttendantAccountAutoAttendantSummary', params: {customerId: this.iCustomer, trunkId: i_account, menuId: i_menu}});
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      }
    },
};
</script>

<template>
    <HistoryCard
        :cust-id="parseInt(this.$route.params.customerId)"
        :loading="customerLoading"
        @update="updateFilter"
        :filter="filter"
        show-filters
    />
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import HistoryCard from '../../components/cards/shared/HistoryCard';
export default {
    name: 'CustomerHistory',
    mixins: [filterMixin, customerDataMixin],
    components: {
        HistoryCard
    },
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
        },
    })
};
</script>

<template>
  <BasicCard
      :loading="loading"
      title="Input Errors"
  >
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold" style="height:100%">On Timeout</span> <v-btn v-if="aaData.msg_timeout_set && parseInt(aaData.msg_timeout_set) == 1"
          plain
          disabled
      ></v-btn>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="(aaData.msg_timeout_type && aaData.msg_timeout_type == 'custom')"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>

        <v-tooltip bottom v-if="currentPromptTimeoutFile && currentPromptTimeoutFile.status == 'IN_PROGRESS'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon
                  class="mr-2"
              >mdi mdi-loading mdi-spin</v-icon>
            </v-btn>
          </template>
          <span>Pending codec conversion...</span>
        </v-tooltip>
        <v-tooltip bottom v-else-if="(aaData.msg_timeout_set && parseInt(aaData.msg_timeout_set) == 1) || (currentPromptTimeoutFile && currentPromptTimeoutFile.status == 'FINISHED')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadFile('timeout')"
            >
              <v-icon v-html="'mdi-download'" />
            </v-btn>
          </template>
          <span>Download file</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense v-if="currentPromptTimeoutFile && currentPromptTimeoutFile.status == 'ERROR'">
      <v-col cols="12">
        <span class="font-weight-bold">Last On Timeout Upload Error</span>
      </v-col>
      <v-col cols="12">
        {{currentPromptTimeoutFile.error_message}} <v-btn
          @click="clearErrorMessage('timeout')"
          x-small
          v-if="!clearTimeoutErrorLoading"
      >
        clear
      </v-btn>
        <v-icon
            class="mr-2"
            v-if="clearTimeoutErrorLoading"
        >mdi mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">On Unavailable Selection</span> <v-btn v-if="aaData.msg_disabled_set && parseInt(aaData.msg_disabled_set) == 1"
          plain
          disabled
      ></v-btn>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="(aaData.msg_disabled_type && aaData.msg_disabled_type == 'custom')"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>
        <v-tooltip bottom v-if="this.currentPromptDisabledFile && this.currentPromptDisabledFile.status == 'IN_PROGRESS'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon
                  class="mr-2"
              >mdi mdi-loading mdi-spin</v-icon>
            </v-btn>
          </template>
          <span>Pending codec conversion...</span>
        </v-tooltip>
        <v-tooltip bottom v-else-if="(aaData.msg_disabled_set && parseInt(aaData.msg_disabled_set) == 1)  || (currentPromptDisabledFile && currentPromptDisabledFile.status == 'FINISHED')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadFile('disabled')"
            >
              <v-icon v-html="'mdi-download'" />
            </v-btn>
          </template>
          <span>Download file</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row dense v-if="currentPromptDisabledFile && currentPromptDisabledFile.status == 'ERROR'">
      <v-col cols="12">
        <span class="font-weight-bold">Last On Unavailable Upload Error</span>
      </v-col>
      <v-col cols="12">
        {{currentPromptDisabledFile.error_message}} <v-btn
          @click="clearErrorMessage('disabled')"
          x-small
          v-if="!clearDisabledErrorLoading"
      >
        clear
      </v-btn>
        <v-icon
            class="mr-2"
            v-if="clearDisabledErrorLoading"
        >mdi mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <span class="font-weight-bold">Input errors count before disconnect</span>: {{ aaData.replay_menu_times }}
      </v-col>
    </v-row>

    <template v-slot:actions>
      <ButtonWithTooltip
          text="Edit"
          flat
          tooltip="Edit prompt"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
    </template>
    <AutoAttendantEditInputErrorsDialog :loading="isBusy" :trunkData="trunkData" :aaData="aaData" v-model="showEditDialog" />
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import AutoAttendantEditInputErrorsDialog from "../../dialogs/AutoAttendants/AutoAttendantEditInputErrorsDialog";
export default {
    name: 'AutoAttendantInputErrorsCard',
    mixins: [apiMixin, paginationComponentMixin],
    data: () => ({
      busy:false,
      showEditDialog:false,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',

      currentPromptTimeoutFile:null,
      currentPromptDisabledFile:null,
      currentPromptTimeoutFilePollStatusInterval:null,
      currentPromptDisabledFilePollStatusInterval:null,
      clearTimeoutErrorLoading:false,
      clearDisabledErrorLoading:false,
    }),
    components: {
      AutoAttendantEditInputErrorsDialog,
      ButtonWithTooltip,
      BasicCard,
    },
    props: {
        trunkData: {
            type: Object,
            required:true
        },
        aaData: {
          type: Object,
          required:true
        },
        loading: {
          type: Boolean,
          default:false,
        },
    },
    methods: {

      downloadFile(prompt_type)
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+prompt_type+'/audio-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },

      async refreshPrompt(promptType){
        let response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+promptType);
        if(response.success){
          if(promptType == 'timeout'){
            this.currentPromptTimeoutFile = response.data;
            if(this.currentPromptTimeoutFile.status != 'IN_PROGRESS'){
              //remove the requesting.
              clearInterval(this.currentPromptTimeoutFilePollStatusInterval);
            }
          }else{
            this.currentPromptDisabledFile = response.data;
            if(this.currentPromptDisabledFile.status != 'IN_PROGRESS'){
              //remove the requesting.
              clearInterval(this.currentPromptDisabledFilePollStatusInterval);
            }
          }
        }
      },

      async clearErrorMessage(promptType){
        if(promptType == 'timeout'){
          if(!this.currentPromptTimeoutFile || this.currentPromptTimeoutFile.status != 'ERROR'){
            return; //no valid to delete
          }
        }else{
          if(!this.currentPromptDisabledFile || this.currentPromptDisabledFile.status != 'ERROR'){
            return; //no valid to delete
          }
        }

        if(promptType == 'timeout'){
          this.clearTimeoutErrorLoading = true;
        }else{
          this.clearDisabledErrorLoading = true;
        }

        let response = await this.Api.send('put','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+promptType+'/clear-conversion-errors');

        if(promptType == 'timeout'){
          if(response.success){
            this.currentPromptTimeoutFile = null;
          }
          this.clearTimeoutErrorLoading = false;
        }else{
          if(response.success){
            this.currentPromptDisabledFile = null;
          }
          this.clearDisabledErrorLoading = false;
        }
      },

    },
    created( ) {

    },
    computed:{
      isBusy( ){
        return this.loading || this.busy;
      },
      timeoutPrompt( ){
        if(this.aaData.prompt_timeout_file_last && this.aaData.prompt_timeout_file_last.id){
          return this.aaData.prompt_timeout_file_last;
        }else{
          return null;
        }
      },
      disabledPrompt( ){
        if(this.aaData.prompt_disabled_file_last && this.aaData.prompt_disabled_file_last.id){
          return this.aaData.prompt_disabled_file_last;
        }else{
          return null;
        }
      }
    },
    watch: {
      timeoutPrompt(value){
        this.currentPromptTimeoutFile = value;
        if(this.currentPromptTimeoutFile && this.currentPromptTimeoutFile.id && this.currentPromptTimeoutFile.status == 'IN_PROGRESS'){
          this.currentPromptTimeoutFilePollStatusInterval= setInterval(() => {
            this.refreshPrompt('timeout');
          }, 3000);
        }
      },
      disabledPrompt(value){
        this.currentPromptDisabledFile = value;
        if(this.currentPromptDisabledFile && this.currentPromptDisabledFile.id && this.currentPromptDisabledFile.status == 'IN_PROGRESS'){
          this.currentPromptDisabledFilePollStatusInterval= setInterval(() => {
            this.refreshPrompt('disabled');
          }, 3000);
        }
      }
    },
};
</script>

<template>
    <div>
        <v-card class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="ID"
                            :disabled="isBusy"
                            v-model="searchFilters.id"
                            @keydown.enter="updateLocalFilter"
                    />
                </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="MAC"
                    :disabled="isBusy"
                    v-model="searchFilters.mac"
                    @keydown.enter="updateLocalFilter"
                />
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Label"
                    :disabled="isBusy"
                    v-model="searchFilters.label"
                    @keydown.enter="updateLocalFilter"
                />
              </v-col>
                <v-col cols="12" sm="3">
                    <v-select
                            hide-details
                            outlined
                            dense
                            label="Provisioned"
                            v-model="searchFilters.provisioned"
                            :disabled="isBusy"
                            :items="provisionedOptions"
                            item-text="option"
                            item-value="value"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isBusy"
                            @click="updateSearch"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCardV2
            :page="searchFilters.page"
            :totalRecords="listData.total_records"
            :totalPages="listData.total_pages"
            :limit="searchFilters.limit"
            :currentPageTotal="(listData.results) ? listData.results.length : 0"
            :loading="isBusy"
            @updatedPagination="updatedPagination"
            :no-skeleton="false"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Ref ID</th>
                        <th class="text-center">MAC</th>
                        <th class="text-center">Label</th>
                        <th class="text-center">Manufacturer/Model</th>
                        <th class="text-center">Provisioned</th>
                    </tr>
                </thead>

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No Devices found
                        </td>
                    </tr>
                    <tr
                      v-for="item in listData.results"
                      :key="item.id"
                      >
                        <td>
                            <router-link
                                :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.i_account,
                                        voipDeviceId:item.id,
                                    },
                                }"
                                class="font-weight-medium"
                                >{{ item.id }}</router-link
                            >
                        </td>
                        <td class="text-center">
                            {{ item.mac_address }}
                        </td>
                        <td class="text-center">
                            {{ item.label }}
                        </td>
                      <td class="text-center">
                        {{ item.manufacturer }}/{{item.model}}
                      </td>
                        <td class="text-center">
                          <v-chip
                              v-if="item.last_provisioned_at == null"
                              small
                              color="red"
                              text-color="white"
                          >
                            Not Provisioned
                          </v-chip>
                          <v-chip
                              v-if="item.last_provisioned_at != null"
                              small
                              color="green"
                              text-color="white"
                          >
                            Provisioned
                          </v-chip>
                        </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCardV2>
    </div>
</template>

<script>
import PaginationCardV2 from '@/components/cards/templates/PaginationCardV2';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'ExtensionVoipDeviceListCard',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
        searchFilters: {
            id: null,
            mac: null,
            label: null,
            provisioned: null,
            page:1,
            limit: 10,
        },
        provisionedOptions: [
            { option: 'Any', value: null },
            { option: 'Provisioned', value: 'Y' },
            { option: 'Not Provisioned', value: 'N' },
        ],
        busy: false,
        listData: {
          total_records:0,
          total_pages:0
        },
        paginationData:[],
    }),
    components: {
        PaginationCardV2,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        extensionId: {
            type: Number,
            default: null,
        },
        customerId: {
          type: Number,
          default: null,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.update( );
      },
        async update() {
            this.busy = true;
            let params = this.searchFilters;

            for (const [key, value] of Object.entries(params)) {
              if(value == null || value == undefined){
                delete params[key];
              }
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            let response;
            if(this.customerId != null){
              response = await this.Api.send('get','customers/'+this.customerId+'/extension-voip-devices?'+queryString);
            }else if(this.extensionId != null){
                response = await this.Api.send('get','extensions/'+this.extensionId+'/voip-devices?'+queryString);
            }else{
                response = await this.Api.send('get','voip-devices?'+queryString);
            }
            this.busy = false;
            if (response.success) {
                this.listData = response.data;
                this.paginationData = {
                  data: response.data.results,
                  count: response.data.total_records,
                  pages: response.data.total_pages
                };
                if (this.firstLoad) this.firstLoad = false;
            } else {
                this.listData = [];
            }
        },
    },
};
</script>

const NSW = { value: 'New South Wales', text: 'NSW' };
const VIC = { value: 'Victoria', text: 'VIC' };
const QLD = { value: 'Queensland', text: 'QLD' };
const TAS = { value: 'Tasmania', text: 'TAS' };
const ACT = { value: 'Australian Capital Territory', text: 'ACT' };
const SA = { value: 'South Australia', text: 'SA' };
const NT = { value: 'Northern Territory', text: 'NT' };
const WA = { value: 'Western Australia', text: 'WA' };

const allStates = [NSW, VIC, QLD, TAS, ACT, SA, NT, WA];

export default Object.freeze({
    allStates: allStates,
    NSW: NSW,
    VIC: VIC,
    QLD: QLD,
    TAS: TAS,
    ACT: ACT,
    SA: SA,
    NT: NT,
    WA: WA,
});

<template>
    <ComplexDialog
        :title="'Create Call Queue'"
        v-model="visible"
        :step="step"
        :loading="isBusy"
        :step-end="4"
        :step-next-disabled="!form.valid"
        :step-end-text="'View New Call Queue'"
        :step-next-text="(step == 3 ? 'Confirm' : 'Next')"
        :hide-step-buttons = "step == 1"
        :stepNextDisabled="(step == 2 && form.callQueueSettings == null)"
        :max-width="800"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <p class="subtitle-1 text--primary">
            Ring Group Select
          </p>
          <p>Select the Ring Group you wish to link with this new Call Queue.</p>
          <CallQueueRingGroupSelectListCard
              v-if="i_customer"
              :i_customer="i_customer"
              :loading="isBusy"
              :filter="{
                limit: 10,
                page: 1,
              }"
              @selected="selectRingGroup"
              show-filters
              select
              small
          />
        </v-window-item>
        <v-window-item :value="2">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="subtitle-1 text--primary">
                Call Queue Settings
              </p>
              <p>Adjust settings for the new Call Queue.</p>
            </v-col>
          </v-row>
          <CallQueueSettingsForm
              v-model="form.callQueueSettings"
          />

        </v-window-item>
        <v-window-item :value="3">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="subtitle-1 text--primary">
                Summary
              </p>
              <p>Please review the setting and confirm your submission.</p>
            </v-col>
          </v-row>
          <div class="pa-4 mb-2 v-sheet theme--light rounded background_accent"  v-if="this.step == 3 && selectedRingGroup && form.callQueueSettings">
            <h3 class="text--primary">Summary</h3>
            <p class="mb-0">Ring Group Number: <strong>{{selectedRingGroup.id}}</strong></p>
            <p class="mb-0">Announce the number of callers ahead of them in the queue: <strong>{{(form.callQueueSettings.announce_number_of_callers ? 'Yes' : 'No')}}</strong></p>
            <p class="mb-0">Announce estimated wait time: <strong>{{(form.callQueueSettings.announce_estimated_wait_time == 'Y' ? 'Yes' : 'No')}}</strong></p>
            <p class="mb-0" v-if="form.callQueueSettings.announce_estimated_wait_time == 'Y'">Average handle time: <strong>{{form.callQueueSettings.average_duration}} minutes</strong></p>
            <p class="mb-0" v-if="form.callQueueSettings.announce_estimated_wait_time == 'Y' || form.callQueueSettings.announce_number_of_callers == 'Y'">Interval between announcements: <strong>{{form.callQueueSettings.announcement_interval}} minutes</strong></p>
            <p class="mb-0" v-if="form.callQueueSettings.play_music_on_hold == 'Y'">Music on Hold file: <strong>{{form.callQueueSettings.music_on_hold_file.name}}</strong></p>
          </div>
          <div class="pa-4 mb-2 v-sheet theme--light rounded background_accent"  v-if="this.step == 3 && selectedRingGroup && form.callQueueSettings">
            <CallQueueSubscriptionSummary />
          </div>
          <CallQueueSubscriptionCard v-if="false"/>
        </v-window-item>
        <v-window-item :value="4">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6" v-if="this.createdCallQueue != null">Call Queue created successfully</p>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import ComplexDialog from "../templates/ComplexDialog";
import RingGroupExtensionSelectListCard from "../../cards/RingGroup/RingGroupExtensionSelectListCard";
import RingGroupExtensionForm from "../../pieces/RingGroup/RingGroupExtensionForm";
import CallQueueRingGroupSelectListCard from "../../cards/CallQueues/CallQueueRingGroupSelectListCard";
import CallQueueSettingsForm from "../../pieces/CallQueues/CallQueueSettingsForm";
import CallQueueSubscriptionCard from "../../cards/CallQueues/CallQueueSubscriptionCard";
import CallQueueSubscriptionSummary from "../../pieces/CallQueues/CallQueueSubscriptionSummary";
export default {
  name: 'CreateCallQueueDialog',
  components: {
    CallQueueSubscriptionSummary,
    CallQueueSubscriptionCard, CallQueueRingGroupSelectListCard, ComplexDialog,CallQueueSettingsForm},
  mixins: [apiMixin, dialogMixin,ringGroupDataMixin],
  data: () => ({
    step: 1,
    busy: false,
    selectedRingGroup:null,
    form: {
      i_c_group: null,
    },
    callQueueSettings: {},
    createdCallQueue: null,

    rules: {
      mac: [
        (v) =>
            !v || v.length == 12 ||
            'Mac Address must have 12 characters.',
      ],
      label: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length < 21) || 'Label cannot be more than 20 characters.'
      ],
      sdID: [
        (v) => !!v || 'Please select',
      ],
    }
  }),
  created: function(){
    this.reset( );
  },
  watch: {
    visible(value) {
      if (value) {
        //this.i_account = null;
      } else {
        this.reset( );
      }
    },
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy || this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.form.i_c_group = null;
      this.selectedRingGroup = null;
      this.createdCallQueue = null;
    },

    selectRingGroup(data){
      this.form.i_c_group = data.i_c_group;
      this.selectedRingGroup = data;
      this.next( );
    },

    async next() {
      if (this.step===3) {
        this.busy = true;
        const params = new FormData();
        if(this.form.callQueueSettings.announce_number_of_callers != 'Y'){
          params.announce_number_of_callers = 'N';
          params.append("announce_number_of_callers", 'N');
        }else{
          params.append("announce_number_of_callers", 'Y');
        }

        if(this.form.callQueueSettings.announce_estimated_wait_time != 'Y'){
          params.append("announce_estimated_wait_time", 'N');
        }else{
          params.append("announce_estimated_wait_time", 'Y');
        }

        params.append("i_c_group", this.form.i_c_group);
        params.append("i_customer", this.i_customer);

        if(this.form.callQueueSettings.average_duration){
          params.append("average_duration", this.form.callQueueSettings.average_duration);
        }
        if(this.form.callQueueSettings.announcement_interval){
          params.append("announcement_interval", this.form.callQueueSettings.announcement_interval);
        }

        if(this.form.callQueueSettings.play_music_on_hold == 'Y'){
          params.append("play_music_on_hold", 'Y');
          params.append("music_on_hold_file", this.form.callQueueSettings.music_on_hold_file);
        }else{
          params.append("play_music_on_hold", 'N');
        }

        let config = {
          "timeout": 300000,
        }

        this.Api.setHttpObject(config);
        let response = await this.Api.send('post', 'call-queues', params);
        if (response.success) {
          this.createdCallQueue = response.data;
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.step++;
        }else if(response.error && response.error == 'Timeout'){
          this.Api.displayError(response.error + ': '+response.errorDetail);
        }
        this.busy = false;
      } else {
        this.step++;
      }
    },
    back() {
      this.step--;
    },
    finish() {
      this.visible = false;
      this.$router.push({"name":"CallQueueSummary","params":{"customerId":this.createdCallQueue.i_customer, "callQueueId":this.createdCallQueue.i_c_queue}});
    },
  },
  props: {
    i_customer: {
      type: Number,
      default: null,
    },
  },

};
</script>
<template>
    <v-row dense v-if="trunkData">
        <v-col cols="12">
          <RingGroupExtensionListCard
              v-if="icgroup"
              :trunkData="trunkData"
              :loading="isBusy"
              @update="updateFilter"
              :filter="filter"
              :customerData="customerData"
              show-filters
          />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Assign Extension" icon="mdi-plus" @click="showNewDevice=true" :loading="isBusy" />
      <LinkRingGroupExtensionDialog v-if="icgroup" v-model="showNewDevice" :trunk-data="trunkData"/>
    </v-row>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import trunkDataMixin from "../../mixins/trunkDataMixin";
import FloatingButton from '@/components/pieces/FloatingButton';
import NewVoipDeviceDialog from "../../components/dialogs/Extension/NewVoipDeviceDialog";
import RingGroupExtensionListCard from "../../components/cards/RingGroup/RingGroupExtensionListCard";
import LinkRingGroupExtensionDialog from "../../components/dialogs/RingGroups/LinkRingGroupExtensionDialog";
export default {
  name: 'RingGroupExtensionList',
  mixins: [filterMixin, customerDataMixin, trunkDataMixin],
  components: {
    LinkRingGroupExtensionDialog,
    RingGroupExtensionListCard,
    FloatingButton,
  },
  data: () => ({
    filter: {
      limit: 10,
      page: 1,
      id: null,
      active: 'Y'
    },
    showNewDevice: false,
    extensionData: null,
  }),
  methods: {
  },
  computed: {
    customerTypeTag: function () {
      if(this.customerData != undefined){
        return this.customerData.customer_class_tag;
      }
      return undefined;
    },
    isBusy: function( ){
      return this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },
    icgroup: function( ){
      if(this.trunkData.ringGroupDetails != undefined){
        return this.trunkData.ringGroupDetails.i_c_group;
      }else{
        return null
      }
    }
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },

};
</script>

<template>
  <ComplexDialog
      v-model="visible"
      title="Update Display name"
      :loading="busy"
      :maxWidth="600"
  >
    <v-alert type="info" outlined dense>
      To edit any other details please contact admin.
    </v-alert>
    <v-alert v-if="showError" type="error" outlined dense>
      {{ this.errorMessage }}
    </v-alert>
    <CustomerIDField v-model="idField" v-on:input="setName" :currentValue="(customerData && customerData.name ? customerData.name : null)"/>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible = false" text>Cancel</v-btn>
      <v-btn @click="changeId( )" :disabled="!idField" text color="primary">Change</v-btn>

    </template>
  </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ComplexDialog from "../templates/ComplexDialog";
import CustomerIDField from "../../pieces/Forms/CustomerIDField";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
  name: 'EditContactDetailsDialog',
  components: { ComplexDialog,CustomerIDField},
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  data: () => ({
    busy: false,
    idField: '',
    showError: false,
    errorMessage:'',
  }),
  props: {
    customerData: {
      type: Object,
      default: null
    },
  },
  methods: {
    /**
     * Takes the emitted value and assigns it to updateName
     * @param event
     *
     * Note. As I understand the $emit('input',value) process from the CustomerIDField component should populate the
     * idField value with the new inputted value.
     * However for some reason this is not the case.
     * Outputting console.log(idField) in this method will output the expected results however the UI is not updated
     * with this value. This causes issues enabling the update buttons.
     * The idField value outputted in the UI via {{idField}} is not updated.
     *
     * This method is a work around created to solve this issue. I was not able to find a solution to this issue.
     */
    setName(event) {
      this.updateName = event;
    },
    async changeId() {
      this.busy = true;
      this.showError = false;

      if(this.updateName != null && this.customerData.name != this.updateName){
        const responseUpdateId = await this.Api.send('put', 'customers/' + this.customerData.i_customer + '/updateId', {
          "fieldId": this.updateName
        }, false);
        if (responseUpdateId.success) {
          this.idField = '';
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
          this.showGlobalSuccessMessage('Name updated successfully');
        }else{
          this.showGlobalErrorMessage('Failed to update Name.');
        }
      }else{
        this.errorMessage = 'Display Name required or to be changed.';
        this.busy = false;
        this.showError = true;
        return;
      }
      this.busy = false;
    },
  },
  beforeUpdate( ){
    if(this.customerData != null){
      this.idField = this.customerData.name;
    }
  }
};
</script>
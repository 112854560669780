<template>
    <BasicCard
        :loading="loading || isLoading"
        :no-skeleton="!firstLoad"
        title="Plan Inclusions"
    >
        <v-row no-gutters>
            <v-col
                cols="12"
                v-for="item in data"
                v-bind:key="item.vdp_name + item.i_vd_dg"
            >
                <v-sheet rounded class="pa-4 mb-2" color="background_accent">
                    <v-chip class="float-right" label small>{{item.vdp_name}}</v-chip>
                    <h3>Calls to {{item.dg_name}}</h3>
                    <h4></h4>
                    <p class="mb-0">
                        {{item.combined_discount}}% discount -
                        <span v-if="!!item.allocated_amount"> {{item.allocated_amount | thousandSeparator}} </span>
                        <span v-else> unlimited </span>
                        {{item.unit}}s
                    </p>
                    <p class="mb-0">
                        {{item.used | thousandSeparator}} {{item.unit}}s used
                    </p>
                    <v-progress-linear
                        v-if="!!item.allocated_amount"
                        :value="(item.used/item.allocated_amount)*100"
                        height="30"
                        class="mt-2"
                        rounded
                    >
                        <span v-if="!item.used">0</span>
                        <span v-else-if="item.used<item.allocated_amount">{{ ((item.used/item.allocated_amount)*100).toFixed(1)>0 ? ((item.used/item.allocated_amount)*100).toFixed(1) : 0}}</span>
                        <span v-else>100</span>
                        % used
                    </v-progress-linear>
                </v-sheet>
            </v-col>
            <v-col
                cols="12"
                v-if="!data.length"
            >
                The selected plan has no inclusions.
            </v-col>
        </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
export default {
    name: 'TrunkDiscountsCard',
    mixins: [apiMixin, refreshListenerMixin],
    components: { BasicCard },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        trunkId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            firstLoad: true,
            data: []
        };
    },
    watch: {
        loading(value) {
            if (!value) this.update();
        }
    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    methods: {
        async update() {
            this.isLoading = true;
            const response = await this.Api.send('post','trunks/' + this.trunkId + '/discounts');
            this.isLoading = false;
            this.firstLoad = false;
            if (response.success) {
                this.data = response.data;
            } else {
                this.data = [];
            }
        },
    },
    created() {
        if (!this.loading) this.update();
    },
};
</script>

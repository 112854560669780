<template>
    <Toolbar
        :title="title"
        :label="label"
        :label-color="labelColor"
        :bread-crumbs="breadCrumbs"
        refresh-button
        @refresh="refresh"
    >

      <template v-if="showExtension" v-slot:extension>
        <v-tabs
            v-if="extension == 'trunk'"
            :fixed-tabs="trunkData.bill_status != 'C' ? true : false"
            show-arrows
            center-active
        >
          <v-tab :to="{ name: 'TrunkSummary' }" exact :style="trunkData.bill_status != 'C' ? '' : 'min-width:300px'">Summary</v-tab>
          <v-tab v-if="trunkData.bill_status != 'C'" :to="{ name: 'TrunkDIDs' }" exact>DIDs</v-tab>
          <v-tab  v-if="trunkData.bill_status != 'C'" :to="{ name: 'TrunkBilling' }" exact>Billing</v-tab>
          <v-tab  v-if="trunkData.bill_status != 'C' && showInbound" :to="{ name: 'TrunkInboundCallSettings' }" exact>Inbound settings</v-tab>
          <v-tab  v-if="trunkData.bill_status != 'C' && showOutbound" :to="{ name: 'TrunkOutboundCallSettings' }" exact>Outbound settings</v-tab>
          <v-tab :to="{ name: 'TrunkCallLogs' }" exact :style="trunkData.bill_status != 'C' ? '' : 'min-width:300px'">Call Logs</v-tab>
          <v-tab v-if="trunkData.bill_status != 'C' && trunkData.account_type == 'HOSTED-EXTENSION'" :to="{ name: 'ExtensionVoipDeviceList' }" exact>Devices</v-tab>
          <v-tab v-if="trunkData.bill_status != 'C' && trunkData.account_type == 'HOSTED-EXTENSION' && trunkData.extensionDetails.total_connected_ring_groups > 0" :to="{ name: 'ExtensionRingGroupList' }" exact>Ring Groups</v-tab>
          <v-tab v-if="trunkData.bill_status != 'C' && trunkData.account_type == 'RING-GROUP'" :to="{ name: 'RingGroupExtensionList' }" exact>Extensions</v-tab>
          <v-tab v-if="trunkData.bill_status != 'C' && trunkData.account_type == 'AUTO-ATTENDANT'" :to="{ name: 'AutoAttendantAccountAutoAttendantList' }" exact>Auto Attendants</v-tab>
        </v-tabs>
        <v-tabs
            v-if="extension == 'billing'"
            fixed-tabs
            show-arrows
            center-active
        >
          <v-tab :to="{ name: 'CustomerBillingSummary' }" exact>Summary</v-tab>
          <v-tab :to="{ name: 'CustomerBillingInvoices' }" exact>
            Invoices
            <v-chip v-if="customerData.unpaid_invoice_info && customerData.unpaid_invoice_info.invoices && customerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length" class="ml-2" small pill style="cursor: pointer;" color="error">{{customerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length}}</v-chip>
            <v-chip v-else-if="customerData.unpaid_invoice_info && customerData.unpaid_invoice_info.total>0" small pill class="ml-2" style="cursor: pointer;" color="info">{{customerData.unpaid_invoice_info.total}}</v-chip>
          </v-tab>
          <v-tab :to="{ name: 'CustomerBillingTransactions' }" exact>Transactions</v-tab>
          <v-tab disabled exact>Credit Card Processing</v-tab>
        </v-tabs>
      </template>
      <v-btn icon @click="emitSendCustomerSMS" v-if="$store.state.user.user_type === 'admin'">
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </Toolbar>
</template>

<script>
import Toolbar from '../templates/Toolbar';
import customerDataMixin from '../../../mixins/customerDataMixin';
export default {
    name: 'CustomerToolbar',
    mixins: [customerDataMixin],
    components: { Toolbar},
    data: () => ({
    }),
    methods: {
      refresh(){

      },
      async emitSendCustomerSMS(){
        document.dispatchEvent(new CustomEvent('sendCustomerSMS'));
      },
    },
    props: {
      /*
        This values is used to determine where and how the tool bar is being used.
        Because of the logic used to layout the UI the toolbar is not manipulatable by the children.
        So we use this value to help determine what sub menu to output and to help determine any bread crumbs.
        Note that we would need to use global storage to pass anything to the toolbar from child pages if any information was needed.
        EG a device ID might be needed for the bread crumb route link pointing to the device summary page.
       */
      extension: {
        type: String,
        default: null,
      }
    },
    watch:{
      //clear previous addon breadcrumbs. Important is we are navigating to a page that does not set any addon breadcrumbs.
      $route (to, from){
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: null});
      }
    },
    computed: {
        showExtension( ){
          if(this.isloading){
            return false;
          }else if(this.extension == null){
            return false;
          }else if(this.extension == 'trunk'
              || this.extension == 'billing'){
            return true;
          }else{
            return false;
          }
        },
      showOutbound( ) {
          if(this.trunkData.account_type == 'FAX-TO-MAIL'){
            return false;
          }else if(this.trunkData.account_type == 'AUTO-ATTENDANT'){
            return false;
          }
          return true;
      },
      showInbound( ) {
        if(this.trunkData.account_type == 'FAX-TO-MAIL' || this.trunkData.account_type == 'AUTO-ATTENDANT'){
          return false;
        }
        return true;
      },
        trunkView() {
            //return this.$route.matched.some((m) => m.name === 'CustomerTrunk');
          return this.extension == 'trunk';
        },
        billingView() {
            return this.$route.matched.some((m) => m.name === 'CustomerBilling');
        },
        trunkData() {
            if (
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account ===
                    parseInt(this.$route.params.trunkId)
            ) {
                return this.$store.state.data.trunk;
            }
            return {};
        },
      /*
      Note that if we are using the CustomerParentPage and the TrunkParentPage then the corrsponding records will be loaded
       */
        breadCrumbs( ) {
          let ret = [];
          if(this.customerData.name){
            ret.push({
              name:'CustomerSummary',
              label:this.customerData.name,
              params:{customerId:this.customerData.i_customer}
            })
          }

          let fromTrunks = false;
          if(this.extension == 'trunk' || this.extension == 'device' || this.extension == 'auto-attendant'){
            fromTrunks = true;
          }

          let addons = this.breadCrumbAddons;

          /**
           * Note that there is a little hack here.
           * Because the devices are not part of the parent child relation between customer and trunks.
           * It was possible the device data would load before the customer and trunk.
           * This would cause the breadcrumb for the device to show before the customer or trunk breadcrumb.
           * So we do a quick here for fromTrunks to help fix this issue.
           * IE when fromTrunk is positive we only load in addon breadcrumb after the trunk breadcrumb is added
           */

          if(fromTrunks) {

            if (this.trunkData.id && this.customerData.name) {
              ret.push({
                name: 'TrunkSummary',
                label: this.trunkData.account_type_label + '(' + this.trunkData.id + ')',
                params: {
                  customerId: this.customerData.i_customer,
                  trunkId: this.trunkData.i_account
                }
              })

              if(addons != null){
                for(let i=0;i<addons.length;i++){
                  ret.push(addons[i]);
                }
              }
            }
          }else {

            /**
             * check the current route and add it on.
             * The work for level 1 deep from the customer
             */
            if(this.$route.name != 'CustomerSummary'){
              ret.push({
                name:this.$route.name,
                label:this.$route.meta.title,
                params:this.$route.params
              })
            }
            if (addons != null) {
              for (let i = 0; i < addons.length; i++) {
                ret.push(addons[i]);
              }
            }
          }

          return ret;
        },
      breadCrumbAddons( ){
          return this.$store.state.data['breadCrumbsAddon'];
      },
      isloading( ){
        let loading = false;
        if(!this.customerData.name){
          loading = true;
        }else if(this.trunkView && !this.trunkData.id){
          loading = true;
        }else if(this.fetchStoreLoading){
          loading = true;
        }
        return loading;
      },
        title() {
          return (!this.isloading ? this.$route.meta.title : 'Loading');

         /* if(this.breadCrumbs.length == 0){
            return (this.customerData.name || 'Loading') +
                (this.trunkView ? ' - '+this.trunkData.account_type_label+' ' + (this.trunkData.id ? '('+this.trunkData.id+')' : 'Loading') : '') +
                (' - ' + this.$route.meta.title || '');
          }else{
            let loading = false;
            if(!this.customerData.name){
              loading = true;
            }else if(this.trunkView && !this.trunkData.id){
              loading = false;
            }
            return (!loading ? this.$route.meta.title : 'Loading');
          }*/
        },
        label() {
            switch (this.customerData.status) {
                case 'blocked':
                    return 'Blocked';
                case 'suspended':
                    return 'Suspended';
                case 'credit_exceeded':
                    return 'Credit exceeded';
                case 'closed':
                    return 'Closed';
                default:
                    return null;
            }
        },
        labelColor() {
            switch (this.customerData.status) {
                case 'blocked':
                    return 'error';
                case 'suspended':
                case 'credit_exceeded':
                    return 'warning';
                default:
                    return null;
            }
        },
    },
};
</script>

<template>
    <BasicCard title="Global Caller ID Settings" :loading="loading" :no-skeleton="false">
        <v-row dense class="text--primary">
            <v-col cols="7"> Overwrite Outbound CLI </v-col>
            <v-col cols="5">
                {{ cli ? cli.effective_flag_value : 'Loading...' }}
            </v-col>
            <v-col cols="7"> Outbound CLI </v-col>
            <v-col cols="5">
                {{
                    cli && cli.attributes
                        ? cli.attributes.find(
                              (e) => e.name === 'centrex'
                          ).effective_values[0]
                            ? cli.attributes.find(
                                  (e) => e.name === 'centrex'
                              ).effective_values[0]
                            : 'N/A'
                        : 'Loading...'
                }}
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip text="Edit" icon="mdi-pencil" flat @click="showDialog=true" />
        </template>
      <CustomerCallerIDSettingsDialog v-model="showDialog" :customerData="customerData" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CustomerCallerIDSettingsDialog from "../../dialogs/Customer/CustomerCallerIDSettingsDialog";
export default {
    name: 'CustomerDefaultCallerIDCard',
    components: { CustomerCallerIDSettingsDialog, ButtonWithTooltip, BasicCard },
    data: () => ({
      showDialog: false,
    }),
    computed: {
        clir() {
            return this.customerData && this.customerData.service_features
                ? this.customerData.service_features.find((e) => e.name === 'clir')
                : null;
        },
        cli() {
            return this.customerData && this.customerData.service_features
                ? this.customerData.service_features.find((e) => e.name === 'cli')
                : null;
        },
    },
    props: {
        customerData: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
};
</script>
<template>
  <div>
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Action</span>
      </v-col>
      <v-col cols="7">
        {{actionValueToDisplay(aatData.action)}}
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'Directory'">
      <v-col cols="5">
        <span class="font-weight-bold">Announce extension number</span>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="aatData.announce_ext_numbers == 'Y'"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'Transfer'">
      <v-col cols="5">
        <span class="font-weight-bold">Transfer caller to phone Number/extension</span>
      </v-col>
      <v-col cols="7">
        {{aatData.destination}}
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'TransferE164'">
      <v-col cols="5">
        <span class="font-weight-bold">Transfer caller to phone Number/extension</span>
      </v-col>
      <v-col cols="7">
        {{aatData.destination}}
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'Extension'">
      <v-col cols="5">
        <span class="font-weight-bold">Max digits for Extension number</span>
      </v-col>
      <v-col cols="7">
        {{aatData.max_size}}
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'Menu'">
      <v-col cols="5">
        <span class="font-weight-bold">Auto Attendant</span>
      </v-col>
      <v-col cols="7">
        {{aatData.targetAutoAttendant.name}}
      </v-col>
    </v-row>

    <v-row dense v-if="aatData.action == 'Queue'">
      <v-col cols="5">
        <span class="font-weight-bold">Call Queue</span>
      </v-col>
      <v-col cols="7">
        {{aatData.targetCallQueue.ring_group_name}}
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Play Prompt</span>
      </v-col>
      <v-col cols="7">
        <span v-if="aatData.action == 'Disabled'">N/A</span>

        <v-icon
            v-if="aatData.action != 'Disabled' && aatData.play_prompt == 'Y'"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else-if="aatData.action != 'Disabled'"
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>

        <v-tooltip bottom v-if="aatData && this.currentPromptFile && this.currentPromptFile.status == 'IN_PROGRESS'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon
                  class="mr-2"
              >mdi mdi-loading mdi-spin</v-icon>
            </v-btn>
          </template>
          <span>Pending codec conversion...</span>
        </v-tooltip>

        <v-tooltip bottom v-else-if="(aatData && aatData.prompt_set) || (currentPromptFile && currentPromptFile.status == 'FINISHED')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadFile()"
            >
              <v-icon v-html="'mdi-download'" />
            </v-btn>
          </template>
          <span>Download file</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row dense v-if="aatData && this.currentPromptFile && this.currentPromptFile.status == 'ERROR'">
      <v-col cols="12">
        <span class="font-weight-bold">Last Upload Error</span>
      </v-col>
      <v-col cols="12">
        {{this.currentPromptFile.error_message}} <v-btn
          @click="clearErrorMessage()"
          x-small
          v-if="!clearErrorLoading"
      >
        clear
      </v-btn>
        <v-icon
            class="mr-2"
            v-if="clearErrorLoading"
        >mdi mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
export default {
    name: 'AutoAttendantActionFormDisplay',
    components: {},
    mixins: [apiMixin,AutoAttendantMixin],
    data: () => ({
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      pollStatusInterval: null,
      currentPromptFile: null,
      clearErrorLoading: false,
    }),
    computed: {
      targetIMenu( ){
        if(!this.aatData){
          return null;
        }else{
          return this.aatData.target_i_menu;
        }
      },
      
    },
    watch: {
      targetIMenu(value){
        if(value){
          //load in imenu
          this.getTargetAutoAttendant( );
        }
      }
    },
    methods: {
      downloadFile( )
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+this.aatData.i_menu_transition+'/prompt-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },

      async refreshTransitionPrompt( ){
        let response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+this.aatData.i_menu_transition+'/prompts/'+this.aatData.codec_converter_file_last.id);
        if(response.success){
          this.currentPromptFile = response.data;
          if(this.currentPromptFile.status != 'IN_PROGRESS'){
            //remove the requesting.
            clearInterval(this.pollStatusInterval);
          }
        }
      },

      async clearErrorMessage( ){
        if(!this.currentPromptFile || !this.currentPromptFile.status == 'ERROR'){
          return; //no valid to delete
        }
        this.clearErrorLoading = true;
        let response = await this.Api.send('put','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+this.aatData.i_menu_transition+'/clear-conversion-errors');
        if(response.success){
          this.currentPromptFile = null;
        }
        this.clearErrorLoading = false;
      },
    },
    created( ) {
      if (this.aatData) {
        if(this.aatData.codec_converter_file_last && this.aatData.codec_converter_file_last.id){
          this.currentPromptFile = this.aatData.codec_converter_file_last;
        }

        if(this.aatData.codec_converter_file_last && this.aatData.codec_converter_file_last.id && this.aatData.codec_converter_file_last.status == 'IN_PROGRESS'){
          this.pollStatusInterval= setInterval(() => {
            this.refreshTransitionPrompt( );
          }, 3000);
        }
      }
    },

    props: {
      trunkData: {
        type: Object,
        required:true,
      },
      aaData:{
        type: Object,
        default: function (){return {}}
      },
      aatData:{
        type: Object,
        default: function (){return {}}
      },
    }
};
</script>
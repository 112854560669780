<template>
    <ComplexDialog
        title="Service Policy Settings"
        v-model="visible"
        :loading="busy"
        :maxWidth="300"
    >
      <SwitchYesNoInput v-model="form.enable_rInstance" label="Enable rInstance" class="pb-4" />
      <template v-slot:actions>
            <v-spacer />
            <v-btn
                :loading="busy"
                @click="send"
                text
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import SwitchYesNoInput from "../../pieces/SwitchYesNoInput";
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from "../../../mixins/trunkSettingsDataMixin";
export default {
    name: 'TrunkServicePolicyEditDialog',
    mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
    components: {ComplexDialog, SwitchYesNoInput},
    data: () => ({
        busy: false,
        form: {
          enable_rInstance: 'N',
        },
    }),
  watch: {
    visible(val) {
      if (val) {
        if (this.trunkData) {
          this.form.enable_rInstance = this.rInstance;
        }
      } else {
        this.form.enable_rInstance = 'N';
      }
    },
  },
  computed: {
    rInstance() {
      if(this.trunkData && this.trunkData.service_features){
        let record = this.trunkData.service_features.find((e) => e.name === 'voice_service_policy');
        let id = record.attributes.find((e) => e.name === 'id');
        if(id.values[0] == this.servicePolicyRInstanceId && record.flag_value == 'Y'){
          return 'Y';
        }
      }
      return 'N';
    }
  },
    methods: {
        async send() {
            this.busy = true;
            const response = await this.Api.send('put', 'trunks/' + this.trunkData.i_account + '/service-policies', this.form);
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.visible = false;
            }
            this.busy = false;
        }
    },
    props: {
        trunkData: {
            type: Object,
            default: null
        }
    }
};
</script>
<template>
    <SimpleDialog
        :loading="busy"
        v-model="visible"
        @close="close"
        yes-text="Confirm"
        @click="terminate"
        :yesDisabled="!yesEnabled"
        no-text="Cancel"
        :title="'Reset Provisioning'"
        persistent
        styledHeader
    >
        <p>Reset Provisioning for Device '{{deviceData.label}}?</p>
        <v-alert type="error">This operation will terminate the current connection to the device</v-alert>
        <p class="text--primary">This device '{{deviceData.label}}' will require the provisioning process to be conducted in order for the device to connect to the extension.</p>
        <p class="text--primary">
            To reset the provisioning of this '{{deviceData.label}}', type 'reset' in the box below:
        </p>
        <v-text-field
            v-model="confirmReset"
            autofocus
            outlined
            dense
            :rules="rules"
            :disabled="busy"
        />
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'ResetVoipDialogProvisioningDialog',
    mixins: [apiMixin,dialogMixin],
    components: {SimpleDialog},
    data: () => ({
        busy: false,
        confirmReset: '',
        rules: [(v) => /^reset$/i.test(v) || "Enter 'reset' to confirm"],
        error: null,
        validationMessage:null,
        success: false,
    }),
    props: {
      deviceData: {
        type: Object,
        required:true,
      },
    },
    methods: {
        async terminate() {
            this.busy = true;
            const response = await this.Api.send('put','extensions/'+this.deviceData.porta_extension_i_c_ext+'/voip-devices/'+this.deviceData.id+'/reset-provisioning');
            if (response.success) {
              if(response.data.id == this.deviceData.id){
                this.showGlobalSuccessMessage('Device Re-Provisioned successfully.');
                  this.close( );
                 document.dispatchEvent(new CustomEvent('refreshRequested'));
              }else{
                  this.error = response.data.error;
              }
            } else {
                this.error = 'Failed to Reset Provisioning.';
            }
          this.busy = false;
        },
        close() {
            this.reset( );
            this.$emit('close');
        },
        reset( ){
          this.confirmReset = '';
          this.error = null;
          this.validationMessage = null;
          this.success = false;
        },
    },
  watch: {
    visible(value) {
      if (this.value!==value) this.$emit('input', value);

      //reset on close and open.
      this.reset( );

    },
  },
  computed:{
    yesEnabled:function(){
      return this.confirmReset.toLowerCase() === 'reset' && !this.busy && this.validationMessage == null;
    },
  }
};
</script>

<template>
    <BasicCard title="Web Self-Care" :loading="loading">
        <v-row dense class="body-1 text--primary" v-if="data.login && data.password">
            <v-col cols="4">
                <span class="font-weight-bold">Username</span>
            </v-col>
            <v-col cols="8">
                {{ data.login }}
                <CopyToClipboard :value="data.login" small />
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Password</span>
            </v-col>
            <v-col cols="8">
                <Password :value="data.password" />
            </v-col>
            <v-col cols="12">
                    Web self-care URL:
                    <a href="https://admin.breezeconnect.com.au:8444/" target="_blank">https://admin.breezeconnect.com.au:8444/</a>
            </v-col>
        </v-row>
        <v-row dense class="body-1 text--primary" v-else>
            <v-col cols="12">
                <v-alert type="info">Web self-care is disabled for this user.</v-alert>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <form method="post" action="https://admin.breezeconnect.com.au:8444/"
                  target="_blank">
                <input type="hidden" name="pb_auth_user" :value="data.login">
                <input type="hidden" name="pb_auth_password" :value="data.password">
                <input type="hidden" name="pb_no_pass_change" value="1">
                <v-btn type="submit" :disabled="!data.login || !data.password" text><v-icon v-html="'mdi-key'" left />Login</v-btn>
            </form>
            <ButtonWithTooltip
                disabled
                text="Edit"
                icon="mdi-pencil"
                tooltip="Change login details"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import Password from '../../pieces/Password';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CopyToClipboard from '../../pieces/CopyToClipboard';
export default {
    name: 'CustomerLoginCard',
    components: { CopyToClipboard, ButtonWithTooltip, Password, BasicCard },
    props: ['data', 'loading'],
};
</script>

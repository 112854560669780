<template>
  <ComplexDialog
      title="Edit Device"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
    <v-text-field v-model="form.label" label="Name" outlined clearable counter="20" :rules="[
            (v) =>
                (!v || v.length <= 20)  ||
                'Name must be 20 characters or less',

        ]"/>
    </v-form>
    <v-alert type="info" text class="mt-4 mb-0" v-if="deviceData.first_provisioned_at != null">
      Note in order for any changes to take effect the Device must be reprovisioned.
      <span v-if="deviceData.last_provisioned_at == null">
          This can be done by provisioning the Device.
        </span>
      <span v-else>
          This can be achieved by rebooting the Device.
        </span>
    </v-alert>

    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditVoipDeviceDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      label:null,
    },
    busy: false,
    isValid: false,
  }),
  methods: {
    async update() {
      this.busy = true;
      const response = await this.Api.send('put', 'extensions/' + this.deviceData.porta_extension_i_c_ext + '/voip-devices/'+ this.deviceData.id, this.form);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    deviceData: {
      type: Object,
      default: null
    }
  },
  created(){
    this.form.label = this.deviceData.label;

  }
};
</script>
<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <DIDListCard
                :cust-id="customerData.i_customer"
                :loading="customerLoading"
                :filter="filter"
                @update="updateFilter"
                show-filters
                show-tools
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed' && ($store.state.user.isAdmin || $store.state.user.isPartner)" tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" :loading="customerLoading" />
      <AddDIDDialog v-model="showNewDIDs" :customer-id="customerId" processTypeSwitch="customer" :validOptions="addDidOptions"/>
    </v-row>
</template>
<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from '@/components/dialogs/DID/AddDIDDialog';
export default {
    name: 'CustomerDIDs',
    mixins: [filterMixin, customerDataMixin],
    components: {
      AddDIDDialog,
      FloatingButton,
        DIDListCard,
    },
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        showNewDIDs: false,
        addDidOptions: {"admin":[1,2,3],"partner":[1]}
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    }
};
</script>

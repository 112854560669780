<template>
    <SimpleDialog
        v-model="visible"
        ok-only
        title="Invoice details"
        :max-width="420"
        styledHeader
    >
        <v-row dense>
            <v-col cols="6">
                <span class="font-weight-bold">Invoice number</span>
            </v-col>
            <v-col cols="6" class="text-right">
                {{data.i_invoice}}
            </v-col>
            <v-col cols="6" :class="{ 'error--text': data.invoice_status==='Overdue'}">
                <span class="font-weight-bold">Invoice status</span>
            </v-col>
            <v-col cols="6" class="text-right" :class="{ 'error--text': data.invoice_status==='Overdue'}">
                {{ data.invoice_status || data.invoice_status_desc || 'Unknown' }}
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Issue Date</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <LocalDateTime :value="data.issue_date" hide-tooltip />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Account No</span>
            </v-col>
            <v-col cols="6" class="text-right">
                {{data.i_customer}}
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Customer</span>
            </v-col>
            <v-col cols="6" class="text-right">
                {{data.customer_name}}
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Billing period start</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <LocalDateTime :value="data.period_from" show-time hide-tooltip />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Billing period end</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <LocalDateTime :value="data.period_to" show-time hide-tooltip />
            </v-col>
            <v-col cols="6" v-if="data.pdf_created">
                <span class="font-weight-bold">PDF generated</span>
            </v-col>
            <v-col cols="6" v-if="data.pdf_created" class="text-right">
                <LocalDateTime :value="data.pdf_created" />
            </v-col>
        </v-row>
        <h3 class="pt-4 pb-2 text--primary">Account</h3>
        <v-row dense>
            <v-col cols="8">
                <span class="font-weight-bold">Opening balance</span> <span class="text--disabled">on <LocalDateTime :value="data.period_from" hide-tooltip /></span>
            </v-col>
            <v-col cols="4" class="text-right">
                <Currency :value="data.previous_balance" hide-tooltip />
            </v-col>
            <v-col cols="8">
                <span class="font-weight-bold">Payments/adjustments</span>
            </v-col>
            <v-col cols="4" class="text-right">
                <Currency :value="-data.payments" hide-tooltip />
            </v-col>
            <v-col cols="8">
                <span class="font-weight-bold">Overdue amount</span> <span class="text--disabled">as at <LocalDateTime :value="data.period_to" hide-tooltip /></span>
            </v-col>
            <v-col cols="4" class="text-right">
                <Currency :value="data.previous_balance+data.payments" hide-tooltip />
            </v-col>
        </v-row>
        <h3 class="pt-4 pb-2 text--primary">This bill</h3>
        <v-row dense>
            <v-col cols="6">
                <span class="font-weight-bold">New charges</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <Currency :value="data.amount_net" hide-tooltip />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Payments</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <Currency :value="data.amount_paid" hide-tooltip />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Adjustments</span>
            </v-col>
            <v-col cols="6" class="text-right">
                <Currency :value="data.adjustments" hide-tooltip />
            </v-col>
            <v-col cols="6" class="font-weight-bold" :class="{ 'error--text': data.invoice_status==='Overdue'}">
                <span >Outstanding balance</span>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold" :class="{ 'error--text': data.invoice_status==='Overdue'}">
                <Currency :value="outstanding" hide-tooltip />
            </v-col>
        </v-row>

    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import Currency from '../../pieces/Currency';
import LocalDateTime from '../../pieces/LocalDateTime';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'InvoiceSummaryDialog',
    mixins: [dialogMixin],
    components: { LocalDateTime, Currency, SimpleDialog },
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        outstanding() {
            return parseFloat(this.data.amount_net-this.data.amount_paid).toFixed(2)-parseFloat(this.data.adjustments);
        }
    }
};
</script>
<template>
    <SimpleDialog
      title="Credit Limit Warnings"
      no-text="Cancel"
      yes-text="Save"
      :yesDisabled="!yesEndabled"
      v-model="displayDialog"
      @click="update"
      >
      <p>Set up to three (3) credit limit warning thresholds.</p>
      <p>Example: If the threshold is set at 80%, a warning email will be triggered when the balance reaches 80% of the credit limit.</p>

        <v-form v-model="formValid">
          <v-row dense class="mt-2">
            <v-col cols="4">
              <v-text-field
                append-icon="mdi-percent"
                v-model="warningThreshold1"
                outlined
                :rules="[thresholdRule]"
                :disabled="isBusy"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                append-icon="mdi-percent"
                v-model="warningThreshold2"
                outlined
                :rules="[thresholdRule]"
                :disabled="isBusy"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                append-icon="mdi-percent"
                v-model="warningThreshold3"
                outlined
                :rules="[thresholdRule]"
                :disabled="isBusy"
              />
            </v-col>
          </v-row>
        </v-form>
      <h3 v-if="customerData.bcc && customerData.bcc != null && customerData.bcc != ''">
        Email is sent to: {{customerData.email}}
      </h3>
      <h3 v-else>Emails are sent to: {{customerData.email}} and {{customerData.bcc}}</h3>
      </SimpleDialog>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'CustomerCreditLimitWarningDialog',
    mixins: [apiMixin, GlobalHelperMixin],
    components: {SimpleDialog},
    data() {
        return {
            busy: false,
            displayDialog: false,
            warningThreshold1:null,
            warningThreshold2:null,
            warningThreshold3:null,
            formValid: false,

            // rules: {
            //   percent: [
            //         // (v) => !!v || 'Required',
            //         (v) => (!v ||
            //             /^\d+(?:\.\d{1,2})?$/i.test(v)) ||
            //             'Enter a valid number',
            //         (v) => (!v || (parseInt(v) > 0 && parseInt(v) <= 100)) || 'Invalid percent value.',
            //     ],
            // },
        };
    },
    props: {
        customerData: {
          default: () => [],
        },
    },
    watch: {
        displayDialog: function (val) {
            if(val){
              this.reset( );
            }
        },
    },
    computed: {
      isBusy( ){
        return this.busy || this.customerData == null
      },
      yesEndabled: function( ){
        return !this.busy && this.formValid;
      }
    },
    methods: {
        open( ){
          this.displayDialog = true;
        },
      thresholdRule(value){
        if(value == null || value == ''){
          return true;
        }

        if(!/^\d+(?:\.\d{1,2})?$/i.test(value)){
          return 'Enter a valid number';
        }

        const numValue = Number(value);
        const isWholeNumber = (Math.floor(numValue) === numValue);

        if(!isWholeNumber){
          return 'Must be a whole number.';
        }

        if(parseInt(value) < 1 || parseInt(value) > 100){
          return 'Invalid percent value.';
        }

        //need to be unique between the other thresholds.
        let matchCount = 0;
        if(this.warningThreshold1 == value){
          matchCount++;
        }
        if(this.warningThreshold2 == value){
          matchCount++;
        }
        if(this.warningThreshold3 == value){
          matchCount++;
        }

        if(matchCount > 1){
          /**
           * Note we will match with more than 1 as it the value will always match with itself.
           */
          return 'Thresholds must be unique.'
        }

        return true;
      },
        async update() {

          let params = {
            "credit_warnings":[]
          };
          if(this.warningThreshold1 != null && this.warningThreshold1 != ''){
            params.credit_warnings.push(this.warningThreshold1);
          }
          if(this.warningThreshold2 != null && this.warningThreshold2 != ''){
            params.credit_warnings.push(this.warningThreshold2);
          }
          if(this.warningThreshold3 != null && this.warningThreshold3 != ''){
            params.credit_warnings.push(this.warningThreshold3);
          }
          this.busy = true;
            const response = await this.Api.send('post',
                'customers/' + this.customerData.i_customer + '/update',
              params
            );

          this.busy = false;
            if (response.success) {
              this.showGlobalSuccessMessage('Warning Thresholds updated successfully');
              document.dispatchEvent(new CustomEvent('refreshRequested'));
              this.displayDialog = false;
            }
        },

        reset( ){
          this.warningThreshold1 = null;
          this.warningThreshold2 = null;
          this.warningThreshold3 = null;

          if(this.customerData.credit_limit_warning && this.customerData.credit_limit_warning != null && this.customerData.credit_limit_warning.length >0){
            for(let i = 0; i<this.customerData.credit_limit_warning.length; i++){
              if(i == 0){
                this.warningThreshold1 = this.customerData.credit_limit_warning[i].warning_threshold;
              }else if(i == 1){
                this.warningThreshold2 = this.customerData.credit_limit_warning[i].warning_threshold;
              }else{
                this.warningThreshold3 = this.customerData.credit_limit_warning[i].warning_threshold;
              }
            }
          }
        },
    },
};
</script>

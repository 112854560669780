<template>
  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
            v-model="form.name"
            label="Name"
            :rules="[ruleCheckName]"
            outlined
            hint="Must be unique for this Account."
            maxlength="64"
            counter
            :disabled = "aaData && aaData.name == 'ROOT'"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-radio-group v-model="form.active_toggle" class="mt-0"
                       :rules="[ruleActiveTog]"
        >
          <v-radio
              label="Always active"
              value="always"
              key="0"
          ></v-radio>
          <v-radio
              label="Only at the following time interval(s)"
              value="interval"
              key="1"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card
    outlined
    v-if="form.active_toggle=='interval'"
    class="mt-2"
    ><v-card-text>
    <div>Active Time Period(s)</div>
    </v-card-text>
    <v-row no-gutters>
      <v-col cols="12">
        <AutoAttendantActiveTimeConditionComponent :updateCallback="updateTimeConditions" :workingTimePeriods="form.active_time_list" :trunkData="trunkData" :aaData="aaData" :loading="loading" />
      </v-col>
    </v-row>
  </v-card>

    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
            v-model="form.direct_dial_enabled"
            label="Allow callers to dial a known extension directly:"
            value="Y"
        ></v-checkbox>
      </v-col>
    </v-row>


    <v-row no-gutters v-if="form.direct_dial_enabled=='Y'">
      <v-col cols="12">
        <v-text-field
            v-model="form.next_digit_timeout"
            label="Interdigit timeout when entering an extension"
            :rules="[rules.required, rules.numeric]"
            outlined
            hint="Seconds"
        />
      </v-col>
    </v-row>
    <v-card
        outlined
    >
      <v-card-text>
        <AutoAttendantActionForm :event="'Not Active'" :trunkData="trunkData" :aaData="aaData" :aatData="(aaData && aaData.inactive_auto_account_transition ? aaData.inactive_auto_account_transition : null)" :loading="false" v-model="form.actionValues"/>
      </v-card-text>
    </v-card>

  </v-form>
</template>

<script>
import AutoAttendantActiveTimeConditionComponent from "./AutoAttendantActiveTimeConditionComponent";
import SpinnerOverlay from "../Global/SpinnerOverlay";
import AutoAttendantActionForm from "./AutoAttendantActionForm";
export default {
    name: 'AutoAttendantGeneralSettingsForm',
    components: {AutoAttendantActionForm, SpinnerOverlay, AutoAttendantActiveTimeConditionComponent},
    data: () => ({
      valid: false,
      busy: false,
      form:{
        name:null,
        active_toggle:'always',
        period:null,
        direct_dial_enabled:null,
        next_digit_timeout:3,
        actionValues:{},
      },
      rules: {
        required: v => !!v || 'Required.',
        numeric: v => !isNaN(v) || 'Must be whole number',
        activeTog: v => (v == 'active' || v == 'inactive') || 'Please select.',
      },
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
      isBusy( ){
        return this.loading || this.busy;
      }
    },
    watch: {
      trunkData(value) {
            this.reset();
        },
      aaData(value) {
          this.reset();
      },
      valid: {
        handler(value)
        {
          if(!value){
            this.changeCallBack(null);
          }else{
            //check transition form
            if(!this.form.actionValues){
              this.changeCallBack(null);
            }else{
              this.changeCallBack(this.form);
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      ruleCheckName(nameIn) {
        let name = nameIn;
        /*if(nameIn == null) {
          name = this.form.name;
        }*/
        if (name == null) {
          return 'Required';
        }

        for(let i = 0; i<this.trunkData.auto_attendants.length; i++){
          if(name.trim( ).toLowerCase() == this.trunkData.auto_attendants[i].name.toLowerCase() && (!this.aaData || this.aaData.i_menu != this.trunkData.auto_attendants[i].i_menu)){
            return 'Name is already in use for this account.';
          }
        }
        return true;
      },
      ruleActiveTog(nameIn) {
        let name = nameIn;
        if(nameIn == null) {
          name = this.form.active_toggle;
        }

        if (name == null) {
          return 'Required';
        }

        if(name != 'always' && name != 'interval'){
          return 'Invalid select option.';
        }
        return true;
      },
      reset( ){
        this.form.name=null;
        this.form.active_toggle='always';
        this.form.active_time_list=[];
        this.form.direct_dial_enabled=null;
        this.form.next_digit_timeout=3;
        this.form.actionValues={};

        if(this.aaData){
          this.form.name=this.aaData.name;
          if(this.aaData.period != 'Always'){
            this.form.active_toggle='interval';
            this.convertPeriodString(this.aaData.period, this.aaData.period_desc);
          }
          this.form.direct_dial_enabled=this.aaData.direct_dial_enabled;
          this.form.next_digit_timeout=this.aaData.next_digit_timeout;
        }
      },
      updateTimeConditions(val){
        this.form.period = val;
      },

      convertPeriodString(period, description){
        this.form.active_time_list = [];
        let timePeriods = period.split(', ');
        let timeDescriptions = description.split(' or ');
        if (timePeriods && timeDescriptions && timePeriods.length===timeDescriptions.length) {
          for (let i = 0; i < timePeriods.length; i++) {
            this.form.active_time_list.push({ period: timePeriods[i], description: timeDescriptions[i]});
          }
        }
      }
    },
  created( ){
    this.reset( );
  },
    props: {
      trunkData: {
        type: Object,
        required:true,
      },
      aaData:{
        type: Object,
        default: function (){return {}}
      },
      loading: {
        type:Boolean,
        default:false,
      },
      changeCallBack:{
        type: Function,
        required: true,
      }
    }
};
</script>
<template>
    <ComplexDialog
        title="Edit Dialing Rule"
        v-model="visible"
        :loading="busy"
    >
      <v-form ref="form" v-model="isValid">
        <v-select
            outlined
            label="Dialing Rule *"
            v-model="form.iDialRule"
            :disabled="busy"
            :items="dialRuleSelectOptions"
            item-text="option"
            item-value="value"
            hint=""
            :menu-props="{ top: true, offsetY: true }"
            :rules="rules.required"
        />
      </v-form>
      <v-sheet>
        <v-switch
          v-model="form.translateCliIn"
          inset
          :label="`Apply rule to CLI`"
        ></v-switch>
        <v-switch
          v-model="form.translateCldIn"
          inset
          :label="`Apply rule to CLD`"
        ></v-switch>
      </v-sheet>
      <template v-slot:actions>
        <v-spacer />
        <v-btn
            :loading="busy"
            @click="update"
            text
            :disabled="!isValid"
        >Save</v-btn>
      </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import RadioSelect from '../../pieces/Forms/RadioSelect';
import CallerIDField from '@/components/pieces/CallerIDField';
export default {
    name: 'CustomerDialingRuleConfigDialog',
    mixins: [apiMixin, dialogMixin],
    components: {ComplexDialog},
    data: () => ({
      busy: false,
      isValid:null,
      form: {
        iDialRule:null,
        translateCliIn:null,
        translateCldIn:null,
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
        ]
      },
      dialRuleSelectOptions:[],
    }),
  watch: {
    visible(val) {
      if (val) {
        if (this.customerData) {
          this.form.cli = this.cli;
          //this.form.blocked = this.blocked;
          this.form.override_cli = this.override_cli;
          this.cid_type = this.form.override_cli==='Y' ? 2 : 1;
        }
      } else {
        this.form.cli = null;
        this.form.override_cli = null;
      }
    },
    dialRuleOptions(val){
      this.reset( );
    },
  },
  created() {

  },
  computed: {
    blocked() {
      return null;
      // return this.customerData && this.customerData.service_features
      //     ? this.customerData.service_features.find((e) => e.name === 'clir').attributes.find((e) => e.name === 'blocked').effective_values[0]
      //     : null;
    },
  },
    methods: {
      reset( ){
        let self = this;
        this.form.iDialRule = this.iDialRule;
        this.form.translateCliIn = this.translateCliIn;
        this.form.translateCldIn = this.translateCldIn;
        this.dialRuleOptions.forEach(function (item) {
          self.dialRuleSelectOptions.push({"value":item['i_dial_rule'],"option":item['name']});
        });
      },

      async update() {
        this.busy = true;
        const response = await this.Api.send('put', 'customers/' + this.customerData.i_customer + '/dialing-rule-options', this.form);
        if (response.success) {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
        }
        this.busy = false;
      }
    },
    props: {
      customerData: {
          type: Object,
          default: null
      },
      dialRuleOptions:{
        type: Array,
        required: true
      },
      iDialRule:{
        type: Number,
        required: true
      },
      translateCliIn:{
        type: Boolean,
        required: true
      },
      translateCldIn:{
        type: Boolean,
        required: true
      }
    }
};
</script>
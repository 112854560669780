<template>
    <ComplexDialog
        v-model="visible"
        :title="'View Plan Details - Trunk '+ data.id"
    >
        <TrunkPlanInfoSummary
            :data="data"
        />
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                @click="visible=false"
                text
            >Close</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ComplexDialog from '../templates/ComplexDialog';
    import dialogMixin from '../../../mixins/dialogMixin';
    import TrunkPlanInfoSummary from '../../pieces/Trunk/TrunkPlanInfoSummary';
    export default {
        name: 'ViewTrunkPlanDialog',
        mixins: [dialogMixin],
        components: { TrunkPlanInfoSummary, ComplexDialog },
        props: {
            data: {
                type: Object,
                default: null
            }
        },
    };
</script>

<style scoped>

</style>
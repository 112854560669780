var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicCard',{attrs:{"loading":_vm.loading,"title":"Prompts"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ButtonWithTooltip',{attrs:{"text":"Edit","flat":"","tooltip":"Edit prompt","icon":"mdi-pencil"},on:{"click":function($event){_vm.showEditDialog = true}}})]},proxy:true}])},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"height":"100%"}},[_vm._v("Intro")]),_vm._v(" "),(_vm.aaData.msg_intro_set && parseInt(_vm.aaData.msg_intro_set) == 1)?_c('v-btn',{attrs:{"plain":"","disabled":""}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"7"}},[((_vm.aaData.msg_intro_type && _vm.aaData.msg_intro_type == 'custom'))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-cancel")]),(_vm.currentPromptIntroFile && _vm.currentPromptIntroFile.status == 'IN_PROGRESS')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi mdi-loading mdi-spin")])],1)]}}],null,false,1681114892)},[_c('span',[_vm._v("Pending codec conversion...")])]):((_vm.aaData.msg_intro_set && parseInt(_vm.aaData.msg_intro_set) == 1) || (_vm.currentPromptIntroFile && _vm.currentPromptIntroFile.status == 'FINISHED'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile('intro')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s('mdi-download')}})],1)]}}])},[_c('span',[_vm._v("Download file")])]):_vm._e()],1)],1),(_vm.currentPromptIntroFile && _vm.currentPromptIntroFile.status == 'ERROR')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Last Intro Upload Error")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.currentPromptIntroFile.error_message)+" "),(!_vm.clearIntroErrorLoading)?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.clearErrorMessage('intro')}}},[_vm._v(" clear ")]):_vm._e(),(_vm.clearIntroErrorLoading)?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi mdi-loading mdi-spin")]):_vm._e()],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Menu")]),_vm._v(" "),(_vm.aaData.msg_menu_set && parseInt(_vm.aaData.msg_menu_set) == 1)?_c('v-btn',{attrs:{"plain":"","disabled":""}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"7"}},[((_vm.aaData.msg_menu_type && _vm.aaData.msg_menu_type == 'custom'))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-cancel")]),(this.currentPromptMenuFile && this.currentPromptMenuFile.status == 'IN_PROGRESS')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi mdi-loading mdi-spin")])],1)]}}],null,false,1681114892)},[_c('span',[_vm._v("Pending codec conversion...")])]):((_vm.aaData.msg_menu_set && parseInt(_vm.aaData.msg_menu_set) == 1) || (_vm.currentPromptMenuFile && _vm.currentPromptMenuFile.status == 'FINISHED'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile('menu')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"innerHTML":_vm._s('mdi-download')}})],1)]}}])},[_c('span',[_vm._v("Download file")])]):_vm._e()],1)],1),(_vm.currentPromptMenuFile && _vm.currentPromptMenuFile.status == 'ERROR')?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Last Menu Upload Error")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.currentPromptMenuFile.error_message)+" "),(!_vm.clearMenuErrorLoading)?_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.clearErrorMessage('menu')}}},[_vm._v(" clear ")]):_vm._e(),(_vm.clearMenuErrorLoading)?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi mdi-loading mdi-spin")]):_vm._e()],1)],1):_vm._e(),_c('AutoAttendantEditPromptsDialog',{attrs:{"loading":_vm.isBusy,"trunkData":_vm.trunkData,"aaData":_vm.aaData},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
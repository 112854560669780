<template>
  <ComplexDialog
      title="Edit Ring Group Extension Settings"
      v-model="visible"
      :loading="busy"
  >
    <RingGroupExtensionForm
        v-model="form.extensionSettings"
        :trunkData="trunkData"
        :rgExtData="data"
        :loading="busy"
    />

    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="submit"
          text
          :disabled="form.extensionSettings == null"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import RingGroupExtensionForm from "../../pieces/RingGroup/RingGroupExtensionForm";
export default {
  name: 'UpdateRingGroupSettingsDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin,ringGroupDataMixin],
  components: { ComplexDialog,RingGroupExtensionForm},
  data: () => ({
    valid: false,
    form: {
      extensionSettings: null,
    },
    customerRingGroups: [],
    busy: false,
    rules: {
      required: [
        (v) => !!v || 'Required',
      ]
    }
  }),
  methods: {
    async submit() {
      this.busy = true;
      let params = this.form.extensionSettings;
      params.i_c_ext = this.form.i_c_ext;
      let response = await this.Api.send('put', 'ring-groups/'+this.trunkData.ringGroupDetails.i_c_group+'/extensions/'+this.data.i_cg_ext, params);
      if (response.success) {
        this.createdVoipDevice = response.data.data;
        this.showGlobalSuccessMessage('Ring Group Extensions settings updated successfully.');
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.step++;
      }
      this.busy = false;
    },
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    trunkData: {
      type: Object,
      required:true,
    },
    editingRGExt:{
      type: Object,
      default: null,
    }
  },
  created(){

  },
  watch: {
    visible(value) {
      if (value) {

      }
    },
  },
};
</script>
<template>
    <v-row no-gutters v-if="!!items">
        <v-col
            v-for="item in items"
            :key="item[itemValue]"
            cols="12"
        >
            <v-hover v-slot="{ hover }">
                <v-sheet
                    outlined
                    rounded
                    :elevation="(multiple ? selected.includes(item[itemValue]) : selected===item[itemValue]) ? 4 : hover ? 2 : 0"
                    class="d-flex flex-row align-center"
                    :class="(multiple ? selected.includes(item[itemValue]) : selected===item[itemValue]) ? 'pa-3 ma-1' : 'pa-2 ma-2'"
                    :color="(multiple ? selected.includes(item[itemValue]) : selected===item[itemValue]) ? 'accent' : hover ? 'background_accent' : undefined"
                    @click="toggleItem(item[itemValue])"
                    style="cursor: pointer;"
                    v-ripple
                >
                    <div v-if="icon" style="min-width: 50px;" class="text-center mr-2">
                        <v-icon v-if="item[itemIcon]" v-html="item[itemIcon]" large />
                        <v-icon v-else v-html="(multiple ? selected.includes(item[itemValue]) : selected===item[itemValue]) ? 'mdi-check-circle-outline' : 'mdi-circle-outline'" large />
                    </div>
                    <div class="flex-grow-1 text-truncate">
                        <v-chip v-if="item[itemChip]" small label class="float-right">{{item[itemChip]}}</v-chip>
                        <span v-if="!!item[itemTitle]" class="mb-2">{{item[itemTitle]}}</span>
                        <span v-if="item[itemDescription]" style="font-size: 12px; max-width: 100%;" class="text--secondary">
                            <br/>{{item[itemDescription]}}
                        </span>
                    </div>
                </v-sheet>
            </v-hover>
        </v-col>
    </v-row>
</template>

<script>
import equalsMixin from '../../../mixins/equalsMixin';

export default {
    name: 'LargeSelectButtonList',
    mixins: [equalsMixin],
    data: () => ({
        selected: [],
    }),
    methods: {
        toggleItem(value) {
            if (this.multiple) {
                if (Array.isArray(this.value)) {
                    if (this.selected.includes(value)) {
                        this.selected.splice(this.selected.indexOf(value), 1);
                    } else {
                        this.selected.push(value);
                    }
                } else {
                    this.selected = [ value ];
                }
            } else {
                this.selected = value;
            }
        }
    },
    watch: {
        selected(value) {
            if (!this.equals(this.value, value)) this.$emit('input', value);
        },
        value(value) {
            this.selected = value;
        }
    },
    props: {
        value: {
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemValue: {
            type: String,
            default: 'value'
        },
        itemTitle: {
            type: String,
            default: 'title'
        },
        itemDescription: {
            type: String,
            default: 'description'
        },
        itemChip: {
            type: String,
            default: 'chip'
        },
        itemIcon: {
            type: String,
            default: 'icon'
        },
        icon: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if (this.value) {
            this.selected = this.value;
        }
    }
};
</script>
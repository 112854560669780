<template>
    <v-row dense>
      <v-col cols="12" sm="4" v-if="showCallAnswerMode">
        <TrunkCallAnswerModeCard :data="trunkData" :loading="trunkLoading"/>
      </v-col>
      <v-col cols="12" sm="4" v-if="showFaxToMail">
        <TrunkFax2MailCard :data="trunkData" :loading="trunkLoading"/>
      </v-col>
      <v-col cols="12" sm="4" v-if="showCallForwarding">
          <TrunkCallForwardingCard :data="trunkData" :loading="trunkLoading"/>
      </v-col>
      <v-col cols="12" v-if="showCallScreening">
        <TrunkCallScreeningCard :data="trunkData" :loading="trunkLoading"/>
      </v-col>
      <v-col cols="12" sm="4" v-if="showServicePolicy">
        <TrunkServicePolicy :trunkData="trunkData" :loading="trunkLoading"/>
      </v-col>
    </v-row>
</template>

<script>
import TrunkCallForwardingCard from '../../components/cards/Trunk/TrunkCallForwardingCard';
import trunkDataMixin from '@/mixins/trunkDataMixin';
import TrunkCallAnswerModeCard from "../../components/cards/Trunk/TrunkCallAnswerModeCard";
import TrunkCallScreeningCard from "../../components/cards/Trunk/TrunkCallScreeningCard";
import TrunkFax2MailCard from "../../components/cards/Trunk/TrunkFax2MailCard";
import TrunkServicePolicy from "../../components/cards/Trunk/TrunkServicePolicy";
export default {
    name: 'TrunkCallForwarding',
    components: {
      TrunkServicePolicy,
      TrunkFax2MailCard, TrunkCallScreeningCard, TrunkCallAnswerModeCard, TrunkCallForwardingCard },
    mixins: [trunkDataMixin],
  computed:{
    showCallAnswerMode( ){
      return (this.trunkData.account_type != 'FAX-TO-MAIL' && this.trunkData.account_type != 'AUTO-ATTENDANT')
    },
    showFaxToMail( ){
      return (this.trunkData.account_type == 'FAX-TO-MAIL' || this.trunkData.account_type == 'SIP-TRUNK');
    },
    showCallForwarding( ){
      return this.trunkData.account_type != 'FAX-TO-MAIL' && this.trunkData.account_type != 'AUTO-ATTENDANT';
    },
    showCallScreening( ){
      return this.trunkData.account_type != 'FAX-TO-MAIL'&& this.trunkData.account_type != 'AUTO-ATTENDANT';
    },
    showVoiceMailCard( ){
      return this.trunkData.account_type == 'RING-GROUP';
    },
    showServicePolicy( ){
      return this.trunkData.account_type == 'SIP-TRUNK';
    }
  }
};
</script>

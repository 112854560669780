<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        title="Outstanding Invoices"
    >

        <p class="text--primary text-h6" v-if="!loading && !data.unpaid_invoice_info">
            Unable to get invoice summary for account
        </p>
        <p class="text--primary text-h6" v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices">
            {{data.unpaid_invoice_info.total}} unpaid invoice<span v-if="data.unpaid_invoice_info.total!==1">s</span>
        </p>
        <div v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.total>0">
            <v-sheet
                 v-for="invoice in data.unpaid_invoice_info.invoices"
                 :key="invoice.i_invoice"
                 v-bind:class="{
                    'red darken-4':
                        $vuetify.theme.dark && invoice.invoice_status_desc==='Overdue',
                    'red lighten-4':
                        !$vuetify.theme.dark && invoice.invoice_status_desc==='Overdue',
                 }"
                 :color="invoice.invoice_status_desc!=='Overdue' ? 'background_accent' : null"
                 rounded
                 class="py-2 px-3 mb-1"
            >
                <div class="d-flex">
                    <div class="font-weight-bold mr-auto">
                        Invoice {{invoice.i_invoice}}
                    </div>
                    <div><InvoiceStatus :data="invoice" label /></div>
                </div>
                <div class="d-flex align-end">
                    <div class="mr-auto">
                        <span class="subtitle-1">
                            <Currency :value="parseFloat(invoice.amount_net)" />
                        </span>
                    </div>
                    <div>
                        Due <LocalDateTime :value="invoice.due_date" relative hide-tooltip />
                    </div>
                </div>
            </v-sheet>
        </div>
        <template v-slot:actions>
            <ButtonWithTooltip
                id="viewAllButton"
                text="View All"
                icon="mdi-eye"
                flat
                tooltip="View all invoices"
                :to="{name: 'CustomerBillingInvoices'}"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import Currency from '../../pieces/Currency';
import InvoiceStatus from '../../pieces/Invoice/InvoiceStatus';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'CustomerOutstandingInvoiceCard',
    components: { ButtonWithTooltip, LocalDateTime, InvoiceStatus, Currency, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
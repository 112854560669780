<template>
  <BasicCard
      :loading="loading"
      title="Prompts"
  >
    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold" style="height:100%">Intro</span> <v-btn v-if="aaData.msg_intro_set && parseInt(aaData.msg_intro_set) == 1"
          plain
          disabled
      ></v-btn>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="(aaData.msg_intro_type && aaData.msg_intro_type == 'custom')"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>

        <v-tooltip bottom v-if="currentPromptIntroFile && currentPromptIntroFile.status == 'IN_PROGRESS'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon
                  class="mr-2"
              >mdi mdi-loading mdi-spin</v-icon>
            </v-btn>
          </template>
          <span>Pending codec conversion...</span>
        </v-tooltip>
        <v-tooltip bottom v-else-if="(aaData.msg_intro_set && parseInt(aaData.msg_intro_set) == 1) || (currentPromptIntroFile && currentPromptIntroFile.status == 'FINISHED')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadFile('intro')"
            >
              <v-icon v-html="'mdi-download'" />
            </v-btn>
          </template>
          <span>Download file</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense v-if="currentPromptIntroFile && currentPromptIntroFile.status == 'ERROR'">
      <v-col cols="12">
        <span class="font-weight-bold">Last Intro Upload Error</span>
      </v-col>
      <v-col cols="12">
        {{currentPromptIntroFile.error_message}} <v-btn
          @click="clearErrorMessage('intro')"
          x-small
          v-if="!clearIntroErrorLoading"
      >
        clear
      </v-btn>
        <v-icon
            class="mr-2"
            v-if="clearIntroErrorLoading"
        >mdi mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>


    <v-row dense>
      <v-col cols="5">
        <span class="font-weight-bold">Menu</span> <v-btn v-if="aaData.msg_menu_set && parseInt(aaData.msg_menu_set) == 1"
          plain
          disabled
      ></v-btn>
      </v-col>
      <v-col cols="7">
        <v-icon
            v-if="(aaData.msg_menu_type && aaData.msg_menu_type == 'custom')"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>

        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-cancel</v-icon>
        <v-tooltip bottom v-if="this.currentPromptMenuFile && this.currentPromptMenuFile.status == 'IN_PROGRESS'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon
                  class="mr-2"
              >mdi mdi-loading mdi-spin</v-icon>
            </v-btn>
          </template>
          <span>Pending codec conversion...</span>
        </v-tooltip>
        <v-tooltip bottom v-else-if="(aaData.msg_menu_set && parseInt(aaData.msg_menu_set) == 1) || (currentPromptMenuFile && currentPromptMenuFile.status == 'FINISHED')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="downloadFile('menu')"
            >
              <v-icon v-html="'mdi-download'" />
            </v-btn>
          </template>
          <span>Download file</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row dense v-if="currentPromptMenuFile && currentPromptMenuFile.status == 'ERROR'">
      <v-col cols="12">
        <span class="font-weight-bold">Last Menu Upload Error</span>
      </v-col>
      <v-col cols="12">
        {{currentPromptMenuFile.error_message}} <v-btn
          @click="clearErrorMessage('menu')"
          x-small
          v-if="!clearMenuErrorLoading"
      >
        clear
      </v-btn>
        <v-icon
            class="mr-2"
            v-if="clearMenuErrorLoading"
        >mdi mdi-loading mdi-spin</v-icon>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <ButtonWithTooltip
          text="Edit"
          flat
          tooltip="Edit prompt"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
    </template>
    <AutoAttendantEditPromptsDialog :loading="isBusy" :trunkData="trunkData" :aaData="aaData" v-model="showEditDialog"
    />
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import AutoAttendantEditGeneralSettingsDialog
  from "../../dialogs/AutoAttendants/AutoAttendantEditGeneralSettingsDialog";
import AutoAttendantEditPromptsDialog from "../../dialogs/AutoAttendants/AutoAttendantEditPromptsDialog";
export default {
    name: 'AutoAttendantPromptsCard',
    mixins: [apiMixin, paginationComponentMixin],
    data: () => ({
      busy:false,
      showEditDialog:false,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',

      currentPromptIntroFile:null,
      currentPromptMenuFile:null,
      currentPromptIntroFilePollStatusInterval:null,
      currentPromptMenuFilePollStatusInterval:null,
      clearIntroErrorLoading:false,
      clearMenuErrorLoading:false,
    }),
    components: {
      AutoAttendantEditPromptsDialog,
      ButtonWithTooltip,
      BasicCard,
    },
    props: {
        trunkData: {
            type: Object,
            required:true
        },
        aaData: {
          type: Object,
          required:true
        },
        loading: {
          type: Boolean,
          default:false,
        },
    },
    methods: {
      downloadFile(prompt_type)
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+prompt_type+'/audio-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },

      async refreshPrompt(promptType){
        let response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+promptType);
        if(response.success){
          if(promptType == 'intro'){
            this.currentPromptIntroFile = response.data;
            if(this.currentPromptIntroFile.status != 'IN_PROGRESS'){
              //remove the requesting.
              clearInterval(this.currentPromptIntroFilePollStatusInterval);
            }
          }else{
            this.currentPromptMenuFile = response.data;
            if(this.currentPromptMenuFile.status != 'IN_PROGRESS'){
              //remove the requesting.
              clearInterval(this.currentPromptMenuFilePollStatusInterval);
            }
          }
        }
      },

      async clearErrorMessage(promptType){
        if(promptType == 'intro'){
          if(!this.currentPromptIntroFile || this.currentPromptIntroFile.status != 'ERROR'){
            return; //no valid to delete
          }
        }else{
          if(!this.currentPromptMenuFile || this.currentPromptMenuFile.status != 'ERROR'){
            return; //no valid to delete
          }
        }

        if(promptType == 'intro'){
          this.clearIntroErrorLoading = true;
        }else{
          this.clearMenuErrorLoading = true;
        }

        let response = await this.Api.send('put','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+promptType+'/clear-conversion-errors');

        if(promptType == 'intro'){
          if(response.success){
            this.currentPromptIntroFile = null;
          }
          this.clearIntroErrorLoading = false;
        }else{
          if(response.success){
            this.currentPromptMenuFile = null;
          }
          this.clearMenuErrorLoading = false;
        }
      },

    },
    created( ) {

    },
    computed:{
      isBusy( ){
        return this.loading || this.busy;
      },
      introPrompt( ){
        if(this.aaData.prompt_intro_file_last && this.aaData.prompt_intro_file_last.id){
          return this.aaData.prompt_intro_file_last;
        }else{
          return null;
        }
      },
      menuPrompt( ){
        if(this.aaData.prompt_menu_file_last && this.aaData.prompt_menu_file_last.id){
          return this.aaData.prompt_menu_file_last;
        }else{
          return null;
        }
      }
    },
    watch: {
      introPrompt(value){
        this.currentPromptIntroFile = value;
        if(this.currentPromptIntroFile && this.currentPromptIntroFile.id && this.currentPromptIntroFile.status == 'IN_PROGRESS'){
          this.currentPromptIntroFilePollStatusInterval= setInterval(() => {
            this.refreshPrompt('intro');
          }, 3000);
        }
      },
      menuPrompt(value){
        this.currentPromptMenuFile = value;
        if(this.currentPromptMenuFile && this.currentPromptMenuFile.id && this.currentPromptMenuFile.status == 'IN_PROGRESS'){
          this.currentPromptMenuFilePollStatusInterval= setInterval(() => {
            this.refreshPrompt('menu');
          }, 3000);
        }
      }
    },
};
</script>

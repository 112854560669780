<template>
    <ComplexDialog
        title="Caller ID Setting"
        v-model="visible"
        :loading="busy"
    >

        <RadioSelect
            v-model="cid_type"
            :items="cid_options"
        />


      <v-expand-transition>
          <CallerIDField v-model="form.cli" v-if="cid_type===2 || cid_type===3" outlined autofocus />
      </v-expand-transition>

      <v-alert transition="fade" v-if="cid_type===3" type="info" outlined>A valid Caller ID must still be sent to the carrier even if Caller ID is hidden. Some call types will be rejected by the carrier without a valid CLI.</v-alert>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="!cid_type || (cid_type===2 || cid_type===3) && !form.cli || (cli===form.cli && override_cli===form.override_cli && blocked===form.blocked)"
                :loading="busy"
                @click="update"
                text
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import RadioSelect from '../../pieces/Forms/RadioSelect';
import CallerIDField from '@/components/pieces/CallerIDField';
export default {
    name: 'CallerIDSettingsDialog',
    mixins: [apiMixin, dialogMixin],
    components: { CallerIDField, RadioSelect, ComplexDialog },
    data: () => ({
        busy: false,
        cid_type: null,
        form: {
            cli: null,
            blocked: null,
            override_cli: null
        },
        cid_options: [
            {
                value: 1,
                label: 'Dynamic',
                description: 'SIP device must be configured to send a valid CLI'
            },
            {
                value: 2,
                label: 'Static',
                description: 'Manually configured CLI (SIP device setting is ignored)'
            },
            {
              value: 3,
              label: 'Private',
              description: 'Hide outbound CLI (SIP device setting is ignored)'
            }

        ]
    }),
  watch: {
    visible(val) {
      if (val) {
        if (this.data) {
          this.form.cli = this.cli;
          this.form.blocked = this.blocked;
          this.form.override_cli = this.override_cli;
          this.cid_type = this.form.blocked==='Y' ? 3 : this.form.override_cli==='Y' ? 2 : 1;
        }
      } else {
        this.form.cli = null;
        this.form.override_cli = null;
      }
    },
    cid_type(val) {
      switch (val) {
        case 1:
          this.form.cli = null;
          this.form.override_cli = 'N';
          this.form.blocked = 'N';
          break;
        case 2:
          this.form.override_cli = 'Y';
          this.form.blocked = 'N';
          break;
        case 3:
          this.form.override_cli = 'Y';
          this.form.blocked = 'Y';
          break;
      }
    }
  },
  computed: {
    cli() {
      return this.data && this.data.service_features
          ? this.data.service_features.find((e) => e.name === 'cli').attributes.find((e) => e.name === 'display_number').effective_values[0]
          : null;
    },
    blocked() {
      return this.data && this.data.service_features
          ? this.data.service_features.find((e) => e.name === 'clir').attributes.find((e) => e.name === 'blocked').effective_values[0]
          : null;
    },
    override_cli() {
      if(this.data && this.data.service_features){
        let record = this.data.service_features.find((e) => e.name === 'cli');
        if(record.flag_value == '^'){
          /**
           * No local setting for trunk. We are running of inherited settings.
           */
          return 'N';
        }else{
          return record.effective_flag_value;
        }
      }else{
        return null;
      }
    }

  },
    methods: {
        async update() {
            this.busy = true;

            const response = await this.Api.send('post', 'trunks/' + this.data.i_account + '/update_call_settings', this.form);
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.visible = false;
            }
            this.busy = false;
        }
    },
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>
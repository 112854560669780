<template>
  <SimpleDialog
    v-model="visible"
    :title="(!!this.form.i_cp_condition ? 'Edit' : 'New') + ' Number Group'"
    yes-text="Save"
    :loading="busy"
    :yes-disabled="!valid || !form.numbers.length"
    no-text="Cancel"
    @click="submit"
    persistent
    styledHeader
  >
    <v-form v-model="valid">
      <v-text-field v-model="form.name" label="Name *" outlined :rules="rules.name" autofocus :error-messages="errors.name"  />
      <v-simple-table dense class="mt-2">
        <thead>
          <tr>
            <th>Number</th>
            <th>Type</th>
            <th class="text-right">Delete</th>
          </tr>
        </thead>
        <tbody>
        <tr v-if="form.numbers && !form.numbers.length" key="nodata">
          <td colspan="3" class="text-center">
            No numbers added!
          </td>
        </tr>
          <tr v-for="(number, index) in form.numbers" :key="index">
            <td>
              {{number.number}}
            </td>
            <td><span v-html="number.negated==='Y' ? 'does not match' : 'match'" /></td>
            <td class="text-right">
              <v-btn icon @click="deleteNumber(index)"><v-icon v-html="'mdi-close'" /></v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-sheet rounded class="pa-4 mt-4" color="background_accent">
        <p class="font-weight-bold mb-3">Add a number</p>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="input.number" label="Number *" outlined @keydown.enter="addNumber" dense hide-details :disabled="form.numbers.filter(e => e.negated==='Y').length>0" />
          </v-col>
          <v-col cols="12" md="7">
            <SwitchYesNoInput v-model="input.negated" dense hide-details class="mt-0" label="Match all EXCEPT this" :disabled="form.numbers.length>0" />
          </v-col>
          <v-col cols="12" md="5">
            <v-btn block text color="primary" :disabled="!input.number || form.numbers.filter(e => e.negated==='Y').length>0" @click="addNumber"><v-icon left v-html="'mdi-plus'"/> Add Number</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from '@/components/dialogs/templates/SimpleDialog';
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import SwitchYesNoInput from '@/components/pieces/SwitchYesNoInput';
export default {
  name: 'CallScreeningNumberGroupDialog',
  components: { SwitchYesNoInput, SimpleDialog },
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    valid: false,
    busy: false,
    errors: {
      name: null
    },
    input: {
      number: null,
      negated: "N",
      onnet: "N"
    },
    form: {
      i_cp_condition: null,
      name: "",
      numbers: [],
      type: null
    },
    rules: {
      name: [(v) => !!v || 'Name is required'],
    }
  }),
  methods: {
    addNumber() {
        if (!this.input.number) return;
        if (this.form.numbers.filter(e => e.negated==='Y').length) return;
        this.form.numbers.push({ ...this.input });
        this.input.number = null;
        this.input.negated = "N";
        this.input.onnet = "N";
    },
    deleteNumber(index) {
        this.form.numbers.splice(index, 1);
    },
    async submit() {
      this.busy = true;
      const response = await this.Api.send(!!this.form.i_cp_condition ? 'put' : 'post', 'trunks/' + this.data.i_account + '/call_screening_condition', this.form);
      if (response.success) {
        this.$emit('change', response.data.i_cp_condition);
        this.visible = false;
      }
      this.busy = false;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.condition) {
          this.form = JSON.parse(JSON.stringify(this.condition));
        } else {
          this.form.type = this.type;
        }
      } else {
        this.form.name = '';
        this.form.numbers = [];
        this.form.i_cp_condition = null;
        this.input.number = null;
        this.input.negated = "N";
        this.input.onnet = "N";
        this.errors.name = null;
      }
    },
    'form.name'(val) {
      if (val && this.conditions && this.conditions.length) {
        let valid = true;
        this.conditions.forEach(e => {
          if (e.name.toLowerCase()===val.toLowerCase() && e.i_cp_condition!==this.form.i_cp_condition) valid = false;
        });
        this.errors.name = valid ? null : 'This name is already in use';
      }
    }
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'FromNumber'
    },
    condition: {
      type: Object,
      default: null
    },
    conditions: {
      type: Array,
      default: null
    }
  }
};
</script>
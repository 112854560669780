<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        title="Outstanding Balance"
    >
        <p class="text-h6 error--text" v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.overdue_balance>0">
            <Currency :value="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.overdue_balance" /> overdue
        </p>
        <p class="text-h6 text--primary" v-else-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance>0">
            <Currency :value="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance" /> outstanding
        </p>
        <p class="text-h6 text--primary" v-else-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance===0">
            Account is up to date
        </p>
        <p class="text--primary text-h6" v-else-if="!loading && !data.unpaid_invoice_info">
            Unable to get invoice summary for account
        </p>

        <v-row dense v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.overdue_balance>0" class="error--text font-weight-bold">
            <v-col cols="5">Overdue balance</v-col>
            <v-col cols="7">
                <Currency :value="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.overdue_balance" hide-tooltip />
            </v-col>
        </v-row>
        <v-row dense v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary">
            <v-col cols="5">
                <span class="font-weight-bold">Outstanding balance</span>
            </v-col>
            <v-col cols="7">
                <Currency :value="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance" hide-tooltip />
            </v-col>
            <v-col cols="5" class="font-weight-bold">Unallocated payments</v-col>
            <v-col cols="7">
                <Currency :value="data.unallocated_payments" hide-tooltip />
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Last payment</span>
            </v-col>
            <v-col cols="7">
                <span v-if="data.unpaid_invoice_info.invoices_summary.last_payment_amount">
                    <Currency :value="data.unpaid_invoice_info.invoices_summary.last_payment_amount" hide-tooltip /> on <LocalDateTime :value="data.unpaid_invoice_info.invoices_summary.last_payment_date" />
                </span>
                <span v-else>
                    None
                </span>
            </v-col>
        </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import Currency from '../../pieces/Currency';
import addressDataMixin from '../../../mixins/addressDataMixin';
import LocalDateTime from '../../pieces/LocalDateTime';
export default {
    name: 'CustomerOutstandingBalanceCard',
    mixins: [addressDataMixin],
    components: { LocalDateTime, Currency, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    filters: {
        upper(value) {
            return value.toString().toUpperCase();
        },
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>

<template>
    <div>
        <v-autocomplete
            v-if="!disabled"
            :items="data"
            :loading="loading"
            :label="label"
            :search-input.sync="query"
            :disabled="busy"
            :readonly="!!id"
            :value="id"
            :hint="!id ? 'Start typing customer name to search' : ''"
            item-text="name"
            item-value="i_customer"
            @change="change"
            @click:clear="clear"
            outlined
            no-filter
            hide-no-data
            clearable
            @keydown.enter.native.prevent
            return-object
            :autofocus="autofocus"
        />
        <v-text-field
            v-if="disabled"
            outlined
            :label="label"
            :loading="loading"
            v-model="currentQuery"
            disabled
        />
    </div>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CustomerDropdown',
    mixins: [apiMixin],
    data: () => ({
        data: [],
        id: null,
        loading: false,
        busy: false,
        timer: null,
        query: null,
        currentQuery: '',
        error: false,
        selected: null,
    }),
    methods: {
        change: function (value) {
            this.currentQuery = value ? value.name : '';
            this.selected = value;
            this.id = value ? value.i_customer : null;
            this.$emit('update', value);
        },
        clear: function () {
            this.$emit('update', null);
            this.$emit('clear');
        },
        async search(query) {
            this.loading = true;
            this.currentQuery = query;
            const response = await this.Api.send('post','customers/list', {
                name: query,
            });
            this.loading = false;
            if (response.success) {
                this.data = response.data.data;
            } else {
                this.error = true;
            }
        },
        async get(id) {
            this.loading = true;
            this.busy = true;
            const response = await this.Api.send('post','customers/' + id + '/info');
            this.loading = false;
            this.busy = false;
            if (response.success) {
                this.data = [response.data];
                this.currentQuery = response.data.name;
                this.$emit('update', response.data);
            } else {
                this.error = true;
            }
        },
    },
    watch: {
        query(val) {
            clearTimeout(this.timer);
            if (val) {
                if (!this.selected || this.selected.name !== val) {
                    this.timer = setTimeout(() => {
                        if (val !== this.currentQuery) {
                            this.search(val);
                        }
                    }, 500);
                }
            } else {
                this.data = [];
                this.selected = null;
                this.currentQuery = '';
            }
        },
        id(val) {
            this.$emit('input', val);
        },
    },
    created() {
        if (this.value) {
            this.id = this.value;
            this.get(this.id);
        }
    },
    props: {
        label: {
            type: String,
            default: 'Customer',
        },
        value: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>

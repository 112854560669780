<template>
    <BasicCard
        :loading="isBusy"
        :no-skeleton="false"
        title="NBN Plan(s)"
    >
      <p v-if="nbnSubscriptions==null || nbnSubscriptions.length == 0">
        Customer has no current NBN Plans.
      </p>
      <p v-else>
        Customer has {{nbnSubscriptions.length}} current NBN Plans.
      </p>
      <p v-if="nbnExtraSubscriptions!=null && nbnExtraSubscriptions.length > 0">
        Customer has {{nbnExtraSubscriptions.length}} current NBN related product(s).
      </p>
        <template v-slot:actions>
            <ButtonWithTooltip
                tooltip="Search NBN Plans"
                text="Search Plans"
                icon="mdi-web"
                flat
                @click="openNBNSearch"
            />
          <ButtonWithTooltip
              v-if="nbnSubscriptions != null && nbnSubscriptions.length>0"
              tooltip="View current NBN Plan"
              text="Plans"
              icon="mdi-eye"
              flat
              @click="showNbnSubscriptionsDialog=true"
          /><ButtonWithTooltip
            v-if="nbnExtraSubscriptions != null && nbnExtraSubscriptions.length>0"
            tooltip="View current NBN Products"
            text="Products"
            icon="mdi-eye"
            flat
            @click="showNbnExtraSubscriptionsDialog=true"
        />
        </template>
      <CustomerNBNSubscriptionListDialog v-model="showNbnSubscriptionsDialog" :customer-data="customerData" :nbn-subscriptions="nbnSubscriptions" @reloadSubscriptions="getNBNSubscriptions"/>
      <CustomerNBNExtraSubscriptionListDialog v-model="showNbnExtraSubscriptionsDialog" :customer-data="customerData" :nbn-extra-subscriptions="nbnExtraSubscriptions" />
    </BasicCard>
</template>

<script>
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import Currency from "../../pieces/Currency";
import customerDataMixin from "../../../mixins/customerDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import CustomerNBNSubscriptionListDialog from "../../dialogs/Customer/CustomerNBNSubscriptionListDialog";
import CustomerNBNExtraSubscriptionListDialog from "../../dialogs/Customer/CustomerNBNExtraSubscriptionListDialog";
export default {
    name: 'CustomerNBNCard',
    mixins: [GlobalHelperMixin],
    components: {
      CustomerNBNSubscriptionListDialog,
      CustomerNBNExtraSubscriptionListDialog,
        ButtonWithTooltip,
        BasicCard,
    },
    data: () => ({
        busy:false,
        nbnSubscriptions: null,
        nbnExtraSubscriptions: null,
        showNbnSubscriptionsDialog:false,
        showNbnExtraSubscriptionsDialog:false,
    }),
    methods: {
      async getNBNSubscriptions(){
        this.busy = true;
        let response = await this.Api.send('get', 'customers/'+this.customerData.i_customer+'/nbn-subscription-details');
        this.busy = false;
        if (response.success) {
          if(response.data){
            this.nbnSubscriptions = response.data;
          }
        }
      },
      async getNBNExtraSubscriptions(){
        this.busy = true;
        let response = await this.Api.send('get', 'customers/'+this.customerData.i_customer+'/nbn-extra-subscription-details');
        this.busy = false;
        if (response.success) {
          if(response.data){
            this.nbnExtraSubscriptions = response.data;
          }
        }
      },

      openNBNSearch( ){
        this.$router.push({name: 'CustomerNBNSearch',params:{customerId:this.customerData.i_customer}});
      }
    },
    computed: {
      isBusy(){
        return this.loading || this.busy;
      },
      iCustomer( ){
        //text="nbnSubscription.admin_email_sent_at == null ? 'Send Order Email' : 'RESEND Order Email'"
        if(this.customerData != null && this.customerData.i_customer){
          return this.customerData.i_customer;
        }else{
          return null;
        }
      },
    },
    watch: {
      iCustomer(value) {
        if (value != null) {
          this.getNBNSubscriptions();
          this.getNBNExtraSubscriptions();
        }
      },
    },
  created(){
    if(this.iCustomer != null){
        this.getNBNSubscriptions();
        this.getNBNExtraSubscriptions();
    }
  },
    props: {
      customerData:{
        type:Object,
        default:null
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
};
</script>

<template>
    <SimpleDialog
        :loading="busy"
        v-model="visible"
        @close="close"
        yes-text="Confirm"
        @click="terminate"
        :yesDisabled="!yesEnabled"
        no-text="Cancel"
        :title="'Delete Call Queue'"
        persistent
        styledHeader
    >
      <p>Delete Call Queue '{{callQueueData.group_id}}'?</p>
      <span  v-if="validationMessage != null">
        <p class="text--primary">Unable to delete Call Queue.</p>
        <v-alert type="error" v-if="validationMessage != null">{{validationMessage}}</v-alert>
      </span>
      <span  v-if="validationMessage == null">
        <v-alert type="error">This operation cannot be undone.</v-alert>
        <p class="text--primary">This Call Queue will be deleted immediately.</p>
        <p class="text--primary">
            Are you sure you wish to delete this Call Queue?
        </p>
      </span>
      <v-alert v-if="error" type="warning">Error: {{ error }}</v-alert>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'TerminateCallQueueDialog',
    mixins: [apiMixin,dialogMixin],
    components: {SimpleDialog},
    data: () => ({
        busy: false,
        validationMessage:null,
        error: null,
    }),
    props: {
      callQueueData: {
        type: Object,
        required:true,
      },
    },
    methods: {
        async terminate() {
            this.busy = true;
            this.error = null;
            const response = await this.Api.send('delete','call-queues/' + this.callQueueData.i_c_queue);
            if (response.success) {
              if(response.data.i_c_queue == this.callQueueData.i_c_queue){
                  this.showGlobalSuccessMessage('Deleted Call Queue successfully.');
                //to refresh the side menu.
                document.dispatchEvent(new CustomEvent('refreshRequestedParentCustomer'));
                  this.close( );
                  this.$router.push({
                      name: 'CustomerSummary',
                      params:{customerID : this.callQueueData.i_customer},
                    });
              }else{
                  this.error = 'Unknown issue. Failed to delete.';
              }
            } else {
                this.error = response.data.description;
            }
          this.busy = false;
        },
        close() {
            this.reset( );
            this.$emit('close');
        },
        reset( ){
          this.validationMessage = null;
          this.error = null;
        },
        async validate() {
          this.busy = true;
          const response = await this.Api.send('get',
              'call-queues/' + this.callQueueData.i_c_queue + '/delete-validation'
          );
          if (response.success) {
            if(response.data.can_delete === true){
              this.validationMessage = null;
            }else {
              this.validationMessage = response.data.message;
            }
          }else{
            this.validationMessage = 'Delete validation failed.';
          }
          this.busy = false;

        },
    },
  watch: {
    visible(value) {
      if (this.value!==value) this.$emit('input', value);

      //reset on close and open.
      this.reset( );

      if(value){
        this.validate( );
      }
    },
  },
  computed:{
    yesEnabled:function(){
      return !this.busy && this.validationMessage == null;
    },
  }
};
</script>

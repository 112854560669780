<template>
    <v-row dense>
        <v-col cols="12" md="6">
            <CustomerOutstandingBalanceCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerOutstandingInvoiceCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerPaymentInfoCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" md="6">
            <CustomerBillingDetailCard :data="customerData" :loading="customerLoading" />
        </v-col>
    </v-row>
</template>

<script>

    import customerDataMixin from '../../mixins/customerDataMixin';
    import CustomerOutstandingInvoiceCard from '../../components/cards/Customer/CustomerOutstandingInvoiceCard';
    import CustomerBillingDetailCard from '../../components/cards/Customer/CustomerBillingDetailCard';
    import CustomerOutstandingBalanceCard from '../../components/cards/Customer/CustomerOutstandingBalanceCard';
    import CustomerPaymentInfoCard from '../../components/cards/Customer/CustomerPaymentInfoCard';
    export default {
        name: 'CustomerBillingSummary',
        components: { CustomerPaymentInfoCard, CustomerOutstandingBalanceCard, CustomerBillingDetailCard, CustomerOutstandingInvoiceCard },
        mixins: [customerDataMixin],

        data: () => ({

        }),
    };
</script>
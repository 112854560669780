<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
          <div>
            <PaginationCardV2
                :page="searchFilters.page"
                :totalRecords="listData.total_records"
                :totalPages="listData.total_pages"
                :limit="searchFilters.limit"
                :currentPageTotal="(listData.results) ? listData.results.length : 0"
                :loading="isBusy"
                @updatedPagination="updatedPagination"
                :no-skeleton="false"
            >
              <v-simple-table>
                <thead>
                <tr>
                  <th>Ref ID</th>
                  <th class="text-center">MAC</th>
                  <th class="text-center">Label</th>
                  <th class="text-center">Manufacturer/Model</th>
                  <th class="text-center">Provisioned</th>
                </tr>
                </thead>

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                  <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                    <td colspan="100%" class="text-center">
                      No Devices found
                    </td>
                  </tr>
                  <tr
                      v-for="item in listData.results"
                      :key="item.id"
                  >
                    <td>
                      <router-link
                          :to="{
                                    name: 'VoipDevice',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.i_account,
                                        voipDeviceId:item.id,
                                    },
                                }"
                          class="font-weight-medium"
                      >{{ item.id }}</router-link
                      >
                    </td>
                    <td class="text-center">
                      {{ item.mac_address }}
                    </td>
                    <td class="text-center">
                      {{ item.label }}
                    </td>
                    <td class="text-center">
                      {{ item.manufacturer }}/{{item.model}}
                    </td>
                    <td class="text-center">
                      <v-chip
                          v-if="item.last_provisioned_at == null"
                          small
                          color="red"
                          text-color="white"
                      >
                        Not Provisioned
                      </v-chip>
                      <v-chip
                          v-if="item.last_provisioned_at != null"
                          small
                          color="green"
                          text-color="white"
                      >
                        Provisioned
                      </v-chip>
                    </td>
                  </tr>
                </v-slide-x-transition>
              </v-simple-table>
            </PaginationCardV2>
          </div>
        </v-col>
    </v-row>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import PaginationCardV2 from "../../components/cards/templates/PaginationCardV2";
export default {
    name: 'CustomerVoipDevicePendingList',
    mixins: [filterMixin, customerDataMixin,refreshListenerMixin],
  data: () => ({
    busy:false,
    searchFilters: {
      page:1,
      limit: 10,
    },
    listData: {
      total_records:0,
      total_pages:0
    },
  }),
    components: {
      PaginationCardV2
    },
  methods: {
    updatedPagination(data){
      this.searchFilters.page = data.page;
      this.searchFilters.limit = data.limit;
      this.update( );
    },
    async update() {
      if(!this.customerData || !this.customerData.i_customer){
        return;
      }
      this.busy = true;
      let params = this.searchFilters;

      for (const [key, value] of Object.entries(params)) {
        if(value == null || value == undefined){
          delete params[key];
        }
      }

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('get','customers/' + this.customerData.i_customer + '/voip-devices-pending-config?' + queryString);
      this.busy = false;
      if (response.success) {
        this.listData = response.data;
        this.paginationData = {
          data: response.data.results,
          count: response.data.total_records,
          pages: response.data.total_pages
        };
        if (this.firstLoad) this.firstLoad = false;
      } else {
        this.listData = [];
      }
    },
  },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
  },
  created(){
    //this.update( );
  },
  watch: {
    customerData(value) {
      this.update( );
    }
  },

};
</script>

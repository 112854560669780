<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="Extension Number"
                            :disabled="isLoading"
                            v-model="localFilter.id"
                            @keydown.enter="updateLocalFilter"
                    />
                </v-col>
                <v-col cols="12" sm="2">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCard
            :page="filter.page"
            :data="data"
            :limit="filter.limit"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th v-if="trunkData.ringGroupDetails.sequence == 'Order'" class="text-center">Order</th>
                        <th class="text-center">Extension Number</th>
                        <th class="text-center">Assigned to Phone Line</th>
                        <th class="text-center">Ringing</th>
                        <th class="text-center">Ignore Follow-me/Voicemail</th>
                        <th class="text-center">Primary</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No linked extensions
                        </td>
                    </tr>
                    <tr
                        v-for="item in data.data"
                        :key="item.id"
                        v-bind:class="{
                            /*'red darken-4':
                                $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',*/
                            'grey darken-3':
                                $vuetify.theme.dark && item.hunt_active === 'N',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.hunt_active === 'N',
                        }"
                    >
                      <td class="text-center" v-if="trunkData.ringGroupDetails.sequence == 'Order'">
                        {{ item.hunt_order }}
                      </td>
                        <td class="text-center">
                            <router-link
                                v-if="item.i_account"
                                :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: customerData.i_customer,
                                        trunkId: item.i_account,
                                    },
                                }"
                                class="font-weight-medium"
                                >{{ item.id }}</router-link>
                          <span v-else>{{ item.id }}</span>
                        </td>
                        <td class="text-center">
                            {{ item.account_id }}
                        </td>
                        <td class="text-center">
                          <span v-if="item.hunt_active === 'N'">Disabled</span>
                          <span v-if="item.hunt_active === 'Y' && item.hunt_delay != null && item.hunt_expire != null">Wait for {{ item.hunt_delay }} seconds, then rings for {{item.hunt_expire}} seconds</span>
                          <span v-if="item.hunt_active === 'Y' && (item.hunt_delay == null && item.hunt_expire == null)">Permanently Enabled</span>
                        </td>
                        <td class="text-center">
                          <v-icon
                              v-if="item.huntstop === 'Y' && trunkData.ringGroupDetails.sequence != 'Simultaneous'"                              color="success"
                              class="mr-2"
                          >mdi-check</v-icon>
                          <v-icon
                              v-if="item.huntstop === 'N' && trunkData.ringGroupDetails.sequence != 'Simultaneous'"
                              color="error"
                              class="mr-2"
                          >mdi-cancel</v-icon>
                          <IconWithTooltip
                              v-if="trunkData.ringGroupDetails.sequence == 'Simultaneous'"
                              tooltip="Not applicable when Sequence is set to Simultaneous."
                              icon="mdi-checkbox-blank-circle-outline"
                              color="grey"
                          />
                        </td>
                      <td class="text-center">
                        <v-icon
                            v-if="item.primary_group === 'Y'"
                            color="success"
                            class="mr-2"
                        >mdi-check</v-icon>
                        <v-icon
                            v-if="item.primary_group === 'N'"
                            color="error"
                            class="mr-2"
                        >mdi-cancel</v-icon>
                      </td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  @click="openUpdateExtensionDialog(item);"
                              >
                                <v-icon v-html="'mdi-border-color'" />
                              </v-btn>
                            </template>
                            <span>Edit Settings</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  color="error"
                                  @click="openDeleteExtensionDialog(item);"
                              >
                                <v-icon v-html="'mdi-link-off'" />
                              </v-btn>
                            </template>
                            <span>Unassign Extension</span>
                          </v-tooltip>
                        </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCard>
      <UpdateRingGroupSettingsDialog
          v-model="showUpdateExtension"
          :data="interactingExtensionData"
          :trunk-data="trunkData"
      />
      <RemoveRingGroupSettingsDialog
          v-model="showDeleteExtension"
          :data="interactingExtensionData"
          :trunk-data="trunkData"
      />
    </div>
</template>

<script>
import PaginationCard from "../templates/PaginationCard";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import UpdateRingGroupExtensionDialog from "../../dialogs/RingGroups/UpdateRingGroupExtensionDialog";
import UpdateRingGroupSettingsDialog from "../../dialogs/RingGroups/UpdateRingGroupExtensionDialog";
import RemoveRingGroupSettingsDialog from "../../dialogs/RingGroups/RemoveRingGroupExtensionDialog";
import IconWithTooltip from "../../pieces/IconWithTooltip";
export default {
    name: 'RingGroupExtensionListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        localFilter: {
            id: null,
            active: 'Y',
            note:null,
        },
        statusOptions: [
            { option: 'Active', value: 'Y' },
            { option: 'Terminated', value: 'N' },
        ],
        showUpdateExtension: false,
        showDeleteExtension: false,
        interactingExtensionData: null,
    }),
    components: {
      IconWithTooltip,
      RemoveRingGroupSettingsDialog,
      UpdateRingGroupSettingsDialog,
      PaginationCard,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        trunkData: {
            type: Object,
            required:true,
        },
        small: {
            type: Boolean,
            default: false,
        },
      customerData:{
          type: Object,
          required:true,
      }
    },
    methods: {
      reset( ){
        this.showUpdateExtension = false;
        this.showDeleteExtension = false;
        this.interactingExtensionData = null;
      },
      openUpdateExtensionDialog(item){
        this.interactingExtensionData = item;
        this.showUpdateExtension = true;
      },
      openDeleteExtensionDialog(item){
        this.interactingExtensionData = item;
        this.showDeleteExtension = true;
      },
        async update() {
          this.reset( );
            this.isLoading = true;
            this.getLocalFilters();
            let params = { limit: this.filter.limit, page: this.filter.page};

            if (this.filter.id)
              params.id = this.filter.id;

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            const response = await this.Api.send('get','ring-groups/'+this.trunkData.ringGroupDetails.i_c_group+'/extensions?'+queryString, params);
            this.isLoading = false;
            this.data = response.data;
            this.data.count = this.data.total;
            this.data.pages = Math.ceil(this.data.total/this.filter.limit);

        },
    },
};
</script>

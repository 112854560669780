<template>
    <v-row dense>
        <v-col cols="12">
          <router-view />
          <router-view name="toolbarExtension" />
        </v-col>
    </v-row>
</template>

<script>
import apiMixin from '../../mixins/apiMixin';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
export default {
    name: 'CallQueueParent',
    mixins: [apiMixin, refreshListenerMixin, GlobalFetchStoreMixin],
    data: () => ({
    }),
    computed: {
        callQueueData() {
          if(this.$store.state.data['call-queue'] && this.$store.state.data['call-queue'].i_c_queue == parseInt(this.$route.params.callQueueId)){
            return this.$store.state.data['call-queue'];
          }
          return {};
        },
        loading() {
          return this.customerLoading || this.fetchStoreLoadingByLabel('call-queue');
        },
    },
    methods: {
        async get(id, force) {

          let checkParams = {"i_c_queue":id};
          const i_customer = this.$route.params.customerId;
          if(i_customer){
            checkParams.i_customer = i_customer;
          }

          if(force || !this.fetchStoreIsAlreadyStored('call-queue', checkParams)){
            //no matching data stored. We load in data
            let redirect = {};
            if(this.$store.state.user.isAdmin && i_customer){
              redirect.name = 'CustomerSummary';
              redirect.params = {customerId : i_customer};
            }else{
              redirect.name = 'Home';
            }
            await this.fetchStoreRequest('call-queue', 'call-queues/'+id, {"i_c_queue":id}, redirect, 'get', force, 'Call Queue');
            this.buildBreadCrumbs( );
          }

        },
        update() {
          /*
          Need the check here as some process emit a request but the param is empty.
          I believe this is a timing issue. IE a refresh is emitted and then the page changes.
           */
          if(this.$route.params.callQueueId != undefined) {
            this.get(this.$route.params.callQueueId, true);
          }
        },
        buildBreadCrumbs( ){
          let breadCrumbs = [
            {
              name:"CallQueueSummary",
              label: "Call Queue ("+this.callQueueData.group_id+")",
              params:{"callQueueId":this.callQueueData.i_c_queue}
            }
          ];
          this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
        }

    },
    beforeRouteUpdate(to, from, next) {
        this.get(to.params.callQueueId);
        next();
    },
    created() {
        this.update();
    },
};
</script>

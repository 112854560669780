<template>
    <ComplexDialog
        v-model="visible"
        :title="'Change Plan - '+label+' '+ data.id"
        :step="step"
        :close-confirmation="!!plan && step!==3"
        close-confirmation-message="The new plan you selected has not been applied yet."
        @step-next="next"
        :step-next-text="step===2 ? 'Apply' : undefined"
        :step-next-disabled="(step===1 && !plan)"
        @step-back="step--"
        @step-end="visible=false"
        :step-end="3"
        :max-width="950"
        :loading="busy"
    >
        <v-window v-model="step">
            <v-window-item :value="1">
                <p>Please choose the new plan to apply to this service.</p>
                <TrunkPlanForm
                    v-if="visible && data.account_type == 'SIP-TRUNK'"
                    v-model="plan"
                    :i_product="data ? data.i_product : undefined"
                    :assigned_addons="data ? data.assigned_addons : undefined"
                    :show-nfr="customerData.nfr_limit > 0"
                />
              <ExtensionPlanForm
                  v-if="visible && data.account_type == 'HOSTED-EXTENSION'"
                  v-model="plan"
                  :plan-details="data.planDetails"
                  :show-nfr="customerData.nfr_limit > 0"
              />
              <FaxToMailPlanForm
                  v-if="visible && data.account_type == 'FAX-TO-MAIL'"
                  v-model="plan"
                  :plan-details="data.planDetails"
                  :show-nfr="customerData.nfr_limit > 0"
              />
              <RingGroupPlanForm
                  v-if="visible && data.account_type == 'RING-GROUP'"
                  v-model="plan"
                  :plan-details="data.planDetails"
                  :show-nfr="customerData.nfr_limit > 0"
              />
              <AutoAttendantPlanForm
                  v-if="visible && data.account_type == 'AUTO-ATTENDANT'"
                  v-model="plan"
                  :plan-details="data.planDetails"
                  :show-nfr="customerData.nfr_limit > 0"
              />
            </v-window-item>
            <v-window-item :value="2">
                <v-alert type="info">The new plan will be applied effective immediately - plan charges will be pro-rated.</v-alert>
                <p>The new plan you have selected is summarized below.</p>
                <TrunkPlanInfoSummary v-if="step===2 && data.account_type == 'SIP-TRUNK'" :data="plan" />
              <ExtensionPlanInfoSummary v-if="step===2 && data.account_type == 'HOSTED-EXTENSION'" :data="plan" />
              <FaxToMailPlanInfoSummary v-if="step===2 && data.account_type == 'FAX-TO-MAIL'" :data="plan" />
              <RingGroupPlanInfoSummary v-if="step===2 && data.account_type == 'RING-GROUP'" :data="plan" />
              <AutoAttendantPlanInfoSummary v-if="step===2 && data.account_type == 'AUTO-ATTENDANT'" :data="plan" />
            </v-window-item>
            <v-window-item :value="3">
                <v-row no-gutters>
                    <v-col cols="12" class="text-center py-4">
                        <v-icon size="120" color="success"
                            >mdi-check-circle-outline</v-icon
                        >
                        <p class="text-h6">Plan changed successfully</p>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </ComplexDialog>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ComplexDialog from '../templates/ComplexDialog';
import TrunkPlanForm from '../../pieces/Trunk/TrunkPlanForm';
import ExtensionPlanForm from "../../pieces/Extension/ExtensionPlanForm";
import FaxToMailPlanForm from "../../pieces/FaxToMail/FaxToMailPlanForm";
import RingGroupPlanForm from "../../pieces/RingGroup/RingGroupPlanForm";
import TrunkPlanInfoSummary from '../../pieces/Trunk/TrunkPlanInfoSummary';
import ExtensionPlanInfoSummary from "../../pieces/Extension/ExtensionPlanInfoSummary";
import FaxToMailPlanInfoSummary from "../../pieces/FaxToMail/FaxToMailPlanInfoSummary";
import RingGroupPlanInfoSummary from "../../pieces/RingGroup/RingGroupPlanInfoSummary";
import customerDataMixin from "../../../mixins/customerDataMixin";
import AutoAttendantPlanForm from "../../pieces/AutoAttendant/AutoAttendantPlanForm";
import AutoAttendantPlanInfoSummary from "../../pieces/AutoAttendant/AutoAttendantPlanInfoSummary";
export default {
    name: 'ChangeTrunkPlanDialog',
    components: {
      AutoAttendantPlanInfoSummary,
      AutoAttendantPlanForm, TrunkPlanInfoSummary, TrunkPlanForm, ComplexDialog, ExtensionPlanForm,FaxToMailPlanForm,ExtensionPlanInfoSummary,FaxToMailPlanInfoSummary, RingGroupPlanForm, RingGroupPlanInfoSummary},
    mixins: [apiMixin, dialogMixin,customerDataMixin],
    data: () => ({
        step: 1,
        plan: null,
        busy: false,
        error: null
    }),
  computed: {
    label() {
      return this.data.account_type_label;
    },
  },
    methods: {
        next() {
            if (this.step===2) {
                this.submit(this.plan);
            } else {
                this.step++;
            }
        },
        async submit(data) {
            this.busy = true;
            let response = null;
            if(this.data.account_type == 'SIP-TRUNK'){
              response = await this.Api.send('post','trunks/' + this.data.i_account + '/update_plan', data);
            }/*else if(this.data.account_type == 'HOSTED-EXTENSION'){
              response = await this.Api.send('put','extensions/' + this.data.extensionDetails.i_c_ext + '/plan', data);
            }*/else{
                response = await this.Api.send('put','trunks/' + this.data.i_account + '/plan', data);
            }

            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.step = 3;
            }
            this.busy = false;
        }
    },
    watch: {
        visible(value) {
            if (this.visible!==value) this.visible = value;
            if (!value) {
                this.step = 1;
            }
        }
    },
    props: {
        data: {
            default: null
        },
        showNfr: {
            type: Boolean,
            default: false
        }
    },
};
</script>
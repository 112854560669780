<template>
    <ComplexDialog
        v-model="visible"
        :title="'Create new Auto Attendant for account '+ trunkData.id"
    >
      <p>Fill out this form to create a new Auto Attendant for this Auto Attendant Account.</p>
  <AutoAttendantGeneralSettingsForm :loading="isBusy" :trunkData="trunkData" :aaData="null" :changeCallBack="settingsChanged"/>
        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >Create</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
    export default {
        name: 'AutoAttendantCreateDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin,AutoAttendantMixin],
        components: {AutoAttendantGeneralSettingsForm,  ComplexDialog },
        props: {
            trunkData: {
                type: Object,
                required: true,
            },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,
          rules: {
            required: v => !!v || 'Required.',
            numeric: v => !isNaN(v) || 'Must be whole number',
          },
        }),

      methods:{
          settingsChanged(form){
            this.form = form;
          },
        async submit() {
          this.busy = true;
          let params = this.processDataToSave(this.form);

          let response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants', params);
          //this.$router.router.go(path);
          if (response.success) {
            this.showGlobalSuccessMessage('Successfully create new Auto Attendant');
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
            this.$router.push({ name: 'AutoAttendantAccountAutoAttendantSummary',params:{"customerId":this.trunkData.i_customer,"trunkId":this.trunkData.i_account,"menuId":response.data.i_menu}});
          }
          this.busy = false;
        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      }
    };
</script>

<style scoped>

</style>
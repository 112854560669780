<template>
    <BasicCard
        :loading="loading"
        title="Ring Group Settings"
        v-if="data.ringGroupDetails"
    >
        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Number</span>
            </v-col>
            <v-col cols="7">
                {{data.ringGroupDetails.id}}
            </v-col>
        </v-row>
      <v-row dense v-if="false">
        <v-col cols="5">
          <span class="font-weight-bold">Name</span>
        </v-col>
        <v-col cols="7">
          {{data.ringGroupDetails.name}}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Sequence</span>
        </v-col>
        <v-col cols="7">
          {{ringGroupSelects.sequence.find(element => element.value == data.ringGroupDetails.sequence).option}}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Caller ID</span>
        </v-col>
        <v-col cols="7">
          {{ringGroupSelects.callerId.find(element => element.value == data.ringGroupDetails.caller_id).option}}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Call Pickup Allowed</span>
        </v-col>
        <v-col cols="7">
          <v-icon
              v-if="data.ringGroupDetails.call_pickup_allowed === 'Y'"
              color="success"
              class="mr-2"
          >mdi-check</v-icon>
          <v-icon
              v-if="data.ringGroupDetails.call_pickup_allowed === 'N'"
              color="error"
              class="mr-2"
          >mdi-cancel</v-icon>
        </v-col>
      </v-row>
        <template v-slot:actions>
          <v-btn
              v-if="$route.name != 'CallQueueSummary' && data.ringGroupDetails.i_c_queue"
              :to="{ name: 'CallQueueSummary',params: {customerId : data.i_customer, callQueueId:data.ringGroupDetails.i_c_queue} }"
              text
          ><v-icon v-html="'mdi-clipboard-list-outline'" left /> Call Queue</v-btn>

          <v-btn
              v-if="$route.name != 'RingGroupExtensionList'"
              :to="{ name: 'RingGroupExtensionList' }"
              text
          ><v-icon v-html="'mdi-phone-voip'" left /> Extensions</v-btn>

          <ButtonWithTooltip
              text="Edit"
              flat
              tooltip="Edit Settings"
              icon="mdi-pencil"
              @click="showEditDialog = true"
          />
        </template>
      <EditRingGroupSettingsDialog v-model="showEditDialog" :data="data"/>
    </BasicCard>
</template>

<script>
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import EditRingGroupSettingsDialog from "../../dialogs/RingGroups/EditRingGroupSettingsDialog";
export default {
    name: 'RingGroupSettingsCard',
    mixins: [ringGroupDataMixin],
    components: {EditRingGroupSettingsDialog,  ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data: () => ({
      showEditDialog: false,
    }),
};
</script>

<template>
    <BasicCard :title="title" :loading="loading">
        <p class="title text--primary">
            <span v-if="data.salutation">{{ data.salutation }} </span>
            <span v-if="data.firstname">{{ data.firstname }} </span>
            <span v-if="data.midinit">{{ data.midinit }} </span>
            <span v-if="data.lastname">{{ data.lastname }}</span>
        </p>
        <v-row dense v-if="data.phone1">
            <v-col cols="4" class="font-weight-bold"> Phone </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.phone1" />
            </v-col>
        </v-row>
        <v-row dense v-if="data.phone2">
            <v-col cols="4" class="font-weight-bold"> Mobile / SMS </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.phone2" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="4" class="font-weight-bold"> Email </v-col>
            <v-col cols="8">
                <a :href="'mailto:' + data.email">{{ data.email }}</a>
                <CopyToClipboard :value="data.email" small />
            </v-col>
        </v-row>
        <v-row dense v-if="data.bcc && data.bcc != null && data.bcc != ''">
          <v-col cols="4" class="font-weight-bold"> BCC Email </v-col>
          <v-col cols="8">
            <a :href="'mailto:' + data.bcc">{{ data.bcc }}</a>
            <CopyToClipboard :value="data.bcc" small />
          </v-col>
        </v-row>
      <v-row dense v-if="data.user_id">
        <v-col cols="4" class="font-weight-bold"> Username </v-col>
        <v-col cols="8">
          <span>{{ data.username }}</span>
          <CopyToClipboard :value="data.username" small />
        </v-col>
      </v-row>
        <template v-slot:actions >
            <ButtonWithTooltip
                text="Primary Contact"
                icon="mdi-pencil"
                key="1"
                flat
                @click="$emit('editDetails',data)"
                :disabled="data.status == 'closed'"
            />
          </template>
        <template v-slot:feature>
            <v-chip v-if="data.user_id && data.active_at != null" color="info" label small>User</v-chip>
            <v-chip v-if="data.authorized==1" class="ml-1" color="success" label small>Authorized</v-chip>
        </template>

    </BasicCard>
</template>

<script>
import BasicCard from './templates/BasicCard';
import PhoneNumber from '../pieces/PhoneNumber';
import CopyToClipboard from '../pieces/CopyToClipboard';
import ButtonWithTooltip from '../pieces/ButtonWithTooltip';
import apiMixin from '../../mixins/apiMixin';
import dialogMixin from "../../mixins/dialogMixin";
export default {
    name: 'ContactCard',
    mixins: [apiMixin, dialogMixin],
    components: { ButtonWithTooltip, CopyToClipboard, PhoneNumber, BasicCard },
    data: () => ({
      showConvertToUser: false,
    }),
    methods: {
      async disableLogin() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.user_id+ '/disable-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      },
      async activateLogin() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.user_id+ '/activate-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      }
    },
    props: {
        data: {
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        allowEdit: {
          type: Boolean,
          default: false,
        },
        title: {
            type: String,
            default: null,
        },
        createUser:{
            type: Boolean,
            default: false,
        },
        partnerId: {
          type: Number,
          default: null,
        },
    },
};
</script>

<style scoped></style>

<template>
    <SimpleDialog
        :loading="busy"
        v-model="visible"
        @close="close"
        yes-text="Confirm"
        @click="terminate"
        :yesDisabled="!yesEnabled"
        no-text="Cancel"
        :title="'Delete device'"
        persistent
        styledHeader
    >
      <p>Delete device '{{deviceData.label}}?</p>
      <span  v-if="validationMessage != null">
        <p class="text--primary">Unable to delete {{deviceData.label}}.</p>
        <v-alert type="error" v-if="validationMessage != null">{{validationMessage}}</v-alert>
      </span>
      <span  v-if="validationMessage == null">
        <p class="text--primary">This device {{deviceData.label}} will be deleted immediately.</p>

        <p class="text--primary">
            To delete this {{deviceData.label}}, type 'delete' in the box below:
        </p>

        <v-text-field
            v-model="confirmDeletion"
            autofocus
            outlined
            dense
            :rules="rules"
            :disabled="busy"
        />

        <v-alert type="success" v-if="success">
          Device delete successfully.
        </v-alert>
      </span>
        <v-alert v-if="error" type="warning">Error: {{ error }}</v-alert>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'TerminateVoipDialog',
    mixins: [apiMixin,dialogMixin],
    components: {SimpleDialog},
    data: () => ({
        busy: false,
        confirmDeletion: '',
        rules: [(v) => /^delete$/i.test(v) || "Enter 'delete' to confirm"],
        error: null,
        validationMessage:null,
        success: false,
    }),
    props: {
      deviceData: {
        type: Object,
        required:true,
      },
    },
    methods: {
        async terminate() {
            this.busy = true;
            const response = await this.Api.send('delete','extensions/'+this.deviceData.porta_extension_i_c_ext+'/voip-devices/'+this.deviceData.id);
            if (response.success) {
              if(response.data.id == this.deviceData.id){
                this.showGlobalSuccessMessage('Device deleted successfully.');
                document.dispatchEvent(new CustomEvent('refreshRequestedParentCustomer'));
                  this.close( );
                  this.$router.push({ name: 'ExtensionVoipDeviceList',  params: { trunkId: this.deviceData.i_account } });
              }else{
                  this.error = response.data.error;
              }
            } else {
                this.error = 'Failed to delete.';
            }
          this.busy = false;
        },
        close() {
            this.reset( );
            this.$emit('close');
        },
        reset( ){
          this.confirmDeletion = '';
          this.error = null;
          this.validationMessage = null;
          this.success = false;
        },
    },
  watch: {
    visible(value) {
      if (this.value!==value) this.$emit('input', value);

      //reset on close and open.
      this.reset( );

    },
  },
  computed:{
    yesEnabled:function(){
      return this.confirmDeletion.toLowerCase() === 'delete' && !this.busy && this.validationMessage == null;
    },
  }
};
</script>

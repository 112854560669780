<template>
    <v-row dense>
        <v-col cols="12">
            <TransactionListCard
                :filter="filter"
                :loading="customerLoading"
                :cust-id="parseInt($route.params.customerId)"
                @update="updateFilter"
                show-filters
            />
        </v-col>
    </v-row>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import TransactionListCard from '../../components/cards/Transactions/TransactionListCard';
export default {
    name: 'CustomerTransactions',
    mixins: [filterMixin, customerDataMixin],
    components: {
        TransactionListCard,
    },
    data: () => ({
        filter: {
            from: null,
            to: null,
            page: 1,
            limit: 10,
            include_unsuccessful: false,
            cld: null,
            cli: null,
            i_account: null,
            i_service: '3'
        },
    }),
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customerData)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('PaginationCardV2',{attrs:{"page":_vm.searchFilters.page,"totalRecords":_vm.listData.total_records,"totalPages":_vm.listData.total_pages,"limit":_vm.searchFilters.limit,"currentPageTotal":(_vm.listData.results) ? _vm.listData.results.length : 0,"loading":_vm.isBusy,"no-skeleton":false},on:{"updatedPagination":_vm.updatedPagination}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Ref ID")]),_c('th',{staticClass:"text-center"},[_vm._v("MAC")]),_c('th',{staticClass:"text-center"},[_vm._v("Label")]),_c('th',{staticClass:"text-center"},[_vm._v("Manufacturer/Model")]),_c('th',{staticClass:"text-center"},[_vm._v("Provisioned")])])]),_c('v-slide-x-transition',{attrs:{"tag":"tbody","hide-on-leave":"","group":""}},[(_vm.listData.results && _vm.listData.results.length === 0)?_c('tr',{key:"nodata"},[_c('td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v(" No Devices found ")])]):_vm._e(),_vm._l((_vm.listData.results),function(item){return _c('tr',{key:item.id},[_c('td',[_c('router-link',{staticClass:"font-weight-medium",attrs:{"to":{
                                name: 'VoipDevice',
                                params: {
                                    customerId: item.i_customer,
                                    trunkId: item.i_account,
                                    voipDeviceId:item.id,
                                },
                            }}},[_vm._v(_vm._s(item.id))])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.mac_address)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.manufacturer)+"/"+_vm._s(item.model)+" ")]),_c('td',{staticClass:"text-center"},[(item.last_provisioned_at == null)?_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white"}},[_vm._v(" Not Provisioned ")]):_vm._e(),(item.last_provisioned_at != null)?_c('v-chip',{attrs:{"small":"","color":"green","text-color":"white"}},[_vm._v(" Provisioned ")]):_vm._e()],1)])})],2)],1)],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
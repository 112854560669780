<template>
    <ComplexDialog
        v-model="visible"
        :title="aatData ? 'Edit Action '+ aatData.event : 'Create Action'"
    >
    <AutoAttendantActionForm  :loading="isBusy" v-model="form" :trunkData="trunkData" :aaData="aaData" :aatData="aatData" :aaTransitions="aaTransitions"/>


        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >{{aatData ? 'Update' : 'Create'}}</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
import AutoAttendantPromptForm from "../../pieces/AutoAttendant/AutoAttendantPromptForm";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AutoAttendantActionForm from "../../pieces/AutoAttendant/AutoAttendantActionForm";
    export default {
        name: 'AutoAttendantTransactionEditDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin, AutoAttendantMixin],
        components: {AutoAttendantActionForm, ComplexDialog },
        props: {
            trunkData: {
                type: Object,
                required: true,
            },
          aaData: {
            type: Object,
            required: true,
          },
          aatData: {
            type: Object,
            default: null,
          },
          aaTransitions: {
            type: Array,
            default:null
          },
          loading: {
            type:Boolean,
            default:false,
          },
          updateCallback: {
            type:Function,
            required:true,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,

        }),

      methods:{
          /*settingsChanged(form){
            this.form = form;
          },*/
        async submit() {
          this.busy = true;
          this.Api.setHttpObject({"timeout":60000});

          let params = new FormData();

          let values = this.formatTransitionDataToSave(this.form);

          for (let prop in values) {
            if (Object.prototype.hasOwnProperty.call(values, prop)) {
              params.append(prop, values[prop]);
            }
          }

          let response = null;
          let refresh = false;
          if(!this.aatData){
            response = await this.Api.send('post', 'auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/' + this.aaData.i_menu + '/auto-attendant-transitions', params);
          }else {
            response = await this.Api.send('post', 'auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/' + this.aaData.i_menu + '/auto-attendant-transitions/' + this.aatData.i_menu_transition, params);
          }

          if (response.success && response.data.i_menu_transition) {
            if(this.aatData) {
              this.showGlobalSuccessMessage('Successfully updated Action.');
            }else{
              this.showGlobalSuccessMessage('Successfully created Action.');
            }
            refresh = true;
          }else{
            if(this.aatData) {
              this.showGlobalErrorMessage('Failed to updated Action.');
            }else{
              this.showGlobalErrorMessage('Failed to created Action.');
            }
          }

          this.busy = false;
          this.Api.setHttpObject({"timeout":20000});

          if (refresh) {
            //we cannot use refresh here. As it caused too many process to repload and there is a change reactions of watched which load other processes
            //document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.updateCallback( );
            this.visible = false;
          }

        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      }
    };
</script>
import apiMixin from './apiMixin';

export default {
    mixins: [apiMixin],
    methods: {
        async getCallBarringRules() {
            const response = await this.Api.send('get','general/call_barring_rules');
            if (response.success) {
                response.data.sort(function(a, b) {
                    if (a.name < b.name) return -1;
                    if (b.name > b.name) return 1;
                    return 0;
                });
                response.data.forEach((e) => {
                    e.i_cp_condition = e.i_cp_condition.toString();
                    let description = '';
                    e.number_pattern_list.sort(function(a, b) {
                        return a.number < b.number ? -1 : 1;
                    });
                    e.number_pattern_list.forEach((f) => {
                        description += (!!description ? ', ' : '') + f.number;
                    });
                    e.description = (e.matching_type==='including' ? 'Block calls to: ' : 'Only allow calls to: ') + description;
                    e.chip = e.barred_by_default==='Y' ? 'Default' : null;
                });
                this.$store.commit('data', {
                    name: 'barring_rules',
                    data: response.data,
                });
            } else {
                this.error = response.errorDetail;
            }
        },
    },
    computed: {
        callBarringData() {
            return !!this.$store.state.data.barring_rules ? this.$store.state.data.barring_rules : null;
        }
    },
    created() {
        if (!this.$store.state.data.barring_rules) this.getCallBarringRules();
    }
}
<template>
    <ComplexDialog
        :title="'Switch  Extension'"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="3"
        :step-next-disabled="!form.valid"
        :step-end-text="'Close'"
        :step-next-text="step==1?'Switch':'Confirm'"
        :max-width="400"
        :closeHidden="step==3"
        :closeConfirmation="false"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-form v-model="form.valid">
            <v-row no-gutters>
              <v-col cols="12">
                <span v-if="deviceData == null">
                  <p>Here you can switch all the devices attached to Extension {{oldExtensionsData.id}} to a different Extension.</p>
                  <p>There are 45 Devices attached to this Extension.</p>
                </span>
                <span v-else>
                  <p>Here you can switch the Extension of the following Device.</p>
                  <p>{{deviceData.label}} - {{ deviceData.mac_address }}</p>
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-autocomplete
                    dense
                    solo
                    hide-details
                    outlined
                    v-model="form.extensionIAccount"
                    :disabled="loading || busy"
                    :rules="rules.extensionIAccount"
                    :search-input.sync="searchExtensions"
                    :items="extensionItems"
                    class="mb-2"

                    hide-no-data
                    hide-selected
                    label="New Extension"
                    placeholder="Start typing to Search"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="2">
          <v-row no-gutters>
            <v-col cols="12">
              <span v-if="deviceData == null">
                <p class="subtitle-1 text--primary">
                  Confirm Switching of all 45 Devices for Extension {{oldExtensionsData.id}}
                </p>
                <p v-if="oldExtensionsData"><strong>Current Extension</strong><br />
                  Extension Number: {{oldExtensionsData.id}} <br />
                  ID: {{oldExtensionsData.i_account}}
                </p>
                <p v-if="newExtensionsTrunkData"><strong>New Extension</strong><br />
                  Extension Number: {{newExtensionsTrunkData.extensionDetails.id}} <br />
                  Reference ID: {{newExtensionsTrunkData.i_account}}
                </p>
                <p>All Devices will need to be reprovisioned before any changes will take effect on the Device. This can be achieved by rebooting each Device or provisioning the Device (if not yet provisioned).</p>
              </span>
              <span v-else>
                <p class="subtitle-1 text--primary">
                  Confirm Switching of Extension for device '{{ deviceData.label }}'
                </p>
                <p v-if="oldExtensionsData"><strong>Current Extensions</strong><br />
                  Extension Number: {{oldExtensionsData.id}} <br />
                  ID: {{oldExtensionsData.account_id}}
                </p>
                <p v-if="newExtensionsTrunkData"><strong>New Extensions</strong><br />
                  Extension Number: {{newExtensionsTrunkData.extensionDetails.id}} <br />
                  Reference ID: {{newExtensionsTrunkData.account_id}}
                </p>
                <p v-if="deviceData.first_provisioned_at != null">The Device will need to be reprovisioned before any changes will take effect on the Device. This can be achieved by rebooting the Device or provisioning the Device (if not yet provisioned).</p>
              </span>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="3">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <span v-if="deviceData == null">
                <p class="text-h6">All Devices for Extension {{oldExtensionsData.id}} have been switched successfully</p>
              </span>
              <span v-else>
                <p class="text-h6">Device '{{ deviceData.label }}' extension has been switched successfully</p>
              </span>
            </v-col>
            <v-alert type="info" text class="mt-4 mb-0" v-if="deviceData.first_provisioned_at != null">
              Note that this Device needs to be reprovisioned in order for changes to take effect.
              <span v-if="deviceData.last_provisioned_at == null">
                This can be done by provisioning the device.
              </span>
                    <span v-else>
                This can be achieved by rebooting the Device.
              </span>

            </v-alert>
          </v-row>
        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from "../templates/ComplexDialog";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import GlobalFetchStoreMixin from "../../../mixins/GlobalFetchStoreMixin";
import Vue from 'vue';
export default {
  name: 'ChangeVoipDeviceExtensionDialog',
  components: {ComplexDialog},
  data: () => ({
    step: 1,
    busy: false,
    searchExtensions:null,
    basicExtensionList: {},
    basicExtensionListAll: {},
    extensionItems:[],
    newExtensionsTrunkData:null,
    oldExtensionsData:null,
    form: {
      extensionIAccount: null,
    },
    rules: {
      extensionIAccount: [
        (v) => !!v || 'Please select',
      ],
    },
  }),
  props: {
    //if deviceData is null then all devices will be moved.
    deviceData: {
      type: Object,
      default:null,
    },
    //current extension we are moving from.
    extensionId: {
      type: Number,
      require:true,
    },
  },
  mixins: [apiMixin, dialogMixin,refreshListenerMixin,GlobalFetchStoreMixin],
  watch: {
    visible(value) {
      if (value) {
        this.updateBasicExtensionsList(null);
      } else {
        this.reset( );
      }
    },
    searchExtensions(val) {
      if(val != null && val != '' && this.basicExtensionList[val] == null){
        this.updateBasicExtensionsList(val);
      }else{
        this.updateExtensionItem(val);
      }
    },
    extensionId(val){
      this.reset( );
    }
  },
  created( ){
    this.reset( );
  },
  methods: {
    reset( ){
      this.step = 1;
      this.busy = false;
      this.form.extensionIAccount = null;
      this.newExtensionsTrunkData = null;
      this.oldExtensionsData = null;
      this.getOldExtension( );
    },
    updateExtensionItem(val){
      let list = [];
      if(val == null){
        list = this.basicExtensionListAll;
      }else if(this.basicExtensionList[val]){
        list = this.basicExtensionList[val];
      }

      /*this.extensionItems =  Array.from(list).map(entry => {
        let text = entry.extension_id+' '+entry.id;
        if(entry.description != null){
          text += ' - '+entry.description;
        }
        return {"text":text, "value": entry.i_account};
      });*/


      let iAccount = this.oldExtensionsData.i_account;
      this.extensionItems = Array.from(list).reduce(function(result, entry) {
        if(entry.i_account != iAccount){
          let text = entry.extension_id+' '+entry.id;
          if(entry.description != null){
            text += ' - '+entry.description;
          }
          result.push({"text":text, "value": entry.i_account});
        }
        return result;
      }, []);

    },
    async updateBasicExtensionsList (input) {
      let response = null;
      if(input == null){
        response = await this.Api.send('get', 'customers/'+this.deviceData.i_customer+'/accounts/auto-complete');
        this.basicExtensionListAll = response.data;
        this.updateExtensionItem();
      }else{
        response = await this.Api.send('get', 'customers/'+this.deviceData.i_customer+'/accounts/auto-complete?i_account='+input+'&extension_id='+input);
        this.basicExtensionList[input] = response.data;
        this.updateExtensionItem(input);
      }
      //response.data.forEach(element => this.extensionIAccountOptions.push({"value":element.i_account,"option":this.getManufacturerTitle(element.manufacturer)+'/'+element.model}));
    },

    async getOldExtension( )
    {
      this.busy = true;
      let extensionResponse = await this.Api.send('get', 'extensions/'+this.extensionId);
      if (!extensionResponse.success) {
        this.Api.displayError('Failed to find Extension account.');
        this.visible = false;
        return;
      }
      this.oldExtensionsData = extensionResponse.data;
      this.busy = false;
    },

    async next() {
      if (this.step===1) {
        this.busy = true;
        //pull all trunk data to get the extension id
        let trunkResponse = await this.Api.send('get', 'trunks/'+this.form.extensionIAccount);
        if (!trunkResponse.success) {
          this.Api.displayError('Failed to find Extension account.');
          this.visible = false;
          return;
        }
        this.newExtensionsTrunkData = trunkResponse.data;
        this.busy = false;
        this.step++;
        return
      }else if (this.step===2) {
        this.busy = true;
        let response = await this.Api.send('put', 'extensions/'+this.extensionId+'/voip-devices/'+this.deviceData.id+'/switch-extension/'+this.newExtensionsTrunkData.extensionDetails.i_c_ext);
        if (response.success) {
          this.step++;
        }else if(!response.success && response.error){
          this.Api.displayError(response.error+': '+response.errorDetail,'',null);
        }
        this.busy = false;
        //we fresh the source data rather than update the page so we can show the success slide.
        // let redirect = {
        //   name: 'TrunkSummary',
        //   params:{trunkId : this.deviceData.i_account},
        // };
        //this.fetchStoreRequest('voipDevice', 'extensions/'+this.newExtensionsTrunkData.extensionDetails.i_c_ext+'/voip-devices/'+this.deviceData.id, {id:this.deviceData.id}, redirect, 'get', true);
      } else {
        this.step++;
      }
    },
    back() {
      this.step--;
    },
    finish() {
      this.$router.push({ name: 'VoipDevice',  params: {customerId:this.deviceData.i_customer, trunkId: this.newExtensionsTrunkData.i_account, voipDeviceId: this.deviceData.id }});
      this.visible = false;
      location.reload( );
    },
  },

};
</script>
<template>
    <span>
        <span v-if="showPassword">
            {{ value }}
        </span>
        <span v-if="!showPassword"> ************ </span>
        <!--v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" :href="'tel:'+phone" small icon text><v-icon small>mdi-phone</v-icon></v-btn>
            </template>
            <span>Call</span>
        </v-tooltip -->
        <!-- v-tooltip v-if="mobile" bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('sms')" small icon text><v-icon small>mdi-cellphone</v-icon></v-btn>
            </template>
            <span>Send SMS</span>
        </v-tooltip -->
        <ButtonWithTooltip
            v-if="!showPassword"
            icon="mdi-eye"
            tooltip="Reveal password"
            @click="showPassword = true"
            small
        />
        <ButtonWithTooltip
            v-if="showPassword"
            icon="mdi-eye-off"
            tooltip="Hide password"
            @click="showPassword = false"
            small
        />

        <CopyToClipboard :value="value" small password />
    </span>
</template>

<script>
import CopyToClipboard from './CopyToClipboard';
import ButtonWithTooltip from './ButtonWithTooltip';
export default {
    name: 'Password',
    components: { ButtonWithTooltip, CopyToClipboard },
    methods: {
        stripSpaces: function (value) {
            if (value) {
                return value.replace(/\s/g, '');
            } else {
                return '';
            }
        },
        isMobile: function (value) {
            let mobile = new RegExp(/(0|61)?4[0-9]{8}/g);
            return mobile.test(this.stripSpaces(value));
        },
    },
    data() {
        return {
            showPassword: false,
        };
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        mobile: function () {
            return this.isMobile(this.value);
        },
        icon: function () {
            return this.mobile ? 'mdi-cellphone' : 'mdi-phone';
        },
    },
    watch: {
        value: function (value) {
            this.phone = this.stripSpaces(value);
        },
    },
};
</script>

<template>

  <SimpleDialog
      :loading="busy"
      v-model="visible"
      @close="close"
      yes-text="Reset Provisioning"
      @click="openResetProvisioningDialog"
      :yesDisabled="false"
      no-text="Cancel"
      :title="'Reset Device Users'"
      persistent
      styledHeader
  >
    <p>In order to reset the users on a Device the provisioning process must be reset and run again.</p>

  </SimpleDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import SimpleDialog from "../templates/SimpleDialog";
import Vue from 'vue';
export default {
  name: 'ResetVoipDeviceUsersForwardToResetProvisioningDialog',
  components: {SimpleDialog},
  props:['loading'],
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    busy: false,
    visible:true,
  }),

  watch: {

  },
  methods: {
    openResetProvisioningDialog(){
      document.dispatchEvent(new CustomEvent('openDeviceResetProvisioningDialog'));
      this.close( );
    },
    reset( ){
      this.busy = false;
    },
    close( ){
      this.reset( );
      this.visible = false;
    }
  },

};
</script>
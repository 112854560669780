<template>
    <v-slide-group
        v-if="items"
        v-model="selected"
        show-arrows
        center-active
        mandatory
    >
        <v-slide-item
            v-for="item in items"
            :key="item.value"
            :value="item.value"
            v-slot="{ active, toggle }"
        >
            <v-hover
                    v-slot="{ hover }"
            >
                <v-card
                    :elevation="active ? 4 : hover ? 2 : 0"
                    :color="active ? selectedColor : hover ? hoverColor : undefined"
                    class="text-center"
                    :class="active ? 'ma-2 pa-5' : 'ma-3 pa-4'"
                    :width="itemWidth + (active ? 10 : 0)"
                    @click="toggle"
                    outlined
                    :height="itemHeight + (active ? 10 : 0)"
                >
                    <v-row no-gutters align="center" justify="center" class="fill-height">
                        <v-col>
                            <v-icon v-if="item.icon" v-html="item.icon" size="48" />
                            <h2 v-if="item.channels">{{item.channels}} Line<span v-if="item.channels>1">s</span></h2>
                            <h4 v-if="planTypeText && !item.alternate_text">{{planTypeText}}</h4>
                            <p v-if="item.mobile && addOn">+ <b>{{item.mobile | thousandSeparator}}</b> mobile minutes</p>
                            <h1 v-if="item.cost || item.cost===0" class="mt-4 mb-1"><span v-if="addOn">+ </span>${{item.cost | thousandSeparator}}</h1>
                            <p v-if="item.cost || item.cost===0" class="mb-2">per month</p>
                            <p v-if="item.mobile && !addOn" class="mb-0 text--disabled" style="font-size: 14px;">(includes <b>{{item.mobile | thousandSeparator}}</b> mobile minutes)</p>
                            <h3 v-if="item.alternate_text" class="my-4">{{item.alternate_text}}</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-hover>
        </v-slide-item>

    </v-slide-group>
</template>

<script>
    export default {
        name: 'PlanSelectSlider',
        data: () => ({
            selected: null,
        }),
        watch: {
            value(val) {
                if (this.selected!==val) this.selected=val;
            },
            selected(val) {
                if (this.value!==val) this.$emit('input', val);
            }
        },
        props: {
            items: {
                default: null
            },
            value: {
                type: Number,
                default: null
            },
            selectedColor: {
                type: String,
                default: 'accent'
            },
            hoverColor: {
                type: String,
                default: 'background_accent'
            },
            itemHeight: {
                type: Number,
                default: 210
            },
            itemWidth: {
                type: Number,
                default: 150
            },
            addOn: {
                type: Boolean,
                default: false
            },
            planTypeText: {
                type: String,
                default: null
            },
        },
        filters: {
            thousandSeparator: function(value) {
                if (!value) value = 0;
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
        },
        created() {
            if (this.value) this.selected = this.value;
        }
    };
</script>

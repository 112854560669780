<template>
    <BasicCard title="Fax mail settings" :loading="loading || busy" :no-skeleton="!!data.service_features">
      <v-row dense class="text--primary" v-if="!settings.fax_only_mode && !(loading || busy)">
        <v-col cols="12"><em>fax to mail is disabled</em></v-col>
      </v-row>
        <v-row dense class="text--primary" v-if="settings.fax_only_mode && !(loading || busy)">
            <v-col cols="6">Sending faxes to email</v-col>
            <v-col cols="6">
                {{settings.fax_email}}
            </v-col>
            <v-col cols="6">Keep copy in fax inbox </v-col>
            <v-col cols="6">
              <v-icon v-if="settings.fax_email_action == 'copy'" v-html="'mdi-check-circle'" color="success" />
              <v-icon v-else-if="settings.fax_email_action == 'forward'" v-html="'mdi-close-circle'" color="error" />
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip v-if="!settings.fax_only_mode" text="Enable Fax to Mail" icon="mdi-pencil" flat @click="showFaxDialog=true" />
            <ButtonWithTooltip v-if="settings.fax_only_mode" text="Disable Fax to Mail" icon="mdi-pencil" flat @click="disable( )" />
        </template>
      <VoiceFaxMailSettingsDialog v-model="showFaxDialog" :data="data" :loading="loading || busy" />
      <SimpleDialog
          v-model="showDisableSuccess"
          :title="'Success'"
          :loading="loading || busy"
          @click="showDisableSuccess = false"
          okOnly
      >
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="success"
            >mdi-check-circle-outline</v-icon
            >
            <p class="text-h6">Fax to Mail disabled successfully</p>
          </v-col>
        </v-row>
      </SimpleDialog>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import VoiceFaxMailSettingsDialog from "../../dialogs/Trunk/VoiceFaxMailSettingsDialog";
import apiMixin from "../../../mixins/apiMixin";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
export default {
    name: 'TrunkFax2MailCard',
    components: {SimpleDialog, VoiceFaxMailSettingsDialog, ButtonWithTooltip, BasicCard},
    mixins: [apiMixin,trunkSettingsDataMixin,refreshListenerMixin],
    data: () => ({
        showFaxDialog: false,
        settings:{},
        showDisableSuccess: false,
        busy:false,
    }),
    methods:{
      async update( ){
        this.busy = true;
        const response = await this.Api.send('get', 'trunks/' + this.data.i_account + '/fax2mail-settings');
        this.settings = response.data;
        this.busy = false;
      },
      async disable( ){
        this.busy = true;
        const response = await this.Api.send('delete', 'trunks/' + this.data.i_account + '/fax2mail-settings');
        if(response.success){
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showDisableSuccess = true;
          this.update( );
        }
        this.busy = false;
      }
    },
    computed: {
        answer_mode() {
          return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
              ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').effective_flag_value)
              : null;
        },
        timeout() {
          return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
              ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').attributes.find((e) => e.name === 'timeout').effective_values[0])
              : null;
        },
        iAccount( ) {
          return this.data.i_account
        }
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
      loading: {
          type: Boolean,
        default: false
      },
    },
    watch: {
      iAccount(value) {
        if(value != null){
          //for page refresh when already on tab
          this.update( );
        }
      },
    },
  created() {
      if(this.data.i_account) {
        //when switching to the tab
        this.update( );
      }
    },
};
</script>

<style scoped></style>

<template>
    <div>
        <v-card class="pa-2 mb-2" v-if="false">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="Number"
                            :disabled="isBusy"
                            v-model="searchFilters.group_number"
                            @keydown.enter="updateLocalFilter"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isBusy"
                            @click="updateSearch"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCardV2
            :page="searchFilters.page"
            :totalRecords="listData.total_records"
            :totalPages="listData.total_pages"
            :limit="searchFilters.limit"
            :currentPageTotal="(listData.results) ? listData.results.length : 0"
            :loading="isBusy"
            @updatedPagination="updatedPagination"
            :no-skeleton="false"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Number</th>
                      <th class="text-center">Sequence</th>
                      <th class="text-center">Ringing</th>
                    </tr>
                </thead>

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No Ring Groups found
                        </td>
                    </tr>
                    <tr
                      v-for="item in listData.results"
                      :key="item.id"
                      >
                        <td class="text-center">
                            <router-link
                                :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.bc_i_account,
                                    },
                                }"
                                class="font-weight-medium"
                                >{{ item.id }}</router-link
                            >
                        </td>
                      <td class="text-center">
                        {{ringGroupSelects.sequence.find(element => element.value == item.hunt_sequence).option}}
                      </td>
                      <td class="text-center">
                        {{ringGroupSelects.callerId.find(element => element.value == item.hunt_keep_original_cli).option}}
                      </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCardV2>
    </div>
</template>

<script>
import PaginationCardV2 from "../templates/PaginationCardV2";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
export default {
    name: 'ExtensionRingGroupListCard',
    mixins: [apiMixin, refreshListenerMixin,ringGroupDataMixin],
    data: () => ({
        searchFilters: {
            group_number: null,
            page:1,
            limit: 10,
        },
        busy: false,
        listData: {
          total_records:0,
          total_pages:0
        },
        paginationData:[],
    }),
    components: {
        PaginationCardV2,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        trunkData: {
            type: Object,
            requiured:true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.update( );
      },
        async update() {
            this.busy = true;
            let params = this.searchFilters;
            params.i_c_ext = this.trunkData.extensionDetails.i_c_ext;

            for (const [key, value] of Object.entries(params)) {
              if(value == null || value == undefined){
                delete params[key];
              }
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            const response = await this.Api.send('get','customers/'+this.trunkData.i_customer+'/ring-groups?'+queryString, params);

            this.busy = false;
            if (response.success) {
                this.listData = response.data;
                this.paginationData = {
                  data: response.data.results,
                  count: response.data.total_records,
                  pages: response.data.total_pages
                };
                if (this.firstLoad) this.firstLoad = false;
            } else {
                this.listData = [];
            }
        },
    },
};
</script>

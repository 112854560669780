<template>
    <NavMenu
        :hide="hide"
        v-if="$store.state.user.isLoggedIn"
        v-model="show"
        :main-menu="mainMenu"
        @menu="$emit('menu')"
    >
        <v-list class="py-0">
            <v-list-item class="py-2" style="height: 80px;">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold mb-1">
                      <v-expand-x-transition>
                        <v-progress-circular
                            v-if="customerLoading"
                            class="mr-1"
                            :width="3"
                            :size="20"
                            color="primary"
                            indeterminate
                        />
                      </v-expand-x-transition>
                        <span
                            v-html="
                                customerData.name ? customerData.name : 'Loading...'
                            "
                        />
                    </v-list-item-title>
                    <v-expand-transition>
                        <v-list-item-subtitle v-if="customerData.creation_date">
                            Customer since
                                <LocalDateTime
                                    :value="customerData.creation_date"
                                    hide-tooltip
                            />
                        </v-list-item-subtitle>
                    </v-expand-transition>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="$store.state.user.isAdmin" />
            <v-list-item v-if="$store.state.user.isAdmin" style="height: 60px;">
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <v-expand-x-transition>
                          <v-progress-circular
                                v-if="!customerData || customerLoading"
                                class="mr-1"
                                :width="2"
                                :size="16"
                                color="primary"
                                indeterminate
                            />
                        </v-expand-x-transition>
                        <span v-if="!customerData || customerLoading">Loading...</span>
                        <span v-else-if="customerData.i_rep">Managed by partner</span>
                        <span v-else-if="customerData.i_parent">Sub-customer of reseller</span>
                        <span v-else>This customer is unmanaged</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="customerData && (customerData.i_rep || customerData.i_parent)">
                        <div v-if="partnerLoading || resellerLoading">
                            <v-progress-circular
                                class="mr-1"
                                :width="2"
                                :size="14"
                                color="primary"
                                indeterminate
                            />
                            Loading...
                        </div>
                        <router-link
                                v-if="customerData.i_rep && !partnerLoading"
                                class="text--secondary"
                                :to="{
                                name: 'AdminPartnerSummary',
                                params: {
                                    partnerId:
                                        customerData.i_rep,
                                },
                            }"
                        >
                            <a>{{
                                partnerData.companyname || 'Error'
                                }}</a>
                        </router-link>
                        <div
                                v-if="customerData.i_parent && !resellerLoading"
                                class="text--secondary text-decoration-underline"
                        >
                            <a>{{
                                resellerData.name || 'Error'
                                }}</a>
                        </div>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item link :to="{ name: 'CustomerSummary' }">
                <v-list-item-action>
                    <v-icon v-html="'mdi-account-circle'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Summary</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-divider />
          <v-slide-y-transition>
          <v-list-item v-if="customerData.customer_class_tag == 'sip-trunk'" link :to="{ name: 'CustomerTrunks' }">
                <v-list-item-action>
                    <v-icon v-html="mdiPhoneVoip" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>SIP Trunks</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small pill style="cursor: pointer;">{{customerData.trunk_count || '-'}}</v-chip>
                </v-list-item-action>
            </v-list-item>
            </v-slide-y-transition>

          <v-slide-y-transition>
          <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerExtensions' }">
            <v-list-item-action>
              <v-icon v-html="mdiPhoneVoip" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Extensions</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{customerData.extension_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>
            </v-slide-y-transition>
          <v-slide-y-transition>
            <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerRingGroups' }">
              <v-list-item-action>
                <v-icon v-html="mdiPhoneVoip" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ring Groups</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small pill style="cursor: pointer;">{{customerData.ring_group_count || '-'}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>

          <v-slide-y-transition>
            <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerCallQueues' }">
              <v-list-item-action>
                <v-icon>mdi-clipboard-list-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Call Queues</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small pill style="cursor: pointer;">{{customerData.call_queue_count || '-'}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>



          <v-slide-y-transition>
            <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerAutoAttendantAccounts' }">
              <v-list-item-action>
                <v-icon>mdi-badge-account-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Auto Attendant Accounts</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small pill style="cursor: pointer;">{{customerData.auto_attendant_account_count || '-'}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>

            <v-list-item link :to="{ name: 'CustomerDIDs' }">
                <v-list-item-action>
                    <v-icon v-html="mdiNumeric" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>DIDs</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small pill style="cursor: pointer;">{{customerData.did_count || '-'}}</v-chip>
                </v-list-item-action>

            </v-list-item>

          <v-slide-y-transition>
            <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerFaxToMails' }">
              <v-list-item-action>
                <v-icon>mdi-fax</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Fax To Mail</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small pill style="cursor: pointer;">{{customerData.fax_to_mail_count || '-'}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>

          <v-slide-y-transition>
            <v-list-item v-if="customerData.customer_class_tag == 'hosted-extension'" link :to="{ name: 'CustomerExtensionVoipDevices' }">
              <v-list-item-action>
                <v-icon>mdi-deskphone</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Extension Devices</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip small pill style="cursor: pointer;">{{customerData.extension_voip_device_count || '-'}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-slide-y-transition>

          <v-list-item link :to="{ name: 'CustomerCallLogs' }">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Call Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-divider />


          <v-list-item link :to="{ name: 'CustomerContacts' }" v-if="false">
            <v-list-item-action>
              <v-icon v-html="mdiCardAccountDetails" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Contacts</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;">{{customerData.contact_count || '-'}}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
              v-if="$store.state.user.isAdmin"
              disabled
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-account'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item link :to="{ name: 'CustomerBillingSummary' }">
                <v-list-item-action>
                    <v-icon>mdi-currency-usd</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Billing</v-list-item-title>
                </v-list-item-content>
                <v-fade-transition>
                    <v-list-item-action v-if="customerData && customerData.unpaid_invoice_info && customerData.unpaid_invoice_info.total>0">
                        <v-chip v-if="customerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length" small pill style="cursor: pointer;" color="error">{{customerData.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length}} overdue</v-chip>
                        <v-chip v-else small pill style="cursor: pointer;" color="info">{{customerData.unpaid_invoice_info.total}}</v-chip>
                    </v-list-item-action>
                </v-fade-transition>
            </v-list-item>

            <v-divider v-if="$store.state.user.isAdmin" />

            <!-- v-list-item v-if="$store.state.user.isAdmin" link :to="{ name: 'CustomerNotes' }">
                <v-list-item-action>
                    <v-icon>mdi-forum</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Notes</v-list-item-title>
                </v-list-item-content>
                <v-fade-transition>
                    <v-list-item-action v-if="customerData && customerData.flagged_notes">
                        <v-chip v-if="!!customerData.flagged_notes" small pill style="cursor: pointer;" color="info">{{customerData.flagged_notes.length}}</v-chip>
                    </v-list-item-action>
                </v-fade-transition>
            </v-list-item -->

            <v-list-item v-if="$store.state.user.isAdmin" link :to="{ name: 'CustomerHistory' }">
                <v-list-item-action>
                    <v-icon>mdi-history</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>History</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-list-item
              link
              :to="{ name: 'CustomerNBNSearch' }"
          >
            <v-list-item-action>
              <v-icon v-html="'mdi-web'" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>NBN</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small pill style="cursor: pointer;"
                      color="orange"
                      text-color="white"
              >New</v-chip>
            </v-list-item-action>
          </v-list-item>

        </v-list>
        <template v-slot:bottom v-if="!customerLoading">
          <v-list-item link
                       :to="{ name: 'CustomerOperationalStatus' }"
          >
            <v-list-item-action>
              <v-icon>mdi-shield-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Operational Status</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
            <v-list-item tag="a" v-if="$store.state.user.portaone_url" :href="$store.state.user.portaone_url + (trunkId ? 'account/' + trunkId : 'customer/' + customerId)" target="_blank">
                <v-list-item-action>
                    <v-icon>mdi-open-in-new</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Open in Porta Admin</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </NavMenu>
</template>

<script>
import { mdiPhoneVoip, mdiNumeric, mdiCardAccountDetails } from '@mdi/js';
import LocalDateTime from '../../pieces/LocalDateTime';
import NavMenu from '../templates/NavMenu';
import customerDataMixin from '../../../mixins/customerDataMixin';
export default {
    name: 'CustomerMenu',
    mixins: [customerDataMixin],
    components: { NavMenu, LocalDateTime },
    data: () => ({
        show: false,
        mdiPhoneVoip: mdiPhoneVoip,
        mdiNumeric: mdiNumeric,
        mdiCardAccountDetails: mdiCardAccountDetails,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        mainMenu: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        trunkView() {
            return this.$route.matched.some((m) => m.name === 'CustomerTrunk') || this.$route.matched.some((m) => m.name === 'AdminCustomerTrunk');
        },
        trunkId() {
            return parseInt(this.$route.params.trunkId);
        },
        partnerLoading() {
            return this.$store.state.loading.partner;
        },
        partnerData() {
            if (
                this.customerData.i_rep &&
                this.$store.state.data.partner &&
                this.customerData.i_rep === this.$store.state.data.partner.i_rep
            ) {
                return this.$store.state.data.partner;
            }
            return [];
        },
        resellerLoading() {
            return this.$store.state.loading.reseller;
        },
        resellerData() {
            if (
                this.customerData.i_parent &&
                this.$store.state.data.reseller &&
                this.customerData.i_parent === this.$store.state.data.reseller.i_customer
            ) {
                return this.$store.state.data.reseller;
            }
            return [];
        },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    created() {
        this.show = this.value;
    },
};
</script>

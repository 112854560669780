<template>
    <v-row dense v-if="trunkData">
        <v-col cols="12">
            <ExtensionVoipDeviceListCard
                v-if="icext"
                :extensionId="icext"
                :loading="isBusy"
                @update="updateFilter"
                :filter="filter"
                show-filters
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed'" tooltip="Add New Device" icon="mdi-plus" @click="showNewDevice=true" :loading="isBusy" />
      <NewVoipDeviceDialog v-if="icext" v-model="showNewDevice" type="hosted-extension" :icext="icext" :customerId="trunkData.i_customer" />
    </v-row>
</template>

<script>
import ExtensionVoipDeviceListCard from '../../components/cards/Extension/ExtensionVoipDeviceListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import trunkDataMixin from "../../mixins/trunkDataMixin";
import FloatingButton from '@/components/pieces/FloatingButton';
import NewVoipDeviceDialog from "../../components/dialogs/Extension/NewVoipDeviceDialog";
export default {
  name: 'ExtensionVoipDeviceList',
  mixins: [filterMixin, customerDataMixin, trunkDataMixin],
  components: {
    NewVoipDeviceDialog,
    FloatingButton,
    ExtensionVoipDeviceListCard,
  },
  data: () => ({
    filter: {
      limit: 10,
      page: 1,
      id: null,
      active: 'Y'
    },
    showNewDevice: false,
    extensionData: null,
  }),
  methods: {
  },
  computed: {
    customerTypeTag: function () {
      if(this.customerData != undefined){
        return this.customerData.customer_class_tag;
      }
      return undefined;
    },
    isBusy: function( ){
      return this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
    },
    icext: function( ){
      if(this.trunkData.extensionDetails != undefined){
        return this.trunkData.extensionDetails.i_c_ext;
      }else{
        return null
      }
    }
  },
  created(){
    if(this.customerTypeTag != undefined && this.customerTypeTag != 'hosted-extension'){
      this.$router.push({name: 'CustomerSummary'});
    }
  },
  watch: {
    customerTypeTag(value) {
      if (value != undefined && value != 'hosted-extension') {
        this.$router.push({name: 'CustomerSummary'});
      }
    }
  },

};
</script>

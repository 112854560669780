<template>
  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <span class="subtitle-1 text--primary"> Toggle Music on hold </span>
    <v-radio-group v-model="form.toggle" class="mt-0" row>
      <v-radio
          label="Disabled"
          value="false"
          key="0"
      ></v-radio>
      <v-radio
          label="Enabled"
          value="true"
          key="1"
      ></v-radio>
    </v-radio-group>
    <v-slide-y-transition>
      <v-card
          outlined
          class="mt-2"
          v-if="form.toggle == 'true'"
      >
        <v-card-text>
          <div>
            <span>Current File: <strong>{{mohFile}}</strong></span>
            <span><br /><a href="#" @click="downloadFile('intro')">Download current stored file</a></span>
          </div><br />
          <span class="subtitle-1 text--primary"> Audio file</span>
          <v-radio-group v-model="form.fileSource" class="mt-0" column>
            <v-radio v-for="item in mohSelectOptions"
                     :key="item.value"
                :label="item.option"
                :value="item.value"
            ></v-radio>
            <v-radio
                     :key="(mohSelectOptions.length +1)"
                     label="Upload"
                     value="upload"
            ></v-radio>
          </v-radio-group>
          <v-slide-y-transition>
            <v-card-text v-if="form.fileSource == 'upload'">
              <v-text-field
                  v-model="form.audioUploadName"
                  :rules="form.fileSource == 'upload' ? [rules.required] : []"
                  :label="'File label'"
                  outlined
              />
              <div>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-file-input
                        v-model="form.audioUploadFile"
                        show-size
                        :disabled="isBusy"
                        :rules="form.fileSource == 'upload' ? [rules.file,rules.required] : [ ]"
                        :accept="validPromptAudioTypes"
                        label="Audio file (max 3mb)"
                        prepend-icon="mdi-volume-high"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-slide-y-transition>
        </v-card-text>
      </v-card>
      </v-slide-y-transition>
  </v-form>
</template>

<script>
import apiMixin from "../../../mixins/apiMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import SpinnerOverlay from "../Global/SpinnerOverlay";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
export default {
  name: 'CustomerMOHForm',
  mixins: [apiMixin],
  components: {SpinnerOverlay},
  data: () => ({
    linkBase: process.env.VUE_APP_API_BASE || '/api/',
    valid: false,
    busy: false,
    form:{
      toggle:'none',
      fileSource:null,
      audioUploadFile:null,
    },
    rules: {
      required: v => !!v || 'Required.',
      numeric: v => !isNaN(v) || 'Must be whole number',
      file: v => !v || v.size < 3145728 || 'Audio file size should be less than 3 MB.',
    },
    validPromptAudioTypes:".mp3,.wav,audio/mpeg,audio/wav"
  }),
  computed: {
    isBusy( ){
      return this.loading || this.busy;
    },
    mohToggle( ){
      let tmp = null;
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'music_on_hold'){
          tmp = feature.flag_value;
        }
      });
      return tmp;
    },
    iMoh( ){
      let tmp = null;
      this.customerData.service_features.forEach(function (feature) {
        if(feature.name == 'music_on_hold'){
          feature.attributes.forEach(function (item) {
            if(item.name == 'i_moh'){
              tmp = item.effective_values[0];
            }
          });
        }
      });
      return tmp;
    },
    mohFile( ){
      if(!this.iMoh){
        return null
      }
      let tmp = null;
      let self = this;
      this.mohList.forEach(function (file) {
        if(file.i_moh == self.iMoh){
          tmp = file.name;
        }
      });
      return tmp;
    },
    mohSelectOptions( ){
      let tmp = [];
      let self = this;
      this.mohList.forEach(function (file) {
        tmp.push({'option':file.name,'value':file.i_moh});
      });
      return tmp;
    }
  },
  watch: {
    iMoh(value) {
      this.selectedIMoh = value;
    },
    valid(value) {
      if (value) {
        this.$emit('input', this.form);
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    downloadFile(prompt_type)
    {
      throw 'To do';
      const url = this.linkBase + 'auto-attendant-accounts/'+this.aaData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/prompts/'+prompt_type+'/audio-file?session-token=' + this.$store.state.session.token;
      window.location.href = url;
    },
    reset( ){
      this.form = {
        "toggle":this.mohToggle == 'Y' ? 'true' : 'false',
        "audioUploadFile":null,
        "fileSource":parseInt(this.iMoh),
      }
      this.valid = false;
    },
  },
  created() {
    this.reset( );
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
    mohList:{
      type: Array,
      required:true,
    },
    loading: {
      type:Boolean,
      default:false,
    },
  },
};
</script>
<template>
    <v-row dense>
        <v-col cols="12">
            <PageAlert v-if="data.status === 'closed'">
                This {{ data.account_type_label }} has been terminated.
            </PageAlert>
            <PageAlert
                v-else-if="data.assigned_addons && data.assigned_addons.length === 0"
                button
                button-text="Add Plan"
                dismissable
                type="warning"
                @click="dialogs.changePlan=true"
            >
                This {{ data.account_type_label }} has no assigned plan! This {{ data.account_type_label }} will be unable to make/receive calls until a plan is assigned.
            </PageAlert>
            <PageAlert
                v-else-if="!data.first_usage_time && !loading"
                dismissable>
                This is a new {{ data.account_type_label }} - there is no recorded registration or call activity.
            </PageAlert>
            <PageAlert
                v-else-if="data.idle_days > 13"
                dismissable
            >
                This {{ data.account_type_label }} appears to be idle. The last call activity was <LocalDateTime :value="data.last_usage" relative />.
            </PageAlert>
            <router-view />
          <router-view name="toolbarExtension" />
            <ChangeTrunkPlanDialog v-model="dialogs.changePlan" :data="data"/>
        </v-col>
    </v-row>
</template>

<script>
import LocalDateTime from '../../components/pieces/LocalDateTime';
import apiMixin from '../../mixins/apiMixin';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
import PageAlert from '../../components/core/PageAlert';
import ChangeTrunkPlanDialog from '../../components/dialogs/Trunk/ChangeTrunkPlanDialog';
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
export default {
    name: 'TrunkParent',
    mixins: [apiMixin, refreshListenerMixin,GlobalFetchStoreMixin],
    data: () => ({
        reloadDIDList: false,
        dialogs: {
            newDID: false,
            changePlan: false,
        },
    }),
    components: { ChangeTrunkPlanDialog, PageAlert, LocalDateTime },
    computed: {
        data() {
            if (
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account ===
                    parseInt(this.$route.params.trunkId)
            ) {
                return this.$store.state.data.trunk;
            }
            return {};
        },
        iAccount( ) {
          return this.data.i_account;
        },
        loading() {
          return this.customerLoading || this.trunkLoading || this.fetchStoreLoading;
        },
    },
    methods: {
        async get(id, force) {
            if (
                !force &&
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account === parseInt(id)
            ) {
                //this.$store.commit('loading', { name: 'trunk', data: false });
                this.fetchStoreSetLoading('trunk',null);
            } else {
                //this.$store.commit('loading', { name: 'trunk', data: true });
                this.fetchStoreSetLoading('trunk',{'i_account':id});
                const response = await this.Api.send('get','trunks/' + id, false);
                //this.$store.commit('loading', { name: 'trunk', data: false });
              this.fetchStoreSetLoading('trunk',null);
                if (response.success) {
                    if (response.data.i_customer!==parseInt(this.$route.params.customerId)){
                      //sometimes the customer details are not in storage
                      if(this.$store.state.data.customer == undefined){
                        this.$router.push({name: 'CustomerSummary'});
                      }else {
                        if (this.$store.state.data.customercustomer_class_tag == 'sip-trunk') {
                          this.$router.push({name: 'CustomerTrunks'});
                        } else if (this.$store.state.data.customercustomer_class_tag == 'hosted-extension') {
                          this.$router.push({name: 'CustomerExtensions'});
                        } else {
                          this.$router.push({name: 'CustomerTrunks'});
                        }
                      }
                    }

                    this.fetchStoreCommitData('trunk',response.data);
                    /*this.$store.commit('data', {
                        name: 'trunk',
                        data: response.data,
                    });*/
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
            }
        },
        update() {
            this.get(this.$route.params.trunkId, true);
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
  watch:{
      iAccount( ){
        this.update( );
      }
  }
};
</script>

<template>
  <ComplexDialog
      title="Remove Extension from Ring Group"
      v-model="visible"
      :loading="busy"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <p class="subtitle-1 text--primary">
          Please Confirm
        </p>
        <p v-if="data">Are you sure you wish to remove <strong>Extension {{data.id}}</strong> from this Ring Group?</p>
        <p>Any attached settings will be deleted.</p>
      </v-col>
    </v-row>

    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="submit"
          text
      >Confirm Remove</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
  name: 'RemoveRingGroupSettingsDialog',
  mixins: [apiMixin, dialogMixin],
  components: { ComplexDialog},
  data: () => ({
    valid: false,
    form: {
      extensionSettings: null,
    },
    customerRingGroups: [],
    busy: false,
    rules: {
      required: [
        (v) => !!v || 'Required',
      ]
    }
  }),
  methods: {
    async submit() {
      this.busy = true;
      let response = await this.Api.send('delete', 'ring-groups/'+this.trunkData.ringGroupDetails.i_c_group+'/extensions/'+this.data.i_cg_ext);
      if (response.success && response.data.i_cg_ext == this.data.i_cg_ext) {
        this.showGlobalSuccessMessage('Ring Group Extensions removed successfully.');
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.step++;
      }
      this.busy = false;
    },
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    trunkData: {
      type: Object,
      required: true,
    },
  },

};
</script>
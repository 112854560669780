<template>
    <ComplexDialog
        :title="'Reset Device Users'"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="2"
        :step-next-disabled="false"
        :step-end-text="'Close'"
        :step-next-text="'Confirm'"
        :max-width="400"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="subtitle-1 text--primary">
                Are you sure?
              </p>
              <p>Conducting this process will reset the username and password for the user(s) associated with this device.
              The device will need to be reprovisioned in order for these changes to take affect.</p>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="2">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6">Device '{{ deviceData.label }}' user(s) have been reset successfully.</p>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
import Vue from 'vue';
export default {
  name: 'ResetVoipDeviceUsersDialog',
  components: {ComplexDialog},
  data: () => ({
    step: 1,
    busy: false,
  }),

  watch: {
    visible(value) {
      if (!value) {
        this.reset( );
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      }
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.busy = false;
    },
    async next() {
      if (this.step===1) {
        this.busy = true;
        //pull all trunk data to get the extension id
        let response = await this.Api.send('put', 'voip-devices/'+this.deviceData.id+'/users/reset');
        if (!response.success) {
          //this.Api.displayError('Failed to find Extension account.');
          this.visible = false;
          return;
        }
        this.busy = false;
        this.step++;
        return
      }
    },
    back() {
      this.step--;
    },
    finish() {
      this.visible = false;
    },
  },
  props:['deviceData','loading'],
  mixins: [apiMixin, dialogMixin],
};
</script>
<template>
    <div>
        <v-card class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="Auth ID"
                            :disabled="isBusy"
                            v-model="searchFilters.id"
                            @keydown.enter="updateSearch"
                    />
                </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Description"
                    :disabled="isBusy"
                    v-model="searchFilters.note"
                    @keydown.enter="updateSearch"
                />
              </v-col>

                <v-col cols="12" sm="4">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isBusy"
                            @click="updateSearch"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
      <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="searchResults.total_records"
          :totalPages="searchResults.total_pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(searchResults.results) ? searchResults.results.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="!firstLoad"
      >
        <v-simple-table>
          <thead>
          <tr>
            <th>Auth ID</th>
            <th v-if="!small" class="text-left">
              Description
            </th>
            <th v-if="!custId">Customer</th>
            <th v-if="!small" class="text-left">Product</th>
            <th class="text-center">Status</th>
            <th></th>
          </tr>
          </thead>

          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="searchResults.results && searchResults.results.length === 0" key="nodata">
              <td colspan="100%" class="text-center">
                No Extensions found
              </td>
            </tr>
            <tr
                v-for="item in searchResults.results"
                :key="item.id"
                v-bind:class="{
                            /*'red darken-4':
                                $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',*/
                            'grey darken-3':
                                $vuetify.theme.dark && !extensionAvailable(item),
                            'grey lighten-3':
                                !$vuetify.theme.dark && !extensionAvailable(item),
                        }"
            >
              <td>
                <router-link
                    :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.i_account,
                                    },
                                }"
                    class="font-weight-medium"
                >{{ item.id }}</router-link
                >
              </td>
              <td v-if="!small">
                {{ item.note }}
              </td>
              <td v-if="!custId">
                <router-link
                    :to="{
                                    name: 'CustomerSummary',
                                    params: {
                                        customerId: item.i_customer,
                                    },
                                }"
                >{{ item.customer_name || item.i_customer }}</router-link
                >
              </td>
              <td v-if="!small">
                {{ item.product_visible_name }}
              </td>
              <td class="text-center">
                <TrunkStatus :data="item" />
              </td>
              <td class="text-center">
                <v-btn
                    v-if="extensionAvailable(item)"
                    height="40"
                    :loading="isLoading"
                    @click="selectExtension(item)"
                >Select</v-btn>
                <span v-else>Already Assigned</span>
              </td>

            </tr>
          </v-slide-x-transition>
        </v-simple-table>
      </PaginationCardV2>.

    </div>
</template>

<script>
import TrunkStatus from '../../pieces/Trunk/TrunkStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import PaginationCardV2 from "../templates/PaginationCardV2";
export default {
    name: 'RingGroupExtensionSelectListCard',
    mixins: [apiMixin,  refreshListenerMixin],
    data: () => ({
      firstLoad: false,
      isLoading:false,
      currentExtensions:null,
      searchFilters: {
            id: null,
            note:null,
            page:1,
            limit:10,
        },
      searchResults:{
        total_records: 0,
        total_pages:0,
        results:[],
      },
        statusOptions: [
            { option: 'Active', value: 'Y' },
            { option: 'Terminated', value: 'N' },
        ],
    }),
    components: {
      PaginationCardV2,
        TrunkStatus,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        custId: {
            type: Number,
            default: null,
        },
        ringGroupId: {
          type: Number,
          default: null,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
  computed: {
      isBusy( ){
        return this.isLoading;
      }
  },

    methods: {
      extensionAvailable(extension)
      {
        if(this.currentExtensions == null){
          return true;
        }
        let id = extension.i_account;
        if(this.currentExtensions.find(element => element.i_account == id) == null){
          return true;
        }else{
          return false;
        }
      },

      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.getTrunkList( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.getTrunkList( );
      },

      update( )
      {
        this.getTrunkList( );
        this.getExtensionList();
      },

        async getTrunkList() {
          if(this.currentExtensions == null){
            this.getExtensionList();
          }

            this.isLoading = true;
            let params = this.searchFilters;
            params.trunkType = 'hosted-extension';
            params.i_customer = this.custId;
            params.active = 'Y';

            Object.keys(params).forEach(key => {
              if (params[key] == null) {
                delete params[key];
              }
            });

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            const response = await this.Api.send('get','trunks?'+queryString);

            this.isLoading = false;
            if (response.success) {
                this.searchResults.results = response.data.data;
                this.searchResults.total_records = response.data.count;
                this.searchResults.total_pages = response.data.pages;
                if (this.firstLoad) this.firstLoad = false;
            } else {
                this.searchResults.results = [];
            }

        },

      async getExtensionList() {
        this.isLoading = true;
        const response = await this.Api.send('get','ring-groups/'+this.ringGroupId+'/extensions');
        this.isLoading = false;
        this.currentExtensions = response.data.data;
      },


      async  selectExtension(account){
        this.isLoading = true;
        let trunkResponse = await this.Api.send('get', 'trunks/'+account.i_account);
        this.isLoading = false;
        if (!trunkResponse.success) {
          this.Api.displayError('Failed to find Extension account.');
          this.visible = false;
          return null;
        }
        this.$emit('selected', trunkResponse.data);
      }
    },
};
</script>

<template>
    <ComplexDialog
        v-model="visible"
        :title="'Edit Auto Attendant Prompts for account '+ trunkData.id"
    >
      <AutoAttendantPromptForm :loading="isBusy" v-model="form" :aaData="aaData" />
        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >Update</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import AutoAttendantPromptForm from "../../pieces/AutoAttendant/AutoAttendantPromptForm";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
    export default {
        name: 'AutoAttendantEditPromptsDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin],
        components: {AutoAttendantPromptForm,  ComplexDialog },
        props: {
            trunkData: {
                type: Object,
                required: true,
            },
          aaData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,

        }),

      methods:{
          /*settingsChanged(form){
            this.form = form;
          },*/
        async submit() {
          this.busy = true;
          this.Api.setHttpObject({"timeout":60000});

          let response = null;
          let refresh = false;
          let params = new FormData();

          if(this.form.intro_toggle == 'company-greeting') {
            params.append("prompt_toggle", 'Y');
          }else{
            params.append("prompt_toggle", 'N');
          }

          if(this.form.intro_prompt_audio_file){
              params.append("prompt_audio_file", this.form.intro_prompt_audio_file);
          }

          response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu+'/prompts/intro', params);
          if (response.success && response.data.i_menu) {
            this.showGlobalSuccessMessage('Successfully updated Intro Prompt.');
            refresh = true;
          }else{
            this.showGlobalErrorMessage('Failed to update Intro Prompt.');
          }

          params = new FormData();
          if(this.form.menu_toggle == 'custom') {
            params.append("prompt_toggle", 'Y');
          }else{
            params.append("prompt_toggle", 'N');
          }

          if(this.form.menu_prompt_audio_file){
            params.append("prompt_audio_file", this.form.menu_prompt_audio_file);
          }

          response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu+'/prompts/menu', params);
          if (response.success && response.data.i_menu) {
            this.showGlobalSuccessMessage('Successfully updated Menu Prompt.');
            refresh = true;
          }else{
            this.showGlobalErrorMessage('Failed to update Menu Prompt.');
          }

          this.busy = false;
          this.Api.setHttpObject({"timeout":20000});

          if (refresh) {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
          }
        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      }
    };
</script>
<template>
    <v-chip
        small
        :color="color"
        :text-color="color === 'green' || color === 'red' ? 'white' : 'black'"
    >
        {{ text }}
    </v-chip>
</template>

<script>
export default {
    name: 'TrunkStatus',
    props: {
        data: {
            default: [],
        },
    },
    computed: {
        text: function () {
            if (this.data.bill_status === 'C') return 'Terminated';
            if (this.data.blocked === 'Y') return 'Blocked';
            if (this.data.i_account_role === 6) return 'IP Auth';
            return this.data.sip_status === 1 ? 'Registered' : 'Unregistered';
        },
        color: function () {
            if (this.data.bill_status === 'C') return 'grey';
            if (this.data.blocked === 'Y') return 'red';
            if (this.data.i_account_role === 1 && this.data.sip_status === 1)
                return 'green';
            return '';
        },
    },
};
</script>

<template>
    <ComplexDialog
        title="Customer NBN Subscriptions"
        v-model="visible"
        :loading="busy"
        :max-width="($store.state.user.isAdmin) ? 1100 : 600"
    >
      <v-simple-table v-if="nbnSubscriptions!=null">
        <thead>
        <tr>
          <th>Name</th>
          <th>Monthly Fee</th>
          <th>Activation Date</th>
          <th v-if="$store.state.user.isAdmin">i subscription</th>
          <th v-if="$store.state.user.isAdmin">i customer subscription</th>
          <th v-if="$store.state.user.isAdmin">Order ID</th>
          <th v-if="$store.state.user.isAdmin">Order Created</th>
          <th v-if="$store.state.user.isAdmin"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in nbnSubscriptions"
            v-bind:key="item.order_id"
            dense
        >
          <td>{{item.name}}</td>
          <td><Currency :value="item.effective_fee" addGst/></td>
          <td>
            <span v-if="item.activation_date == null">Pending</span>
            <span v-else>{{item.activation_date}}</span>
          </td>
          <td v-if="$store.state.user.isAdmin">{{ item.i_subscription }}</td>
          <td v-if="$store.state.user.isAdmin">{{ item.i_customer_subscription }}</td>
          <td v-if="$store.state.user.isAdmin">{{ item.order_id }}</td>
          <td v-if="$store.state.user.isAdmin">{{ item.order_created_at }}</td>
          <td><ButtonWithTooltip
              v-if="$store.state.user.isAdmin"
              :tooltip="item.complete_order_email_last_sent_at == null ? 'Send customer email with the details of the NBN Plan' : 'Resend email top customer with the details of the NBN Plan'"
              :text="item.complete_order_email_last_sent_at == null ? 'Send' : 'RESEND'"
              icon="mdi-email-outline"
              @click="linkOrder(item);"
          /></td>
        </tr>
        </tbody>
      </v-simple-table>
      <NBNLinkOrderDialog v-model="showLinkOrderDialog" :nbnSubscription="linkingSubscription" :customer-data="customerData" @submitLink="sendNBNEmail"/>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from "../templates/ComplexDialog";
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import Currency from "../../pieces/Currency";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import NBNLinkOrderDialog from "../NBN/NBNLinkOrderDialog";
export default {
    name: 'CustomerNBNSubscriptionListDialog',
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    components: {NBNLinkOrderDialog, Currency, ComplexDialog,ButtonWithTooltip},
    data: () => ({
      busy: false,
      isValid:null,
      showLinkOrderDialog:false,
      linkingSubscription:null,
      form: {
        iDialRule:null
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
        ]
      },
      dialRuleSelectOptions:[],
    }),
  watch: {
    visible(val) {
      if (val) {
        if (this.customerData) {
          this.form.cli = this.cli;
          //this.form.blocked = this.blocked;
          this.form.override_cli = this.override_cli;
          this.cid_type = this.form.override_cli==='Y' ? 2 : 1;
        }
      } else {
        this.form.cli = null;
        this.form.override_cli = null;
      }
    },
    dialRuleOptions(val){
      this.reset( );
    },
  },
  created() {

  },
  computed: {
    blocked() {
      return null;
      // return this.customerData && this.customerData.service_features
      //     ? this.customerData.service_features.find((e) => e.name === 'clir').attributes.find((e) => e.name === 'blocked').effective_values[0]
      //     : null;
    },
  },
    methods: {
      async sendNBNEmail(values){
        this.busy = true;
        let update = false;
        let params = {};
        if(values.i_customer_subscription){
          params.i_customer_subscription = values.i_customer_subscription;
          update = true;
        }
        if(values.alt_email && values.alt_email != null && values.alt_email != ''){
          params.alt_email = values.alt_email;
          update = true;
        }
        let response = await this.Api.send('put', 'customers/'+this.customerData.i_customer+'/nbn-orders/'+values.order_id+'/send-email',params);
        this.busy = false;
        if (response.success) {
          if(update) {
            this.showGlobalSuccessMessage('Order linked and Email sent successfully.');
          }else{
            this.showGlobalSuccessMessage('Email sent successfully.');
          }
          this.$emit('reloadSubscriptions');
        }else{
          let error = 'Failed to query NBN products.';
          if(response.error){
            error += ' '+response.error;
          }
          this.showGlobalErrorMessage(error);
        }
      },
      linkOrder(subscription){
        this.linkingSubscription = subscription;
        this.showLinkOrderDialog = true;
      }
    },
    props: {
      customerData: {
          type: Object,
          default: null
      },
      nbnSubscriptions:{
        type: Array,
        default:null
      }
    }
};
</script>
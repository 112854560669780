<template>
    <div>
        <v-card v-if="showFilters && false" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-text-field
                            hide-details
                            outlined
                            clearable
                            dense
                            label="Ref ID"
                            :disabled="isLoading"
                            v-model="searchFilters.id"
                            @keydown.enter="updateLocalFilter"
                    />
                </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Name"
                    :disabled="isLoading"
                    v-model="searchFilters.name"
                    @keydown.enter="updateLocalFilter"
                />
              </v-col>
                <v-col cols="12" sm="2">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>

      <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="listData.total_records"
          :totalPages="listData.total_pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(listData.results) ? listData.results.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="false"
          :limit-options="[]"
          :hide-paginations-buttons="true"
      >
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Ref ID</th>
                        <th class="text-center">Name</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No Auto Attendants
                        </td>
                    </tr>
                    <tr
                        v-for="item in listData.results"
                        :key="item.i_menu"
                        v-bind:class="{
                            /*'red darken-4':
                                $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',*/
                            'grey darken-3':
                                $vuetify.theme.dark && item.hunt_active === 'N',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.hunt_active === 'N',
                        }"
                    >
                        <td class="text-center">
                            <router-link
                                v-if="item.i_menu"
                                :to="{
                                    name: 'AutoAttendantAccountAutoAttendantSummary',
                                    params: {
                                        customerId: trunkData.i_customer,
                                        accountId: trunkData.i_account,
                                        menuId: item.i_menu,
                                    },
                                }"
                                class="font-weight-medium"
                                >{{ item.i_menu }}</router-link>
                          <span v-else>{{ item.name }}</span>
                        </td>
                      <td class="text-center">
                        {{ item.name }} <v-chip
                          v-if="item.name == 'ROOT'"
                          class="ma-2"
                          color="warning"
                      >
                        Parent
                      </v-chip>
                      </td>
                        <td class="text-center">

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                  :to="{
                                    name: 'AutoAttendantAccountAutoAttendantSummary',
                                    params: {
                                        customerId: trunkData.i_customer,
                                        accountId: trunkData.i_account,
                                        menuId: item.i_menu,
                                    },
                                }"
                                  class="font-weight-medium"
                                  style="text-decoration:none;"
                              >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                >
                                  <span>View</span>
                                </v-btn>
                              </router-link>
                            </template>
                            <span>Open Auto Attendant Page</span>
                          </v-tooltip>

                        </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
      </PaginationCardV2>
    </div>
</template>

<script>
import PaginationCardV2 from "../templates/PaginationCardV2";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import UpdateRingGroupExtensionDialog from "../../dialogs/RingGroups/UpdateRingGroupExtensionDialog";
import UpdateRingGroupSettingsDialog from "../../dialogs/RingGroups/UpdateRingGroupExtensionDialog";
import RemoveRingGroupSettingsDialog from "../../dialogs/RingGroups/RemoveRingGroupExtensionDialog";
export default {
    name: 'AutoAttendantAccountAutoAttendantListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
      searchFilters: {
            id: null,
            name:null,
        },
      listData: {
        total_records:0,
        total_pages:0
      },
    }),
    components: {
      PaginationCardV2,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        trunkData: {
            type: Object,
            required:true,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
      reset() {
        this.localFilter.id = null;
        this.localFilter.null = null;
      },
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        //this.update( ); //need to write this method if we have proper pagination
      },
      /*
      need this method is using pagination
      updateSearch(data){
        this.searchFilters.page = 1;
        this.update( );
      },*/
      setPagination( ){
        this.listData.results = this.trunkData.auto_attendants;
        this.listData.total_records = this.trunkData.auto_attendants.length;
        this.listData.total_pages = 1;
        this.paginationData = {
          data: this.trunkData.auto_attendants,
          count: this.trunkData.auto_attendants.length,
          pages: 1,
        }
      },
      update( ){
        if(this.trunkData.auto_attendants) {
          this.setPagination();
        }//else will be caught via the watcher
      }
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
  created(){
      if(this.trunkData.auto_attendants) {
        this.setPagination();
      }//else will be caught via the watcher
  },
      watch: {
        trunkData() {
          this.setPagination( );
        }
      }
}
</script>

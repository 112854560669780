<template>
  <SimpleDialog
      :title="'Delete Confirm'"
      v-model="visible"
      @click="deleteRecord"
      :loading="busy"
      yesText="Yes, delete"
      noText="No, cancel"
      yesColor="red"
      :yesDisabled="!canDelete['can_delete']"
      styledHeader
  >
    <p>Delete Auto Attendant '{{aaData.name}}'.</p>
    <span  v-if="!canDelete['can_delete']">Delete this Auto Attendant is not possible at this time.</span>
    <v-alert v-if="!canDelete['can_delete']"  type="error" text>{{canDelete['message']}}</v-alert>
    <span v-if="canDelete['can_delete']">Are you sure you wish to delete Auto Attendant '{{aaData.name}}'?</span>


  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from "../../../mixins/apiMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
import SimpleDialog from "../templates/SimpleDialog";
import GlobalFetchStoreMixin from "../../../mixins/GlobalFetchStoreMixin";
    export default {
        name: 'AutoAttendantDeleteDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin,AutoAttendantMixin,GlobalFetchStoreMixin],
        components: {SimpleDialog},
        props: {
            trunkData: {
            type: Object,
            required: true,
          },
          aaData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,
          rules: {
            required: v => !!v || 'Required.',
            numeric: v => !isNaN(v) || 'Must be whole number',
          },
          canDelete:{
            'can_delete':true,
            'message':null
          }
        }),

      methods:{
        reset( ){
          this.checkCanDelete( );
        },
        async deleteRecord() {
          this.busy = true;
          let response = await this.Api.send('delete','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu);
          if (response.success) {
            this.showGlobalSuccessMessage('Successfully deleted Auto Attendant');
            //document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
            /*
              We need to update the trunk cache data. Because the way components a structure directing to the summary page will not update the trunk data and it will show the previous AA list.
              Also we cannot run an update emit here as it will try to update the deleted AA which it will fail as it was deleted.
             */
            let redirect = {
              name: 'CustomerSummary',
              params:{customerID : this.trunkData.i_customer},
            }
            this.fetchStoreRequest('trunk', 'trunks/'+this.trunkData.i_account, {i_account:this.trunkData.i_account}, redirect, 'GET', true, 'Account');
            this.$router.push({ name: 'TrunkSummary',params:{"customerId":this.trunkData.i_customer,"trunkId":this.trunkData.i_account}});
          }
          this.busy = false;
        },

        async checkCanDelete() {
          this.busy = true;
          let response = await this.Api.send('get','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu+'/delete-validation');
          if (response.success) {
            this.canDelete = response.data;
          }
          this.busy = false;
        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      },
      watch: {
        trunkData(value) {
          if(value && this.aaData){
            this.reset();
          }
        },
        aaData(value) {
          if(value && this.trunkData){
            this.reset();
          }
        },
      },
      created() {
        this.reset( );
      }
    };
</script>
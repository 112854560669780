<template>
  <ComplexDialog
      title="Edit Ring Group Settings"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
      <v-text-field
          v-model="form.name"
          label="Name *"
          :counter="41"
          :rules="[ruleCheckRingGroupName]"
          hint="Reference name"
          outlined
          v-if="false"
      />
      <v-select
          outlined
          label="Sequence *"
          v-model="form.sequence"
          :disabled="busy"
          :items="ringGroupSelects.sequence"
          item-text="option"
          item-value="value"
          hint="Ringing strategy"
          :menu-props="{ top: true, offsetY: true }"
          :rules="rules.required"

      />
      <v-select
          outlined
          label="Caller ID *"
          v-model="form.callerId"
          :disabled="busy"
          :items="ringGroupSelects.callerId"
          item-text="option"
          item-value="value"
          hint="How the receiver sees the caller's ID"
          :menu-props="{ top: true, offsetY: true }"
          :rules="rules.required"
      />

        <v-checkbox
            v-model="form.call_pickup_allowed"
            label="Call Pickup Allowed"
            value="Y"
        ></v-checkbox>

    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditRingGroupSettingsDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin,ringGroupDataMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      name:null,
      sequence:null,
      callerId:null,
    },
    customerRingGroups: [],
    busy: false,
    isValid: false,
    rules: {
      required: [
        (v) => !!v || 'Required',
      ]
    }
  }),
  methods: {
    async update() {
      this.busy = true;
      const response = await this.Api.send('put', 'ring-groups/' + this.data.ringGroupDetails.i_c_group, this.form);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },
    ruleCheckRingGroupName(nameIn) {
      let name = nameIn;
      if(nameIn == null) {
        name = this.form.name;
      }

      if (name == null) {
        return 'Required';
      }

      if (name.length < 3) {
        return 'Name must be between at least 3 characters.';
      }

      for(let i = 0; i<this.customerRingGroups.length; i++){
        if(name == this.customerRingGroups[i].name && this.data.ringGroupDetails.i_c_group != this.customerRingGroups[i].i_c_group){
          return 'Ring Group Name \''+name+'\' already used by customer.';
        }
      }
      return true;
    },
    async getCustomerRingGroups( ){
      this.busy = true;
      let response = await this.Api.send('get', 'customers/'+this.data.i_customer+'/ring-groups');
      if (response.success) {
        this.customerRingGroups = response.data;
      }
      this.busy = false;
    },
  },

  props: {
    data: {
      type: Object,
      default: null
    }
  },
  created(){
    this.form.name = this.data.ringGroupDetails.name;
    this.form.sequence = this.data.ringGroupDetails.sequence;
    this.form.callerId = this.data.ringGroupDetails.caller_id;
    this.form.call_pickup_allowed = this.data.ringGroupDetails.call_pickup_allowed;

  },
  watch: {
    visible(value) {
      if (value) {
      this.getCustomerRingGroups();
      }
    },
  },
};
</script>
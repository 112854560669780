<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <InvoiceListCard
                :cust-id="customerData.i_customer"
                :filter="filter"
                :loading="customerLoading"
                @update="updateFilter"
            />
        </v-col>
    </v-row>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import InvoiceListCard from '../../components/cards/Invoice/InvoiceListCard';
export default {
    name: 'CustomerInvoices',
    mixins: [filterMixin, customerDataMixin],
    components: {
        InvoiceListCard

    },
    data: () => ({
        filter: {
            from: null,
            to: null,
            page: 1,
            limit: 10,
        },
    }),
};
</script>

<template>
    <BasicCard
        :loading="loading"
        title="Device Provisioning"
    >
      <p class="title text--primary mt-1" v-if="data.id">Status:
        <span v-if="data.last_provisioned_at">Provisioned</span>
        <span v-else-if="data.manufacturer_contacted_at">Awaiting Provisioning on device</span>
        <span v-else>Failure. Please reset Provisioning</span>
      </p>
      <v-row dense v-if="data.provisioning_pin!=null">
        <v-col cols="5">
          <span class="font-weight-bold">Provisioning Pin</span>
        </v-col>
        <v-col cols="7">
          {{ data.provisioning_pin }}
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="5">
          <span class="font-weight-bold">Provisioned At</span>
        </v-col>
        <v-col cols="7">

          <LocalDateTime v-if="data.last_provisioned_at" :value="data.last_provisioned_at" hide-tooltip />
          <span v-if="!data.last_provisioned_at">N/A</span>
        </v-col>
      </v-row>
      <v-row dense v-if="data.last_config_sent_at!=null">
        <v-col cols="5">
          <span class="font-weight-bold">Config last supplied</span>
        </v-col>
        <v-col cols="7">
          <LocalDateTime v-if="data.last_config_sent_at" :value="data.last_config_sent_at" hide-tooltip />
        </v-col>
      </v-row>

      <v-alert type="warning" text class="mt-2" v-if="data.first_provisioned_at != null && (data.last_config_sent_at == null || (data.config_updated_at != null && data.last_config_sent_at < data.config_updated_at))">
        This Device needs to be reprovisioned in order for changes to take effect on the Device.
        <span v-if="data.last_provisioned_at == null">
          This can be done by provisioning the device.
        </span>
        <span v-else>
          This can be achieved by rebooting the Device.
        </span>
      </v-alert>

        <template v-slot:actions>
          <ButtonWithTooltip
              text="Reset Provisioning"
              flat
              tooltip="Reset Provisioning"
              icon="mdi-reload"
              @click="showResetDialog = true"
          />
        </template>
        <ResetVoipDeviceProvisioningDialog
            v-if="data.id != undefined"
            v-model="showResetDialog"
            :deviceData="data"
            :loading="loading"
        />

    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import LocalDateTime from '../../pieces/LocalDateTime';
import ResetVoipDeviceProvisioningDialog from "../../dialogs/VoipDevice/ResetVoipDeviceProvisioningDialog";
export default {
    name: 'DeviceProvisionCard',
    components: {ResetVoipDeviceProvisioningDialog, LocalDateTime,  ButtonWithTooltip, BasicCard},
    props: ['data', 'loading'],
    data: () => ({
      showResetDialog: false,
      showAdminProvisionDialog:false,
    }),
    mounted() {
      document.addEventListener('openDeviceResetProvisioningDialog', this.refreshHandler);
    },
    beforeDestroy() {
      document.removeEventListener('openDeviceResetProvisioningDialog', this.refreshHandler);
    },
  methods: {
    refreshHandler() {
      this.showResetDialog = true;
    },
  },
};
</script>

<template>
    <div>
        <LoadingMessageForm v-if="!callBarringData" />
        <LargeSelectButtonList
            :items="callBarringData"
            item-value="i_cp_condition"
            item-title="name"
            v-model="rules"
            multiple
            icon
        />
    </div>
</template>

<script>
import LoadingMessageForm from './LoadingMessageForm';
import equalsMixin from '../../../mixins/equalsMixin';
import callBarringDataMixin from '../../../mixins/callBarringDataMixin';
import LargeSelectButtonList from './LargeSelectButtonList';
export default {
    name: 'CallBarringForm',
    components: { LargeSelectButtonList, LoadingMessageForm },
    mixins: [callBarringDataMixin, equalsMixin],
    data: () => ({
        rules: [],
    }),
    watch: {
        rules: {
            deep: true,
            handler(value) {
                this.$emit('input', this.equals(this.data, [...value].sort()) ? null : value);
            }
        }
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        data: {
            default: null
        }
    },
    created() {
        if (this.data) {
            this.rules = [ ...this.data ];
        } else {
            if (this.data===null) {
                this.callBarringData.forEach((e) => {
                    if (e.barred_by_default==='Y') this.rules.push(e.i_cp_condition);
                });
            }
        }

    }
};
</script>
<template>
  <BasicCard
      :loading="isBusy"
      title="Actions"

  >
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-center">User Input</th>
                  <th>Action</th>
                  <th>Additional Info</th>
                  <th class="text-center">Play Prompt</th>
                  <th class="text-center"></th>
                </tr>
            </thead>

            <v-slide-x-transition tag="tbody" hide-on-leave group>
                <tr v-if="list && list.length == 0" key="nodata">
                    <td colspan="100%" class="text-center">
                        No Action/transitions found
                    </td>
                </tr>
                <tr
                  v-for="item in list"
                  :key="item.i_menu_transition"
                  >
                  <td class="text-center">
                    {{formattedEvent(item.event)}}
                  </td>
                  <td>
                    {{actionValueToDisplay(item.action)}}
                    <span v-if="item.action == 'Menu'"></span> <span v-if="item.action == 'Queue'"></span>
                  </td>
                    <td>
                        <span v-if="item.action == 'Directory'">
                          <span v-if="item.announce_ext_numbers == 'Y'">Announce extension numbers.</span>
                          <span v-else>Do not announce extension numbers.</span>
                        </span>
                        <span v-if="item.action == 'Transfer' || item.action == 'TransferE164'">
                          Transfer the call to: <span v-if="item.action == 'TransferE164'">+</span>{{ item.destination }}
                        </span>
                        <span v-if="item.action == 'Extension'">
                          Max {{ item.max_size }} digits for extension number
                        </span>
                        <span v-if="item.action == 'Menu'">
                          Transfer to Auto Attendant '{{item.targetAutoAttendant.name}}'
                        </span>
                        <span v-if="item.action == 'Queue'">
                          Add to Call Queue '{{item.targetCallQueue.ring_group_name}}'
                        </span>

                    </td>
                  <td class="text-center">

                    <span v-if="item.action == 'Disabled'">N/A</span>

                    <v-icon
                        v-if="item.action != 'Disabled' && item.play_prompt == 'Y'"
                        color="success"
                        class="mr-2"
                    >mdi-check</v-icon>

                    <v-icon
                        v-else-if="item.action != 'Disabled'"
                        color="error"
                        class="mr-2"
                    >mdi-cancel</v-icon>

                    <v-tooltip bottom v-if="currentPromptFiles[item.i_menu_transition] && currentPromptFiles[item.i_menu_transition].status == 'IN_PROGRESS'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                        >
                          <v-icon
                              class="mr-2"
                          >mdi mdi-loading mdi-spin</v-icon>
                        </v-btn>
                      </template>
                      <span>Pending codec conversion...</span>
                    </v-tooltip>

                    <v-tooltip bottom v-else-if="(currentPromptFiles[item.i_menu_transition] && currentPromptFiles[item.i_menu_transition].status == 'FINISHED') || item.prompt_set">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="downloadFile(item)"
                        >
                          <v-icon v-html="'mdi-download'" />
                        </v-btn>
                      </template>
                      <span>Download file</span>
                    </v-tooltip>

                    <div v-if="currentPromptFiles[item.i_menu_transition] && currentPromptFiles[item.i_menu_transition].status == 'ERROR'">

                      <br /><span class="font-weight-bold">Last Upload Error</span><br />
                        {{this.currentPromptFile.error_message}} <v-btn
                          @click="clearErrorMessage(item)"
                          x-small
                          v-if="!clearErrorLoadings[item.i_menu_transition]"
                      >
                        clear
                      </v-btn>
                        <v-icon
                            class="mr-2"
                            v-if="clearErrorLoadings[item.i_menu_transition]"
                        >mdi mdi-loading mdi-spin</v-icon>

                    </div>

                  </td>
                  <td class="text-center">
                    <ButtonWithTooltip
                        tooltip="Edit this action"
                        icon="mdi-pencil"
                        color="default"
                        small
                        @click="editingTransition = item; editDialog = true"
                    />
                    <ButtonWithTooltip
                        tooltip="Delete this action"
                        icon="mdi-delete"
                        color="error"
                        small
                        @click="deletingTransition = item; deleteDialog = true"
                    />
                  </td>
                </tr>
            </v-slide-x-transition>
        </v-simple-table>
    <AutoAttendantTransactionEditDialog :updateCallback="update" :loading="isBusy" v-model="editDialog" :trunkData="trunkData" :aaData="aaData" :aatData="editingTransition" :aaTransitions="list"/>
    <SimpleDialog
        v-model="deleteDialog"
        :title="'Delete Action for input ('+(deletingTransition ? deletingTransition.event : '')+')?'"
        @click="deleteTransition(deletingTransition)"
        :loading="busy"
        yesText="Yes, delete"
        noText="No, cancel"
        yesColor="red"
    >
      Are you sure you wish to delete this action?
    </SimpleDialog>
    <template v-slot:actions>
      <ButtonWithTooltip
          :tooltip="(list.count > 13) ? 'All user inputs have Actions. Cannot create new Action' : 'Create new Action'"
          :icon="'mdi-plus'"
          :text="'Add Action'"
          flat
          @click="editingTransition = null; editDialog = true"
          :disabled="list.count > 13"
      />
    </template>
    </BasicCard>
</template>

<script>
import PaginationCardV2 from "../templates/PaginationCardV2";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import BasicCard from "../templates/BasicCard";
import AutoAttendantTransactionEditDialog from "../../dialogs/AutoAttendants/AutoAttendantTransactionEditDialog";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
export default {
    name: 'AutoAttendantActionListCard',
    mixins: [apiMixin, refreshListenerMixin,ringGroupDataMixin,AutoAttendantMixin],
    data: () => ({
      busy: false,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      list:[],
      callQueueSelectOptions:[],
      currentPromptFiles:{},
      pollStatusIntervals:{},
      clearErrorLoadings: {},
      editDialog: false,
      editingTransition: null,
      deleteDialog:false,
      deletingTransition:null,

    }),
    components: {
      SimpleDialog,
      ButtonWithTooltip,
      AutoAttendantTransactionEditDialog,
      BasicCard

    },
    props: {
        trunkData: {
            type: Object,
            requiured:true,
        },
        aaData: {
          type: Object,
          requiured:true,
        },
        loading: {
          type: Boolean,
          default:false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
      autoAttendantSelectOptions( ){
        return this.trunkData.auto_attendants.map(function( n ) {
          return { option: n.name, value: n.i_menu };
        })
      },
    },
  watch: {
    trunkData(value) {
      if(value && this.aaData){
        this.reset();
      }
    },
    aaData(value) {
      if(value && this.trunkData){
        this.reset();
      }
    },
  },
    methods: {
      reset( ){
        this.list = [];
        this.callQueueSelectOptions = [];
        if(this.trunkData && this.trunkData.i_account && this.aaData && this.aaData.i_menu) {
          this.getCallQueues();
          this.update();
        }
      },

      formattedEvent(event){
        for(const element of this.transitionEventSelectOptions){
          if(event == element.value){
            return element.option;
          }
        }
        return 'N/A';
      },

      async refreshTransitionPrompt(transition){
        let response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+transition.i_menu_transition+'/prompts/'+transition.codec_converter_file_last.id);
        if(response.success){
          //this.currentPromptFiles[transition.i_menu_transition] = response.data;
          let updatedPrompts = JSON.parse(JSON.stringify(this.currentPromptFiles))
          updatedPrompts[transition.i_menu_transition] = response.data;
          this.currentPromptFiles = updatedPrompts;
          if(this.currentPromptFiles[transition.i_menu_transition].status != 'IN_PROGRESS'){
            //remove the requesting.
            clearInterval(this.pollStatusIntervals[transition.i_menu_transition]);
          }
        }
      },

      async clearErrorMessage(transition){
        if(!this.currentPromptFiles[transition.i_menu_transition] || !this.currentPromptFiles[transition.i_menu_transition].status == 'ERROR'){
          return; //no valid to delete
        }
        this.clearErrorLoadings[transition.i_menu_transition] = true;
        let response = await this.Api.send('put','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+transition.i_menu_transition+'/clear-conversion-errors');
        if(response.success){
          this.currentPromptFiles[transition.i_menu_transition] = null;
        }
        this.clearErrorLoadings[transition.i_menu_transition] = false;
      },

      async deleteTransition(transition){
        this.busy = true;
        const response = await this.Api.send('delete','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+transition.i_menu_transition);
        if (response.success) {
          this.showGlobalSuccessMessage('Successfully deleted action.');
          this.update( );
        }else{
          this.showGlobalErrorMessage('Failed to delete action.');
        }
      },

      downloadFile(transition)
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+transition.i_menu_transition+'/prompt-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },

        async update() {
          this.busy = true;

          //clear all intervals before a reset
          for(let propertyName in this.pollStatusIntervals) {
            clearInterval(this.pollStatusIntervals[propertyName]);
          }
          const response = await this.Api.send('get','auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions');
            this.busy = false;
            if (response.success) {
              //remove the Not Active action as this is set in general settings.
              let data = response.data.filter(function(element){
                return element.event != 'Not Active';
              });
              for (const element of data) {
                this.currentPromptFiles[element.i_menu_transition] = element.codec_converter_file_last;
                if(element.codec_converter_file_last && element.codec_converter_file_last.id && element.codec_converter_file_last.status == 'IN_PROGRESS'){
                  this.pollStatusIntervals[element.i_menu_transition]= setInterval(() => {

                    this.refreshTransitionPrompt(element);
                  }, 3000);
                }
              }

              //reorder list via event.
              data.sort(function(a, b) {
                return  (a.event > b.event) ? 1 : -1;
              })

              this.list = data;
            } else {
                this.list = [];
              this.currentPromptFiles = [];
            }
        },


      async getCallQueues( ){
        this.busy = true;
        let response = await this.Api.send('get','customers/'+this.trunkData.i_customer+'/call-queues');
        this.busy = false;
        this.callQueueSelectOptions = [];
        if(response.success && response.data.results){
          //create the call queue select
          for(let i=0;i<response.data.results.length; i++){
            this.callQueueSelectOptions.push({ option: response.data.results[i].group_name, value: response.data.results[i].i_c_queue });
          }
          this.callQueueSelectOptions.sort(function( a, b ) {
            if ( a.option.toLowerCase() < b.option.toLowerCase() ){
              return -1;
            }
            if ( a.option.toLowerCase() > b.option.toLowerCase() ){
              return 1;
            }
            return 0;
          });
        }
      }
    },
};
</script>

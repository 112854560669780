const MastercardTypeRegex = /^(?:5[1-5][0-9]|2[2-7]2)/;
const VISATypeRegex = /^4[0-9]/;
export default {
    data: () => ({
        months: [
            {value: "01", text: '01 January'},
            {value: "02", text: '02 February'},
            {value: "03", text: '03 March'},
            {value: "04", text: '04 April'},
            {value: "05", text: '05 May'},
            {value: "06", text: '06 June'},
            {value: "07", text: '07 July'},
            {value: "08", text: '08 August'},
            {value: "09", text: '09 September'},
            {value: "10", text: '10 October'},
            {value: "11", text: '11 November'},
            {value: "12", text: '12 December'}
        ],
    }),
    methods: {
        validate_credit_card(number) {
            // https://gist.github.com/ShirtlessKirk/2134376
            var luhnChk = (function (arr) {
                return function (ccNum) {
                    var len = ccNum.length,
                        bit = 1,
                        sum = 0,
                        val;

                    while (len) {
                        val = parseInt(ccNum.charAt(--len), 10);
                        sum += (bit ^= 1) ? arr[val] : val;
                    }

                    return sum && sum % 10 === 0;
                };
            })([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);
            return !!number && number.length===16 ? luhnChk(number) : false;
        },
        get_card_type(number) {
            if (MastercardTypeRegex.test(number)) return 'mastercard';
            if (VISATypeRegex.test(number)) return 'visa';
            return null;
        }
    },
    computed: {
        minCardYear() {
            return new Date().getFullYear();
        },
        minCardMonth () {
            return new Date().getMonth() + 1;
        },
        years () {
            var currentYear = new Date().getFullYear();
            var data = [];
            var i;
            for (i=0; i < 20; i++) {
                data.push(currentYear + i);
            }
            return data;
        }
    }
};

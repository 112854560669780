<template>
    <BasicCard
        :loading="loading"
        title="Device Users"
    >
      <p v-if="!deviceUserData || deviceUserData.length == 0">No users to display. Note that users are created after provisioning the device.</p>

      <v-row dense>
        <v-col cols="6" v-if="adminUser">
          <p><strong>Admin User</strong></p>
          <v-row dense>
            <v-col cols="5">
              <span class="font-weight-bold">Username</span>
            </v-col>
            <v-col cols="7">
              {{ adminUser.username }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5">
              <span class="font-weight-bold">Password</span>
            </v-col>
            <v-col cols="7">
              <Password :value="adminUser.password" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" v-if="user">
          <p><strong>User</strong></p>
          <v-row dense>
            <v-col cols="5">
              <span class="font-weight-bold">Username</span>
            </v-col>
            <v-col cols="7">
              {{ user.username }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5">
              <span class="font-weight-bold">Password</span>
            </v-col>
            <v-col cols="7">
              <Password :value="user.password" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

        <template v-slot:actions>
          <ButtonWithTooltip
              text="Reset Users"
              flat
              tooltip="Reset Users"
              icon="mdi-sync"
              @click="showUserResetDialog = true"
          />
        </template>

      <ResetVoipDeviceUsersDialog v-model="showUserResetDialog" :loading="loading" :deviceData="data" v-if="false && data.label"/>
      <ResetVoipDeviceUsersForwardToResetProvisioningDialog v-model="showUserResetDialog" :loading="loading" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import ResetVoipDeviceUsersDialog from "../../dialogs/VoipDevice/ResetVoipDeviceUsersDialog";
import ResetVoipDeviceUsersForwardToResetProvisioningDialog
  from "../../dialogs/VoipDevice/ResetVoipDeviceUsersForwardToResetProvisioningDialog";
import Password from "../../pieces/Password";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from "../../../mixins/apiMixin";
import paginationComponentMixin from "../../../mixins/paginationComponentMixin";
export default {
    name: 'DeviceUsersCard',
    mixins: [refreshListenerMixin],
    components: {ResetVoipDeviceUsersDialog,ButtonWithTooltip, BasicCard, Password,ResetVoipDeviceUsersForwardToResetProvisioningDialog },
    props: ['data','loading'],
    data: () => ({
      deviceUserData:null,
      showUserResetDialog: false,
    }),
  watch: {
    data(value) {
      if(value.id && this.deviceUserData == null){
        this.update();
      }
    },
  },
  computed:{
      adminUser( ){
        if(!this.deviceUserData){
          return null;
        }
        return this.deviceUserData.find(o => o.user_type === 'admin');
      },
      user( ){
        if(!this.deviceUserData){
          return null;
        }
        return this.deviceUserData.find(o => o.user_type === 'user');
      }
  },
    methods: {
      async update( ){
        let response = await this.Api.send('get', 'voip-devices/'+this.data.id+'/users');
        if (response.success) {
          this.deviceUserData = response.data;
        }
      }
    }
};
</script>

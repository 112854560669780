<template>
    <v-row dense>
        <v-col cols="12" v-if="customerLoading || busy" class="text-center">
            <BasicCard class="pa-2">
                <v-progress-circular indeterminate /> Loading...
            </BasicCard>
        </v-col>
        <v-col v-for="contact in data.data" :key="contact.contact_id" cols="12" sm="6" lg="4">
            <v-fade-transition>
                <ContactCard :data="contact" />
            </v-fade-transition>
        </v-col>
        <FloatingButton tooltip="New Contact" icon="mdi-plus" @click="showNewContact=true" :loading="customerLoading" />
        <AddContactDialog v-model="showNewContact" />
    </v-row>
</template>

<script>
    import customerDataMixin from '../../mixins/customerDataMixin';
    import ContactCard from '../../components/cards/ContactCard';
    import FloatingButton from '@/components/pieces/FloatingButton';
    import AddContactDialog from '@/components/dialogs/AddContactDialog';
    import apiMixin from '@/mixins/apiMixin';
    import BasicCard from '@/components/cards/templates/BasicCard';
    export default {
        name: 'CustomerContacts',
        components: { BasicCard, AddContactDialog, FloatingButton, ContactCard },
        mixins: [apiMixin, customerDataMixin],
        data: () => ({
            showNewContact: false,
            data: [],
          busy: false
        }),
        watch: {
            customerLoading(value) {
                if (!value && this.customerData.i_customer) this.update();
            }
        },
        methods: {
            async update() {
                this.busy = true;
                const response = await this.Api.send('get','customers/' + this.customerData.i_customer + '/contacts');
                this.data = response.success ? response.data : null;
                this.busy = false;
            },
        },
        created() {
            if (this.customerData.i_customer) this.update();
        }
    };
</script>
export default {
    computed: {
        trunkLoading() {
            if(this.$store.state.loading['trunk']){
                return true;
            }else{
                return false;
            }
            //return this.$store.state.loading.trunk;
        },
        trunkData() {
            if (
                this.$store.state.data.trunk &&
                this.$store.state.data.trunk.i_account ===
                parseInt(this.$route.params.trunkId)
            ) {
                return this.$store.state.data.trunk;
            }
            return {};
        },
    },
};
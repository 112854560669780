<template>
    <div>
      <v-sheet v-if="!!subProductDetails && subProductDetails.value != null" rounded class="pa-4" color="background_accent">
        <v-row no-gutters v-if="subProductDetails.value != 'no-plan'">
          <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
            <p class="text-h6 mb-1">{{subProductDetails.name}}</p>
            <p><span class="text-subtitle-1">{{subProductDetails.cost| currency}} per month </span><span class="text--disabled">including GST</span></p>
            <p>
              <span>Includes outbound calls to 1800 numbers and other numbers hosted with Breeze Connect.</span>
              All other calls are billed at standard rates.
            </p>
            <p v-if="subProductDetails.url">For full details refer to the <a :href="subProductDetails.url" target="_blank">Critical Information Summary</a>.</p>
          </v-col>
          <v-col cols="12" md="6">
            <v-simple-table style="max-width: 600px;" dense v-if="!!subProductDetails">
              <thead>
              <tr>
                <th class="text-left">
                  Calls to
                </th>
                <th class="text-left">
                  Cost
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  Local/national numbers
                </td>
                <td>
                  <span v-if="subProductDetails.local_national_numbers_label">{{subProductDetails.local_national_numbers_label}}</span>
                  <span v-else>9c per call</span>
                </td>
              </tr>
              <tr>
                <td>Australian mobiles</td>
                <td>
                  <span v-if="subProductDetails.mobile_minutes">{{subProductDetails.mobile_minutes | thousandSeparator}} minutes included<br/><span style="font-size:11px;">then 14c per minute + 14c connection fee</span></span>
                  <span v-else>14c per minute<br/><span style="font-size:11px;">plus 14c connection fee</span></span>
                </td>
              </tr>
              <tr>
                <td>13/1300 numbers</td>
                <td>
                  <span v-if="subProductDetails.numbers_13_1300_label">{{ subProductDetails.numbers_13_1300_label }}</span>
                  <span v-else>28c per call</span>
                </td>
              </tr>
              <tr>
                <td>1800 numbers</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Breeze Connect numbers</td>
                <td>Included</td>
              </tr>
              <tr>
                <td>Satellite numbers (014x)</td>
                <td>$3.00 per min</td>
              </tr>
              <tr>
                <td>International calls</td>
                <td>
                  <span>Standard international rates apply<br/><span style="font-size:11px;">minimum charge 60 seconds</span></span>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="subProductDetails.value == 'no-plan'">
          <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;">
            <p class="text-h6 mb-1">{{subProductDetails.name}}</p>
            <p><span class="text-subtitle-1">{{subProductDetails.cost| currency}} per month </span><span class="text--disabled">including GST</span></p>
            <p v-if="subProductDetails.url">For full details refer to the <a :href="subProductDetails.url" target="_blank">Critical Information Summary</a>.</p>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
          color="background_accent"
          rounded
          class="pa-4 mt-4 text-right"
          v-if="!!subProductDetails"
      >
        <span class="text-h5 text--disabled">Monthly Plan Fees </span><span class="text-h3">{{subProductDetails.cost + (!!productOptionDetails ? productOptionDetails.cost : 0) | currency}} </span>
      </v-sheet>

        <v-sheet v-if="(!subProductDetails || subProductDetails.value == null) || subProductDetails.value == 'no-plan'" rounded class="pa-4 mb-4" color="background_accent">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-4" style="font-size: 13px;" v-if="!subProductDetails">
              <p class="text-h6 mb-1">No plan selected</p>
              <p><span class="text-subtitle-1">$0.00 per month </span></p>
            </v-col>
            <v-col cols="12" md="6" v-if="!subProductDetails">
              <v-simple-table style="max-width: 600px;" dense>
                <thead>
                <tr>
                  <th class="text-left">
                    Calls to
                  </th>
                  <th class="text-left">
                    Cost
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    All destinations
                  </td>
                  <td>Not available</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12">
                <strong class="font-weight-bold red--text">Auto Attendant will not function until a plan is selected.</strong>
            </v-col>
          </v-row>
        </v-sheet>
    </div>
</template>

<script>
import planDataMixin from '../../../mixins/planDataMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'AutoAttendantPlanInfoSummary',
    mixins: [planDataMixin],
    data: () => ({
        subProductDetails:null,
        productOptionDetails:null,
        type: null
    }),
    props: {
        data: {
            type: Object,
            default: null,
        }
    },
    methods: {

    },
    created() {
        if (this.data && this.data.subProduct){
          this.subProductDetails = this.autoattendantPlanData.types.find(f => f.value == this.data.subProduct);
          /*if (this.subProductDetails) {
            this.productOptionDetails = this.subProductDetails.options.find(f => f.value == this.data.productOption);
          }*/
        }

    }
};
</script>
<style scoped>
    table > tbody > tr:hover {
        background: transparent !important;
    }
    table > tbody > tr > td {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
    table > thead > tr > th {
        font-size: 12px !important;
        padding: 5px 10px 5px 10px !important;
    }
</style>
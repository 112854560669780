<template>
    <ComplexDialog
        v-model="visible"
        title="Edit Call Barring Rules"
        :loading="busy"
    >
        <p>Please choose which call barring rules to apply to the service.<span v-if="data===null"> The default rules have been selected.</span></p>
        <CallBarringForm v-if="visible" v-model="barring" :data="data" />
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="!barring || busy"
                text
                @click="submit"
                :loading="busy"
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import ComplexDialog from '../templates/ComplexDialog';
import CallBarringForm from '../../pieces/Forms/CallBarringForm';
import apiMixin from '../../../mixins/apiMixin';
import callBarringDataMixin from '../../../mixins/callBarringDataMixin';
export default {
    name: 'ChangeTrunkCallBarringDialog',
    components: { CallBarringForm, ComplexDialog },
    mixins: [apiMixin, callBarringDataMixin, dialogMixin],
    data: () => ({
        barring: null,
        busy: false
    }),
    methods: {
        async submit() {
            this.busy = true;
            const response = await this.Api.send('post','trunks/' + this.i_account + '/update_barring', { values: this.barring });
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.$emit('input', false);
            }
            this.busy = false;
        }
    },
    watch: {
        visible(value) {
            if (this.visible!==value) this.visible = value;
            this.barring = null;
        },
    },
    props: {
        data: {
            default: null
        },
        i_account: {
            type: Number,
            default: null,
        }
    }
};
</script>

<style scoped>

</style>
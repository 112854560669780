<template>
    <ComplexDialog
        v-model="visible"
        :title="'Edit Auto Attendant Input Errors for account '+ trunkData.id"
    >
      <AutoAttendantInputErrorForm :loading="isBusy" v-model="form" :aaData="aaData"/>
        <template v-slot:actions>
            <v-spacer />

            <v-btn
                @click="visible=false"
                text
            >Cancel</v-btn>
          <v-btn
              @click="submit( )"
              :disabled="form == null"
              text
              color="primary"
          >Update</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import AutoAttendantGeneralSettingsForm from "../../pieces/AutoAttendant/AutoAttendantGeneralSettingsForm";
import apiMixin from "../../../mixins/apiMixin";
import AutoAttendantPromptForm from "../../pieces/AutoAttendant/AutoAttendantPromptForm";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AutoAttendantInputErrorForm from "../../pieces/AutoAttendant/AutoAttendantInputErrorForm";
    export default {
        name: 'AutoAttendantEditInputErrorsDialog',
        mixins: [dialogMixin, apiMixin, GlobalHelperMixin],
        components: {AutoAttendantInputErrorForm, ComplexDialog },
        props: {
            trunkData: {
                type: Object,
                required: true,
            },
          aaData: {
            type: Object,
            required: true,
          },
          loading: {
            type:Boolean,
            default:false,
          },
        },
        data: () => ({
          valid: false,
          busy: false,
          form:null,

        }),

      methods:{
        async submit() {
          this.busy = true;
          this.Api.setHttpObject({"timeout":60000});

          let response = null;
          let refresh = false;
          let params = new FormData();

          if(this.form.timeout_toggle == 'custom') {
            params.append("prompt_toggle", 'Y');
          }else{
            params.append("prompt_toggle", 'N');
          }

          if(this.form.timeout_prompt_audio_file){
              params.append("prompt_audio_file", this.form.timeout_prompt_audio_file);
          }

          response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu+'/prompts/timeout', params);
          if (response.success && response.data.i_menu) {
            this.showGlobalSuccessMessage('Successfully updated On Timeout Prompt.');
            refresh = true;
          }else{
            this.showGlobalErrorMessage('Failed to update On Timeout Prompt.');
          }

          params = new FormData();
          if(this.form.disabled_toggle == 'custom') {
            params.append("prompt_toggle", 'Y');
          }else{
            params.append("prompt_toggle", 'N');
          }

          if(this.form.disabled_prompt_audio_file){
            params.append("prompt_audio_file", this.form.disabled_prompt_audio_file);
          }

          params.append("replay_menu_times", this.form.replay_menu_times);

          response = await this.Api.send('post','auto-attendant-accounts/' + this.trunkData.i_account + '/auto-attendants/'+this.aaData.i_menu+'/prompts/disabled', params);
          if (response.success && response.data.i_menu) {
            this.showGlobalSuccessMessage('Successfully updated On Unavailable Prompt.');
            refresh = true;
          }else{
            this.showGlobalErrorMessage('Failed to update On Unavailable Prompt.');
          }

          this.busy = false;
          this.Api.setHttpObject({"timeout":20000});

          if (refresh) {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.visible = false;
          }
        }
      },

      computed:{
        isBusy( ){
          return this.loading || this.busy;
        }
      }
    };
</script>
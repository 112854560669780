<template>
    <v-row no-gutters align="center" style="height: 50px;">
        <v-col class="text-center">
            <v-progress-circular
                class="mr-2"
                :width="3"
                :size="20"
                color="primary"
                indeterminate
            />Loading...
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'LoadingMessageForm'
};
</script>

<template>
  <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <v-row no-gutters v-if="this.event != 'Not Active'">
      <v-col cols="12">
        <v-select
            v-model="form.event"
            :rules="[rules.required]"
            :items="eventSelectOptionsFormatted"
            :label="'Event'"
            item-text="option"
            item-value="value"
            outlined
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-select
            v-model="form.action"
            :rules="[rules.required]"
            :items="actionTypeSelectOptions"
            :label="'Inactive Action'"
            item-text="option"
            item-value="value"
            outlined
        />
      </v-col>
    </v-row>
    <div v-if="form.action == 'Directory'">
      <v-row no-gutters v-if="showFileInputToggle">
        <v-col cols="12">
          <v-checkbox
              class="mt-0"
              v-model="form.announce_ext_numbers"
              :label="'Announce extension number'"
              outlined
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>

    <div v-if="form.action == 'Transfer'">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
              v-model="form.destination"
              :rules="form.action == 'Transfer' ? [rules.required,rules.numeric] : []"
              :label="'Transfer caller to phone Number/extension'"
              outlined
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="form.action == 'TransferE164'">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
              v-model="form.destination"
              :rules="form.action == 'TransferE164' ? [rules.required,rules.numeric] : []"
              :label="'Transfer caller to phone Number/extension'"
              outlined
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="form.action == 'Extension'">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
              v-model="form.max_size"
              :rules="form.action == 'Extension' ? [rules.required,rules.numeric, rules.maxSize] : []"
              :label="'Max digits for Extension number'"
              outlined
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="form.action == 'Menu'">
      <v-row no-gutters>
        <v-col cols="12">
          <v-select
              v-model="form.target_i_menu"
              :items="autoAttendantSelectOptions"
              :label="'Auto Attendant'"
              item-text="option"
              item-value="value"
              outlined
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="form.action == 'Queue'">
      <v-row no-gutters>
        <v-col cols="12">
          <v-select
              v-model="form.target_i_queue"
              :items="callQueueSelectOptions"
              :label="'Call Queue'"
              item-text="option"
              item-value="value"
              outlined
          />
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters v-if="showFileInputToggle">
      <v-col cols="12">
        <v-checkbox
            class="mt-0"
            v-model="form.play_prompt"
            :label="play_prompt_label"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-slide-y-transition>
      <v-card
          outlined
          v-if="showFileInput && showFileInputToggle"
          class="mt-2"
      ><v-card-text>
        <div>Prompt audio file
          <span v-if="currentPromptFileName"><br />Current File: <strong>{{currentMohFileName}}</strong></span>
          <span v-if="aatData && aatData.prompt_set && aatData.codec_converter_file_last && aatData.codec_converter_file_last.status == 'FINISHED'"><br /><a href="#" @click="downloadFile()">Download current stored file</a></span>
          <br /></div>
        <v-row no-gutters>
          <v-col cols="12">
            <v-file-input
                v-model="form.prompt_audio_file"
                show-size
                :disabled="isBusy"
                :rules="[ruleCheckFile]"
                :accept="validPromptAudioTypes"
                label="Upload Music File (max 3mb)"
                prepend-icon="mdi-volume-high"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text></v-card>
    </v-slide-y-transition>
  </v-form>
</template>

<script>
import SpinnerOverlay from "../Global/SpinnerOverlay";
import apiMixin from '../../../mixins/apiMixin';
import AutoAttendantMixin from "../../../mixins/AutoAttendant/AutoAttendantMixin";
export default {
    name: 'AutoAttendantActionForm',
    components: {SpinnerOverlay},
    mixins: [apiMixin,AutoAttendantMixin],
    data: () => ({
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      valid: false,
      busy: false,
      form:{
        action: 'Disabled',
        play_prompt:false,
        prompt_audio_file: null,
        announce_ext_numbers:false,
        destination:null,
        max_size:3,
        target_i_menu:0, //zero is what porta uses for null
        target_i_queue:0,
      },
      currentPromptFileName: null,


      rules: {
        required: v => !!v || 'Required.',
        requiredNumeric: v => ((v != '' && v != null) || v === 0) || 'Required.', //allows passing a 0 int
        numeric: v => !isNaN(v) || 'Must be whole number',
        activeTog: v => (v == 'active' || v == 'inactive') || 'Please select',
        maxSize: v => (v>0 && v<33) || 'Max size must be a positive value no greater than 32.',
      },
      callQueueSelectOptions: [],
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
        isBusy( ){
          return this.loading || this.busy;
        },
      /*
      A null return means that the file input is not applicable.
       */
        play_prompt_label( ){
          let type = this.form.action;
          if(type == 'Directory'){
            return 'Directory Intro Prompt';
          }else if(type != 'Disabled'){
            return 'Play Before Action';
          }

          return null;
        },
        showFileInput( )
        {
          if(this.form.play_prompt){
            return true;
          }
          return false;
        },

        showFileInputToggle( ){
          return this.play_prompt_label != null;
        },

        autoAttendantSelectOptions( ){
          return this.trunkData.auto_attendants.map(function( n ) {
            return { option: n.name, value: n.i_menu };
          })
        },
        eventSelectOptionsFormatted( ){
          let options = this.transitionEventSelectOptions;
          //we need to remove events that are already in use
          if(this.aaTransitions == null){
            //error.
            alert('Auto Attendant Transitions are missing during edit process.');
            return [];
          }

          for (const aaTransition of this.aaTransitions) {
            for(let i = 0; i<options.length; i++){
              if(aaTransition.event == options[i].value && (this.aatData == null || options[i].value != this.aatData.event)){
                options.splice(i, 1);
                break;
              }
            }
          }
          return options;
        }
    },
    watch: {
      valid(value) {
        if(value) {
          this.emitForm();
        }else{
          this.$emit('input', null);
        }
      },
      trunkData(value) {
            this.reset();
        },
      aaData(value) {
          this.reset();
      },
      form: {
        handler(value)
        {
          if(this.valid){
            this.emitForm( );
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {

      downloadFile( )
      {
        const url = this.linkBase + 'auto-attendant-accounts/'+this.trunkData.i_account+'/auto-attendants/'+this.aaData.i_menu+'/auto-attendant-transitions/'+this.aatData.i_menu_transition+'/prompt-file?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },

      emitForm( ){
        this.$emit('input', this.form);
      },

      ruleCheckFile(valueIn){
        let value = valueIn;
        if(valueIn == null) {
          value = this.form.prompt_audio;
        }
        if(this.form.play_prompt !== true){
          return true;
        }

        if(this.aatData && this.aatData.prompt_set){
          //we already have a file set so do not need to force upload
          return true;
        }

        if(value == null){
          return 'Audio file must be supplied.';
        }
        return true;
      },

      reset( ){
        this.form = {
          event:null,
          action: 'Disabled',
          play_prompt:false,
          prompt_audio: null,
          announce_ext_numbers:false,
          destination:null,
          max_size:3,
          target_i_menu:0, //porta uses zero as null
          target_i_queue:0
        }

        if(this.aatData){
          this.form.event = this.aatData.event;
          //load the ui
          if(this.aatData.play_prompt == true || this.aatData.play_prompt == 'Y' || this.aatData.play_prompt == 1){
            this.form.play_prompt = true;
          }
          this.form.action = this.aatData.action;
          if(this.aatData.action == 'Directory'){
            if(this.aatData.announce_ext_numbers == 'Y'){
              this.form.announce_ext_numbers = true;
            }else{
              this.form.announce_ext_numbers = false;
            }
          }
          this.form.destination = this.aatData.destination;
          this.form.max_size = this.aatData.max_size;
          this.form.target_i_menu = this.aatData.target_i_menu;
          this.form.target_i_queue = this.aatData.target_i_queue;
        }

        this.getCallQueues( );
      },

      async getCallQueues( ){
        this.busy = true;
        let response = await this.Api.send('get','customers/'+this.trunkData.i_customer+'/call-queues');
        this.busy = false;
        this.callQueueSelectOptions = [];
        if(response.success && response.data.results){
          //create the call queue select
          for(let i=0;i<response.data.results.length; i++){
            this.callQueueSelectOptions.push({ option: response.data.results[i].group_name, value: response.data.results[i].i_c_queue });
          }
          this.callQueueSelectOptions.sort(function( a, b ) {
            if ( a.option.toLowerCase() < b.option.toLowerCase() ){
              return -1;
            }
            if ( a.option.toLowerCase() > b.option.toLowerCase() ){
              return 1;
            }
            return 0;
          });
        }
      }
    },
  created( ){
    this.reset( );
  },
    props: {
      trunkData: {
        type: Object,
        required:true,
      },
      aaData:{
        type: Object,
        default: function (){return {}}
      },
      aatData:{
        type: Object,
        default: function (){return {}}
      },
      aaTransitions:{
        type: Array,
        default: null,
      },
      loading: {
        type:Boolean,
        default:false,
      },
      //we need this to help detect the action drop down.
      event:{
        type: String,
        default:null,
      }
    }
};
</script>
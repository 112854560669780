<template>
    <div>
        <v-row no-gutters>
            <v-col
                v-for="(item, key) in planData.types"
                :key="key" :cols="$vuetify.breakpoint.xsOnly ? 12 : undefined"
                v-show="!item.nfr || showNfr"
            >
                <v-hover
                    v-slot="{ hover }"
                >
                    <v-card
                        v-ripple
                        outlined
                        :elevation="baseProduct===item.value ? 4 : hover ? 2 : 0"
                        :color="baseProduct===item.value ? highlightColor : hover ? 'background_accent' : undefined"
                        :height="($vuetify.breakpoint.xsOnly ? 90 : 150) + (baseProduct===item.value ? 10 : 0)"
                        class="px-2 text-center"
                        :class="baseProduct===item.value ? 'ma-2 py-5' : 'ma-3 py-4'"
                        @click="changeBaseProduct(item.value)"
                    >
                        <v-icon v-html="item.icon" color="secondary" size="48" :class="$vuetify.breakpoint.xsOnly ? 'float-left ma-2' : 'mb-2'" />
                        <h3 class="mb-0">{{item.name}}</h3>
                        <p class="text--disabled subtitle-2">{{item.description}}</p>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-expand-transition>
            <PlanSelectSlider
                v-if="baseProduct===paygIProduct"
                v-model="mainProduct"
                :items="this.planData.plans.filter(e => e.type === paygIProduct)"
                :plan-type-text="this.planData.plans.filter(e => e.type === paygIProduct).name"
            />
        </v-expand-transition>
        <v-expand-transition>
            <PlanSelectSlider
                v-if="baseProduct===120"
                v-model="mainProduct"
                :items="this.planData.plans.filter(e => e.type === 120 && !e.addOn)"
                :plan-type-text="this.planData.plans.filter(e => e.type === 120 && !e.addOn).name"
            />
        </v-expand-transition>
        <v-expand-transition>
            <PlanSelectSlider
                v-if="baseProduct===120 && mainProduct && false"
                v-model="addonProduct"
                :items="this.planData.plans.filter(e => e.type === 120 && e.addOn)"
                add-on
            />
        </v-expand-transition>
        <v-expand-transition>
            <v-row no-gutters v-if="baseProduct===11">
                <v-col
                    v-for="item in planData.plans.filter(e => e.type === 11)"
                    :key="item.value"
                >
                    <v-hover
                            v-slot="{ hover }"
                    >
                        <v-card
                            :elevation="mainProduct===item.value ? 4 : hover ? 2 : 0"
                            :color="mainProduct===item.value ? highlightColor : hover ? 'background_accent' : undefined"
                            class="text-center"
                            :height="mainProduct===item.value ? 220 : 210"
                            :class="mainProduct===item.value ? 'pa-5 ma-2' : 'pa-4 ma-3'"
                            @click="mainProduct=item.value"
                            outlined
                        >
                            <v-row no-gutters align="center" justify="center" class="fill-height">
                                <v-col v-if="item.alternate_text">
                                    <h3 v-if="item.alternate_text" class="my-4">{{item.alternate_text}}</h3>
                                </v-col>
                                <div v-else>
                                    <h2>{{item.channels}} Line<span v-if="item.channels>1">s</span></h2>
                                    <h3 class="mb-6">NFR</h3>
                                    <h1 class="mb-1">${{item.cost}}</h1>
                                    <p>per month</p>
                                </div>
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-expand-transition>
    </div>
</template>

<script>
import PlanSelectSlider from '../Forms/PlanSelectSlider';
import planDataMixin from '../../../mixins/planDataMixin';
export default {
    name: 'TrunkPlanForm',
    mixins: [planDataMixin],
    components: { PlanSelectSlider },
    data: () => ({
        baseProduct: null,
        originalBaseProduct: null,
        mainProduct: null,
        originalMainProduct: null,
        addonProduct: null,
        originalAddonProduct: null,
    }),
    computed: {
        highlightColor() {
            return 'accent';
        },
        fees() {
            let fees = 0;
            if (this.mainProduct || this.mainProduct===0) {
                let product = this.planData.plans.find(e => e.value === this.mainProduct);
                fees = fees + (product ? product.cost : 0);
                if (product && product.type===120 && this.addonProduct) {
                    let addon = this.planData.plans.find(e => e.value === this.addonProduct);
                    fees = fees + (addon ? addon.cost : 0);
                }
            }
            return fees;
        },
        dirty() {
            return this.baseProduct===this.originalBaseProduct && (this.mainProduct ? this.mainProduct : null)===this.originalMainProduct && (this.addonProduct ? this.addonProduct : null)===this.originalAddonProduct;
        },
        paygIProduct( ){
          if(process.env.VUE_APP_PAYG_I_PRODUCT == null) return 88;
          return parseInt(process.env.VUE_APP_PAYG_I_PRODUCT);
        }
    },
    watch: {
        mainProduct(value) {
            this.update();
        },
        addonProduct(value) {
            this.update();
        }
    },
    methods: {
        findPlan(item) {
            let plan = this.planData.plans.find(f => f.value===item.i_product);
            if (plan) {
                if (!plan.addOn) {
                    this.baseProduct = plan.type;
                    this.originalBaseProduct = plan.type;
                    this.mainProduct = plan.value;
                    this.originalMainProduct = plan.value;
                } else {
                    this.addonProduct = plan.value;
                    this.originalAddonProduct = plan.value;
                }
            }
        },
        changeBaseProduct(value) {
            this.baseProduct = value;
            this.mainProduct = this.originalBaseProduct===value ? this.originalMainProduct : null;
            this.addonProduct = this.originalBaseProduct===value ? this.originalAddonProduct : null;
        },
        update() {
            if (this.dirty) {
                this.$emit('input', null);
            } else {
                this.$emit('input', {
                    i_product: this.baseProduct,
                    assigned_addons: this.mainProduct ? (this.addonProduct ? [{i_product: this.mainProduct},{i_product: this.addonProduct}] : [{i_product: this.mainProduct}]) : null
                });
            }

        }
    },
    filters: {
        thousandSeparator: function(value) {
            if (!value) value = 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    created() {
        this.baseProduct = this.i_product;
        this.originalBaseProduct = this.i_product;
        if (this.assigned_addons) {
            this.assigned_addons.forEach(item => this.findPlan(item));
        }
    },
    props: {
        i_product: {
            type: Number,
            default: null,
        },
        assigned_addons: {
            type: Array,
            default: null
        },
        showNfr: {
            type: Boolean,
            default: false
        }
    }
};
</script>
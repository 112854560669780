<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="input"
          ref="time"
          :label="label"
          @focusout="dirty = true"
          @input="dirty = true"
          append-icon="mdi-clock-outline"
          outlined
          :dense="dense"
          :disabled="disabled || loading"
          :clearable="clearable"
          :hide-details="hideDetails"
          :hint="hint"
          :persistent-hint="persistentHint"
          v-bind="attrs"
          v-on="on"
          @click:append="menu = !menu"
          @click:clear="clear"
          :loading="loading"
          :rules="rules"
          :error="error"
          :errorMessages="errorMessages"
      />
    </template>
    <v-sheet>
      <v-time-picker
          v-model="input"
          ref="timepicker"
          v-if="!loading && menu"
          format="24hr"
          scrollable
          @click="$refs.menu.save(input)"
          header-color="secondary"
      >

        <v-row dense>
          <v-col cols="6">
            <v-btn v-if="clearable" text block @click="clear">Clear</v-btn>
          </v-col>
          <v-col :cols="clearable ? 6 : 12">
            <v-btn text block color="info" @click="menu=false">OK</v-btn>
          </v-col>
        </v-row>
      </v-time-picker>
    </v-sheet>
  </v-menu>
</template>

<script>
const TimeRegex = /^([0-1]\d|2[0-3])(?::([0-5]\d)(?::([0-5]\d))?)$/i;
export default {
  name: 'TimePicker',
  data: () => ({
    input: null,
    time: null,
    menu: false,
    rules: [
      (v) =>
          !v ||
          TimeRegex.test(v) ||
          'Enter time in HH:MM (24-hour)',
    ],
  }),
  methods: {
    clear() {
      this.input = null;
    },
    change(val) {
      this.input = val;
    }
  },
  watch: {
    input(val) {
      if (this.value!==val) this.$emit('input', val);
    },
    value(val) {
      if (this.input!==val) this.input = val;
    }
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Time',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error:{
      type: Boolean,
      default: false,
    },
    errorMessages:{
      type: Array,
      default: function( ){
        return [];
      }
    }
  },
};
</script>
<template>
    <ComplexDialog
        v-model="visible"
        title="Update Address"
        :loading="busy"
        :maxWidth="1000"
        noText="Cancel"
        yesText="Change"
        @click="changeAddress( )"
    >
      <ExistingAddressDetailsForm
          v-model="address"
          v-if="visible"
          show-search
          :customerId="customerId"
          :addresses="storedAddresses"
      />
      <template v-slot:actions>
        <v-spacer/>
        <v-btn @click="visible=false" text>Cancel</v-btn>
        <v-btn @click="deleteConfirm=true" :disabled="(address == null)" text>Delete</v-btn>
        <v-btn @click="changeAddress( )" :disabled="(address == null)" text color="primary">Change</v-btn>
      </template>
      <SimpleDialog
          v-model="deleteConfirm"
          title="Delete Address Record?"
          @click="deleteAddress"
          :loading="busy"
          yesText="Yes, delete"
          noText="No, cancel"
          yesColor="red"
          styledHeader
      >
        Are you sure you wish to delete address: <AddressText :data="address" />?
      </SimpleDialog>
    </ComplexDialog>
</template>
<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ExistingAddressDetailsForm from "../../pieces/Forms/ExistingAddressDetailsForm";
import ComplexDialog from "../templates/ComplexDialog";
import AddressText from '../../pieces/Address/AddressText';
import SimpleDialog from "../templates/SimpleDialog";
export default {
    name: 'UpdateAddressDialog',
    components: {SimpleDialog, ComplexDialog,ExistingAddressDetailsForm,AddressText},
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        busy: false,
        address: null,
        deleteConfirm: false,
        storedAddresses:[],
    }),
    props: {
      customerId: {
        type: Number,
        default: null
      },
      trunkId: {
        type: Number,
        default: null
      },
      typeSwitch:{
        type: String,
        default: 'customer',
      }
    },
    methods: {
      async changeAddress() {
        this.busy = true;
        let newAddressId = null;
        let deleteOnFail = false;
        if(!this.address.address_id && this.checkUnique(this.address)){
          //new address is entered and it does not already exist. So we add it.
          const responseNewAddress = await this.Api.send('post','customers/' + this.customerId + '/addresses',this.address, false);
          if (responseNewAddress.success) {
            newAddressId = responseNewAddress.data.id;
            deleteOnFail = true;
            await this.getAddresses(true);
          }else{
            this.busy = false;
            return;
          }
        }else if(!this.address.address_id) {
          newAddressId = this.getMatchingAddress(this.address).address_id;
        }else{
          newAddressId = this.address.address_id;
        }

        let responseChangeAddress = null;
        if(this.typeSwitch == 'customer') {
            responseChangeAddress = await this.Api.send('put', 'customers/' + this.customerId + '/addresses/' + newAddressId + '/setActive', {}, false);
        }else if(this.typeSwitch == 'trunk') {
            this.Api.setHttpObject({timeout:60000});
            /**
             * Note that we do not delete on fail for a trunk.
             * This is because when an address is changed on a trunk and the trunk has a DID that is linked to a NetSip Indial
             * we need to update the IPND (address record) linked to the indial.
             * This will sometimes fail to save the address to NetSip as netsip is strict in its address matching.
             * In such situations the address is SUCCESSFULLY APPLIED to the trunk and PortaOne but fails to update in netsip.
             * Hence with this type of failure we still want to address in the database as the trunk and PortaOne are updated.
             * Ideally this all should be handled on the API. Rather than the deleted on fail fired form the front end.
             *
             * However do not have time for this atm and not convinced the feature is worth the engineering.
             * So any failure to add an address to a PortaAccount the address will still exist in the users list.
             */
            deleteOnFail = false;
            responseChangeAddress = await this.Api.send('put', 'trunks/' + this.trunkId + '/address/' + newAddressId, {}, false);
            this.Api.setHttpObject({timeout:20000});
        }else{
          throw Error('Unrecognised typeSwitch ('+this.typeSwitch+').');
        }
        if (!responseChangeAddress.success && deleteOnFail) {
          //will fail silently.
          const responseDeleteAddress = await this.Api.send('delete','customers/' + this.customerId + '/addresses/'+newAddressId, {}, true);
          await this.getAddresses(true);
        }

        this.address = null;
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.busy = false;
        this.visible = false;
      },

      /**
       *
       * @param ignoreBusyToggle does not toggle the busy toggle.
       * This method is used within the changeAddress process and
       * this can toggle off the busy toggle mid process.
       * @returns {Promise<void>}
       */
      async getAddresses(ignoreBusyToggle = false) {
        if(!ignoreBusyToggle) {
          this.busy = true;
        }
        const response = await this.Api.send('get','customers/' + this.customerId + '/addresses');
        if (response.success) {
          this.storedAddresses = response.data;
        }
        if(!ignoreBusyToggle) {
          this.busy = false;
        }
      },

      checkUnique(checkAddress)
      {
        let matched = this.getMatchingAddress(checkAddress);
        if(matched == null){
          return true;
        }else{
          return false;
        }
      },

      getMatchingAddress(matchAddress)
      {
        //clean
        for (let prop in matchAddress) {
          if(matchAddress[prop] == "" || matchAddress[prop] == null){
            matchAddress[prop] = null;
          }
        }

        let mismatch = false;
        for(let i = 0; i<this.storedAddresses.length; i++) {
          mismatch = false;
          for (let prop in matchAddress) {
            if(matchAddress[prop] != this.storedAddresses[i][prop]){
              mismatch = true;
              break;
            }
          }
          if(mismatch == false){
            return this.storedAddresses[i];
          }
        }
        return null;
      },

      async checkIfCurrent(checkAddress)
      {
        const response = await this.Api.send('post','customers/' + this.customerId + '/addresses/checkIfCurrent', checkAddress, false);
        return response.data.checkIfCurrent;
      },

      async deleteAddress() {
        this.busy = true;
        if(this.address.address_id){
          /* NOTE this was added to prevent users delete the address record linked to a customer.
          but address can be link in multiple places so this has been disabled for now.

          if(await this.checkIfCurrent(this.address)){
            this.Api.throwError('Cannot delete user\'s currently save address.');
            this.busy = false;
            this.deleteConfirm = false;
            return;
          }*/
          const responseDeleteAddress = await this.Api.send('delete','customers/' + this.customerId + '/addresses/'+this.address.address_id, {}, false);
        }
        this.address = null;
        await this.getAddresses();
        this.busy = false;
        this.deleteConfirm = false;
        this.visible = false;
      },
    },
  watch: {
    customerId(value) {
      if(value != null){
        this.getAddresses();
      }
    }
  },
};
</script>
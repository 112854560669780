<template>
    <BasicCard title="Caller ID Settings" :loading="loading || !data || !data.service_features" :no-skeleton="false">
        <v-row dense class="text--primary">
            <v-col> Hide Caller ID </v-col>
            <v-col>
                {{
                    clir && clir.attributes
                        ? clir.attributes.find((e) => e.name === 'blocked')
                              .effective_values[0]
                        : 'Loading...'
                }}
            </v-col>
          </v-row>
      <v-row dense class="text--primary">
            <v-col> Overwrite Outbound CLI </v-col>
            <v-col>
              {{ cli ? (cli.flag_value == '^' ? 'N' : cli.effective_flag_value) : 'Loading...' }} <span class="default" v-if="cli && cli.flag_value == '^' && cli.effective_flag_value == 'Y'">(Customer Global: Y)</span>
            </v-col>
      </v-row>
      <v-row dense class="text--primary">
            <v-col> Outbound CLI </v-col>
            <v-col>
                {{
                    cli && cli.attributes
                        ? cli.attributes.find(
                              (e) => e.name === 'display_number'
                          ).effective_values[0]
                            ? cli.attributes.find(
                                  (e) => e.name === 'display_number'
                              ).effective_values[0]
                            : 'N/A'
                        : 'Loading...'
                }}
              <span class="default" v-if="cli && cli.flag_value == '^' && cli.effective_flag_value == 'Y'">(Customer Global: {{cli.attributes.find((e) => e.name === 'centrex').effective_values[0]}})</span>
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip text="Edit" icon="mdi-pencil" flat @click="showDialog=true" />
        </template>
      <CallerIDSettingsDialog v-model="showDialog" :data="data" />
    </BasicCard>
</template>

<script>
/**
 * Note that this card is designed to show the actual caller id setting for the trunk.
 * These can be overwritten by the customer default caller id settings.
 * Note that the only value that is useful to show if overwritten is if the Outbound CLI setting are specified.
 * If customer is set to 'Dynamic' then the trunk is also set to dynamic which is its default.
 * If the trunk is different than dynamic, and dynamic is the customer settings, then the trunk setting would overwrite the customer setting.
 * CURRENTLY the only situation we need to show the customer default caller id settings, is if the trunk is set to dynamic and the Outbound CLI values are specified for the customer defaults.
 *
 */
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CallerIDSettingsDialog from '@/components/dialogs/Trunk/CallerIDSettingsDialog';
export default {
    name: 'TrunkCallerIDCard',
    components: { CallerIDSettingsDialog, ButtonWithTooltip, BasicCard },
    data: () => ({
      showDialog: false,
    }),
    computed: {
        clir() {
            return this.data && this.data.service_features
                ? this.data.service_features.find((e) => e.name === 'clir')
                : null;
        },
        cli() {
            return this.data && this.data.service_features
                ? this.data.service_features.find((e) => e.name === 'cli')
                : null;
        },
    },
    props: {
        data: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style scoped>
.default{
  color:grey;
  font-size:smaller;
}
</style>

<template>
    <ComplexDialog
        title="Call Forwarding Settings"
        v-model="visible"
        :loading="busy"
    >
      <v-form ref="form" v-model="valid">
        <v-select outlined label="Answer Mode" :items="selectAnswerModes" v-model="form.answer_mode" />

        <v-text-field outlined label="Timeout" type="number" v-model="form.timeout" :rules="timeoutRules">
          <template v-slot:append>seconds</template>
        </v-text-field>

        <v-expand-transition>
          <div v-if="(form.answer_mode == 4 || form.answer_mode == 6) && data.account_type == 'RING-GROUP'">
            <v-text-field autofocus outlined label="Email Address to send voice mails" v-model="form.email" :rules="rules.email" />
          </div>
        </v-expand-transition>
      </v-form>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                :disabled="!valid || (!answer_mode && answer_mode!==0) || !timeout || (answer_mode===form.answer_mode && timeout===form.timeout && email==form.email)"
                :loading="busy"
                @click="update"
                text
            >Apply</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from "../../../mixins/trunkSettingsDataMixin";

export default {
    name: 'AnswerModeSettingsDialog',
    mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin],
    components: { ComplexDialog },
    data: () => ({
        valid: false,
        form: {
            answer_mode: null,
            timeout: null,
            email:null,
        },
        busy: false,
        timeoutRules: [
          (v) => !!v || 'Required',
          (v) => /^\d+$/i.test(v) || 'Enter a valid number',
          (v) => parseInt(v)<=120 || 'Maximum timeout is 120 seconds',
          (v) => parseInt(v) !== 0 || 'Cannot be zero',
        ],
        selectAnswerModes: [],
      rules: {
        required:[
          (v) => !!v || 'Required',
        ],
        email: [
          (v) => !!v || 'Required',
          (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
        ],
      },
    }),

    methods: {
        async update() {
            this.busy = true;
            const response = await this.Api.send('post', 'trunks/' + this.data.i_account + '/answer_mode', this.form);
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.visible = false;
            }
            this.busy = false;
        },
        genSelectAnswerModes( ){
          let rework = null;
          if(this.data.account_type == 'RING-GROUP'){
            rework = this.trunkSettingsData.answerModes.filter(mode => mode.text.indexOf("Ring") != 0);
            rework = rework.map(mode2 =>  {
              if(mode2.text == 'Forward Only'){
                mode2.text = 'Ring Only';
              }else if(mode2.text == 'Forward then voicemail'){
                mode2.text = 'Ring then voicemail'
              }
              return mode2;
            });
            this.selectAnswerModes = rework;
          }else {
            this.selectAnswerModes =  this.trunkSettingsData.answerModes;
          }
        }
    },
  computed: {
    accountType( ){
      if(this.data && this.data.account_type){
        return this.data.account_type;
      }else{
        return null;
      }
    },
    answer_mode() {
      return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
          ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').effective_flag_value)
          : null;
    },
    timeout() {
      return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
          ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').attributes.find((e) => e.name === 'timeout').effective_values[0])
          : null;
    },
    email( ){
      if(this.vmSettings == null) {
        return null;
      }else{
        return this.vmSettings.ext_email;
      }
    }
  },
  created(){
    this.genSelectAnswerModes( );
  },
  watch: {
    visible(val) {
      this.form.voice_mail_email = null;
      if (val) {
        this.form.answer_mode = this.answer_mode;
        this.form.timeout = this.timeout;
        this.form.email = this.email;
      } else {
        this.form.answer_mode = null;
        this.form.timeout = null;
      }
    },
    accountType(val){
      if(val != null){
        this.genSelectAnswerModes( );
      }
    }
  },
    props: {
        data: {
            type: Object,
            default: null
        },
        vmSettings: {
          type: Object,
          default: null
        }
    }
};
</script>
<template>
    <BasicCard title="Answer Mode" :loading="isLoading" :no-skeleton="!!data.service_features">
        <v-row dense class="text--primary">
            <v-col cols="6">Answer Mode </v-col>
            <v-col cols="6">
                {{answerModeDisplay}}
            </v-col>
            <v-col cols="6">Timeout </v-col>
            <v-col cols="6">
              {{
                  timeout ? timeout + ' second' + (timeout>1 ? 's' : '') : 'Loading...'
              }}
            </v-col>

          <v-col cols="6" v-if="(answer_mode == 4 || answer_mode == 6) && data.account_type == 'RING-GROUP'">Send Voice Mail to </v-col>
          <v-col cols="6" v-if="(answer_mode == 4 || answer_mode == 6) && data.account_type == 'RING-GROUP'">{{email}}</v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip text="Edit" icon="mdi-pencil" flat @click="showDialog=true" />
        </template>
      <AnswerModeSettingsDialog v-model="showDialog" :data="data" :vmSettings="vmSettings" :loading="isLoading" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import AnswerModeSettingsDialog from "../../dialogs/Trunk/AnswerModeSettingsDialog";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
export default {
    name: 'TrunkCallAnswerModeCard',
    components: { AnswerModeSettingsDialog, ButtonWithTooltip, BasicCard },
    mixins: [trunkSettingsDataMixin,refreshListenerMixin],
    data: () => ({
        busy: false,
        showDialog: false,
        vmSettings: null,
    }),
  methods: {
    async update( ){
      this.busy = true;
      const response = await this.Api.send('get', 'trunks/' + this.data.i_account + '/voice-mail-settings');
      this.vmSettings = response.data;
      this.busy = false;
    },
  },
    computed: {
        answer_mode() {
          return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
              ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').effective_flag_value)
              : null;
        },
        timeout() {
          return this.data && this.data.service_features && this.data.service_features.find((e) => e.name === 'default_action')
              ? parseInt(this.data.service_features.find((e) => e.name === 'default_action').attributes.find((e) => e.name === 'timeout').effective_values[0])
              : null;
        },
      email( ){
          if(this.vmSettings == null){
            return null;
          }else{
            return this.vmSettings.ext_email;
          }
      },
      isLoading( ){
        return this.loading || this.busy;
      },
      iAccount( ){
          if(this.data == null){
            return null;
          }else{
            return this.data.i_account;
          }
      },
        answerModeDisplay( ){
          if(this.answer_mode !== null){
            let value = this.trunkSettingsData.answerModes.find((e) => e.value === this.answer_mode);
            if(value != null){
              let text = this.trunkSettingsData.answerModes.find((e) => e.value === this.answer_mode).text;
              if(this.data.account_type == 'RING-GROUP'){
                text = text.replace('Forward','Ring');
              }
              return text;
            }else{
              return 'Unknown';
            }
          }else{
            return 'Loading...';
          }
        }
    },
  created( ){
      if(this.iAccount != null){
        this.update( );
      }
  },
  watch: {
    iAccount(value) {
      if(value != null){
        //for page refresh when already on tab
        this.update( );
      }
    },
  },
    props: {
        data: {
            type: Object,
            default: null,
        },
      loading: {
          type: Boolean,
        default: false
      },

    },
};
</script>

<style scoped></style>

<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">Call Queue</v-chip>

            <v-icon right x-large>mdi-clipboard-list-outline</v-icon>
        </template>
        <p class="title text--primary mt-1" v-if="callQueueData.i_c_queue">{{ callQueueData.group_id }}</p>

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Reference ID</span>
            </v-col>
            <v-col cols="7">
                {{ callQueueData.i_c_queue }}
            </v-col>
        </v-row>
        <template v-slot:actions>

            <ButtonWithTooltip
                text="Targeted Actions"
                flat
                tooltip="List actions that are pointing to this Call Queue"
                icon="mdi-format-list-bulleted"
                @click="showTargetListDialog = true"
            />
            <ButtonWithTooltip
                text="Delete"
                flat
                tooltip="Terminate Trunk"
                icon="mdi-delete"
                @click="showTerminateDialog = true"

                color="error"
            />
        </template>
              <TerminateCallQueueDialog
          v-model="showTerminateDialog"
          :callQueueData="callQueueData"
          />
      <CallQueueTargetedTransitionDialog :cqData="callQueueData" :loading="loading"  v-model="showTargetListDialog"/>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TerminateTrunkDialog from '../../dialogs/Trunk/TerminateTrunkDialog';
import TerminateCallQueueDialog from "../../dialogs/CallQueues/TerminateCallQueueDialog";
import CallQueueTargetedTransitionDialog from "../../dialogs/CallQueues/CallQueueTargetedTransitionsDialog";
export default {
    name: 'CallQueueCard',
    components: {CallQueueTargetedTransitionDialog, TerminateCallQueueDialog, ButtonWithTooltip, BasicCard },
  props: {
    callQueueData: {
      type: Object,
      required:true,
    },
    loading: {
      type: Boolean,
      default:false,
    },
  },
    data: () => ({
      showTargetListDialog:false,
      showTerminateDialog: false,
      showEditDialog: false,
    }),
};
</script>

<template>
    <v-row dense>
        <v-col cols="12" v-if="trunkData.i_account">
            <DIDListCard
                v-if="trunkData.bill_status !== 'C'"
                :trunkData="trunkData"
                :cust-id="trunkData.i_customer"
                :loading="trunkLoading"
                :filter="filter"
                @update="updateFilter"
                hide-status
                show-tools
                show-filters
            />
        </v-col>
      <FloatingButton tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" :loading="trunkLoading" />
      <AddDIDDialog :trunkData="trunkData" v-model="showNewDIDs" :customer-id="trunkData.i_customer" processTypeSwitch="trunk" :validOptions="addDidOptions"/>
    </v-row>
</template>

<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import trunkDataMixin from '../../mixins/trunkDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from '@/components/dialogs/DID/AddDIDDialog';
export default {
    name: 'TrunkDIDs',
    mixins: [filterMixin, trunkDataMixin],
    components: {
      AddDIDDialog,
      FloatingButton,
        DIDListCard,
    },
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        showNewDIDs: false,
        addDidOptions: {"admin":[1,2,3],"partner":[1]}
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    }
};
</script>

<style scoped></style>

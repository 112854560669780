<template>
  <v-text-field
      v-model="cli"
      :rules="rules"
      :dense="dense"
      :outlined="outlined"
      :hide-details="hideDetails"
      :autofocus="autofocus"
      :label="label"
  />
</template>
<script>
const CLIRegex = /^(?:61[2,3,4,7,8]\d{6}(?:\d{2}|\d0-9|00-99)|(?:64)[1-9]\d{7})$/;
export default {
  name: 'CallerIDField',
  data: () => ({
    cli: null,
    valid: false,
    rules: [
      v => !/^(?:61)?(?:13|18).*/.test(v) || "Cannot use 13/1300/1800 number as outbound CLI",
      v => CLIRegex.test(v) || "CLI is invalid/incomplete"
    ]  }),
  watch: {
    value(val) {
      if (!!val && val!==this.cli) this.cli = val;
    },
    cli(val) {
      // check if user has entered '0' and replace with '61', otherwise
      if (val==='0') {
        this.cli = '61';
      } else {
        if (val!==this.value) this.$emit('input', CLIRegex.test(val) ? val : null);
      }
    }
  },
  props: {
    value: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Caller ID',
    },
    outlined: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },

  },
  created() {
    if (this.value) this.cli = this.value;
  }
};
</script>
<template>
  <div>

    <v-row no-gutters>
      <v-col
          v-for="(item, key) in ringGroupPlanData.types"
          :key="key" :cols="$vuetify.breakpoint.xsOnly ? 12 : undefined"
      >
        <v-hover
            v-slot="{ hover }"
        >
          <v-card
              v-ripple
              outlined
              :elevation="subProduct===item.value ? 4 : hover ? 2 : 0"
              :color="subProduct===item.value ? highlightColor : hover ? 'background_accent' : undefined"
              :height="($vuetify.breakpoint.xsOnly ? 90 : 150) + (subProduct===item.value ? 10 : 0)"
              class="px-2 text-center"
              :class="subProduct===item.value ? 'ma-2 py-5' : 'ma-3 py-4'"
              @click="changeSubProduct(item.value)"
          >
            <v-icon v-html="item.icon" color="secondary" size="48" :class="$vuetify.breakpoint.xsOnly ? 'float-left ma-2' : 'mb-2'" />
            <h3 class="mb-0">{{item.name}}</h3>
            <p class="text--disabled subtitle-2">{{item.description}}</p>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import PlanSelectSlider from '../Forms/PlanSelectSlider';
import planDataMixin from '../../../mixins/planDataMixin';
export default {
  name: 'RingGroupPlanForm',
  mixins: [planDataMixin],
  components: { },
  data: () => ({

    subProduct: null,
    productOption: null,

    originalSubProduct: null,
    originalProductOption: null,
  }),
  computed: {
    highlightColor() {
      return 'accent';
    },
    dirtyAndValid() {
      return (this.subProduct != this.originalSubProduct) && (this.subProduct != null);
    }
  },
  watch: {
    productOption(value) {
      this.update();
    }
  },
  methods: {
    changeProductOption(value) {
      this.productOption = value;
    },
    changeSubProduct(value) {
      this.subProduct = value;
    },
    update() {
      if (this.dirtyAndValid) {
        this.$emit('input', {
          subProduct: this.subProduct,
          productOption: this.productOption
        });
      } else {
        this.$emit('input', null);
      }

    }
  },
  filters: {
    thousandSeparator: function(value) {
      if (!value) value = 0;
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  created() {

    if(this.planDetails.planTag) {
      this.originalSubProduct = this.planDetails.planTag;
      this.subProduct = this.planDetails.planTag;
    }else{
      if(!this.newProduct) {
        this.originalSubProduct = 'no-plan';
      }
      this.subProduct = 'no-plan';
      if(this.newProduct){
        //watch is not triggered in created
        this.update( );
      }
    }

    /*
    We need to build the watcher here as the watcher fires after the created method and clears the productOption value.
    Using the beforeCreate method does not work as it cannot access planDetails. Not sure why but I could not get it working and found this solution.
     */
    this.$watch('subProduct', () => {
      this.update(); //clear the submitted values. Needs a product option
      this.productOption = null;
    });

    /*
    We run an update here as if this is a new product we have set the plan to no-plan which is an option the user can select.
    So we need to run update to push the value to the parent so that the default select item is selectable.
    IE if we did not run update here the parent model would have a value of null still and the user could not be able to click next.
     */
    this.update();
  },
  props: {
    planDetails: {
      type: Object,
      default: function() { return {}; },
    },
    newProduct: {
      type: Boolean,
      default:false
    }
  }
};
</script>
<template>
    <ComplexDialog
        title="Customer NBN Products"
        v-model="visible"
        :loading="busy"
        :max-width="($store.state.user.isAdmin) ? 1100 : 600"
    >
      <v-simple-table v-if="nbnExtraSubscriptions!=null">
        <thead>
        <tr>
          <th>Name</th>
          <th>Monthly Fee</th>
          <th>Activation Date</th>
          <th v-if="$store.state.user.isAdmin">i subscription</th>
          <th v-if="$store.state.user.isAdmin">i customer subscription</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in nbnExtraSubscriptions"
            v-bind:key="item.order_id"
            dense
        >
          <td>{{item.name}}</td>
          <td><Currency :value="item.effective_fee" addGst/></td>
          <td>
            <span v-if="item.activation_date == null">Pending</span>
            <span v-else>{{item.activation_date}}</span>
          </td>
          <td v-if="$store.state.user.isAdmin">{{ item.i_subscription }}</td>
          <td v-if="$store.state.user.isAdmin">{{ item.i_customer_subscription }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from "../templates/ComplexDialog";
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import Currency from "../../pieces/Currency";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'CustomerNBNExtraSubscriptionListDialog',
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    components: {Currency, ComplexDialog},
    data: () => ({
      busy: false,
      isValid:null,
      showLinkOrderDialog:false,
      linkingSubscription:null,
      form: {
        iDialRule:null
      },
      rules: {
        required: [
          (v) => !!v || 'Required',
        ]
      },
      dialRuleSelectOptions:[],
    }),
  watch: {
    visible(val) {
      if (val) {
        if (this.customerData) {
          this.form.cli = this.cli;
          this.form.override_cli = this.override_cli;
          this.cid_type = this.form.override_cli==='Y' ? 2 : 1;
        }
      } else {
        this.form.cli = null;
        this.form.override_cli = null;
      }
    },
  },
    props: {
      customerData: {
          type: Object,
          default: null
      },
      nbnExtraSubscriptions:{
        type: Array,
        default:null
      }
    }
};
</script>